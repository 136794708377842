// import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
// import { baseUrl } from "../../config";

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";



export const orderApiSlice = apiSlice.injectEndpoints({
    tagTypes: [],
    endpoints: builder => ({

        getListOfUnit: builder.query({
            query: () => ({
                url: '/Order/GetListofUnit',
                method: 'GET',
                keepUnusedDataFor: 0,
                // providesTags: [],
            }),
        }),

    })
})
export const { useGetListOfUnitQuery } = apiSlice;



const initialState = []
const unitSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {},

})


// export const unitList  = (state) => state.unit;

export const { } = unitSlice.actions

export default unitSlice.reducer