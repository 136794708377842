import { apiSlice } from "../../app/services/authServices";

export const pickUpApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConsignor: builder.query({
            query: () => ({
                url: `Order/GetOrderPickupDropInfoList`,
                method: 'GET',
                keepUnusedDataFor: 200,
            }),
        }),
        getPickUpInfo: builder.query({
            query: (consignorId) => ({
                url: `/Order/GetOrderPickupDropInfoDetailsByID?consignorId=${consignorId}`,
                method: 'GET',
                keepUnusedDataFor: 200,
            }),
        }),
        getDropInfo: builder.query({
            query: (consignorId) => ({
                url: `/Order/GetOrderPickupDropInfoDetailsByID?consignorId=${consignorId}`,
                method: 'GET',
                keepUnusedDataFor: 200,
            }),
        }),

    })
})
export const { useGetConsignorQuery, useLazyGetPickUpInfoQuery , useLazyGetDropInfoQuery } = apiSlice;




