import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";


export const vehicleSlice = apiSlice.injectEndpoints({
    tagTypes:['Vehicle'],
    endpoints:builder => ({
      getVehicle: builder.query({
        query: () => ({
          url: 'Customer/GetListOfVehicleType',
          method: 'GET',
          providesTags: ['Vehicle'],
        }),
      }),
      updateVehicleData: builder.mutation({
        query(data) {
            const {rateCardVehicleID, ...body} = data
            return {
                url: `Customer/EditVehicleSurchargeSection?rateCardVehicleID=${rateCardVehicleID}`,
              method: "POST",
              body
            };
          },
      }),
      deleteVehicleData: builder.mutation({
        query : (rateCardVehicleID) => ({
          url: `Customer/DeleteVehicleSurchargeSection?RateCardVehicleID=${rateCardVehicleID}`,
          method: "POST",
      }),
      }),

      addVehicleSurchargeAfterEdit: builder.mutation({
        query : (body) => ({
          url: `Customer/AddCustomerVehicleSurchargeAfterEdit`,
          method: "POST",
          body,
      }),
      }),
   
    }),
})

export const { useGetVehicleQuery, useUpdateVehicleDataMutation , useDeleteVehicleDataMutation ,useAddVehicleSurchargeAfterEditMutation } = apiSlice

const initialState = {
    list: []
};

const vehicleDetailSlice = createSlice({
    name: "vehicleDetails",
    initialState,
    reducers: {
        addVehicle: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteVehicle: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editVehicle : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
           
            return { ...state, list: currentLists };
        },
        resetVehicle: () => initialState,
    },
})

export const { addVehicle, editVehicle, deleteVehicle, resetVehicle } = vehicleDetailSlice.actions

export default vehicleDetailSlice.reducer