import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";


export const fullLoadsSlice = apiSlice.injectEndpoints({
    endpoints:builder => ({
      editFullFuelEffective: builder.mutation({
      query(data) {
        const {fuelsurchargerateid, ...body} = data
        return {
          url: `Customer/EditFuelEffectivelSection?fuelsurchargerateid=${fuelsurchargerateid}`,
          method: "POST",
          body
        };
      },
      }),
      deleteFullFuel: builder.mutation({
        query : (fuelsurchargerateid) => ({
              url: `/Customer/DeleteFuelEffectiveDateSectionLTLFTLHTL?fuelsurchargerateid=${fuelsurchargerateid}`,
              method: "POST",
          }),
      }), 
      addCustomerFuelFtl: builder.mutation({
        query(body) {
          return {
            url: `Customer/AddCustomerAddFuelLTLHTLFTLafterEdit`,
            method: "POST",
            body
          };
        },
        }),
   
    }),
})

export const { useEditFullFuelEffectiveMutation, useAddCustomerFuelFtlMutation,useDeleteFullFuelMutation} = apiSlice

const initialState = {
    list: []
};

const fullLoadSlice = createSlice({
    name: "fullLoad",
    initialState,
    reducers: {
        addFullLoad: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteFullLoad: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editFullLoad : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            return { ...state, list: currentLists };
        },
        editFullLoadAfter: (state, action) => {
          const currentLists = Array.from(state.list);
          const filterData = state.list.findIndex((list) => list.list.fuelsurchargerateid === action.payload.id);
          currentLists[filterData] = {
              id: action.payload.id,
              list: action.payload
          }
          return { ...state, list: currentLists };
        },
        resetFullLoad: () => initialState,
    },
})

export const { addFullLoad, editFullLoad, deleteFullLoad,editFullLoadAfter, resetFullLoad} = fullLoadSlice.actions

export default fullLoadSlice.reducer