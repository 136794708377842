import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logout } from '../../features/auth/authSlice'
import { baseUrl } from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}`,
  withCredentials: true,
  prepareHeaders: (headers, { getState }) => {
    const state = getState()
    if (localStorage.getItem('token')) {
      // include token in req header
      headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
      return headers
    }
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery({ url: '/Account/RefreshAccessToken', method: 'POST' }, api, extraOptions)
    if (refreshResult?.data?.data) {
      const user = api.getstate().auth.user
      api.dispatch(setCredentials({ token: refreshResult.data }))
      result = await baseQuery(args, api, extraOptions)
    }
    else {
      api.dispatch(logout())
      window.location.reload(false);
    }
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({})
})

