import Wrapper from "../Wrapper";
import CustomerList from "./CustomerList";
import { CreateCustomer } from "../../context/CustomerContext";

 const Customer = ()=>{
     return (
         <>
         <CreateCustomer>
         <Wrapper>
        <CustomerList></CustomerList>
         </Wrapper>
         </CreateCustomer>
         </>
     );
 }
 export default Customer;