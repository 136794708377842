import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { ArrowDropDown } from '@material-ui/icons';
import { useLocation } from "react-router-dom"
const MenuItem = ({ menuItem }) => {
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();
    if (menuItem?.subMenus) {
        let newPathName = "/" + pathname?.split("/")[1]
        let result;
        result = menuItem?.subMenus.every(element => element === false);

        return (

            <>
                {result === false ?
                    <div className={open && "sidebar-item open" || newPathName === menuItem?.to && "sidebar-item open" || "sidebar-item"}>
                        <div className="sidebar-title"
                            onClick={() => setOpen(!open)}
                        >
                            <div className="menu-icon">
                                <div>{menuItem.image}</div>
                            </div>
                            <span>{menuItem.name}</span>
                            <div className="toggle-btn"><ArrowDropDown /></div>
                        </div>
                        <div className="sidebar-content">
                            {menuItem.subMenus.map((menu, index) => <MenuItem key={index} menuItem={menu} />)}
                        </div>
                    </div>

                    : ""}
            </>
        )
    } else {
        return (
            <>
                {/* <>{menuItem !== false ? <p>dfdfd</p>: <p>1222dfdfdf</p>}</> */}
                {menuItem !== false ?
                    <NavLink to={menuItem?.to || "#"} className="sidebar-item plain">
                        <div className="single-menu">
                            <div className="menu-icon">
                                <div>{menuItem?.image}</div>
                            </div>
                            <span>{menuItem?.name}</span>
                        </div>
                    </NavLink>
                    : ""
                }
            </>
        )
    }
}

export default MenuItem;
