import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";


const AccountTable = () => {

    const columns = [
     
        { field: "Account", headerName: "Account", width: 90 },
        { field: "Company_Name", headerName: "Company_Name", width: 100 },
        { field: "Code", headerName: "Code", width: 80 },
        { field: "Date", headerName: "Date", width: 90 },
        { field: "Balance", headerName: "Balance", width: 90 },
        { field: "Over", headerName: "Over", width: 90 }
    ];
    const rows = [
        { id: 1, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "90" },
        { id: 2, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 3, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "90" },
        { id: 4, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 5, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "90" },
        { id: 6, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 7, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "90" },
        { id: 8, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 9, Account: 'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 10, Account:'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 11, Account:'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
        { id: 12, Account:'LVM123', Company_Name: 'LVMTech', Code: "325", Date: "10/09/2023", Balance: "2012.0", Over: "124" },
    ];
    return (
        <>
                <div className="account-table">
                <div className="search-form">
            <form gap="3" className="ml-auto">
            <input placeholder="Search" aria-label="Search" type="search" className="me-2 form-control" style={{width:"100%"}}/>
                <span><i className="fa fa-search" aria-hidden="true"></i></span>
                </form>
                </div>
                    {/* <h6>Inventory list by Account</h6> */}
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} />
                </div>
                </>
        )
}
export default AccountTable;