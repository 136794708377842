import { apiSlice } from "../../app/services/authServices";
import { pricingUrl } from "../../config";

export const orderSlice = apiSlice.injectEndpoints({
    tagTypes:['Order'],
    endpoints:builder => ({
        sendEmail: builder.mutation({
          query: (body) => ({
            url: 'Order/SendEmails',
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Order']
        }),
        getQuoteListByID: builder.query({
          query: (quoteNo) => ({
            url: `Order/GetListofDataforQuoteNumber?QuoteNo=${quoteNo}`,
            method: 'GET',
            providesTags: ['Order'],
          }),
        }),
        getVehicleById: builder.query({
          query: (account_No) => ({
            url: `Order/GetListOfVehicleTypes?AccountNo=${account_No}`,
            method: 'GET',
            providesTags: ['order'],
          }),
        }),
        getAccessorialByVehicleType: builder.query({
          query: ({AccountNo,VehicleType}) => ({
            url: `Order/GetListOfAccessorialByCustomerVehicleType?AccountNo=${AccountNo}&VehicleTypeID=${VehicleType}`,
            method:'GET',
            //params: service
          }),
        }),
        getServiceByVehicleType: builder.query({
          query: ({AccountNo,VehicleType}) => ({
            url: `Order/GetListOfService?AccountNo=${AccountNo}&VehicleTypeID=${VehicleType}`,
            method:'GET',
            //params: service
          }),
        }),
        getListOfAccessorialByCustomer: builder.query({
          query: (AccountNo) => ({
            url: `Order/GetListOfAccessorialByCustomer?AccountNo=${AccountNo}`,
            method: 'GET',
            providesTags: ['order'],
          }),
        }),

        // getServiceByVehicleType: builder.mutation({
        //   query: ( service = { account_No: 'account_No', vehicleType: 'vehicleType' } ) => ({
        //       url: 'Order/GetListOfService',
        //       method:'GET',
        //       params: service
        //   }),
        // }),

        getPricing:builder.query({
          query: (body) => ({
            url:`${pricingUrl}`,
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Order']
        }),
        getGoogleMapsAPIKey: builder.query({
          query: () => ({
            url: 'Order/GetKey',
            method: 'GET',
          }),
        }),
        updateOrderNotification: builder.mutation({
          query: (body) => ({
            url: 'Order/UpdateOrderNotification',
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Order']
        }),
      }),
})

export const { useSendEmailMutation, useLazyGetQuoteListByIDQuery , useLazyGetPricingQuery, useLazyGetVehicleByIdQuery, useLazyGetServiceByVehicleTypeQuery ,
   useLazyGetAccessorialByVehicleTypeQuery, useGetGoogleMapsAPIKeyQuery, useLazyGetListOfAccessorialByCustomerQuery, useUpdateOrderNotificationMutation } = apiSlice;
