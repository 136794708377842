export default function validate(values) {

   const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let errors = {};
    if (!values.username) {
      errors.username = 'Username is required';
    }
     else if (!/^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g.test(values.username)) {
      errors.username = 'Username is invalid';
    }
    else if (values.username.length > 16){
      errors.username = 'Username should not be greater than 16 character';
    }
    if (!values.emailid) {
      errors.emailid = 'Email address is required';
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.emailid)) {
      errors.emailid = 'Email address is invalid';
    }
    if(!reg.test(values.password)){
      errors.password = 'Password should contain at least one Digit,one Special Character and one Uppercase';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8 ) {
      errors.password = 'Password strength is Low';
    }
    else if (values.password.length > 16 ) {
      errors.password = 'Password should not be greater than 16 character';
    }
    if (!values.password2) {
      errors.password2 = 'Password is required';
    } else if (values.password2 !== values.password) {
      errors.password2 = 'Passwords do not match';
    }
    return errors;
  };