import { useRef, useEffect } from "react";
import useOrder from '../../../context/OrderContext';

const LocationAutoComplete = (props) => {
 const autoCompleteRef = useRef();
 const inputRef = useRef();
 const { scriptLoaded } = useOrder();

 const getField = (fieldName, address) => {return address.address_components.find((field) => { return field.types.includes(fieldName)})}

 const parseAddress = (address) => {
  const streetNum = getField("street_number", address).long_name
  const streetName = getField("route", address).long_name
  const unit = getField("subpremise", address)?.long_name || "";
  const city = getField("locality", address).long_name
  const province = getField("administrative_area_level_1", address).short_name
  const country = getField("country", address).short_name
  const postalCode = getField("postal_code", address).long_name
  props.setAutocomplete({streetNum: streetNum, streetName: streetName, unit: unit, postalCode: postalCode, city: city, province: province, country: country === "CA"? "CAN": "USA"})
 }

 const options = {
  componentRestrictions: { country: "ca" },
  fields: ["address_components", "geometry", "icon", "name"],
  types: []
 };
 useEffect(() => {
  if (!scriptLoaded) return;
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
   inputRef.current,
   options
  );
  autoCompleteRef.current.addListener("place_changed", async function () {
    const place = await autoCompleteRef.current.getPlace();
    parseAddress(place);
   });
  }, [scriptLoaded]);

 return (
  <div>
   <label>Enter address: </label>
   <input ref={inputRef} />
  </div>
 );
};
export default LocationAutoComplete;