import { Edit } from "@material-ui/icons";


const DisableEditClick = ({onDisableClick}) => {
    return(
    <>
    <div onClick={() => { onDisableClick()}} >
    <Edit/>
   </div>
   </>
    )
}
export default DisableEditClick