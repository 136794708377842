import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { assignUserRole, toggleShow } from "../../../../features/admin/role/createRoleSlice";
import { useGetRolesQuery, useAddUserRolesMutation, useGetRegisterUserQuery } from "../../../../features/admin/role/createRoleSlice";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../../Spinner"


const AddUserRole = () => {
    const { data: role, } = useGetRolesQuery({}, { refetchOnMountOrArgChange: true })
    const { data, refetch } = useGetRegisterUserQuery()
    const userRegister = data?.data
    const [email, setEmail] = useState('')
    const [addUserRoles, {isLoading}] = useAddUserRolesMutation()
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const show = useSelector((state) => state.createRole.show)
    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState({})
    const toggleHandler = () => { dispatch(toggleShow()) }
    let subscribe = watch();

    const onSubmit = async (userRole) => {
        setUserRole(userRole)
        await addUserRoles(userRole)
        reset({ role: "", email: "", password: "", });
        refetch();
        if (data.statusCode === 200) {
            setTimeout(function () {
                toast.success("User register successfully !", {
                    position: "top-center",
                })
            }, 1000);
        }
    }

    
    useEffect(() => {
        if (subscribe?.email) {
            setEmail(findEmail(subscribe?.email))
        }
    }, [subscribe?.email])

    const findEmail = (email) => {
        let data = [...userRegister];
        let filterData = data?.find((data) => data.email == email?.toLowerCase()) || false
        return filterData
    }


    return (
        <>
            <div className="role-assignment mb-3">
                <div className="role-assignment-name">
                    <h5>User Role Management</h5>
                </div>
                <div className="add-role-button d-flex">
                    <span><Button variant="outline-primary" onClick={toggleHandler}> <AddCircle /> Add User Role</Button></span>
                </div>
            </div>
            {/* Add user profile form */}
            <>
                {show ?
                    <fieldset className="user-role-form">
                        <label className="label-heading">Add User Profile</label>
                        <div className="add-user-profile">
                            <Row>
                                <Col>
                                    <Form.Group controlId="Roletype">
                                        <FloatingLabel controlId="floatingSelectGrid" label="Role">
                                            <Form.Select aria-label="Floating label select example" name="role"
                                                {...register('Role', { required: 'Field is required', })}>
                                                <option value="">Select</option>
                                                {
                                                    role?.data?.map((data, index) => (
                                                        <option key={index} >{data}</option>
                                                    ))

                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                        {errors.Role?.message && (<p className="error-message">{" * " + errors.Role?.message}</p>)}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div className="form-floating">
                                        <Form.Control type="email" placeholder="Email" name="email" {...register('email', { required: 'Field is required', pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "Please enter a valid email" } })} />
                                        <label htmlFor="Email">Email</label>
                                        {errors.email?.message && (<p className="error-message">{" * " + errors.email?.message}</p>)}
                                        {email && <p className="error-message">* This Email is already registered</p>}
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-floating">
                                        <Form.Control type="password" placeholder="password" name="password" {...register('password', { required: 'Field is required', pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, message: "Password should contain at least one Digit,one Special Character and one Uppercase" } })} />
                                        <label htmlFor="Password">Password</label>
                                        {errors.password?.message && (<p className="error-message">{" * " + errors.password?.message}</p>)}
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    {isLoading ?
                                        <div className="section_action_btn">
                                            <div className="add_btn">
                                                <Button> <AddCircle /> <Spinner /></Button>
                                            </div>
                                        </div>
                                        :
                                        <div className="section_action_btn">
                                            <div className="add_btn" onClick={handleSubmit(onSubmit)}>
                                                <Button> <AddCircle /> Add User</Button>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </fieldset>
                    : null}
            </>
        </>
    )
}
export default AddUserRole