import React from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const LoadingPage = () => {
  return (
    <Col sm={8}>
      <div className="">
        <fieldset className='createNewTrip mt-3'>
          <h6>Create New Trip</h6>
        </fieldset>
        <fieldset>
          <div className="create-form-container">
            <div className="sample">

              <FloatingLabel controlId="floatingSelectGrid" label="Trip">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>

            <div className="sample" style={{ display: "flex" }}>
              <Form.Control type="text" placeholder=" Date" style={{ width: "100px" }} name="date" />
              <FloatingLabel controlId="floatingSelectGrid" label="Destination">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>

            <div className="sample">

              <FloatingLabel controlId="floatingSelectGrid" label="Line-Hauler">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">

              <FloatingLabel controlId="floatingSelectGrid" label="Second driver (team)">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">

              <FloatingLabel controlId="floatingSelectGrid" label="Unit#">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Vehicle">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Plate">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">

              <FloatingLabel controlId="floatingSelectGrid" label="State">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">

              <FloatingLabel controlId="floatingSelectGrid" label="Port">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>

            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Phone Number">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>
            </div>

            {/* <div className="sample">  
       <FloatingLabel controlId="floatingSelectGrid" label="Phone Number">
         <Form.Control type="text" placeholder="Created date" name="Createddate" />
       </FloatingLabel>
   </div> */}

            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Skid">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>
            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Wgt">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>
            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Feet">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>
            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Stops">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>
            </div>
            <div className="sample">
              <FloatingLabel controlId="floatingSelectGrid" label="Needs">
                <Form.Control type="text" placeholder="Created date" name="Createddate" />
              </FloatingLabel>
            </div>
          </div>

          <fieldset className='mt-4'>
            <label htmlFor="" className='label-heading '>Trailer Info</label>
            <div className="create-form-container">
              <div className="sample">
                <FloatingLabel controlId="floatingSelectGrid" label="Trailer Number">
                  <Form.Control type="text" placeholder="Created date" name="Createddate" />
                </FloatingLabel>
              </div>
              <div className="sample">
                <FloatingLabel controlId="floatingSelectGrid" label="Trailer Plate">
                  <Form.Control type="text" placeholder="Created date" name="Createddate" />
                </FloatingLabel>
              </div>
              <div className="sample">
                <FloatingLabel controlId="floatingSelectGrid" label="Seal No.">
                  <Form.Control type="text" placeholder="Created date" name="Createddate" />
                </FloatingLabel>
              </div>
              <div className="sample">
                <FloatingLabel controlId="floatingSelectGrid" label="Switch Trailer">
                  <Form.Control type="text" placeholder="Created date" name="Createddate" />
                </FloatingLabel>
              </div>
              <div className="sample">
                <FloatingLabel controlId="floatingSelectGrid" label="Trailer Plate">
                  <Form.Control type="text" placeholder="Created date" name="Createddate" />
                </FloatingLabel>
              </div>
            </div>
          </fieldset>
          <Row className='mt-4'>
            <Col sm={4}>
              <fieldset>
                <label htmlFor="" className='label-heading '>Departure time</label>
                <div className="form-floating">
                  <Form.Control type="date" autoComplete="off" placeholder="" name="" />
                  <label htmlFor=""></label>
                </div>
              </fieldset>
            </Col>
            <Col sm={8}>
              <fieldset>
                <label htmlFor="" className='label-heading '>Estimated X border time</label>
                <div className="d-flex estimated-border-field">
                  <div className="form-floating">
                    <Form.Control type="date" autoComplete="off" placeholder="" name="" />
                    <label htmlFor=""></label>
                  </div>
                  <div className="form-floating">
                    <Form.Control type="date" autoComplete="off" placeholder="" name="" />
                    <label htmlFor=""></label>
                  </div>
                </div>
              </fieldset>
            </Col>
          </Row>


        </fieldset>
      </div>
    </Col>
  )
}

export default LoadingPage
