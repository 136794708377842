import React from 'react'
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AddCircle, Edit } from '@material-ui/icons';
import { useGetVehicleQuery } from '../../../features/vehicle/vehicleDetailSlice';
import { useAddAssetDataMutation, useAddAssetTypeWithFieldsMutation, useGetAllAssetFieldsQuery, useGetAssetTypeListQuery } from '../../../features/vehicle/Assets/AssetsFieldSlice';
import { useEffect } from 'react';
import Spinner from '../../Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCustomData } from '../../Sidebar/Permission';
import { useLazyGetAssetsTypeByIDQuery, useUpdateAssetsTypeDataMutation } from '../../../features/vehicle/Assets/AssetsFieldSlice';

const AssetsType = () => {
    const [permission] = useCustomData()
    const { register, setValue, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const [list, setList] = useState([])
    const [checkedValue, setCheckedValue] = useState([]);
    const [inputText, setInputText] = useState('');
    const [AssetsField, setAssetField] = useState('');
    const [show, setShow] = useState(false);
    const [disable, setDisable] = useState(false)
    const [loading, setLoading] = useState(false);
    const [checkedData, setCheckedData] = useState({})
    const [newCheck, setNewCheck] = useState({})
    const [editField, setEditField] = useState('')
    const [addAssetData] = useAddAssetDataMutation();
    const { data: getAllAssetFields, refetch: getData } = useGetAllAssetFieldsQuery();
    const [addAssetTypeWithFields, result] = useAddAssetTypeWithFieldsMutation()
    const { data: table, isLoading, refetch } = useGetAssetTypeListQuery();
    const tableList = table?.data;
    const [setRowId, getAssetTypeData] = useLazyGetAssetsTypeByIDQuery()
    const [updateAssetsType] = useUpdateAssetsTypeDataMutation();
    useEffect(() => {
        setLoading(true)
        setAssetField(getAllAssetFields?.data)
        setLoading(false)
    }, [getAllAssetFields, list])

    const addList = async (inputText) => {
        try {
            if (!inputText) {
                return
            }
            setLoading(true)
            let apiData = {
                "assetFieldName": inputText
            }
            setList([...list, inputText])
            await addAssetData(apiData)
            setLoading(false)
            getData()
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }

    const checkHandler = (e, listItem) => {
        try {
            if (e.target.checked) {
                setCheckedData((p) => ({ ...p, [e.target.name]: true }))
                setCheckedValue((p) => ([...p, { assetFieldId: listItem?.assetFieldId }]))
            }

            else {
                setCheckedData((p) => ({ ...p, [e.target.name]: false }))
                let filterData = checkedValue.filter((data) => data?.assetFieldId !== listItem?.assetFieldId);
                setCheckedValue(filterData)
            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }
    useEffect(() => {
        setLoading(true)
        setCheckedData({})
        getAssetTypeData?.data?.data?.fieldDetails?.length > 0 && getAssetTypeData?.data?.data?.fieldDetails?.map((d) => {
            if (d?.fieldName === null) return;
            setNewCheck((p) => ({ ...p, [d?.assetFieldName]: d?.assetTypeId }))
            setCheckedData((p) => ({ ...p, [d?.assetFieldName]: true }))
            const updateD = getAssetTypeData?.data?.data?.fieldDetails?.map(d => ({ assetFieldId: d.assetFieldId }))
            setCheckedValue(updateD)
        })
        setLoading(false)
    }, [getAssetTypeData])
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            let arr2 = []
            for (let key1 in checkedData) {
                if (checkedData[key1]) {
                    arr2.push({ [key1]: newCheck[key1] })
                }
            }
            const newData = {
                "assetTypeDescription": data?.asset_type,
                "fieldDetails": checkedValue
            }
            const submitData = await addAssetTypeWithFields(newData)
            setLoading(false)
            refetch()
            setCheckedData({})
            reset({ asset_type: "", checkedValue: "" });
            if (submitData?.data?.statusCode == 200) {
                toast.success("New Asset Added!", {
                    position: "top-center",
                })

            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }
    const update = async () => {
        try {
            let arr2 = [];
            setLoading(true)
            const updateData = {
                "assetTypeId": getAssetTypeData?.data?.data?.assetTypeId,
                "assetTypeDescription": getAssetTypeData?.data?.data?.assetTypeDescription,
                "fieldDetails": checkedValue
            }

            const updatedData = await updateAssetsType(updateData)
            await refetch()
            getData()
            setCheckedData({})
            reset({ asset_type: "", checkedValue: "" });
            setShow(false)
            setLoading(false)

            if (updatedData?.data?.statusCode == 200) {
                toast.success("Asset updated Succesfully!", {
                    position: "top-center",
                })
                setDisable(false)
            }

        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }

    if (!tableList || isLoading) return <><Spinner /></>;
    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (e) => {
                    e.stopPropagation();
                    setRowId(data?.id)
                    setValue("asset_type", data?.row?.Asset_Type)
                    setDisable(true)
                    setShow(true)
                };
                return (

                    <div className="action_btn">
                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                            <span className="edit_btn" onClick={onClick}><Edit /></span>
                            : <span className="edit_btn disable"><Edit /></span>
                        }
                    </div>


                )

            },
        },
        { field: "Asset_Type", headerName: "Asset Type", width: 120, },
    ];

    const rows = tableList && tableList?.map((data) => {
        return {
            id: data?.assetTypeId,
            Asset_Type: data?.assetTypeDescription
        }
    })
    return (
        <div>

            {loading && <Spinner />}
            <Row>
                <Col>
                    <div className="orderform mt-2">
                        <Row>
                            <Col sm={5}>
                                <Row>
                                    <Col sm={5}>
                                        <div className="form-floating">
                                            <Form.Control type="text" autoComplete="off" placeholder="Add a Asset Type" name="asset_type"  {...register('asset_type',
                                                { required: 'Field is required', pattern: /^[^-\s][a-zA-Z0-9_\s-]+$/ })} disabled={disable} />
                                            <label htmlFor="Item">Add a Asset Type</label>
                                            <p className="error-message">{errors?.asset_type?.message}</p>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                            <Col sm={5}></Col>
                            <Col sm={2}>
                                {!show ?
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                            <Button onClick={handleSubmit(onSubmit)} className='save-btn'> Submit</Button>
                                            : <Button className='save-btn disable'> Submit</Button>
                                        }
                                    </>
                                    :
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                            <Button onClick={handleSubmit(update)} className='save-btn'>Update</Button>
                                            : <Button className='save-btn disable'> Update</Button>
                                        }
                                    </>
                                }

                            </Col>
                        </Row>

                        <fieldset className="mb-3 mt-4">
                            <label className="label-heading">Select the field you would like to add for the item descriptions</label>
                            {/* Add certifcation sections */}
                            <Row style={{ marginTop: "-20px" }}>
                                <Col sm={9}></Col>
                                <Col sm={3}>
                                    <Row>
                                        <Col sm={8}>
                                            <div className="form-floating">
                                                <Form.Control type="text" autoComplete="off" placeholder="Add more field" name="Addmorefield"
                                                    onChange={(e) => { setInputText(e.target.value) }} value={inputText} />
                                                <label htmlFor="Add more field">Add more field</label>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                                <Button className="btn btn-secondary" onClick={() => { addList(inputText); setInputText("") }} ><AddCircle /> Add </Button>
                                                : <Button className="btn btn-secondary disable"><AddCircle /> Add </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* Checkbox section */}
                            <div className="mt-4 discriptionBox">
                                {AssetsField && AssetsField?.map((listItem, id) => {
                                    return (
                                        <div key={id} className="form-check form-check-inline boxHover">
                                            <div className="form-group">
                                                <input className="form-check-input" type="checkbox" key={checkedData[listItem?.assetFieldName]} checked={checkedData[listItem?.assetFieldName]} value={listItem?.assetFieldId}
                                                    name={listItem?.assetFieldName} onChange={(e) => checkHandler(e, listItem)} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1"> {listItem?.assetFieldName}</label>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </fieldset>
                    </div>
                </Col>
                <Row>
                    {tableList?.length > 0 ?
                        (<div className="user-role-table manageInventoryTable">
                            <h6>Add Assets Type</h6>
                            <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} checkboxSelection />
                        </div>)
                        :
                        (<div className="user-role-table manageInventoryTable">
                            {/* <h6>Add Assets Type</h6> */}
                            <p style={{ textAlign: "center", top: "20px" }}>Data Not Found</p>
                        </div>)

                    }

                </Row>
            </Row>
        </div>
    )
}

export default AssetsType;
