import {  Col, Row, Form, FloatingLabel } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect} from "react";


const Invoice = ({Addresshandler,errors,addressData,invoiceData, Invoicehandler})=>{
  const dispatch = useDispatch();
  const invoicingstyleData = useSelector((state) => state.Getinvoicingstylereducer);
  const invoicingcycleData = useSelector((state) => state.Getinvoicingcyclereducer);
  const invoicingsortonData = useSelector((state) => state.Getinvoicingsortonreducer);
  const invoicecurrenyValue = useSelector((state) => state.Getinvoicecurrencyreducer);
    return(
        <>
        {/* Invoicing care of default address */}
        
 <Row className="mb-4 mt-4">
     <Col sm={12}>
 <fieldset className="mb-3">
 <label className="label-heading">Invoice Info</label>

 <Row className="mb-4 mt-4">
     <Col sm={12}>
 <fieldset className="mb-3">
 <label className="label-heading">Invoice Care of</label>
      <>
  <Row className="mb-3">
    <Col sm={12}>
    <div className="form-check">
         <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Default Address</label>
         </div>
    </Col>
  <Col sm={4}>
  <Form.Group controlId="invoicecare">
  <FloatingLabel controlId="floatingInput" label="Name" >
    <Form.Control type="text" placeholder="Name" name="Name" onChange={e=>Invoicehandler(e)}  value={''} required/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4}>
  <Form.Group controlId="invoicecare">
  <FloatingLabel controlId="floatingInput" label="Unit" >
    <Form.Control type="text" placeholder="Unit" name="Unit" onChange={e=>Invoicehandler(e)}  value={''} required/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4}>
  <Form.Group controlId="invoicecare">
  <FloatingLabel controlId="floatingInput" label="City" >
    <Form.Control type="text" placeholder="Name" name="City" onChange={e=>Invoicehandler(e)}  value={''} required/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  </Row>
  <Row className="mb-3">
  <Col sm={4}>
  <Form.Group controlId="invoicecare">
  <FloatingLabel controlId="floatingInput" label="Province" >
    <Form.Control type="text" placeholder="Province" name="Province" onChange={e=>Invoicehandler(e)}  value={''} required/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4}>
  <Form.Group controlId="invoicecare">
  <FloatingLabel controlId="floatingInput" label="Country" >
    <Form.Control type="text" placeholder="Country" name="Country" onChange={e=>Invoicehandler(e)}  value={''} required/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4}>
  <Form.Group controlId="invoicecare">
  <FloatingLabel controlId="floatingInput" label="Postal Code" >
    <Form.Control type="text" placeholder="Postal Code" name="Postal Code" onChange={e=>Invoicehandler(e)}  value={''} required/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  </Row>
</>
</fieldset>
</Col>
</Row>
<>
  <Row className="mb-3">
  <Col sm={6}>
  <Form.Group controlId="invoice date">
  <FloatingLabel controlId="floatingInput" label="Last invoice date" >
    <Form.Control type="date" placeholder="Date" name="LastInvoicedate" onChange={e=>Invoicehandler(e)} value={ ''} required  disabled/>
  </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={6}>
  <Form.Group controlId="invoicestyle">
  <FloatingLabel controlId="floatingSelectGrid" label="Select invoice style">
      <Form.Select aria-label="Floating label select example" name="invoiceStyle" onChange={e=>Invoicehandler(e)} value={''} required>

         <option>Select</option>:
         <>
          {/* {
          invoicingstyleData.map((data,index)=>(
            <option  key={index} value={data.invoiceStyleID}>{data.invoiceStyle}</option>
          ))
           
          } */}
          </>


      </Form.Select>
    </FloatingLabel>
  </Form.Group> 
  </Col>
  </Row>
  {/* 2nd Row */}
  <Row className="mb-3">
  <Col sm={4}>
  <Form.Group controlId="invoicecycle">
  <FloatingLabel controlId="floatingSelectGrid" label="Select invoice cycle">
      <Form.Select aria-label="Floating label select example" name="InvoiceCycle" onChange={e=>Invoicehandler(e)} value={''} required >
   <option>Select</option>:
         <>
          {/* {
          invoicingcycleData.data.data?.map((data,index)=>(
            <option  key={index} value={data.invoiceCycleID}>{data.invoiceCycle}</option>
          ))
           
          } */}
          </>

      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4}>
  <Form.Group controlId="sortontype">
  <FloatingLabel controlId="floatingSelectGrid" label="Select sort on type">
      <Form.Select aria-label="Floating label select example" name="SortonType" onChange={e=>Invoicehandler(e)} value={''} required >
      <option>Select</option>:
         <>
          {/* {
          invoicingsortonData.data.data?.map((data,index)=>(
            <option  key={index} value={data.invoiceSortonID}>{data.invoiceSortOn}</option>
          ))
           
          } */}
          </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4}>
  <Form.Group controlId="currencyvalue">
  <FloatingLabel controlId="floatingSelectGrid" label="Select currency value">
      <Form.Select aria-label="Floating label select example" name="CurrencyValue" onChange={e=>Invoicehandler(e)} value={''} required >
      <option>Select</option>:
         <>
          {/* {
          invoicecurrenyValue.data.data?.map((data,index)=>(
            <option  key={index} value={data.invoiceCurrencyID}>{data.currency}</option>
          ))
           
          } */}
          </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
 </Row>
</>
{/* Checkbox section */}
<div className="checkbox-option">
    <Row>
        <Col sm={4}>
        <div className="form-check">
         <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Email POD with invoice</label>
         </div>
        </Col>
        <Col sm={4}>
        <div className="form-check">
         <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Multi invoice bundle send in one attachement</label>
         </div>
        </Col>
        <Col sm={4}>
        <div className="form-check">
         <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Sub-Total</label>
         </div>
        </Col>
        <Col sm={4}>
        <div className="form-check">
         <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Email every invoice separately</label>
         </div>
        </Col>
        <Col sm={4}>
        <div className="form-check">
         <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Merge invoice and POD in one pdf</label>
         </div>
        </Col>
    </Row>
</div>
</fieldset>
</Col>
</Row>
</>
    );
}
export default Invoice;