import { Col, Container, Row, Button, Modal, Form } from "react-bootstrap";
import Wrapper from "../../Wrapper";
import { AddCircle } from "@material-ui/icons";
import VehicleList from "./VehicleList";
import Addvehicle from "./AddVehicle";
import { useState } from "react";
import VehicleTabs from "./VehicleTabs";

const Vehicle = () => {
    const [show, setShow] = useState(false);
    const [isShow , setIsShow]=useState(false)
    const onClick = () => { setShow(show => !show); }
    const modalHandler = () => { setIsShow(isShow => !isShow); }

    return (
       <Wrapper>
        <div className="employee">
            <Container fluid>
                <VehicleTabs/>
            </Container>
        </div>
       </Wrapper>
    );
}
export default Vehicle;


{/* <> */}
{/* <Wrapper>
    <div className="employee">
        <Container fluid>
            <div className="role-assignment">
                <div className="role-assignment-name">
                    <h5>Inventory List</h5>
                </div>
                <div className="add-role-button d-flex ">
                    <span style={{marginRight:"20px"}} ><Button variant="outline-primary"  onClick={modalHandler}> <AddCircle /> Add Vehicle</Button></span>
                    <span ><Button variant="outline-primary" onClick={onClick}> <AddCircle /> Add Inventory</Button></span>
                </div>
                <Modal
                    size="md"
                    show={isShow}
                    onHide={() => setIsShow(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            {" "}
                            Add a new vehicle
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        </Row>
                        <Row className="mb-4 mt-3">
                            <Col sm={12}>
                                <>
                                    <div className="assign-role">
                                        <Row className="mt-2" >
                                            <Col sm={10}>
                                                <div className="form-floating">
                                                    <Form.Control type="text" autoComplete="off" placeholder="Add a new vehicle" name="vehicle"
                                                        // {...register('role', {
                                                        //     required: 'Field is required', pattern: {
                                                        //         value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g,
                                                        //         message: "Please enter a valid name"
                                                        //     }
                                                        // })}
                                                         />
                                                    <label htmlFor="newRole">Add a new Vehicle</label>
                                                    {/* <p className="error-message">{errors.role?.message}</p> */}
                                                // </div>
                                            // </Col>
                                    //         <Col sm={2}>
                                    //             <Button className="btn btn-secondary"
                                    //             //  onClick={handleSubmit(onSubmit)}
                                    //              > <AddCircle /></Button>
                                    //         </Col>
                                    //     </Row>
                                    // </div>
                                    {/* List of created role */}
                                    // <Row>
                                    //     {/* {role?.data?.map((data, index) => (
                                    //         <>
                                    //             <div className="created-role" key={index} >
                                    //                 <div className="role-name">{data}</div>
                                    //             </div>
                                    //         </>
                                    //     ))} */}
                                    // </Row>
//                                 </Form>
//                             </Col>
//                         </Row>
//                     </Modal.Body>
//                 </Modal>
//             </div>
//             <Row>
//                 <Col sm={12}>
//                     {show ?
//                         <Addvehicle />
//                         : null}
//                     <VehicleList />
//                 </Col>
//             </Row>
//         </Container>
//     </div>
// </Wrapper> */}
// </>