import { useState } from "react"
import useCustomer from "../../context/CustomerContext"

const useDeleteData = (dataSource) => {
        const {handleAccount,singleAccount} = useCustomer()
        const account = singleAccount && singleAccount[0];
        const [isDelete, setIsDelete] = useState()
        const [open, setOpen] = useState(false);
        const handleOpen = (data) => { setOpen(true); setIsDelete(data) };
        const handleClose = () => { setOpen(false); };

        const deleteData = async () => {
            await dataSource(isDelete)
            handleAccount(account.account_No)
        }
            
        return  [handleClose,handleOpen,open,deleteData]
}
export default useDeleteData