import { apiSlice } from "../../app/services/authServices";


export const accountListApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAccountList: builder.query({
            query: () => ({
                url: `/Order/GetListOfAccountNo`,
                method: 'GET',
            }),
        }),
    })
})
export const { useGetAccountListQuery } = apiSlice;
