import { apiSlice } from "../../app/services/authServices";


export const selectVehicleSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVehicleTypeList: builder.query({
            query: () => ({
                url: 'Vehicle/GetVehicleTypeList',
                method: 'GET',
            }),
        }),

        getAdditionalFieldsForVehicle: builder.query({
            query: (vehicleTypeId) => ({
                url: `Vehicle/GetAllFieldsForVehicleType?vehicleTypeId=${vehicleTypeId}`,
                method: 'GET',
            }),
        }),

        addVehicleInventoryData: builder.mutation({
            query: (data) => ({
                url: 'Vehicle/AddVehicleInventoryData',
                method: 'POST',
                body:data,
            }),
        }),

        getVehicleInventoryList: builder.query({
            query: () => ({
                url: 'Vehicle/GetVehicleInventoryList',
                method: 'GET',
            }),
        }),

        updateVehicleInventoryList: builder.mutation({
            query: (data) => ({
                url: 'Vehicle/UpdateVehicleInventoryData',
                method: 'POST',
                body:data,
            }),
        }),

        getVehicleNumber: builder.query({
            query: (vehicleNumber) => ({
                url: `Asset/GetVehiclenumber?VehicleTypeid=${vehicleNumber}`,
                method: 'GET',
            }),
        }),

        deleteVehicleInventorydata: builder.mutation({
            query: (data) => ({
                url: `Vehicle/DeleteVehicleInventorydata?vehicleInventoryId=${data}`,
                method: 'POST',
                body:"",
            }),
        }),
        getAllFieldsForVehicleType: builder.query({
            query: (data) => ({
                url: `Vehicle/GetAllFieldsForVehicleType?vehicleTypeId=${data}`,
                method: 'GET',
            }),
        }),

        getVehicleInventoryByInventoryId: builder.query({
            query: (vehicleInventoryId) => ({
                url: `Vehicle/GetVehicleInventoryByInventoryId?inventoryId=${vehicleInventoryId}`,
                method: 'GET',
            }),
        }),



        updateVehicleTypeWithFields: builder.mutation({
            query: (data) => ({
                url: `Vehicle/UpdateVehicleTypeWithFields`,
                method: 'POST',
                body:data,
            }),
        }),

    }),
})

export const { useGetVehicleTypeListQuery, useGetAdditionalFieldsForVehicleQuery, useAddVehicleInventoryDataMutation, useGetVehicleInventoryListQuery,
    useUpdateVehicleInventoryListMutation ,useGetVehicleNumberQuery,useDeleteVehicleInventorydataMutation,useLazyGetAllFieldsForVehicleTypeQuery,
    useLazyGetVehicleInventoryByInventoryIdQuery,useUpdateVehicleTypeWithFieldsMutation } = apiSlice
