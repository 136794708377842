import {Col, Row, Form} from "react-bootstrap";
import { useState } from "react";
import ServiceSurchargeOptions from "./ServiceSurchargeOptions";

const ServiceSurchargeSection = () => {
    const [serviceRateValue, setServiceRateValue] = useState(0);
    const [hasPallet, setHasPallet] = useState(false);
    const [hasCWT, setHasCWT] = useState(false);

    return(
        <>
        <Form>
            <Col sm={12} className="mb-3">
                <Row>
                    <Col sm={4}><h6>Select service rate type</h6></Col>
                    <Col sm={8}>
                    <div className="floating-checkbox" name="RateType">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="LOCAL" name="RateType" checked={serviceRateValue === 1} onClick={() => setServiceRateValue(1)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">LOCAL</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox2" disabled={hasPallet} value="CWT" name="RateType" checked={serviceRateValue === 2} onClick={() => setServiceRateValue(2)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox2">CWT</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox3" disabled={hasCWT} value="PALLET" name="RateType" checked={serviceRateValue === 3} onClick={() => setServiceRateValue(3)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox3">PALLET</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox4" value="DISTANCE-BASED" name="RateType" checked={serviceRateValue === 4} onClick={() => setServiceRateValue(4)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox4">DISTANCE-BASED</label>
                        </div>
                    </div>
                    </Col>
                </Row>
                <ServiceSurchargeOptions serviceRateValue={serviceRateValue} setServiceRateValue={setServiceRateValue} setHasCWT={setHasCWT} setHasPallet={setHasPallet}/>
            </Col>
        </Form>        
        </>
    )
}
export default ServiceSurchargeSection;