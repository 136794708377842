import React, { useEffect } from "react";
import { Button, Col, FloatingLabel, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Productdesp from "./Productdesp";
import { useState } from "react";
import Table from 'react-bootstrap/Table';
import { InputGroup } from "react-bootstrap";
import useOrder from "../../../context/OrderContext";
import { useSelector } from "react-redux";
// import Draggable from "react-draggable";
import { useCustomData } from "../../Sidebar/Permission";


const Extrainfo = () => {
  const {permission} = useCustomData()
  const { showPrice, handleQuoteData, quoteData, validateaddSubmit,handlePrint,submitEmail, orderForm ,emailHandler,emailQuotehandler,pricingResult} = useOrder();
  const priceData = useSelector((state) => state.generatePricingReducer);
  const grossPrice = (pricingResult?.data?.vehicleCharge+ pricingResult?.data?.serviceSurcharge) && (pricingResult?.data?.vehicleCharge+ pricingResult?.data?.serviceSurcharge);
  const accessorialSurcharge = pricingResult?.data?.accessorialSurcharge && pricingResult?.data?.accessorialSurcharge
  const fuelSurchargeRate = pricingResult?.data?.fuelSurcharge && pricingResult?.data?.fuelSurcharge
  const rate = (Math.round(grossPrice * 100) / 100).toFixed(2);
  const totalQuotePrice = grossPrice + accessorialSurcharge + fuelSurchargeRate;
  
  const [addressData, setAddressData] = useState({});
  const [priceShow, setPriceShow] = useState(false);
  const Priceshowhandler = () => {
    return (
      setPriceShow(priceShow => !priceShow)
    );
  }

  const Addresshandler = (e) => {
    setAddressData(addressData => ({ ...addressData, [e.target.name]: e.target.value }));
  }
  //Print Quote data
  const handleQuotePrint = () =>{
       setTimeout(function () {
      handlePrint();
  }, 2000);
     validateaddSubmit("quote")
  }

  return (
    <div className="extra-info">
      <div className="orderform-sidesection">
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12} className="mb-2"><Productdesp /></Col>
        </Row>
        <Row className="m-1">
          {/* <fieldset style={{marginBottom:"6px",marginTop:"12px"}}>
            <label className="label-heading">Select the Quote Options</label>
            <div className="quote-list">
            {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
              <div className="RadioButton">
                <input value="Save as Quote" type="radio" name="quote" onClick={() => { validateaddSubmit("quote"); }} />
                <label>Save as Quote</label>
              </div>
              : 
              <div className="RadioButton">
              <input value="Save as Quote" type="radio" name="quote" disabled/>
              <label>Save as Quote</label>
            </div>
            }
            {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
              <div className="RadioButton">
                <input value="saveprint" type="radio" name="quote" onClick={handleQuotePrint}/>
                <label>Save & Print</label>
              </div>
              : <div className="RadioButton">
              <input value="saveprint" type="radio" name="quote" disabled/>
              <label>Save & Print</label>
            </div>}
            {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
              <div className="RadioButton">
                <input value="emailquote" type="radio" name="quote" onClick={emailQuotehandler}/>
                <label>Email Quote</label>
              </div>
              : <div className="RadioButton">
              <input value="emailquote" type="radio" name="quote" disabled/>
              <label>Email Quote</label>
            </div>}
            </div>
          </fieldset> */}
        </Row>
      </div>
      {/* Quote List */}
      {orderForm === "Quote" && (
        <>
      {quoteData?.length > 0 ? <div className="orderform-sidesection mt-4">
        <h5>Quote List</h5>
        <div className="quote-table">
          <Table className="mt-3">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Quote No</th>
              </tr>
            </thead>
            <tbody>
              {quoteData?.length > 0 ? (

                quoteData.map((data, index) => {

                  return (
                    <tr key={index}>
                      <td>{data?.quoteId}</td>
                      <td>{data?.quoteNo}</td>
                    </tr>
                  )
                })

              ) : (
                <p>No data found </p>
              )}
            </tbody>
          </Table>
        </div>
      </div> : ""}
      </>
       )}


      {/* Pricing section */}

              <>
                <>
                  <div className="orderform-sidesection mt-4">
                    <fieldset>
                      <label className="label-heading">Pricing</label>
                      <Form.Group as={Row} className="mb-2" controlId="formHorizontalEmail">
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Gross Price" name="rate" value={rate || "rate"} onChange={e => { Addresshandler(e); }}
                                required />
                              <label htmlFor="Street">Gross price</label>
                         
                            </div>
                          </div>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Button variant="primary" onClick={Priceshowhandler}>View Quote Price Details</Button>
                        </Col>
                      </Form.Group>
                      {/* View details section */}
                      {priceShow ?
                        <div className="view-detail mb-2 mt-2">
                          <div className="btn-status mb-2">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Gross Price" name="rate" value={rate || "rate"} onChange={e => { Addresshandler(e); }}
                              required />
                              <label htmlFor="Street">Gross price</label>
                           
                            </div>
                          </div>

                          <div className="btn-status mb-2">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Accessorial" name="accessorial" value ={accessorialSurcharge} onChange={e => { Addresshandler(e); }}
                              required />
                              <label htmlFor="Accessorial">Accessorial</label>
                              
                            </div>
                          </div>


                          <div className="btn-status mb-2">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Fuel Surcharge" name="Fuelsurcharge" value ={fuelSurchargeRate} onChange={e => { Addresshandler(e); }}
                          required />
                              <label htmlFor="FuelSurcharge">Fuel Surcharge</label>
                         
                            </div>
                          </div>

                          {/* <div className="btn-status mb-2">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Tax" name="Tax" onChange={e => { Addresshandler(e); }}
                                className={`form-control ${touched.Tax && errors.Tax ? "is-invalid" : ""}`} required />
                              <label htmlFor="Tax">Tax</label>
                              <ErrorMessage component="div" name="Tax" className="invalid-feedback" />
                            </div>
                          </div> */}

                          <div className="btn-status mb-2">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Total Quote Price" name="TotalQuote" value ={totalQuotePrice} onChange={e => { Addresshandler(e); }}
                             required />
                              <label htmlFor="TotalQuote"  style={{ fontSize:"0.8em" }}>Total Quote Price</label>
                             
                            </div>
                          </div>
                        </div>
                        : null}
                    </fieldset>
                    {/* <fieldset>
                      <label className="label-heading">Fuel Surcharge</label>
                      <div className="btn-status">
                        <div className="form-floating">
                          <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                          <Form.Control type="text" placeholder="Fuel Surcharge" name="Fuelsurcharge" onChange={e => { Addresshandler(e); }}
                            className={`form-control ${touched.Fuelsurcharge && errors.Fuelsurcharge ? "is-invalid" : ""}`} required />
                          <label htmlFor="FuelSurcharge">Fuel Surcharge</label>
                          <ErrorMessage component="div" name="Fuelsurcharge" className="invalid-feedback" />
                        </div>
                      </div>
                    </fieldset> */}
                    {/* <fieldset>
                      <label className="label-heading">Total price</label>
                      <div className="btn-status ">
                        <div className="form-floating">
                          <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                          <Form.Control type="text" placeholder="Total Price" name="Subtotal" onChange={e => { Addresshandler(e); handleBlur(e); handleChange(e) }}
                            className={`form-control ${touched.Subtotal && errors.Subtotal ? "is-invalid" : ""}`} required />
                          <label htmlFor="Subtotal">Sub Total</label>
                          <ErrorMessage component="div" name="Subtotal" className="invalid-feedback" />
                        </div>
                      </div>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Row className="mt-2">
                          <Col sm={6} className="mb-2">
                            <FloatingLabel controlId="floatingInput" label="Tax Name">
                              <Form.Control type="text" placeholder="tax Name" name="Tax Name" required />
                            </FloatingLabel>
                          </Col>
                          <Col sm={6} className="mb-2">
                            <div className="btn-status" style={{ width: "148px" }}>
                              <div className="form-floating">
                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                <Form.Control type="text" placeholder="Tax Amount" name="Taxamount" onChange={e => { Addresshandler(e); handleBlur(e); handleChange(e) }}
                                  className={`form-control ${touched.Taxamount && errors.Taxamount ? "is-invalid" : ""}`} required />
                                <label htmlFor="TotalPrice">Tax Amount</label>
                                <ErrorMessage component="div" name="Totalamount" className="invalid-feedback" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                      <div className="btn-status ">
                        <div className="form-floating">
                          <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                          <Form.Control type="text" placeholder="Total Price" name="TotalPrice" onChange={e => { Addresshandler(e); handleBlur(e); handleChange(e) }}
                            className={`form-control ${touched.TotalPrice && errors.TotalPrice ? "is-invalid" : ""}`} required />
                          <label htmlFor="TotalPrice">Total Price</label>
                          <ErrorMessage component="div" name="TotalPrice" className="invalid-feedback" />
                        </div>
                      </div>
                    </fieldset> */}
                  </div>
                </>
              </>

 
    </div>
  );
}
export default Extrainfo;