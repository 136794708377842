import React from 'react';
import { parse, format, parseISO } from 'date-fns';

function timeConverter(time) {
    let date;
    const iso8601Regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)(\.\d+)?(Z|([+-](0\d|1[0-3]):([0-5]\d)))$/;

    if(iso8601Regex.test(time)) {
      date = parseISO(time, 'yyyy-MM-dd h:mm:ss a', new Date());
    }
    else{
      date = parse(time, 'yyyy-MM-dd h:mm:ss a', new Date());
    }
    const regex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;
     if (regex.test(time)) {
      return time;
     }

    if (isNaN(date)) {
      return undefined;
    }

    // const formattedTime = format(date, 'hh:mm a'); //12 hour
    const formattedTime = format(date, 'HH:mm'); //24 hour

    return formattedTime;
}
export default timeConverter;