import DashboardIcon from '@material-ui/icons/Dashboard'
import { CreditCard } from '@material-ui/icons';
import { Person } from '@material-ui/icons';
import { LocalShipping } from '@material-ui/icons';
import { Payment } from '@material-ui/icons';
import { Grade} from '@material-ui/icons';
import { Settings } from '@material-ui/icons';
import { List } from '@material-ui/icons';
import { AccountBalance } from '@material-ui/icons';
import {useCustomData } from './Permission';


const useArr = () => {
  const [permission] = useCustomData()

const menuItems = [
  { id: 1, name: "Dashboard", exact: true, image: <DashboardIcon />, to: "/", },
  {
    moduleId: 1, name: "AR", exact: true, image: <AccountBalance />, to: "/AR",
    subMenus: [
      permission?.find(event => event.moduleId === 8) ? { moduleId: 8, name: "Enter A Bank Deposit", exact: true, to: "/AR/enter-a-bank-deposit" } : false,
      permission?.find(event => event.moduleId === 9) ?  { moduleId: 9, name: "Account Enquiry /Collection", exact: true, to: "/AR/account-enquiry" } : false,
      permission?.find(event => event.moduleId === 10) ?  { moduleId: 10, name: "Monthly Sales & Billing Totals", exact: true, to: "/AR/monthly-sales-Billing-Totals" } : false,
      permission?.find(event => event.moduleId === 11) ?  { moduleId: 11, name: "AG Aged Receivable Report", exact: true, to: "/AR/AG-aged-receivable-report" } : false,
      permission?.find(event => event.moduleId === 12) ? { moduleId: 12, name: "Deposits & Chqs Distribution", exact: true, to: "/AR/deposit" } : false]
  },
  {
    id: 2, name: "Billing", exact: true, image: <CreditCard />, to: "/billing",
    subMenus: [permission?.find(event => event.moduleId === 13) ? { moduleId: 13, name: "Process Customer Invoices", exact: true, to: "/billing/processcustomerinvoice" } : false]
  },
  {
    id: 3, name: "Customer", exact: true, image: <Person />, to: "/customer",
    subMenus: [
    permission?.find(event => event.moduleId === 38 || event.moduleId === 39 || event.moduleId === 40 ||event.moduleId === 41 || event.moduleId === 42 || event.moduleId === 43 
      || event.moduleId === 44 || event.moduleId === 45 || event.moduleId === 46 || event.moduleId === 47 || event.moduleId === 48 || event.moduleId === 49 ) ? {moduelId: 16, name: "Customer(CRUD)", exact: true, to: "/customer/CustomerCrud" } : false,
    permission?.find(event => event.moduleId === 51) ? { name: "History management", exact: true, to: "/customer/history-management" } : false]
  },
  {
    id: 4, name: "Dispatch", image: <LocalShipping />, exact: true, to: "/dispatch",
    subMenus: [
      permission?.find(event => event.moduleId === 16) ? { moduelId: 16, name: "Process Prebooked Orders", to: "/dispatch/process-prebooked-order" } : false,
      permission?.find(event => event.moduleId === 17) ? { moduleId: 17, name: "Trip Control System", to: "/dispatch/trip-control" } : false,
      permission?.find(event => event.moduleId === 18) ? { moduleId: 18, name: "ACE Communication", to: "/dispatch/ace-communication" } : false,
      permission?.find(event => event.moduleId === 19) ? { moduleId: 19, name: "Custom Broker list", to: "/dispatch/custom-broker-list" } : false,
      permission?.find(event => event.moduleId === 20) ? { moduleId: 20, name: "Trip Dispatch - Ace Communication", exact: true, to: "/dispatch/trip-dispatch" } : false,
      permission?.find(event => event.moduleId === 21) ? { moduleId: 21, name: "ACI Communicator - CBSA", exact: true, to: "/dispatch/aci-communicator" } : false,
      permission?.find(event => event.moduleId === 22) ? { moduleId: 22, name: "Same Day Dispatch", exact: true, to: "/dispatch/same-day-dispatch" } : false,
      permission?.find(event => event.moduleId === 23) ? { moduleId: 23, name: "Interliners", exact: true, to: "/dispatch/interliners" } : false
    ]
  },
  {
    id: 5, name: "Report", image: <Person />, exact: true, to: "/report",
    subMenus: [
      permission?.find(event => event.moduleId === 24) ? { moduleId: 24, name: "Verify Order Modifications", exact: true, to: "/report/verify-order-modification" } : false,
      permission?.find(event => event.moduleId === 25) ? { moduleId: 25, name: "Detailed Excel Report", exact: true, to: "/report/detailed-excel-report" } : false,
      permission?.find(event => event.moduleId === 26) ? { moduleId: 26, name: "Sales Calender", exact: true, to: "/report/sales-calender" } : false
    ]
  },
  {
    id: 6, name: "Order", exact: true, image: <List />, to: "/order",
    subMenus: [
      permission?.find(event => event.moduleId === 27) ? { moduleId: 27, name: "Order Maintenance & Post Accepted", exact: true, to: "/order/order-maintenance" } : false,
      permission?.find(event => event.moduleId === 28) ? { moduleId: 28, name: "Order Tracking", exact: true, to: "/order/order-tracking" } : false,
      permission?.find(event => event.moduleId === 52) ? { name: "Appointment desk", exact: true, to: "/order/appointment-desk" } : false,
      permission?.find(event => event.moduleId === 29) ? { moduleId: 29, name: "Order Taking By Telephone", exact: true, to: "/order/order-taking-by-telephone" } : false,
      permission?.find(event => event.moduleId === 30) ? { moduleId: 30, name: "Upload Order List", exact: true, to: "/order/upload-order" } : false
    ],
  },
 {
    id: 7, name: "Admin", image: <Settings />, exact: true, to: "/admin",
    subMenus: [
      permission?.find(event => event.moduleId === 31) ? { moduleId: 31, name: "Company Profile", exact: true, to: "/admin/company-profile" } : false,
      permission?.find(event => event.moduleId === 32) ? { moduleId: 32, name: "Users", exact: true, to: "/admin/users" } : false,
      permission?.find(event => event.moduleId === 33) ? { moduleId: 33, name: "Vehicle", exact: true, to: "/admin/vehicle" } : false,
      permission?.find(event => event.moduleId === 34) ? { moduleId: 34, name: "Employee", exact: true, to: "/admin/employee" } : false,
      permission?.find(event => event.moduleId === 35) ? { moduleId: 35, name: "Default Pricing", exact: true, to: "/admin/default-pricing" } : false,

    ]
  },
  {
    id:8, name: "Payroll", exact: true, image: <Payment />, to: "/payroll",
    subMenus: [
      permission?.find(event => event.moduleId === 47) ? { moduleId: 47, name: "Process Payroll", exact: true, to: "/payroll/process-payroll" } : false,
      permission?.find(event => event.moduleId === 48) ? { moduleID: 48, name: "Trip Control Payroll", exact: true, to: "/payroll/trip-control-payroll" } : false
    ]
  },
      
  {
    id:9, name: "Role Management", image: <Grade/>, exact: true, to: "/role-management",
    subMenus: [
      permission?.find(event => event.moduleId === 49) ? { moduleId: 49, name: "Admin Role", exact: true, to: "/role-management/admin-role" } : false,
      permission?.find(event => event.moduleId === 52) ? { name: "User Role", exact: true, to: "/role-management/user-role" } : false]
  },
]
return [menuItems]

}
export default useArr;