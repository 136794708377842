import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

const initialState = {
    list: {}
};


export const contractApiSlice = apiSlice.injectEndpoints({
    tagTypes:['Contract'],
    endpoints:builder => ({ 
        updateContractData: builder.mutation({    
            query(data) {
                const {account, ...body} = data
                return {
                    url: `Customer/EditContactInfo?AccountNo=${account}`,
                  method: "POST",
                  body
                };
              },
          }),
        updateContractDataDefaultPricing: builder.mutation({
            query(data) {
                const { account, ...body } = data;
                return {
                    url: `/Customer/EditContactInfoDefaultPricing?defaultratecardId=${account}`,
                    method: "POST",
                    body
                };
            },
        }),
    })
})

export const {useUpdateContractDataMutation, useUpdateContractDataDefaultPricingMutation } = apiSlice


const contractDataSlice = createSlice({
    name: "contractData",
    initialState,
    reducers: {
        addContract(state, action) {
            return {
                ...state,
                list: action.payload
            }
          },
    }
})
export const { addContract } = contractDataSlice.actions

export default contractDataSlice.reducer