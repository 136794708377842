
import { apiSlice } from "../../app/services/authServices";

export const UnitsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addUnitType: builder.mutation({
            query: (body) => ({
                url: `Admin/AddUnitType`,
                method: 'POST',
                body,
            }),
        }),
        getAllUnitType: builder.query({
            query: () => ({
                url: `/Admin/GetAllUnitType`,
                method: 'GET',
                keepUnusedDataFor: 200,
            }),
        }),
        getDetailById: builder.query({
            query: (unitId) => ({
                url: `/Admin/GetUnitTypeDetailById?unitId=${unitId}`,
                method: 'GET',
                keepUnusedDataFor: 200,
            }),
        }),

        updateUnitType: builder.mutation({
            query: (body) => ({
                url: `Admin/UpdateUnitType`,
                method: 'POST',
                body,
            }),
        }),
        deleteUnitType: builder.mutation({
            query: (unitId) => ({
                url: `Admin/DeleteUnitType?unitId=${unitId}`,
                method: 'POST',
               
            }),
        }),
    })
})
export const { useAddUnitTypeMutation, useGetAllUnitTypeQuery, useLazyGetDetailByIdQuery  , useUpdateUnitTypeMutation , useDeleteUnitTypeMutation} = apiSlice;