import React from 'react';
import { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
// import useOrder from "../../../../../context/OrderContext";
import { useUpdateOrderNotificationMutation } from "../../../../../features/order/orderSlice";

const AutoStatus = ({ selectedOrder }) => {
    // const { pickInfo, dropInfo, accountInfo } = useOrder();
    const [emailValue, setEmailValue] = useState(false);
    const [consignorValue, setConsignorValue] = useState(false)
    const [consigneeValue, setConsigneeValue] = useState(false)
    const [updateOrderNotification] = useUpdateOrderNotificationMutation();
    const [tagsSendToEmail, setTagsSendToEmail] = useState([]);

    
    const consignor = selectedOrder?.pickupContactEmail;
    const consignee = selectedOrder?.dropoffContactEmail;
    const headAccount = selectedOrder?.emailAddress;

    const SubmitHandler = async () => {
        // setAccountInfo("")
        // setPickInfo("")
        // setDropInfo("")
        // setOrderInfo([])
        const data = {
            statusSendToHeadAccount: emailValue ? true : false,
            statusSendToConsignor: consignorValue ? true : false,
            statusSendToConsignee: consigneeValue ? true : false,
            statusSendToEmail: tagsSendToEmail,
        }
        console.log(data);
        await updateOrderNotification(data);
        window.location.reload(false)
    }
    
    const handleChange = event => {
        if (event.target.name === "headaccount") {
            if (event.target.checked) {
                setEmailValue(event.target.value);
            }
            else {
                setEmailValue(false)
            }
        }
        else if (event.target.name === "consignor") {
            if (event.target.checked) {
                setConsignorValue(event.target.value);

            }
            else {
                setConsignorValue(false)

            }
        }
        else if (event.target.name === "consignee") {
            if (event.target.checked) {
                setConsigneeValue(event.target.value);

            }
            else {
                setConsigneeValue(false)

            }
        }
    };
    const addTags = (event, type) => {
        if (event.target.value !== "") {
            switch (type) {
                case 'sendToEmail':
                    setTagsSendToEmail(event.target.value);
                    break;
                default:
                    break;
            }
            event.target.value = "";
        }
    };
    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="email-notify">
                        <div className="email-heading">
                            <h6>Status Notification</h6>
                        </div>
                        <div className="checkbox-section">
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to head account" value={headAccount} onChange={handleChange} name="headaccount" />
                                {emailValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.emailAddress})</label> : ""}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="consignor" value={consignor} onChange={handleChange} />
                                {consignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.pickupContactEmail})</label> : ""}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="consignee" value={consignee} onChange={handleChange} />
                                {consigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.dropoffContactEmail})</label> : ""}
                            </Form.Group>   
                        </div>
                    </div>
                </Col>
                </Row>
            <Row>
                <Col sm={9}>
                    <div className="tags-input">
                        <input
                            value={tagsSendToEmail}
                            onChange={event => addTags(event, 'sendToEmail')}
                            placeholder="Enter Email Address"
                            className="email-input"
                        />
                    </div>
                </Col>
                <Button variant="primary" className="email-btn" type="submit" name="submit" onClick={SubmitHandler}> Submit </Button>
            </Row>
        </>
       
        
    )
}

export default AutoStatus;