import { Col, Row, Form, FloatingLabel, Table, Accordion, InputGroup, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { useAddAccessorialSurchargeAfterEditMutation, useGetAccessorialQuery } from "../../../features/accessorial/accessorialDetailSlice";
import { useUpdateAccessorialDataMutation, useDeleteAccessorialDataMutation } from "../../../features/accessorial/accessorialDetailSlice";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../Sidebar/Permission";
import Select from "react-select"
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { data } from "@syncfusion/ej2";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const EditAccessorialSurcharge = () => {
  const [permission] = useCustomData()
  const { selected, contractInfo, vehicleSurchargeList, singleAccount, accessorialList, handleAccount } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0]
  const { register, unregister, handleSubmit, watch, formState: { errors }, reset, onChange, control, getValues } = useForm();
  let subscribe = watch()
  const { data: accessorialLists } = useGetAccessorialQuery()
  const [updateAccessorialData] = useUpdateAccessorialDataMutation()
  const [deleteAccessorialData] = useDeleteAccessorialDataMutation()
  const [addAccessorialSurchargeAfterEdit] = useAddAccessorialSurchargeAfterEditMutation()
  const [modifyHistory] = useModifyHistoryMutation()
  const account = singleAccount && singleAccount[0];
  const [message, setMessage] = useState("Modified the accessorial charge section")
  const [isEditItem, setIsEditItem] = useState(null);
  const [isDelete, setIsDelete] = useState()
  const [accessorialData, setAccessorialData] = useState({});
  const [showAcesorialSecondFields, setShowAccesrialSecondFields] = useState(false);
  const [editId, setEditId] = useState('')
  const [disabledData, setDisabledData] = useState(false)
  const [show, setShow] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [accessorialOpen, setAccessorialOpen] = useState(false);
  const handleAccessorialOpen = (data) => { setAccessorialOpen(true); setIsDelete(data) };
  const handleAccessorialClose = () => { setAccessorialOpen(false); };
  //const Accessorialupdate = (e) => { setAccessorialData(accessorialData => ({ ...accessorialData, [e.target.name]: e.target.value })) }
  const prevCount = usePrevious(accessorialData);
  const [selectedData, setSelectedData] = useState([])
  const [isEdit, setisEdit] = useState(false)
  const [selectedEditData, setSelectedEditData] = useState([])

  const[rateTypeSelected, setRateTypeSelected] = useState("")

  const fTime = getValues("fromTime");
  const tTime = getValues("toTime");

  const arr = []
  vehicleSurchargeList?.map((user) => {
    return arr.push({ value: user.vehicleType, label: user.vehicleType });
  });


  /* useEffect(() => {
    setAccessorialData(accessorialData)
    reset(accessorialData)
  }, [accessorialData]) */


  const handleSelect = (e) => {
    if (isEdit) {
      const mapData = e?.map((item) => item?.value
      )
      if (mapData && mapData?.length > 0) {
        setSelectedEditData(mapData)
      }
    }
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData?.length > 0) {
      setSelectedData(mapData)
    }
  }
  // I want that whenever the radio button in Base Rate Type Section of either Amount or Percentage is selected, the data.time value is cleared
  const clearTime = () => {
    setAccessorialData(accessorialData => ({ ...accessorialData, time: '' }));
    setShowTime(false)
  }


  // Edit List
  const EditaccessItem = (rateCardAccessorialID) => {
    setisEdit(true)
    setShowTime(true)
    const obj = []
    setEditId(rateCardAccessorialID)
    let newEditItem = accessorialList?.find((data) => {
      return data?.rateCardAccessorialID === rateCardAccessorialID
    });
    newEditItem.vehicleTypes.forEach((elem, i) => {
      obj.push({ value: elem, label: elem })
    })
    setAccessorialData(newEditItem);
    setSelectedData(obj)
    setSelectedEditData(newEditItem?.vehicleTypes)
    setShowAccesrialSecondFields(showAcesorialSecondFields => !showAcesorialSecondFields)
    setIsEditItem(rateCardAccessorialID);
    reset(newEditItem)
    setShow(true)
    setShowTime(accessorialData.rateType === "T" ? true : false)
    setRateTypeSelected(newEditItem.rateType)
  }
  // Update Edited list
  const editAccessCustomerList = async (accessorialData) => {
    accessorialData.vehicleTypes = selectedData
    if (JSON?.stringify(prevCount) === JSON?.stringify(accessorialData)) {
      setAccessorialData("")
      setTimeout(function () {
        toast.info("No Changes found on this accessorial surcharge section !", {
          position: "top-right",
        })
      });
      setSelectedData('')
      setShow(false)
      setShowTime(false)
      setRateTypeSelected("")
    }
    else {
      delete accessorialData.vehicleTypes
      accessorialData.vehicleTypes = selectedEditData
      if(accessorialData.rateType != 'T') accessorialData.time = ""
      const updateAccessorial = await updateAccessorialData({ vehicleTypes: selectedEditData, ...accessorialData })
      setAccessorialData("")
      handleAccount(account.account_No)
      if (updateAccessorial.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Accessorial section updated successfully !", {
            position: "top-right",
          })
        });
        setSelectedData('')
        setShow(false)
        setShowTime(false)
        setRateTypeSelected("")
      }
    }

    if (JSON?.stringify(prevCount) !== JSON?.stringify(accessorialData)) {
      const modifieddata = {
        AccountNo: account.account_No,
        CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
    reset({ accessorialID: "", fromTime: "", toTime: "", timeRateType: "", timeRate: "", rateType: "", rate: "", time: '' });
  }


  // delete List
  const deleteAccessCustomerList = async () => {
    await deleteAccessorialData(isDelete)
    handleAccount(account.account_No)
  }

  const addNewSection = async (data) => {
    if (Object.keys(data).length === 0) {
      setShowAccesrialSecondFields(true)
    }
    //     else if (accessorialList.some((d) => data.accessorialID == d.accessorialID)){
    //       toast.warn("Accessorial Added Already !", {
    //                position: "top-right",
    //            })
    //            return;
    // }
    else {

      delete data.VehicleTypes
      const newData = { VehicleTypes: selectedData, rateCardID: ratecardaccount?.rateCardId, ...data }
      const addDataOnUpdate = await addAccessorialSurchargeAfterEdit(newData)
      handleAccount(account.account_No)
      if (addDataOnUpdate?.data?.statusCode === 200) {
        toast.success("Accessorial Charge section Added successfully !", {
          position: "top-right",
        })
      }
      else if (addDataOnUpdate?.data?.statusCode === 500) {
        toast.error("Failed to add accessorial record", {
          position: "top-right",
        })
      }
    }
    reset({ accessorialID: "", fromTime: "", toTime: "", timeRateType: "", timeRate: "", rateType: "", rate: "", time: "" });
    setSelectedData('')
    setShow(false)
    setShowTime(false)
  }

  const disableHandler = () => { setShow(true) }
  const timeHandler = () => { setShowTime(true) }

  const [statusr, setStatusr] = useState(0);
  const [statust, setStatust] = useState(0);
  const radioHandlerr = (statusr) => { setStatusr(statusr) };
  const radioHandlert = (statust) => { setStatust(statust); setShowTime(false) };

  return (
    <>
      {/* Contract info  section*/}
      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
            <div className="section_action_btn">
              {accessorialData.rateCardAccessorialID ?
                <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(editAccessCustomerList)}>Update record</button></div>
                :
                <>
                  {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Add') !== -1 ?
                    <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addNewSection)}>Add new record</button></div>
                    : <div className="add_btn disable"><button type="button" className="btn">Add new Section</button></div>
                  }
                </>

              }
            </div>

            {showAcesorialSecondFields ?
              <>
                <div className="form-bg">

                  <Row className="mt-2 mb-3">
                    <Col sm={3}>
                      <Controller
                        name="accessorialID"
                        control={control}
                        rules={{
                          required: "Field is requried"
                        }}
                        render={({ field, fieldState: { error } }) => {
                          const { onChange, value, ref } = field;
                          return (
                            <>
                              <Autocomplete
                                value={
                                  value
                                    ? accessorialLists?.data.find((option) => {
                                      return value === option.accessorialID;
                                    }) ?? null
                                    : null
                                }
                                isOptionEqualToValue={(option, value) => option?.description === value?.description}
                                getOptionLabel={(option) => option?.description || ""}
                                onChange={(event, newValue) => {
                                  onChange(newValue ? newValue.accessorialID : null);
                                }}
                                id="controllable-states-demo"
                                options={accessorialLists?.data}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Accessorial Type"
                                    inputRef={ref}
                                  />
                                )}
                              />
                              {error ? (
                                <span style={{ color: "red" }}>{error.message}</span>
                              ) : null}
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <Controller
                        control={control}
                        name="VehicleTypes"
                        defaultValue={selectedData}
                        {...register('VehicleTypes')}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            isMulti
                            placeholder="Select Vehicle"
                            name={name}
                            defaultValue={selectedData}
                            ref={ref}
                            options={arr}
                            // value = {serviceData.VehicleTypes?.map((d)=>d.value)?.join(' , ')}
                            value={selectedData ? selectedData?.find((e) => e.value === value) : ""}
                            onChange={(e) => handleSelect(e)}
                          />
                        )}
                      />
                      {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="From Time">
                        <FloatingLabel controlId="floatingInput" label="From Time" >
                          <Form.Control type="time" placeholder="From Time" name="fromTime" onChange={onChange} onClick={disableHandler}
                            {...register('fromTime')} />
                        </FloatingLabel>
                        {/* {errors.fromTime?.message && (<p className="error-message">{" * " + errors.fromTime?.message}</p>)} */}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="To Time">
                        <FloatingLabel controlId="floatingInput" label="To Time" >
                          <Form.Control type="time" placeholder="To Time" name="toTime" onChange={onChange} onClick={disableHandler}
                            {...register('toTime')} />
                        </FloatingLabel>
                        {/* {errors.toTime?.message && (<p className="error-message">{" * " + errors.toTime?.message}</p>)} */}
                      </Form.Group>
                    </Col>
                  </Row>


                  <Row className="mt-4 mb-3">
                    <Col sm={6}>
                      <fieldset className="mb-3">
                        <label className="label-heading">Base Rate Type Section</label>
                        <Row>
                          <Col sm={12}>
                            <div className="fieldset-inner">
                              <label className="label-heading">Choose the rate type</label>
                              <div className="floating-checbox">
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" id="checkbox1" checked={rateTypeSelected === "A"} value="A" name="rateType" {...register("rateType", { required: 'Field is required' })} onClick={() => {clearTime(); setRateTypeSelected("A")}} />
                                  <label className="form-check-label" htmlFor="checkbox1">Amount</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" id="checkbox2" checked={rateTypeSelected === "P"} value="P" name="rateType" {...register("rateType", { required: 'Field is required' })} onClick={() => {clearTime(); setRateTypeSelected("P")}} />
                                  <label className="form-check-label" htmlFor="checkbox2">Percentage</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" id="checkbox3" checked={rateTypeSelected === "T"} value="T" name="rateType" {...register("rateType", { required: 'Field is required' })} onClick={() => {timeHandler(); setRateTypeSelected("T")}} />
                                  <label className="form-check-label" htmlFor="checkbox3">Time</label>
                                </div>
                              </div>
                            </div>
                            {errors.rateType?.message && (<p className="error-message">{" * " + errors.rateType?.message}</p>)}
                          </Col>
                          <Col sm={6} className="mt-2">
                            {subscribe?.rateType === "A" ? (
                              <div className="btn-status">
                                <div className="form-floating">
                                  <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                  <Form.Control type="text" placeholder="Rate" name="rate" {...register('rate', { required: 'Field is required' })} min='0' />
                                  <label>Rate ($)</label>
                                </div>
                                {errors.rate?.message && (<p className="error-message">{" * " + errors.rate?.message}</p>)}
                              </div>
                            ) : subscribe.rateType === "P" ? (
                              <div className="btn-status">
                                <div className="form-floating">
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Form.Control type="number" placeholder="Rate" name="rate" {...register('rate', { required: 'Field is required' })} min='0' />
                                  <label>Rate (%)</label>
                                </div>
                                {errors.rate?.message && (<p className="error-message">{" * " + errors.rate?.message}</p>)}
                              </div>
                            ) : (
                              <div className="btn-status">
                                <div className="form-floating">
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control type="number" placeholder="Rate" name="rate" {...register('rate', { required: 'Field is required' })} min='0' />
                                  <label>Rate ($)</label>
                                </div>
                                {errors.rate?.message && (<p className="error-message">{" * " + errors.rate?.message}</p>)}
                              </div>
                            )

                            }
                          </Col>
                          {showTime ?
                            <Col sm={6} className="mt-2">
                              <div className="form-floating">
                                <Form.Control type="number" placeholder="Time Rate" name="time" {...register('time')} min='0' />
                                <label>Time (Minutes)</label>
                              </div>
                              {errors.time?.message && (<p className="error-message">{" * " + errors.time?.message}</p>)}
                            </Col>
                            : ""}
                        </Row>
                      </fieldset>
                    </Col>
                    {(show && (fTime || tTime)) ?
                      <Col sm={6}>
                        <fieldset className="mb-3">
                          <label className="label-heading">Time Rate Section</label>
                          <Row>
                            <Col sm={6}>
                              <div className="fieldset-inner">
                                <label className="label-heading">Choose the Time Rate type</label>
                                <div className="floating-checbox">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineRadio1" value="A" name = "timeRateType" {...register("timeRateType", { required: 'Field is required' })} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Amount</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineRadio2" value="P" name = "timeRateType" {...register("timeRateType", { required: 'Field is required' })} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Percentage</label>
                                  </div>
                                </div>
                              </div>
                              {errors.timeRateType?.message && (<p className="error-message">{" * " + errors.timeRateType?.message}</p>)}
                            </Col>
                            <Col sm={6}>
                              {subscribe?.timeRateType === "A" ? (
                                <div className="btn-status">
                                  <div className="form-floating">
                                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                    <Form.Control type="number" placeholder="Rate" name="timeRate" {...register('timeRate', { required: 'Field is required' })} min='0' />
                                    <label> Time Rate ($)</label>
                                  </div>
                                  {errors.timeRate?.message && (<p className="error-message">{" * " + errors.timeRate?.message}</p>)}
                                </div>
                              ) : subscribe?.timeRateType === "P" ? (
                                <div className="btn-status">
                                  <div className="form-floating">
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control type="number" placeholder="Rate" name="timeRate" {...register('timeRate', { required: 'Field is required' })} min='0' />
                                    <label> Time Rate (%)</label>
                                  </div>
                                  {errors.timeRate?.message && (<p className="error-message">{" * " + errors.timeRate?.message}</p>)}
                                </div>
                              ) : (
                                <div className="btn-status">
                                  <div className="form-floating">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control type="number" placeholder="Rate" name="timeRate" {...register('timeRate', { required: 'Field is required' })} min='0' disabled={disabledData} />
                                    <label> Time Rate ($)</label>
                                  </div>
                                  {errors.timeRate?.message && (<p className="error-message">{" * " + errors.timeRate?.message}</p>)}
                                </div>
                              )

                              }
                            </Col>
                          </Row>
                        </fieldset>
                      </Col> : ""}

                  </Row>
                </div>
              </>
              : null}
            {/* table */}
            <Row>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Accessorial ID</th>
                    <th>Vehicle Types</th>
                    <th>Rate Type</th>
                    <th>Rate</th>
                    <th>Time (Minutes)</th>
                    <th>From Time</th>
                    <th>To Time</th>
                    <th>Time Rate Type</th>
                    <th>Time Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {accessorialList?.length > 0 ? (
                    accessorialList?.map((data) => {
                      return (
                        <tr key={data?.rateCardAccessorialID}>
                          <td>
                            <div className="action_btn">
                              {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Update') !== -1 ?
                                <span className="edit_btn" onClick={() => EditaccessItem(data?.rateCardAccessorialID)}><Edit /></span>
                                : <span className="edit_btn disable"><Edit /></span>}
                              {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Delete') !== -1 ?
                                <span className="del_btn" onClick={() => handleAccessorialOpen(data?.rateCardAccessorialID)}><Delete /></span>
                                : <span className="del_btn disable"><Delete /></span>}
                              <div>
                                <Dialog open={accessorialOpen} onClose={handleAccessorialClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                  <DialogTitle id="alert-dialog-title">
                                    Delete Confirmation
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      Are you sure you want to delete this row?
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button className="btn-secondary" onClick={handleAccessorialClose}>Cancel</Button>
                                    <Button className="btn-primary" onClick={() => { deleteAccessCustomerList(data.rateCardAccessorialID); handleAccessorialClose(); }} autoFocus> Confirm</Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                          </td>
                          <td>{data.accessorialDescription}</td>
                          <td>{data?.vehicleTypes?.join(' , ')}</td>
                          <td>{data.rateType}</td>
                          <td>{data.rate}</td>
                          <td>{data.time}</td>
                          <td>{data.fromTime}</td>
                          <td>{data.toTime}</td>
                          <td>{data.timeRateType}</td>
                          <td>{data.timeRate}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <p>No data found </p>
                  )}
                </tbody>
              </Table>
            </Row>
          </fieldset>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
    </>
  );
}
export default EditAccessorialSurcharge;