import React, { useState } from 'react'
import { DataGridPremium, GridToolbar, useGridApiRef, } from '@mui/x-data-grid-premium';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete } from '@material-ui/icons';

const InvoiceTable = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const columns = [
    {
      field: "Select",
      headerName: "Select",
      sortable: false,
      width: 70,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen(); // don't select this row after clicking
        };

        return (
          <>
            <>
              <div className="action_btn">
                <div className="floating-checbox">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="CWT" name="RateType" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1"></label>
                              </div>
                            </div>
              </div>
            </>
            <>
              <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                  <DialogTitle id="alert-dialog-title">
                    Delete Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this row?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className="btn-secondary" onClick={() => { handleClose(); }}>Cancel</Button>
                    <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          </>
        )

      },
    },

    { field: "Date", headerName: "Date", width: 94 },
    { field: "Transaction", headerName: "Transaction", width: 90 },
    { field: "Invoice_No", headerName: "Invoice No", width: 90 },
    { field: "Waybill", headerName: "Waybill", width: 90 },
    { field: "Reference", headerName: "Reference", width: 90 },
    { field: "Amount", headerName: "Amount", width: 90 },
    { field: "Paid", headerName: "Paid", width: 90 },
    { field: "Discount", headerName: "Discount", width: 90 },
    { field: "Balance", headerName: "Balance", width: 90 }
  ];
  const rows = [
    { id: 1, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Waybill: "1234", Reference: "111", Amount: "0.00", Paid:"111", Discount: "252", Discount: "110", Balance: "45123" },
    { id: 2, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Waybill: "1234", Reference: "111", Amount: "0.00", Paid:"111", Discount: "252", Discount: "110", Balance: "45123" },
    { id: 3, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Waybill: "1234", Reference: "111", Amount: "0.00", Paid:"111", Discount: "252", Discount: "110", Balance: "45123" },
    { id: 4, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Waybill: "1234", Reference: "111", Amount: "0.00", Paid:"111", Discount: "252", Discount: "110", Balance: "45123" },
    { id: 5, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Waybill: "1234", Reference: "111", Amount: "0.00", Paid:"111", Discount: "252", Discount: "110", Balance: "45123" },
    { id: 6, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Waybill: "1234", Reference: "111", Amount: "0.00", Paid:"111", Discount: "252", Discount: "110", Balance: "45123" }
  ];


  return (
    <>
      <div className="user-role-table " style={{ height: "760px" }}>
        <h6>List of invoice</h6>
        <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }}
        />
      </div>
    </>

  )
}

export default InvoiceTable;



