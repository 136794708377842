import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

export const accessorialSlice = apiSlice.injectEndpoints({
  tagTypes: ['Accessorial'],
  endpoints: builder => ({
    addVehicleTypePricing: builder.mutation({
      query: (body)=> {
        return {
          url: `Admin/AddVehicleType`,
          method: "POST",
          body
        };
      },
    }),
    getDivisionListPricing: builder.query({
      query: () => ({
        url: 'SameDayDispatch/GetDivisionList',
        method: 'GET',
      }),
    }),
    getAllVehicleTypePricing: builder.query({
      query: () => ({
        url: 'Admin/GetAllVehicleType',
        method: 'GET',
      }),
    }),
    getAllVehicleRateType: builder.query({
      query: () => ({
        url: 'Admin/GetAllVehicleWithType',
        method: 'GET'
      }),
    }),
    deleteVehiclePricingData: builder.mutation({
      query: (body) => ({
        url: 'Admin/DeactivateVehcileType',
        method: "POST",
        body
      }),
    }),

    
  }),
})

export const { useAddVehicleTypePricingMutation ,useGetDivisionListPricingQuery ,useGetAllVehicleTypePricingQuery ,useDeleteVehiclePricingDataMutation, useGetAllVehicleRateTypeQuery} = apiSlice
