import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { useGetPayrollCycleQuery , useGetPayrollBasedOnQuery, useGetPayrollTypeQuery} from "../../../features/admin/employee/employeeSlice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { changeSelected } from "../../../features/admin/employee/employeeSlice";


const PayrollInformation = ({ register, errors, employeeData,subscribe }) => {
    const dispatch = useDispatch()
    const {data:getPayrollCycle} = useGetPayrollCycleQuery()
    const {data:getPayrollBasedOn} = useGetPayrollBasedOnQuery()
    const {data:getPayrollType} = useGetPayrollTypeQuery()
    const selected = useSelector((state)=>state.employee.selected)
    const payrollBased = register("PayrollId", { required: 'Field is required', });
    return (
        <>
            <Row className="mt-2" >
                <Col sm={12}>
                    {/* First row */}
                    <Row>
                        <Col>
                            <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select payroll cycle">
                                    <Form.Select aria-label="Floating label select example" name="PayrollCycleId" {...register('PayrollCycleId', { required: 'Field is required', })}>
                                        <option value={employeeData?.payrollCycleId ? employeeData?.payrollCycleId : ""}>Select</option>
                                        {getPayrollCycle?.data.map((data, index) =>(
                                            <option key={index} value={data?.payrollCycleId} selected={data?.payrollCycleId === employeeData?.payrollCycleId ? true : false}>{data?.payrollCyclename}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.PayrollCycleId?.message && (<p className="error-message">{" * " + errors.PayrollCycleId?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select payroll based on">
                                    <Form.Select aria-label="Floating label select example" name="PayrollId" {...payrollBased} onChange={(e) => {payrollBased.onChange(e); dispatch(changeSelected(e.target.value)); }}>
                                        <option value={employeeData?.payrollId ? employeeData?.payrollId : ""}>Select</option>
                                        {getPayrollBasedOn?.data.map((data,index) =>(
                                            <option key={index} value={data?.payrollId} selected={data?.payrollId === employeeData?.payrollId ? true : false}>{data?.payrollname}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.PayrollId?.message && (<p className="error-message">{" * " + errors.PayrollId?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select payroll type">
                                    <Form.Select aria-label="Floating label select example" name="PayrollTypeId" {...register('PayrollTypeId', { required: 'Field is required', })}>
                                        <option value={employeeData?.payrollTypeId ? employeeData?.payrollTypeId : ""}>Select</option>
                                        {getPayrollType?.data.map((data, index) =>(
                                            <option key={index} value={data?.payrollTypeId} selected={data?.payrollTypeId === employeeData?.payrollTypeId ? true :false}>{data?.payrollTypeName}</option>
                                        ))}
                                       
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.PayrollTypeId?.message && (<p className="error-message">{" * " + errors.PayrollTypeId?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="date" autoComplete="off" placeholder="Birthday Date" name="DateofBirth" defaultValue={moment(employeeData?.dateofBirth).utc().format('YYYY-MM-DD')} 
                                {...register('DateofBirth', { required: 'Field is required', })} />
                                <label htmlFor="DateofBirth">Birthday Date</label>
                                {errors.DateofBirth?.message && (<p className="error-message">{" * " + errors.DateofBirth?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Sales Tax No." name="Salestaxno" defaultValue={employeeData?.salestaxno} {...register('Salestaxno', {})}/>
                                <label htmlFor="Salestaxno">Sales Tax No.</label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Sin no." name="SinNo" defaultValue={employeeData?.sinNo} {...register('SinNo', { required: 'Field is required', })} />
                                <label htmlFor="sinno">Sin no.</label>
                                {errors.SinNo?.message && (<p className="error-message">{" * " + errors.SinNo?.message}</p>)}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        {subscribe?.PayrollId === "1" &&
                            <Col sm={2}>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="Salary" defaultValue={employeeData?.salary} name="Salary" {...register('Salary', {})} min="0"/>
                                    <label htmlFor="salary">Salary</label>
                                </div>
                            </Col>
                        }
                        {subscribe?.PayrollId === "3" &&
                            <Col sm={2}>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Hourly rate" defaultValue={employeeData?.hourly} name="Hourly" {...register('Hourly', {})} min="0"/>
                                    <label htmlFor="Hourly rate">Hourly rate</label>
                                </div>
                            </Col>
                        }
                        {subscribe?.PayrollId === "4" && 
                        <>
                            <Col sm={2}>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Stop rate" name="StopRate" defaultValue={employeeData?.stopRate} {...register('StopRate', {})} min="0"/>
                                    <label htmlFor="Stop rate">Stop rate</label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Mile rate" name="MileRate" defaultValue={employeeData?.mileRate} {...register('MileRate', {})} min="0"/>
                                    <label htmlFor="Mile rate">Mile rate</label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Fuel rate" name="FuelRate" defaultValue={employeeData?.fuelRate} {...register('FuelRate', {})} min="0"/>
                                    <label htmlFor="Fuel rate">Fuel rate</label>
                                </div>
                            </Col>
                        </>
                        }
                    </Row>
                    {/* Second Row */}
                    <fieldset className="user-role-form mt-4 mb-3" style={{ background: "#3c65bb80" }}>
                        <label className="label-heading">Repetitive Deductions</label>
                        <Row>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Cargo Insurance" name="CargoInsurance" defaultValue={employeeData?.cargoinsurance} {...register('CargoInsurance', {})}/>
                                    <label htmlFor="lastname">Cargo Insurance</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Garnishment" name="Garnishment" defaultValue={employeeData?.garnishment} {...register('Garnishment', {})}/>
                                    <label htmlFor="Garnishment">Garnishment</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Uniform" name="Uniform" defaultValue={employeeData?.uniform}{...register('Uniform', {})}/>
                                    <label htmlFor="Uniform">Uniform</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Shareholders loans" name="ShareHolderLoans" defaultValue={employeeData?.shareHolderLoans}{...register('ShareHolderLoans', {})}/>
                                    <label htmlFor="Shareholders loans">Shareholders loans</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Extra Tax Deductions" name="ExtraTaxDeductions" defaultValue={employeeData?.extraTaxDeductions} {...register('ExtraTaxDeductions', {})}/>
                                    <label htmlFor="Extra Tax Deductions">Extra Tax Deductions</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="Refund Fuel Charge (%)" name="Refundfuelcharge" defaultValue={employeeData?.refundfuelcharge} {...register('Refundfuelcharge', {})}/>
                                    <label htmlFor="Refund Fuel Charge (%)">Refund Fuel Charge (%)</label>
                                </div>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        </>
    )
}
export default PayrollInformation;