import Wrapper from "../../Wrapper";
import { Tab, Tabs, Row, Col,Form, FloatingLabel,Button } from "react-bootstrap"
import CanadianSalesTable from "./CanadianSales";
import UsaSalesTable from "./UsaSales";


 const MonthlySales = ()=>{
     return (
         <>
         <Wrapper>
         <h5 className="ml-3 mb-3">Monthly Sales & Billing Totals</h5>
         <div className="sales-data">
         <Tabs defaultActiveKey="Canadian Sales & Billing Totals" id="uncontrolled-tab-example" className="mb-3" >
             {/* Select Canadian Sales & Billing Totals method */}
      <Tab eventKey="Canadian Sales & Billing Totals" title="Canadian Sales & Billing Totals">
      <Row>
                <Col sm={3}>
                <FloatingLabel controlId="floatingInput" label="Select Division">
                    <Form.Select aria-label="Default select example">
                        <option>Select Division.</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col sm={3}>
                    <div className="total">
                        <div className="total-text">Total Sales</div>
                        <input type="number" min="0" className="form-control"></input>
                    </div>
                </Col>
                <Col sm={3}>
                <div className="total">
                        <div className="total-text">Total Outstanding</div>
                        <input type="number" min="0" className="form-control"></input>
                    </div>
                </Col>
                <Col sm={3}>
                <div className="total">
                        <div className="total-text">Total Order</div>
                        <input type="number" min="0" className="form-control"></input>
                    </div>
                </Col>
            </Row>
            {/* Canadian sales table */}
            <CanadianSalesTable/>
      </Tab>
       {/* Select USA Sales & Billing Totals method */}
      <Tab eventKey="USA Sales & Billing Totals" title="USA Sales & Billing Totals">
      <Row>
                <Col sm={3}>
                <FloatingLabel controlId="floatingInput" label="Select Division">
                    <Form.Select aria-label="Default select example">
                        <option>Select Division.</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col sm={3}>
                    <div className="total">
                        <div className="total-text">Total Sales</div>
                        <input type="number" min="0" className="form-control"></input>
                    </div>
                </Col>
                <Col sm={3}>
                <div className="total">
                        <div className="total-text">Total Outstanding</div>
                        <input type="number" min="0" className="form-control"></input>
                    </div>
                </Col>
                <Col sm={3}>
                <div className="total">
                        <div className="total-text">Total Order</div>
                        <input type="number" min="0" className="form-control"></input>
                    </div>
                </Col>
            </Row>
            {/* Canadian sales table */}
            <UsaSalesTable/>
      </Tab>
    </Tabs>
    </div>
         </Wrapper>
         </>
     );
 }
 export default MonthlySales;