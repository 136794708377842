import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Register from "../src/Component/Login/Register"
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./Component/Layout";
import RequireAuth from "./Component/RequireAuth";
import Login from "../src/Component/Login/Login";
import Dashboard from "./Component/Dashboard/Dashboard";
import Forgot from "../src/Component/Login/Forgot";
import Resetpassword from "../src/Component/Login/Resetpassword";
import Order from "./Component/Order/OrderTaking/Order";
import Customer from "./Component/Customer/Customer";
import AdminRole from "./Component/Admin/RoleManagement/AdminRole/AdminRole";
import UserRole from "./Component/Admin/RoleManagement/UserRole/UserRole";
import LocalDispatch from "./Component/Dispatch/SamedayDispatch/Dispatch";
import Employee from "./Component/Admin/Employee/Employee";
import Vehicle from "./Component/Admin/Vehicle/Vehicle"
import HistoryManagement from "./Component/Customer/HistoryManagement/HistoryManagement";
import Interliners from "./Component/Dispatch/Interliners/Interliners"
import EnterABankDeposit from "./Component/AR/EnterABankDeposit/EnterAbankDeposit";
import AccountEnquiry from "./Component/AR/AccountEnquiry/AccountEnquiry";
import MonthlySales from "./Component/AR/MonthlySales/MonthlySales";
import AgedReceivableReport from "./Component/AR/AgedReceivableReport/AgedReceivableReport";
import DepostDistribution from "./Component/AR/DepositDistribution/DepositDistribution";
import TripDispatch from "./Component/Dispatch/TripDispatch/TripDispatch";
import ProcessCustomerInvoice from "./Component/Billing/ProcessCutomerInvoice";
import ProcessPrebookedOrders from "./Component/Dispatch/ProcessPrebookedOrders/ProcessPrebookedOrders";
import TripControlSystem from "./Component/Dispatch/TripControlSystem/TripControlSystem";
import AceCommunications from "./Component/Dispatch/ACECommunication/AceCommunication";
import CustomBrokerList from "./Component/Dispatch/CustomBrokerList/CustomBrokerList";
import Acicommunicator from "./Component/Dispatch/AciCommunicator/AciCommunicator";
import VerifyOrderModification from "./Component/Report/VerifyOrderModifications/VerifyOrderModification";
import SalesCalender from "./Component/Report/SalesCalender/SalesCalender";
import DetailedExcelReport from "./Component/Report/DetailedExcelReport/DetailedExcelReport";
import OrderMaintenance from "./Component/Order/OrderMaintenance/OrderMaintenance";
import OrderTracking from "./Component/Order/OrderTracking/Ordertracking";
import AppointmentDesk from "./Component/Order/Appointmentdesk/AppointmentDesk";
import UploadOrderList from "./Component/Order/UploadOrderList/UploadOrderList";
import CompanyProfile from "./Component/Admin/CompanyProfile/CompanyProfile";
import Users from "./Component/Admin/Users/Users";
import AdminParent from "./Component/Admin/DefaultPricing/AdminParent";
import ProcessPayroll from "./Component/Payroll/ProcessPayroll/ProcessPayroll";
import TripControlPayroll from "./Component/Payroll/TripControlPayroll/TripControlPayroll";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";

const App = ()=>{
  const [loggedIn, setLoggedIn] = useState(true);
  const checkForInactivity = () =>{
    const expireTime = sessionStorage.getItem("expireTime")
     if (expireTime < Date.now()){
      setLoggedIn(false);
      localStorage.clear();
      // toast.error("Session Expired !",{
      //   position: "top-center",
      // })
     }
  }


const updateExpireTime = () =>{
  const expireTime = Date.now() + 3600000;
  sessionStorage.setItem("expireTime", expireTime);
}

useEffect(() =>{
  const interval =setInterval(()=>{
    checkForInactivity()
  },1000);

  return()=>{
    clearInterval(interval)
  }
},[]);

useEffect(() =>{
  updateExpireTime();
  window.addEventListener("click", updateExpireTime);
  window.addEventListener("keypress", updateExpireTime);
  window.addEventListener("scroll", updateExpireTime);
  window.addEventListener("mouseover", updateExpireTime)

  return () =>{
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mouseover", updateExpireTime)
  }

},[])

  return (
    <>
   <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
        <Route exact path="login" element={<Login />} />
        <Route exact path="register" element={<Register />} />
        <Route exact path="forgot" element={<Forgot />} />
        <Route exact path="reset" element={<Resetpassword />} />
        <Route element={<RequireAuth />}>
          
        <Route exact index element={<Dashboard />} />

        <Route exact path="/AR/enter-a-bank-deposit" element={<EnterABankDeposit />} />
        <Route exact path="/AR/account-enquiry" element={<AccountEnquiry/>}/>
        <Route exact path="/AR/monthly-sales-Billing-Totals" element={<MonthlySales/>}/>
        <Route exact path="/AR/AG-aged-receivable-report" element={<AgedReceivableReport/>}/>
        <Route exact path="/AR/deposit" element={<DepostDistribution/>}/>

        <Route exact path="/billing/processcustomerinvoice" element={<ProcessCustomerInvoice/>}></Route>

        <Route exact path="/customer/CustomerCrud" element={<Customer />}></Route>
        <Route exact path="/customer/history-management" element={<HistoryManagement/>}></Route>

        <Route exact path="/dispatch/process-prebooked-order" element={<ProcessPrebookedOrders/>}></Route>
        <Route exact path="/dispatch/trip-control" element={<TripControlSystem/>}></Route>
        <Route exact path="/dispatch/ace-communication" element={<AceCommunications/>}></Route>
        <Route exact path="/dispatch/custom-broker-list" element={<CustomBrokerList/>}></Route>
        <Route exact path="/dispatch/trip-dispatch" element={<TripDispatch/>}/>
        <Route exact path="/dispatch/aci-communicator" element={<Acicommunicator/>}></Route>
        <Route exact path="/dispatch/same-day-dispatch" element={<LocalDispatch />} />        
        <Route exact path="/dispatch/interliners"  element={<Interliners/>}></Route>

        <Route exact path="/report/verify-order-modification" element={<VerifyOrderModification />} />
        <Route exact path="/report/detailed-excel-report" element={<DetailedExcelReport />} />
        <Route exact path="/report/sales-calender" element={<SalesCalender />} />

        <Route exact path="/order/order-maintenance" element={<OrderMaintenance />} />
        <Route exact path="/order/order-tracking" element={<OrderTracking />} />
        <Route exact path="/order/appointment-desk" element={<AppointmentDesk />} />
        <Route exact path="/order/order-taking-by-telephone" element={<Order />} />
        <Route exact path="/order/upload-order" element={<UploadOrderList />} />

        <Route exact path="/admin/company-profile" element={<CompanyProfile/>}></Route>
        <Route exact path="/admin/users" element={<Users/>}></Route>
        <Route exact path="/admin/vehicle" element={<Vehicle/>}></Route>
        <Route exact path="/admin/employee" element={<Employee/>}></Route>
        <Route exact path="/admin/default-pricing" element={<AdminParent />}></Route>
        <Route exact path="/payroll/process-payroll" element={<ProcessPayroll/>}></Route>
        <Route exact path="/payroll/trip-control-payroll" element={<TripControlPayroll />}></Route>
        <Route exact path="/role-management/admin-role/" element={<AdminRole />}></Route>
        <Route exact path="/role-management/user-role" element={<UserRole />}></Route>
        </Route>

        </Route>
        </Routes>
    </BrowserRouter>
    <ToastContainer />
    </>
  );
}

export default App;
