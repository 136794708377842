import Dropupinfo from "./Dropupinfo"
import Personalinfo from "./Personalinfo"
import Pickupinfo from "./Pickupinfo"
import Orderinfo from "./Orderinfo";

export const Form = [
    {
        header: 'Account Information',
        content: <Personalinfo></Personalinfo>
    },
    {
        header: 'Pick-up Information',
        content: <Pickupinfo></Pickupinfo>
    },
    {
        header: 'Drop-off Information',
        content: <Dropupinfo></Dropupinfo>
    },
    {
        header: 'Order Information',
        content: <Orderinfo></Orderinfo>
    },
]