function validateDropUp(dropInfo) {
    let dropErrors = {};
    if (!dropInfo?.customer) {
      dropErrors.customer = 'Field is required';
    }
    if (!dropInfo?.street) {
      dropErrors.street = 'Field is required';
    }
    if (!dropInfo?.streetName) {
      dropErrors.streetName = 'Field is required';
    }
    // if (!dropInfo?.unit) {
    //   dropErrors.unit = 'Field is required';
    // }
    if (!dropInfo?.countryId) {
        dropErrors.countryId = 'Field is required';
      } 
      if (!dropInfo?.provineStateID) {
        dropErrors.provineStateID = 'Field is required';
      } 
    if (!dropInfo?.city) {
      dropErrors.city = 'Field is required';
    }
    if (!dropInfo?.postalCode) {
      dropErrors.postalCode = 'Field is required';
    } 
    if (!dropInfo?.contact) {
        dropErrors.contact = 'Field is required';
      }
      if (!dropInfo?.phone) {
        dropErrors.phone = 'Field is required';
      }
      // if (!dropInfo?.phone_Ext) {
      //   dropErrors.phone_Ext = 'Field is required';
      // }
      if (!dropInfo?.emailAddress) {
        dropErrors.emailAddress = 'Field is required';
      }
      // if (!dropInfo?.appointment) {
      //   dropErrors.appointment = 'Field is required';
      // }
      // if (!dropInfo?.openingTime) {
      //   dropErrors.openingTime = 'Field is required';
      // }
      // if (!dropInfo?.closeTime) {
      //   dropErrors.closeTime = 'Field is required';
      // }
    return dropErrors;
  };

  export default validateDropUp