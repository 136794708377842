import Wrapper from "../../Wrapper";

 const ProcessPrebookedOrders = ()=>{
     return (
         <>
         <Wrapper>
            <h3 style={{textAlign: "center",position: "relative", top: "50px", color:"#bf2527"}}>Process Prebooked Orders</h3>
            
         </Wrapper>
         </>
     );
 }
 export default ProcessPrebookedOrders;