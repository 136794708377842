import { useState} from "react";
import setting from '../../assets/setting.png';
import { useNavigate } from 'react-router-dom';
import { Person } from '@material-ui/icons';
import axios from 'axios';
import { useEffect } from 'react';
import userprofile from '../../assets/user-profile.png';
import user from '../../assets/user.png';

const Userprofile = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const emailId = localStorage.getItem("emailId");
  const userName = localStorage.getItem("userName");

  function logout() {
    localStorage.clear();
    navigate('/login')
  };
  return (
    <div className='dropdown'>
      <div className='dropdown-header' onClick={toggleDropdown}>
          <div className='profile-pic'><Person /></div>          
        <span>{emailId}</span>
        <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
      </div>
      <div className={`dropdown-body ${isOpen && 'open'}`}>
       
         <div className="dropdown-item">
            <img src={user} alt='user profile' />
            <span>Profile</span>
          </div>
          <div className="dropdown-item">
            <img src={setting} alt='user profile' />
            <span>Setting</span>
          </div>
          <div className="dropdown-item" onClick={logout}>
            <span><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</span>
          </div>
      </div>
    </div>
  )
}
export default Userprofile