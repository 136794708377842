import { Col, Row, Form, FloatingLabel, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from "react";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import ContactInfo from "./Contact";
import { addNameAddress } from "../../../features/nameAddress/nameAddressSlice";
import phoneNumberMasking from "../PhoneNumberMasking";
import useCustomer from "../../../context/CustomerContext";
import Validatecustomer from "../ValidateCustomer";
import postalCodeMasking from "../PostalCodeMasking";
import CountryProvince from "../../CountryProvince";
import { Typeahead } from 'react-bootstrap-typeahead';
import InputMask from 'react-input-mask';


const CustomerInfo = () => {
    const { errors, setErrors, addressData, setAddressData, setTempDisplay, showNotes, setShowNotes } = useCustomer()
    const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity, handleProvince, newHandleCountry } = CountryProvince()
    const dispatch = useDispatch();
    const [key, setKey] = useState("Customerdetails");
    // const [addressData, setAddressData] = useState({}); 
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fax, setFax] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [postal, setPostal] = useState("")
    const [checkedData, setCheckedData] = useState(false);
    const officeDatepicker = addressData?.OfficeHour && addressData?.OfficeHour?.toLocaleDateString("es-CL").split("-").reverse().join("-");
    const officeTimepicker = addressData?.OfficeHour && addressData?.OfficeHour?.toLocaleTimeString("es-CL");
    const openTimepicker = officeDatepicker?.concat(" ", officeTimepicker)
    const closeDatepicker = addressData?.CloseTime && addressData?.CloseTime?.toLocaleDateString("es-CL").split("-").reverse().join("-");
    const closeTimepicker = addressData?.CloseTime && addressData?.CloseTime?.toLocaleTimeString("es-CL");
    const closeHourpicker = closeDatepicker?.concat(" ", closeTimepicker)
    const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
    const [selection, setSelection] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const {control} = useForm({mode: "onSubmit"});

    useEffect(async () => {
        if (selection.length > 0) {
            const pList = await handleProvince(selection[0].id);
            setProvinceList(pList.data);
        }
        else {
            setProvinceList([]);
            setCountryList([]);
        }
    }, [selection])

    const setCountry = async (e) => {
        if (e.target.value !== 'Select') {
            const cList = await newHandleCountry(e.target.value);
            setCountryList(cList.data);
        }
        else {
            setCountryList([]);
        }
    }

    useEffect(() => {
        setAddressData(addressData => ({...addressData, OfficeHour: new Date("01/01/2023 09:00 AM"), CloseTime: new Date("01/01/2023 05:00 PM") }));
    }, []);

    useEffect(() => {
        if (selection.length > 0) {
            // update data
            setTempDisplay(false)
            setAddressData(addressData => ({ ...addressData, CityId: selection[0].id }))
            setSelectCity(selection[0].id);
            // setErrors(Validatecustomer(addressData));
            const timer = setTimeout(() => {
                dispatch(addNameAddress({ ...addressData, CityId: selection[0].id }))
            }, 1000);
            return () => clearTimeout(timer)
        }
        else {
            // clear data
            setTempDisplay(false)
            setAddressData(addressData => ({ ...addressData, CityId: null }))
            setSelectCity(null);
            // setErrors(Validatecustomer(addressData));
            const timer = setTimeout(() => {
                dispatch(addNameAddress({ ...addressData, CityId: null }))
            }, 1000);
            return () => clearTimeout(timer)
        }
    }, [selection]);

    const addressHandler = (e) => {
        setTempDisplay(false)
        setAddressData(addressData => ({ ...addressData, [e.target.name]: e.target.value }))
        /* setErrors(Validatecustomer(addressData)); */
        const timer = setTimeout(() => {
            //dispatch(addCustomer(addressData))
            dispatch(addNameAddress({ ...addressData, [e.target.name]: e.target.value }))
        }, 1000);
        return () => clearTimeout(timer)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            addressData.isNotesAppearOnOrder = delete
                dispatch(addNameAddress({ isNotesAppearOnOrder: checkedData, ...addressData }))
        }, 1000);
        return () => clearTimeout(timer)
    }, [])


    const handleInput = e => {
        const formattedPhoneNumber = phoneNumberMasking(e.target.value);
        const formattedPostalCode = postalCodeMasking(e.target.value)
        if (e.target.name === "Phone") {
            setPhoneNumber(formattedPhoneNumber);
        }
        else if (e.target.name === "Fax") {
            setFax(formattedPhoneNumber)
        }
        else if (e.target.name === "Contact") {
            setContactNumber(formattedPhoneNumber)
        }
        else if (e.target.name === "PostalCode") {
            setPostal(formattedPostalCode)
        }
    };
    

    return (
        <>
            <Row className="mb-4 mt-4">
                <Col sm={12}>
                    <div className="name-sec">
                        <Row className="mb-3">
                            <Col sm={4}>
                                <div className="form-floating">
                                    <Form.Select aria-label="Floating label select example" name="AccountType"
                                        onChange={(e) => { addressHandler(e) }} value={addressData.AccountType || ''} className={`input ${errors.AccountType && 'is-danger'}`}>
                                        <option>Select</option>
                                        <option>Inactive Account</option>
                                        <option>CSA Account</option>
                                        <option>US Based Account</option>
                                        <option>Freight Broker</option>
                                    </Form.Select>
                                    <label htmlFor="accounttype">Account Type</label>
                                </div>
                                {errors.AccountType && (<p className="help is-danger">{errors.AccountType}</p>)}
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="accountnumber">
                                    <FloatingLabel controlId="floatingInput" label="Account Number">
                                        <Form.Control type="text" placeholder="Account Number" name="AccountName" onChange={e => addressHandler(e)} disabled />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <span className="last-order-update">
                                    <p>Last order 20/11/2021 08:30:00PM</p>
                                </span>
                            </Col>
                        </Row>
                        {/* 2nd Row */}
                        <Row className="mb-4">
                            {/* <Col sm={4}>
                                <Form.Group controlId="Open Time">
                                    <FloatingLabel controlId="floatingInput" label="Open Time" >
                                        <Form.Control type="time" placeholder="Open time" name="OfficeHour" value={addressData.OfficeHour} onChange={e => addressHandler(e)}
                                            className={`input ${errors.OfficeHour && 'is-danger'}`} />
                                    </FloatingLabel>
                                    <p className="error-message">{errors.OfficeHour?.message}</p>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="Open Time">
                                    <FloatingLabel controlId="floatingInput" label="Close Time" >
                                        <Form.Control type="time" placeholder="Close time" name="CloseTime" value={addressData.CloseTime} onChange={e => addressHandler(e)}
                                            className={`input ${errors.CloseTime && 'is-danger'}`} />
                                    </FloatingLabel>
                                    <p className="error-message">{errors.CloseTime?.message}</p>
                                </Form.Group>
                            </Col> */}
                            <Col sm={4}>
                                <div className="timepicker">
                                    <TimePickerComponent id="time" placeholder="Open time" onChange={e => addressHandler(e)} name="OfficeHour" className={`input ${errors.OfficeHour && 'is-danger'}`} value={"9:00 AM"} format={'hh:mm a'}/>
                                </div>
                                {errors.OfficeHour && (<p className="help is-danger">{errors.OfficeHour}</p>)}
                            </Col>
                            <Col sm={4}>
                                <div className="timepicker">
                                    <TimePickerComponent id="time1" placeholder="Close time" onChange={e => addressHandler(e)} name="CloseTime"  className={`input ${errors.CloseTime && 'is-danger'}`} value={"5:00 PM"} format={'hh:mm a'} />
                                </div>
                                {errors.CloseTime && (<p className="help is-danger">{errors.CloseTime}</p>)}
                            </Col>
                            <Col sm={4}>
                                <div className="form-floating">
                                    <Form.Select aria-label="Floating label select example" name="CustomerFrequencyVolumeID" onChange={(e) => { addressHandler(e) }}
                                        value={addressData.CustomerFrequencyVolumeID || ''} className={`input ${errors.CustomerFrequencyVolumeID && 'is-danger'}`}>
                                        <option>Select</option>
                                        <option>Daily</option>
                                        <option>Weekly</option>
                                        <option>Semi-monthly</option>
                                        <option>Monthly</option>
                                        <option>Occasionally</option>
                                        <option>Fledged</option>
                                    </Form.Select>
                                    <label>Customer Frequency Volume</label>
                                    {errors.CustomerFrequencyVolumeID && (<p className="help is-danger">{errors.CustomerFrequencyVolumeID}</p>)}
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* 2nd fieldset */}
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 contact_listing" >
                <Tab eventKey="Customerdetails" title="Customer-Details" id="name_address">
                    <Row className="mb-3">
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Customer Name" name="Customer" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.Customer || ''} className={`input ${errors.Customer && 'is-danger'}`} />
                                <label htmlFor="Customer">Customer</label>
                            </div>
                            {errors.Customer && (<p className="help is-danger">{errors.Customer}</p>)}
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Street#" name="Street" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.Street || ''} className={`input ${errors.Street && 'is-danger'}`} />
                                <label htmlFor="Street">Street No.</label>
                            </div>
                            {errors.Street && (<p className="help is-danger">{errors.Street}</p>)}
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Street Name" name="StreetName" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.StreetName || ''} className={`input ${errors.StreetName && 'is-danger'}`} />
                                <label htmlFor="StreetName">Street Name</label>
                            </div>
                            {errors.StreetName && (<p className="help is-danger">{errors.StreetName}</p>)}
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Unit" name="Unit" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.Unit || ''} className={`input ${errors.Unit && 'is-danger'}`} />
                                <label htmlFor="unit">Unit</label>
                            </div>
                            {errors.Unit && (<p className="help is-danger">{errors.Unit}</p>)}
                        </Col>
                    </Row>
                    {/* 2nd Row */}
                    <Row className="mb-3">
                        <Col sm={3}>
                            <Form.Group controlId="City">
                                {/* <FloatingLabel controlId="floatingInput" label="City" >  */}
                                    {/* <Form.Select aria-label="Floating label select example" name="CityId" onChange={e => { addressHandler(e); setSelectCity(e.target.value) }} value={addressData.CityId || ''} className={`input ${errors.CityId && 'is-danger'}`}> */}
                                        <Typeahead
                                            id='cityAutocomplete'
                                            labelKey="name"
                                            onChange={setSelection}
                                            options={cityList}
                                            placeholder="Choose a city..."
                                            selected={selection}
                                        />
                                    {/* </Form.Select> */}
                                {/* </FloatingLabel>  */}
                                {errors.CityId && (<p className="help is-danger">{errors.CityId}</p>)}
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="Province/State">
                                <FloatingLabel controlId="floatingSelectGrid" label="Province/State">
                                    <Form.Select aria-label="Floating label select example" name="ProvineStateID" onChange={(e) => {setSelectState(e.target.value); addressHandler(e); handleCity(e); setCountry(e) }} value={addressData.ProvineStateID || ''} className={`input ${errors.ProvineStateID && 'is-danger'}`}>
                                        <option>Select</option>:
                                        <>
                                            {
                                                provinceList?.data?.map((data, index) => (
                                                    <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                ))

                                            }
                                        </>

                                    </Form.Select>
                                </FloatingLabel>
                                {errors.ProvineStateID && (<p className="help is-danger">{errors.ProvineStateID}</p>)}
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="country">
                                <FloatingLabel controlId="floatingSelectGrid" label="Country">
                                    <Form.Select aria-label="Floating label select example" name="CountryId" onChange={e => { handlecountry(e); addressHandler(e) }} value={addressData.CountryId || ''} className={`input ${errors.CountryId && 'is-danger'}`}>

                                        <option>Select</option>:
                                        <>
                                            {
                                                countryList?.data?.map((data, index) => (
                                                    <option key={index} value={data.countryId}>{data.countryname}</option>
                                                ))

                                            }
                                        </>
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.CountryId && (<p className="help is-danger">{errors.CountryId}</p>)}
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Postal code" name="PostalCode" onChange={(e) => { handleInput(e); addressHandler(e) }}
                                    value={postal} className={`input ${errors.PostalCode && 'is-danger'}`} />
                                <label htmlFor="postal code">Postal Code</label>
                            </div>
                            {errors.PostalCode && (<p className="help is-danger">{errors.PostalCode}</p>)}
                        </Col>
                    </Row>
                    {/* 3rd row */}
                    <Row className="mb-3">
                        <Col sm={3}>
                            <div className="form-floating">
                               {/*  <Form.Control type="text" autoComplete="off" placeholder="Phone" name="Phone" onChange={(e) => { handleInput(e); addressHandler(e) }}
                                    value={phoneNumber} className={`input ${errors.Phone && 'is-danger'}`} /> */}
                                <Controller type="text" placeholder="Phone"
                                    name="Phone"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) 999 - 9999" maskChar="_" value={value || ""} onChange={(e) => {onChange(e.target.value); addressHandler(e)}}>
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className={`form-control input ${errors.Phone && 'is-danger'}`}
                                                    placeholder="Phone"
                                                    name="Phone"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                                 <label htmlFor="Phone">Phone</label> 
                            </div>
                            {errors.Phone && (<p className="help is-danger">{errors.Phone}</p>)}
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                {/* <Form.Control type="text" autoComplete="off" placeholder="Fax" name="Fax" onChange={(e) => { handleInput(e); addressHandler(e) }}
                                    value={fax} className={`input ${errors.Fax && 'is-danger'}`} /> */}
                                <Controller type="text" placeholder="Fax"
                                    name="Fax"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) 999 - 9999" maskChar="_" value={value || ""} onChange={(e) => {onChange(e.target.value); addressHandler(e)}}>
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className={`form-control input ${errors.Fax && 'is-danger'}`}
                                                    placeholder="Fax"
                                                    name="Fax"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                 />
                                <label htmlFor="fax">Fax</label>
                            </div>
                            {errors.Fax && (<p className="help is-danger">{errors.Fax}</p>)}
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Phone Ext" name="Phone_Ext" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.Phone_Ext || ''} className={`input ${errors.Phone_Ext && 'is-danger'}`} />
                                <label htmlFor="phoneextension">Phone Extension</label>
                            </div>
                            {errors.Phone_Ext && (<p className="help is-danger">{errors.Phone_Ext}</p>)}
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Contact" name="Contact" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.Contact || ''} className={`input ${errors.Contact && 'is-danger'}`} />
                                <label htmlFor="contact">Contact Name</label>
                            </div>
                            {errors.Contact && (<p className="help is-danger">{errors.Contact}</p>)}
                        </Col>
                    </Row>
                    {/* 4th row */}
                    <Row className="mb-3">
                        <Col sm={12}>
                            <div className="form-floating">
                                <Form.Control type="email" autoComplete="off" placeholder="Email" name="EmailAddress" onChange={(e) => { addressHandler(e) }}
                                    value={addressData.EmailAddress || ''} className={`input ${errors.EmailAddress && 'is-danger'}`} />
                                <label htmlFor="Email">Email</label>
                            </div>
                            {errors.EmailAddress && (<p className="help is-danger">{errors.EmailAddress}</p>)}
                        </Col>
                    </Row>
                    {/* 3rd section */}
                    <Row className="mb-4">
                        <Col sm={12}>
                            <div className="form-check">
                                <input type="checkbox" id="formBasicCheckbox" className="form-check-input" name="isNotesAppearOnOrder" 
                                    onClick={() => setShowNotes(!showNotes)} value={showNotes} />
                                    
                                <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Notes will appear at order taking system</label>
                            </div>
                            <FloatingLabel controlId="floatingTextarea2" label="Type something...">
                                <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '100px' }} name="notes" onChange={e => addressHandler(e)} value={addressData.notes || ''} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Tab>
                {/* Contact details tab */}
                <Tab eventKey="Contactdetails" title="Contact-Details">
                    <ContactInfo />
                </Tab>
            </Tabs>

        </>
    );
}
export default CustomerInfo;