import Wrapper from "../../Wrapper";
import OrderDetails from "./OrderDetails/OrderDetails";
import { Row, Col } from "react-bootstrap";

 const OrderMaintenance = ()=>{
     return (
         <>
         <Wrapper>
         <h5 className="ml-3 mb-3">Order Maintenance & Post Accepted</h5>
         <Row>
            <Col sm={9}><OrderDetails/></Col>
         </Row>

         </Wrapper>
         </>
     );
 }
 export default OrderMaintenance;