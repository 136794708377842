import { Tab, Tabs, Row, Col,Form, FloatingLabel,Button } from "react-bootstrap"
import PaidAmountTable from "./PaidAmountDetail"



const PaymentDetails = () =>{
return(
    <>
    <div className="payment-details">
        <Row>
            <Col sm={6}>
                <h6>Payment Details</h6>
            </Col>
            <Col sm={6}>
            <Form.Select aria-label="Default select example">
                        <option>Select AccountNo.</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
            </Col>
        </Row>
        {/* Select payment method */}
        <fieldset style={{marginBottom:"6px",marginTop:"12px"}}>
            <label className="label-heading">Select the payment method</label>
        <Tabs defaultActiveKey="Cheque" id="uncontrolled-tab-example" className="mb-3" style={{marginTop:"-22px"}} >
             {/* Select Cheque method */}
      <Tab eventKey="Cheque" title="Cheque">
        <>
        <Row>
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Effective date">
                            <Form.Control type="date" placeholder="Effective date" name="EffectiveDate" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Cheque Number">
                            <Form.Control type="text" placeholder="Cheque Number" name="ChequeName" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>
        <fieldset style={{marginBottom:"12px",marginTop:"24px"}}>
            <label className="label-heading">Select the payment method</label>
            <div className="floating-checbox">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="CWT" name="RateType" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Canadian dollars</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="PALLET" name="RateType"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Cheque Number</label>
                              </div>
                            </div>
                            <Row className="mb-2">
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Amount">
                            <Form.Control type="number" placeholder="Amount" name="Amount" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Posted">
                            <Form.Control type="number" placeholder="Posted" name="posted" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Balance">
                            <Form.Control type="number" placeholder="Balance" name="Balance" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Discount/Adjustment">
                            <Form.Control type="number" placeholder="Discount/Adjustment" name="Discount/Adjustment" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>  
            </fieldset>
            </>
      </Tab>
       {/* Select EFT method */}
      <Tab eventKey="EFT" title="EFT">
      <>
        <Row className="mb-2">
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Effective date">
                            <Form.Control type="date" placeholder="Effective date" name="EffectiveDate" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Tranfer Number">
                            <Form.Control type="number" placeholder="Tranfer Number" name="TransferName" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="First Name">
                            <Form.Control type="number" placeholder="First Name" name="Firstname" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Last Number">
                            <Form.Control type="number" placeholder="Last Number" name="LastName" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col sm={4}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Routing Number">
                            <Form.Control type="number" placeholder="Routing Number" name="Routingnumber" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={4}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Account Number">
                            <Form.Control type="number" placeholder="Account Number" name="Account Number" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={4}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Reference Number">
                            <Form.Control type="number" placeholder="Reference Number" name="Reference Number" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>
        <fieldset style={{marginBottom:"12px",marginTop:"24px"}}>
            <label className="label-heading">Select the currency value</label>
            <div className="floating-checbox">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="CWT" name="RateType" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Canadian dollars</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="PALLET" name="RateType"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Cheque Number</label>
                              </div>
                            </div>
            <Row className="mb-2">
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Amount">
                            <Form.Control type="number" placeholder="Amount" name="Amount" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Posted">
                            <Form.Control type="number" placeholder="Posted" name="posted" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col sm={6}>
            <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Balance">
                            <Form.Control type="number" placeholder="Balance" name="Balance" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={6}>
            <Form.Group controlId="Cheuquenumber">
                        <FloatingLabel controlId="floatingInput" label="Discount/Adjustment">
                            <Form.Control type="number" placeholder="Discount/Adjustment" name="Discount/Adjustment" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
            </Col>
        </Row>  
            </fieldset>
            </>
      </Tab>
    </Tabs>
    <div className="" style={{float:"right"}}>
    <Button variant="outline-primary" className="payment-button">Save</Button>
    </div>
    </fieldset>
    </div>
    {/* Paid AMount Table  */}
    <PaidAmountTable/>
    </>
)
}
export default PaymentDetails