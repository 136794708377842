import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import useCustomer from "../../../context/CustomerContext";
import { useAddCustomerFuelLtlMutation, useEditLessFuelEffectiveMutation, useDeleteLessFuelMutation } from "../../../features/lessLoad/lessLoadSlice";
import { useAddCustomerFuelFtlMutation, useEditFullFuelEffectiveMutation } from "../../../features/fullLoad/fullLoadSlice";
import { ToastContainer, toast } from 'react-toastify';
import { addNewRecord } from "@syncfusion/ej2/pivotview";
import { useAddCustomerFuelHtlMutation, useEditHeavyFuelEffectiveMutation } from "../../../features/heavyLoad/heavyLoadSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useDeleteData from "../useDeleteData";

const EditRemoteLogin = () => {
  const { lessTruckLoad, fullTruckLoad, heavyTruckLoad, handleAccount, singleAccount, selected, contractInfo } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0]
  const [editLessLoads] = useEditLessFuelEffectiveMutation()
  const [editFullLoads] = useEditFullFuelEffectiveMutation()
  const [editHeavyLoads] = useEditHeavyFuelEffectiveMutation()
  const [deleteLessFuel] = useDeleteLessFuelMutation()
  const [addfuelLtl] = useAddCustomerFuelLtlMutation()
  const [addfuelFtl] = useAddCustomerFuelFtlMutation()
  const [addfuelHtl] = useAddCustomerFuelHtlMutation()
  const [editId, setEditId] = useState('')
  const [lessFuel, setLessFuel] = useState({})
  const [fullFuel, setFullFuel] = useState({})
  const [heavyFuel, setHeavyFuel] = useState({})
  const account = singleAccount && singleAccount[0];
  const [handleClose,handleOpen,open,deleteData] = useDeleteData(deleteLessFuel)


  const lessFuelhandler = (e) => { setLessFuel(lessFuel => ({ ...lessFuel, [e.target.name]: e.target.value })) }
  const fullFuelhandler = (e) => { setFullFuel(fullFuel => ({ ...fullFuel, [e.target.name]: e.target.value })) }
  const heavyFuelhandler = (e) => { setHeavyFuel(heavyFuel => ({ ...heavyFuel, [e.target.name]: e.target.value })) }

  const editLtl = (fuelsurchargerateid) => {
    setEditId(fuelsurchargerateid)
    let newEditItem = lessTruckLoad.find((data) => {
      return data?.fuelsurchargerateid === fuelsurchargerateid
    });
    setLessFuel(newEditItem)
  }


  const addRecordLtl = async () => {
    try {
      const data = {
        "fuelsurchargerateid": editId,
        "rate": lessFuel?.ltlRate,
        "effectiveDate": lessFuel?.ltlEffectiveDate,
        "weghtType": lessFuel?.ltlWeghtType
      }
      const updateData = await editLessLoads(data)
      if (updateData?.data?.statusCode === 200) {
        setLessFuel("")
        handleAccount(account.account_No)
      }
    }
    catch {
      toast.warning("An Error Occured!", {
        position: "top-center",
      })
    }
  }
  const editFTL = (id) => {
    setEditId(id)
    let newEditItem = fullTruckLoad.find((data) => {
      return data?.fuelsurchargerateid === id
    });
    setFullFuel(newEditItem)
  }
  const addRecordFTL = async () => {
    try {
      const data = {
        "fuelsurchargerateid": editId,
        "rate": fullFuel?.ftlRate,
        "effectiveDate": fullFuel?.ftlEffectiveDate,
        "weghtType": fullFuel?.ftlWeghtType
      }
      const updateData = await editFullLoads(data)
      if (updateData?.data?.statusCode === 200) {
        setFullFuel("")
        handleAccount(account.account_No)
      }
    }
    catch {
      toast.warning("An Error Occured!", {
        position: "top-center",
      })
    }
  }

  const editHTL = (id) => {
    setEditId(id)
    let newEditItem = heavyTruckLoad.find((data) => {
      return data?.fuelsurchargerateid === id
    });
    setHeavyFuel(newEditItem)
  }

  const addRecordHTL = async () => {
    try {
      const data = {
        "fuelsurchargerateid": editId,
        "rate": heavyFuel?.htlRate,
        "effectiveDate": heavyFuel?.htlEffectiveDate,
        "weghtType": heavyFuel?.htlWeghtType
      }
      const updateData = await editHeavyLoads(data)
      if (updateData?.data?.statusCode === 200) {
        setHeavyFuel("")
        handleAccount(account.account_No)
      }
    }
    catch {
      toast.warning("An Error Occured!", {
        position: "top-center",
      })
    }
  }
  const addnewRecordLtl = async () => {
    const data = {
      "rate": lessFuel?.ltlRate,
      "effectiveDate": lessFuel?.ltlEffectiveDate,
      "weghtType": "LTL"
    }

    const addnewLTL = await addfuelLtl({ rateCardId: ratecardaccount?.rateCardId, ...data })
    if (addnewLTL?.data?.statusCode === 200) {
      setLessFuel("")
      handleAccount(account.account_No)
    }
  }

  const addNewRecordFTL = async () => {
    const data = {
      "rate": fullFuel?.ftlRate,
      "effectiveDate": fullFuel?.ftlEffectiveDate,
      "weghtType": "FTL"
    }

    const addnewFTL = await addfuelFtl({ rateCardId: ratecardaccount?.rateCardId, ...data })
    if (addnewFTL?.data?.statusCode === 200) {
      setFullFuel("")
      handleAccount(account.account_No)
    }
  }
  const addNewRecordHTL = async () => {
    const data = {
      "rate": heavyFuel?.htlRate,
      "effectiveDate": heavyFuel?.htlEffectiveDate,
      "weghtType": "HTL"
    }

    const addnewHTL = await addfuelHtl({ rateCardId: ratecardaccount?.rateCardId, ...data })
    if (addnewHTL?.data?.statusCode === 200) {
      setHeavyFuel("")
      handleAccount(account.account_No)
    }
  }

  return (
    <>

      {/* Remote login info */}
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">Remote Login Info</label>
            <Row>
              <Col sm={4}>
                <fieldset className="less_load_table">
                  <label className="label-heading">Effective date on less load (LTL)</label>
                  <>
                    <Row>
                    <Row>
                    {/* <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="maxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="taxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col> */}
                      <Col sm={12} className="justify-content-between">
                        <div className="section_action_btn">
                          {!lessFuel?.fuelsurchargerateid ?
                            <div className="add_btn"><button type="button" className="btn" onClick={addnewRecordLtl}>Add new record</button></div>
                            :
                            <div className="save_btn"><button type="button" className="btn" onClick={addRecordLtl}>Update record</button></div>
                          }
                        </div>
                      </Col>
                    </Row>
                      <Row className="mt-2 mb-3">
                        <Col sm={12} className="mb-2">
                          <Form.Group controlId="Date">
                            <FloatingLabel controlId="floatingInput" label="Date" >
                              <Form.Control type="date" placeholder="Date" name="ltlEffectiveDate" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlEffectiveDate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                              <Form.Control type="number" placeholder="Fuel Charge" name="ltlRate" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlRate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Col sm={12}>
                        <Row>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Fuel Charge</th>
                                {/* <th>Max Field</th>
                                  <th>Carbon Tax</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {lessTruckLoad?.length > 0 ? (
                                lessTruckLoad?.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="action_btn">
                                          <span className="edit_btn" onClick={() => { editLtl(data?.fuelsurchargerateid) }}><Edit /></span>
                                          <span className="del_btn" onClick={() => handleOpen(data?.fuelsurchargerateid)}><Delete /></span>
                                        </div>
                                        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                          <DialogTitle id="alert-dialog-title">
                                            Delete Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              Are you sure you want to delete this row?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions >
                                            <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                            <Button className="btn-primary" onClick={() => {deleteData(); handleClose(); }} autoFocus> Confirm</Button>
                                          </DialogActions>
                                        </Dialog>
                                      </td>
                                      <td>{data?.ltlEffectiveDate}</td>
                                      <td>{data?.ltlRate}</td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <p>No data found </p>
                              )}
                            </tbody>
                          </Table>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="full_load_table">
                  <label className="label-heading">Effective date on full load (FTL)</label>
                  <Row>
                    {/* <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="maxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="taxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col> */}
                  <Col sm={12} className="justify-content-between">
                    <div className="section_action_btn">
                      {!fullFuel?.fuelsurchargerateid ?
                        <div className="add_btn"><button type="button" className="btn" onClick={addNewRecordFTL}>Add new record</button></div>
                        :
                        <div className="save_btn"><button type="button" className="btn" onClick={addRecordFTL}>Update record</button></div>
                      }
                    </div>
                  </Col>
                  </Row>
                  <>
                    <Row>

                      <Row className="mt-2 mb-3">
                        <Col sm={12} className="mb-2">
                          <Form.Group controlId="Date">
                            <FloatingLabel controlId="floatingInput" label="Date" >
                              <Form.Control type="date" placeholder="Date" name="ftlEffectiveDate" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlEffectiveDate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                              <Form.Control type="number" placeholder="Fuel Charge" name="ftlRate" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlRate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Col sm={12}>
                        <Row>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Fuel Charge</th>
                                {/* <th>Max Field</th>
                                  <th>Carbon Tax</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {fullTruckLoad?.length > 0 ? (
                                fullTruckLoad?.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="action_btn">
                                          <span className="edit_btn" onClick={() => editFTL(data?.fuelsurchargerateid)}><Edit /></span>
                                          <span className="del_btn" onClick={() => handleOpen(data?.fuelsurchargerateid)}><Delete /></span>
                                        </div>
                                        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                          <DialogTitle id="alert-dialog-title">
                                            Delete Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              Are you sure you want to delete this row?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions >
                                            <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                            <Button className="btn-primary" onClick={() => { deleteData(); handleClose(); }} autoFocus> Confirm</Button>
                                          </DialogActions>
                                        </Dialog>
                                      </td>
                                      <td>{data.ftlEffectiveDate}</td>
                                      <td>{data.ftlRate}</td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <p>No data found</p>
                              )}
                            </tbody>
                          </Table>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="heavy_load_table">
                  <label className="label-heading">Effective date on heavy equipment load (HTL)</label>
                  <Row>
                    {/* <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="maxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="taxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col> */}
                  <Col sm={12} className="justify-content-between">
                    <div className="section_action_btn">
                      {!heavyFuel?.fuelsurchargerateid ?
                        <div className="add_btn"><button type="button" className="btn" onClick={addNewRecordHTL} >Add new record</button></div> :
                        <div className="save_btn"><button type="button" className="btn" onClick={addRecordHTL}>Update record</button></div>
                      }
                    </div>
                  </Col>
                  </Row>
                  <>
                    <Row>

                      <Row className="mt-2 mb-3">
                        <Col sm={12} className="mb-2">
                          <Form.Group controlId="Date">
                            <FloatingLabel controlId="floatingInput" label="Date" >
                              <Form.Control type="date" placeholder="Date" name="htlEffectiveDate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlEffectiveDate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                              <Form.Control type="number" placeholder="Fuel Charge" name="htlRate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlRate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Col sm={12}>
                        <Row>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Fuel Charge</th>
                                {/* <th>Max Field</th>
                                <th>Carbon Tax</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {heavyTruckLoad?.length > 0 ? (
                                heavyTruckLoad?.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="action_btn">
                                          <span className="edit_btn" onClick={() => editHTL(data?.fuelsurchargerateid)}><Edit /></span>
                                          <span className="del_btn" onClick={() => handleOpen(data?.fuelsurchargerateid)}><Delete /></span>
                                        </div>
                                        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                          <DialogTitle id="alert-dialog-title">
                                            Delete Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              Are you sure you want to delete this row?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions >
                                            <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                            <Button className="btn-primary" onClick={() => { deleteData(); handleClose(); }} autoFocus> Confirm</Button>
                                          </DialogActions>
                                        </Dialog>
                                      </td>
                                      <td>{data.htlEffectiveDate}</td>
                                      <td>{data.htlRate}</td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <p>No data found</p>
                              )}
                            </tbody>
                          </Table>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
    </>

  );
}
export default EditRemoteLogin;