import React from 'react';
import Admin from './Admin';
import { CreateCustomer } from '../../../context/CustomerContext';

const AdminParent = () => {
    return (
        <CreateCustomer>
            <Admin />
        </CreateCustomer>
    );
};

export default AdminParent;
