import { apiSlice } from "../../app/services/authServices";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: []
};

export const addFieldInterlinersSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addSwitchType: builder.query({
      query: () => ({
        url: 'InterlinerAgent/GetSwitchtype',
        method: 'GET',
      }),
    }),
    getDispatcherList: builder.query({
      query: () => ({
        url: 'InterlinerAgent/GetDispatcher',
        method: 'GET',
      }),
    }),
    getReportType: builder.query({
      query: () => ({
        url: 'InterlinerAgent/GetReportType',
        method: 'GET',
      }),
    }),
    addInterliner: builder.mutation({
      query: (data) => ({
        url: '/InterlinerAgent/AddinterlinersAgent',
        method: "POST",
        body: data,
      })
    }),
    getInterlinerAgent: builder.query({
      query: () => ({
        url: "/InterlinerAgent/GetAllInterlinersAgent",
        method: "GET",
      })
    }),
    deleteInterliners: builder.mutation({
      query: (interlinersId) => ({
        url: `InterlinerAgent/DeleteInterLinersAgent?interlinersAgentId=${interlinersId}`,
        method: "POST",
      }),
    }),
    getInterlinersDetailById: builder.query({
      query: (interlinersId) => ({
        url: `/InterlinerAgent/GetInterlinersDetailById?interlinersId=${interlinersId}`,
        method: "GET",
      })
    }),

    updateInterliner: builder.mutation({
      query: (data) => ({
        url: '/InterlinerAgent/UpdateinterlinersAgent',
        method: "POST",
        body: data,
      })
    }),

    getCwtRateCardDetailById: builder.query({
      query: (ratecardId) => ({
        url: `/InterlinerAgent/GetCwtRateCardId?ratecardId=${ratecardId}`,
        method: "GET",
      })
    }),

    deleteCwtRate: builder.mutation({
      query: (ratecardId) => ({
        url: `InterlinerAgent/DeleteCwtRate?ratecardId=${ratecardId}`,
        method: "POST",
      }),
    }),

    updateRateCard: builder.mutation({
      query: (data) => ({
        url: '/InterlinerAgent/UpdateCwtRateCard',
        method: "POST",
        body: data,
      })
    }),

  })
})

export const { useAddSwitchTypeQuery, useGetDispatcherListQuery, useGetReportTypeQuery, useAddInterlinerMutation, useGetInterlinerAgentQuery,
  useLazyGetInterlinersDetailByIdQuery, useDeleteInterlinersMutation ,useUpdateInterlinerMutation, useLazyGetCwtRateCardDetailByIdQuery ,useDeleteCwtRateMutation, useUpdateRateCardMutation } = apiSlice;


const interlinerRateCardSlice = createSlice({
  name: "RateCardSection",
  initialState,
  reducers: {
    toggleShow: (state) => {
      state.show = !state.show
    },
    addRateCard: (state, action) => {
      const lists = {
        id: new Date().getTime().toString(),
        list: action.payload,
      };
      state.list.push(lists);
    },
    deleteRateCard: (state, action) => {
      state.list = state.list.filter(
        (data) => data.id !== action.payload
      )
    },
    editRateCard: (state, action) => {
      const currentLists = Array.from(state.list);
      const filterData = state.list.findIndex((list) => list.id === action.payload.id);
      currentLists[filterData] = {
        id: action.payload.id,
        list: action.payload
      }
      return { ...state, list: currentLists };
    },

  },
})

export const { addRateCard, editRateCard, deleteRateCard, toggleShow } = interlinerRateCardSlice.actions

export default interlinerRateCardSlice.reducer