import { Form } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { Update } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";
import { PersonAddRounded } from "@material-ui/icons";
import { CalendarToday } from "@material-ui/icons";
import { Visibility } from "@material-ui/icons";
import { Publish, } from "@material-ui/icons";



const UpdateUserPermission = ({ data, handleChange, btnStatus }) => {
    return (
        <>
            <div className="user-funtional-role">
                <div className="role-assignment">
                    <div className="role-assignment-name">
                        <h6>Permission - {data?.moduleName}</h6>
                    </div>
                    {/* <div className="add-role-button d-flex">
                        <span>Check All</span>
                    </div> */}
                </div>
                {/* Already assigned roles deatils */}
                <div className="user-permission">
                    {/* role-permission-view */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><Visibility /></span>
                            <div className="permission-name">
                                <span className="permission-name">View Data</span>
                                <span className="permission-name-details">View Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="View" value="View" defaultChecked={data?.permissionType.indexOf('View') !== -1 ? true : false} onChange={e => handleChange(e)}  />
                            </div>
                        </div>
                    </div>
                    {/* role-permission-add */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><AddCircle /></span>
                            <div className="permission-name">
                                <span className="permission-name">Add Data</span>
                                <span className="permission-name-details">Add Customer Info</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="Add" value="Add" defaultChecked={data?.permissionType.indexOf('Add') !== -1 ? true : false}    onChange={e => handleChange(e)} disabled={btnStatus}/>
                            </div>
                        </div>
                    </div>
                    {/* Role-permission-update */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><Update /></span>
                            <div className="permission-name">
                                <span className="permission-name">Update Data</span>
                                <span className="permission-name-details">Update Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="Update" value="Update" defaultChecked={data?.permissionType.indexOf('Update') !== -1 ? true : false}    onChange={e => handleChange(e)}  disabled={btnStatus}/>

                            </div>
                        </div>
                    </div>
                    {/* Role Permission Deactivate */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><Delete /></span>
                            <div className="permission-name">
                                <span className="permission-name">Deactivate/Delete Data</span>
                                <span className="permission-name-details">Delete Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="Delete" value="Delete" defaultChecked={data?.permissionType.indexOf('Delete') !== -1 ? true : false}    onChange={e => handleChange(e)}  disabled={btnStatus}/>
                            </div>
                        </div>
                    </div>
                    {/* Role Permission generate */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><Publish /></span>
                            <div className="permission-name">
                                <span className="permission-name">Run/Generate/Upload Data</span>
                                <span className="permission-name-details">Run/Generate/Upload Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="Upload" value="Upload" defaultChecked={data?.permissionType.indexOf('Upload') !== -1 ? true : false}    onChange={e => handleChange(e)}  disabled={btnStatus}/>
                            </div>
                        </div>
                    </div>
                    {/* Role Created by */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><PersonAddRounded /></span>
                            <div className="permission-name">
                                <span className="permission-name">Create by</span>
                                <span className="permission-name-details">View Created By Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-floating">
                                <Form.Control type="text" placeholder="Created By" name="Createdby" required readOnly  value={data.createdBy}/>
                                <label htmlFor="Created by">Created By</label>
                            </div>
                        </div>
                    </div>
                    {/* Role Create Date */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><CalendarToday /></span>
                            <div className="permission-name">
                                <span className="permission-name">Created Date</span>
                                <span className="permission-name-details">View Create Date Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-floating">
                                <Form.Control type="text" placeholder="Created Date" name="Createddate" required readOnly  value={data.createdDate}/>
                                <label htmlFor="Created date">Created Date</label>
                            </div>
                        </div>
                    </div>
                    {/* Role Modified By */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><PersonAddRounded /></span>
                            <div className="permission-name">
                                <span className="permission-name">Modified By</span>
                                <span className="permission-name-details">Modified Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-floating">
                                <Form.Control type="text" placeholder="Modified By" name="Modifiedby" required readOnly  value={data.modifiedBy}/>
                                <label htmlFor="Modified by">Modified By</label>
                            </div>
                        </div>
                    </div>
                    {/* Role modified date */}
                    <div className="role-permission">
                        <div className="permission-role d-flex">
                            <span className="permission-icon"><CalendarToday /></span>
                            <div className="permission-name">
                                <span className="permission-name">Modified Date</span>
                                <span className="permission-name-details">Modified Customer Data</span>
                            </div>
                        </div>
                        <div className="permission-role-selection">
                            <div className="form-floating">
                                <Form.Control type="text" placeholder="Modified Date" name="Modifieddate" required readOnly  value={data.modifiedDate}/>
                                <label htmlFor="Modified date">Modified Date</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UpdateUserPermission