import { apiSlice } from "../../app/services/authServices";


export const addFieldVehicleSlice = apiSlice.injectEndpoints({
  tagTypes: ['AddField'],
  endpoints: builder => ({
    addFieldData: builder.mutation({
      query: (data) => ({
        url: 'Vehicle/AddField',
        method: 'POST',
        body:data,
        providesTags:['AddField']
      }),
    }),
    getAllFields: builder.query({
      query: () => ({
        url: 'Vehicle/GetAllFields',
        method: 'GET',
        providesTags:['AddField']
      }),
    }),
    vehicleSubmitDatafield: builder.mutation({
      query: (data) => ({
        url: 'Vehicle/AddVehicleTypeWithFields',
        method: 'POST',
        body:data,
        providesTags:['AddField']
      }),
    }),

  })
})

export const { useAddFieldDataMutation, useGetAllFieldsQuery, useVehicleSubmitDatafieldMutation } = apiSlice;
