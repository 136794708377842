import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './style.css';
import './responsive.css';
import "/node_modules/@syncfusion/ej2-base/styles/material.css";
import "/node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css"
import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
import "/node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import { store } from "./app/store"
import { Provider } from "react-redux";

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

