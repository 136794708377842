import Userprofile from './Userprofile';
import Notifications from './Notification';
import Search from './Search';

const Topbar = ()=>{

return(
<div className="topbar-menu">
      <Search></Search>
      <Notifications></Notifications>
      <Userprofile></Userprofile>
      
</div>
);
}
export default Topbar