import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const INVOICECYCLE_URL = `${baseUrl}/Customer/GetListOfInvoiceCycle`


const initialState = []

export const fetchInvoiceCycle  = createAsyncThunk('GetListOfInvoiceCycle/fetchInvoiceCycle', async () =>{
    try{
        const response = await axios.get(INVOICECYCLE_URL);
        return[...response.data.data];
    }catch (err){
        return err.message;
    }
})


const invoiceCycleSlice = createSlice({
    name:"invoicecycle",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchInvoiceCycle.fulfilled, (state, action) => {
          return action.payload
        })
      }
})

export const invoiceCycleList  = (state) => state.invoiceCycleList;

export default invoiceCycleSlice.reducer