import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button, } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { Delete, } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { addService, editService, deleteService } from "../../../../../features/service/serviceDetailSlice";
import { useGetServiceQuery } from "../../../../../features/service/serviceDetailSlice";
import { useForm } from 'react-hook-form';
import { useCustomData } from "../../../../Sidebar/Permission";
import CreateService from "./CreateService"
import ServiceSurchargeSection from "../../../../Customer/Service/ServiceSurchargeSection";


const ServiceSurcharge = () => {
  const [permission] = useCustomData()
  const { data: serviceList } = useGetServiceQuery()
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange } = useForm();
  const dispatch = useDispatch();
  const { serviceLists } = useCustomer();
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [serviceData, setServiceData] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  const Servicehandler = (e) => {
    setServiceData(serviceData => ({ ...serviceData, [e.target.name]: e.target.value }));
  }
  // Add and update vehicle list 
  const addServiceList = (serviceData) => {
    if (Object.keys(serviceData).length === 0) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      dispatch(addService(serviceData));
    } else {
      let editingItem = serviceLists[editingTaskIndex];
      editingItem = serviceData;
      dispatch(editService(editingItem));
    }
    setServiceData("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
    reset({
      ServiceDescription: "", AfterHourPercent: "", CostPerKM: "", BaseRateType: '', BaseRate: '', VehicleType: '', PieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
      WeightIncluded: "", WeightCalcType: "", WaitingTimeUnitRate: "", WaitingtimeUnit: "", FreeWaitingTime: "", PieceRateType: "", WeightRateType: "", WaitingTimeUnitRateType: ""
    });
  };

  // Edit vehicle list
  const editServiceList = (id) => {
    let newEditItem = serviceLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
    setServiceData(newEditItem)
    setEditingTaskIndex(id);
    reset(serviceData)
  }


  const [statusS, setStatusS] = useState(0);
  const [statusSc, setStatusSc] = useState(0);
  const [statusSp, setStatusSp] = useState(0);
  const [statusSw, setStatusSw] = useState(0);

  const radioHandlerS = (statusS) => { setStatusS(statusS) };
  const radioHandlerSc = (statusSc) => { setStatusSc(statusSc) };
  const radioHandlerSp = (statusSp) => { setStatusSp(statusSp) };
  const radioHandlerSw = (statusSw) => { setStatusSw(statusSw) };

  return (
    <>
      {/* Service surcharge section*/}
      <Row className="mb-2 mt-2">
        <Col sm={12}>
            <div className="section_action_btn">
              <CreateService />
            </div>
            {/* table */}
        </Col>
      </Row>
      <ServiceSurchargeSection />
    </>
  );
}
export default ServiceSurcharge;