import { apiSlice } from "../../app/services/authServices";


export const countryStateSlice = apiSlice.injectEndpoints({
    tagTypes:['Country'],
    endpoints:builder => ({
      getCountry: builder.query({
        query: () => ({
          url: 'Customer/GetCountryList',
          method: 'GET',
          providesTags: ['Country'],
        }),
      }),
        getState: builder.query({
          query: (countryId) => ({
            url: `Customer/GetProvinceStateList?CountryID=${countryId}`,
            method: 'GET',
            providesTags: ['Country'],
          }),
        }),
        getCityList: builder.query({
          query: (provineStateID) => ({
            url: `Customer/GetCityList?ProvineStateID=${provineStateID}`,
            method: 'GET',
            providesTags: ['Country'],
          }),
        }),
        getProvinceWithoutCountry: builder.query({
          query: () => ({
            url: 'Customer/GetProvinceList',
            method: 'GET',
            providesTags: ['Country'],
          }),
        }),
        getCityWithoutProvince: builder.query({
          query: () => ({
            url: 'Customer/GetCitiesList',
            method: 'GET',
            providesTags: ['Country'],
          }),
        }),
        getProvinceByCity: builder.query({
          query: (cityId) => ({
            url: `Customer/GetProvinceByCity?cityId=${cityId}`,
            method: 'GET',
            providesTags: ['Country'],
          }),
        }),
        getCountryByProvince: builder.query({
          query: (provinceId) => ({
            url: `Customer/GetCountryByProvince?provinceId=${provinceId}`,
            method: 'GET',
            providesTags: ['Country'],
          })
        }),
        getUniqueCity: builder.query({
          query: ({cityName, provinceName}) => ({
            url: `Customer/GetUniqueCity?cityName=${cityName}&provinceName=${provinceName}`,
            method: 'GET',
            providesTags: ['Country'],
          })
        })
      }),
})

export const { useGetCountryQuery, useLazyGetStateQuery, useLazyGetCityListQuery, useGetProvinceWithoutCountryQuery, useGetCityWithoutProvinceQuery, useLazyGetProvinceByCityQuery, useLazyGetCountryByProvinceQuery, useLazyGetUniqueCityQuery } = apiSlice;


