import React from 'react';
import { useState } from 'react';
import ButtonModals from './ButtonModals';
import AutoStatus from './ButtonContent/AutoStatus';
import Document from './ButtonContent/Document';

const OrderDetails = (props) => {
    const { selectedOrder } = props;
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);
    console.log(selectedOrder);

    const handleShowModal = (title, content) => {
        setModalTitle(title);
        setModalContent(content);
        setModalShow(true);
    };
    
    const handleCloseModal = () => {
        setModalShow(false);
    };

    return (
        <div className="local-dispatch-order-details" >
            <div className="order-details-header">
                <h3 >Order #{selectedOrder?.orderNumber || ''}</h3>
            </div>
            <div className="order-content">
                <div className="form-row" style={{marginBottom: '10px'}}>
                    <div className="ready-time">
                        <label style={{marginLeft: '5px', marginRight: '5px'}}>Ready at:</label>
                        <input type="datetime-local" className="ready-date" value={selectedOrder?.readyDate || ''} />
                        {/* <input type="checkbox" id="autoStatus" />
                        <label htmlFor="autoStatus">Auto Status</label> */}
                    </div>
                    <div className="form-col" style={{ marginRight: '-10px'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <button style={{fontSize: '11px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', padding: '5px'}}>Edit</button>
                            <button style={{fontSize: '11px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', padding: '5px'}} onClick={() => props.setSelectedOrder(null)}>Exit</button>
                        </div>
                    </div>
                </div>
                        
                {/* <div className="row">
                    <div className="col"> */}
                        {/* <label >Email:</label>
                        <input type="email" style={{marginRight: '10px', height: '50px',marginBottom: '10px'}} value={selectedOrder?.emailAddress || ''} />
                        <input type="checkbox" id="waybill" />
                        <label htmlFor="waybill" style={{marginRight: '10px'}} > Waybill</label>
                        <input type="checkbox" id="label" />
                        <label htmlFor="label" style={{marginRight: '10px'}} >Label</label> */}
                        {/* <button style={{marginLeft: '5px',marginBottom: '10px',marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'18px'}}>Email</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'18px'}}>Print</button> */}
                        {/* <button style={{marginLeft: '5px',marginBottom: '10px',marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px'}}>Auto Status</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px'}}>Document</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px',}}>Dispatcher Notes</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px'}}>Order Notes</button> */}
                    {/* </div>
                </div> */}
                <div className="form-row">
                    <div className="local-dispatch-section">
                        <div className="local-dispatch-items">
                            <div >
                                <div className="dispatch-items-header">
                                    <h5>Pick Up</h5>
                                </div>
                                <div className="dispatch-items-content">
                                         <div className="form-col">
                                            <label for='Name'>Contact</label>
                                            <input type="text" value={selectedOrder?.consignorName || ''} />
                                        </div>
                                        <div className="form-col">
                                            <label for='Name'>Customer</label>
                                            <input type="text"  value={selectedOrder?.customer || ''}/>
                                        </div>
                                        <div className="form-col grid-col-span-2">
                                            <label for='City'>Phone #</label>
                                            <input type="text" value={selectedOrder?.pickupPhoneNumber || ''} />
                                        </div>
                                        <div className="form-col grid-col-span-3" >
                                            <label for='Address'>Address</label>
                                            <input type="text" value={selectedOrder?.pickupStreetNumber + " " + selectedOrder?.pickupStreetName + (" Unit " + selectedOrder?.pickupUnit || '')}/>
                                        </div>
                                        <div className="form-col">
                                            <label for="City" >City</label>
                                            <input type="text" value={selectedOrder?.pickupCity || ''} />
                                        </div>
                                        
                                        <div className="form-col">
                                            <label for='Province'>Province</label>
                                            <input type="text" value={selectedOrder?.pickupProvince || ''} />
                                        </div>
                                        <div className="form-col">
                                            <label for='Country'>Country</label>
                                            <input type="text"  value={selectedOrder?.pickupCountry || ''} />
                                        </div>
                                        
                                        
                                        <div className="form-col grid-col-span-2">
                                            <label for='Postal'>Postal</label>
                                            <input type="text" value={selectedOrder?.pickupPostalCode || ''}/>
                                        </div>
                                        <div className="form-col grid-col-span-2">
                                        <label for='Open'>Open</label>
                                        <input type="text" value={selectedOrder?.pickupOpeningTime || ''} />
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for='Close'>Close</label>
                                        <input type="text"value={selectedOrder?.pickupClosingTime || ''}/>
                                    </div>
                                    <div className="form-col">
                                        <label for='IN'>IN</label>
                                        <input type="text"/>
                                    </div>
                                    <div className="form-col">
                                        <label for='OUT'>OUT</label>
                                        <input type="text"/>
                                    </div>
                                    <div className="form-col">
                                        <label for='ETA'>ETA</label>
                                        <input type="text" />
                                    </div>
                                    <div className="form-col">
                                        <label for='Detention'>Detention</label>
                                        <input type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="local-dispatch-items">
                            <div>
                                <div className="dispatch-items-header">
                                    <h5>Delivery To</h5>
                                </div>
                                <div className="dispatch-items-content">
                                        <div className="form-col grid-col-span-2">
                                            <label for='Name' >Contact</label>
                                            <input type="text" value={selectedOrder?.consigneeName || ''} />
                                        </div>
                                        {/* <div className="col">
                                            <label for='Name'style={{fontSize: '14px'}}>Customer Name</label>
                                            <input type="text" style={{width: '90%'}}/>
                                        </div> */}
                                        <div className="form-col grid-col-span-2">
                                            <label for="City" >Phone #</label>
                                            <input type="text"  value={selectedOrder?.dropoffPhoneNumber || ''} />
                                        </div>
                                        <div className="form-col grid-col-span-3">
                                            <label for='Address'>Address</label>
                                            <input type="text" value={selectedOrder?.dropoffStreetNumber + " " + selectedOrder?.dropoffStreetName + (" Unit " + selectedOrder?.dropoffUnit || '') }/>
                                        </div>
                                        <div className="form-col">
                                            <label for="City" >City</label>
                                            <input type="text" value={selectedOrder?.dropoffCity || ''} />
                                        </div>
                                        <div className="form-col">
                                            <label for="Province" >Province</label>
                                            <input type="text"  value={selectedOrder?.dropoffProvince || ''} />
                                        </div>
                                        <div className="form-col">
                                            <label for="Country" >Country</label>
                                            <input type="text"  value={selectedOrder?.dropoffCountry || ''} />
                                        </div>
                                        <div className="form-col grid-col-span-2">
                                            <label for="Postal Code" >Postal</label>
                                            <input type="text"  value={selectedOrder?.dropoffPostalCode || ''} />
                                        </div>
                                        
                                        <div className="form-col grid-col-span-2">
                                        <label for="Open" >Open</label>
                                        <input type="text"   value={selectedOrder?.dropoffOpeningTime || ''}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="Close" >Close</label>
                                        <input type="text"   value={selectedOrder?.dropoffClosingTime || ''}/>
                                    </div>
                                    <div className="form-col">
                                        <label for="IN" >IN</label>
                                        <input type="text"  />
                                    </div>
                                    <div className="form-col">
                                        <label for="OUT" >OUT</label>
                                        <input type="text"   />
                                    </div>
                                    <div className="form-col">
                                        <label for="ETA" >ETA</label>
                                        <input type="text" />
                                    </div>
                                    <div className="form-col">
                                        <label for="Detention" >Detention</label>
                                        <input type="text" />
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className="local-dispatch-items">
                            <div className="dispatch-items-header">
                                <h5 >Order Information</h5>
                            </div>
                            <div className="dispatch-items-content">
                                    <div className="form-col grid-col-span-2">
                                        <label for="Vehicle" >Vehicle </label>
                                        <input type="text" value={selectedOrder?.vehicle || ''} />
                                    </div>
                                    
                                    <div className="form-col grid-col-span-2">
                                        <label for="Service">Service </label>
                                        <input type="text" value={selectedOrder?.service || ''} />
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="Pcs">Pcs </label>
                                        <input type="text" value={selectedOrder?.pcs || ''} />
                                    </div>
                                    <div className="form-col ">
                                        <label for="SKD">Unit </label>
                                        <input type="text"/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="weight">Weight </label>
                                        <input type="text"  value={selectedOrder?.weight || 0}/>
                                    </div>
                                    <div className="form-col ">
                                        <label for="size">Unit </label>
                                        <input type="text"/>
                                    </div>
                                    <div className="form-col ">
                                        <label for='Unit'>Unit</label>
                                        <input type="text" />
                                    </div>
                                    <div className="form-col ">
                                        <label for='Spot'>Spot</label>
                                        <input type="text" value={selectedOrder?.spot }/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label form='Ft'>Ft</label>
                                        <input type="text"value={selectedOrder?.ft || ''} />
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for='Dim. Wgt'>Dim. Weight</label>
                                        <input type="text"value={selectedOrder?.dimWgt || ''} />
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for='Reference'>Reference</label>
                                        <input type="text" value={selectedOrder?.reference || ''} />
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                                
                    
                </div>
                <div className=" local-dispatch-section">
                    <div className=" dispatch-accessorial">
                        <div className="dispatch-items-header-small">
                            <text className="dispatch-items-small"> Accessorials (Pickup)</text>
                        </div>
                        <textarea type="text" placeholder="Accessorials (Pickup)" value={selectedOrder?.pickupAccessorialCodes.join("\n")}/> 
                    </div>
                    <div className="dispatch-accessorial">
                        <div className="dispatch-items-header-small">
                            <text className="dispatch-items-small"> Accessorials (Delivery)</text>
                        </div>
                        <textarea type="text" placeholder="Accessorials (Delivery)" value={selectedOrder?.dropoffAccessorialCodes.join("\n")}/>
                    </div>
                    <div className="dispatch-accessorial">
                        <div className="dispatch-items-header-small">
                            <text className="dispatch-items-small"> Accessorials (Order)</text>
                        </div>
                        <textarea type="text" placeholder="Accessorials (Order)" value={selectedOrder?.orderLevelAccessorialCodes.join("\n")}/>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-col-buttons">
                    <button onClick={() => handleShowModal('Auto Status', <AutoStatus selectedOrder={selectedOrder}/>)}>Auto Status</button>
                    <button onClick={() => handleShowModal('Document', <Document selectedOrder={selectedOrder}/>)}>Document</button>
                    <button >Dispatcher Notes</button>
                    <button >Order Notes</button>
                        <button >Map</button>
                        <button >Return No Delivery</button>       
                        <button >Future</button>       
                        <button >Add Accessorial</button>       
                    </div>
                    <ButtonModals show={modalShow} title={modalTitle} content={modalContent} handleClose={handleCloseModal} />
                </div>
            </div>
        </div>  
    );
};
 
export default OrderDetails;