import React from "react";
import { Col, Row, Form, FloatingLabel, Tab, Tabs, Table, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { AddCircle, Email, Save, } from "@material-ui/icons";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCustomData } from "../../Sidebar/Permission";
import useCustomer from "../../../context/CustomerContext";
import EditContact from "./EditContactDetails";
import { useForm, Controller } from 'react-hook-form';
import InputMask from "react-input-mask";
import CountryProvince from "../../CountryProvince";
import timeConverter from "../../Order/OrderTaking/timeConverter";

const EditCustomer = ({ showhandler }) => {
  const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
  const [permission] = useCustomData();
  const { singleAccount, handleAccount } = useCustomer();
  const [key, setKey] = useState("Customerdetails");
  const accountData = singleAccount && singleAccount[0];
  const [addressData, setAddressData] = useState({})
  const [checkedData, setCheckedData] = useState({})
  const Addresshandler = (e) => { setAddressData({ ...addressData, [e.target.name]: e.target.value }) }
  const {control} = useForm();
  
  useEffect(() => {
    // log officeHour and closeTime with text to descirbe both
    console.log("officeHour " + accountData?.officeHour)
    console.log('closeTime' + accountData?.closeTime)
  }, [accountData]);
  const formatTime = (time) => {
    // given the format YYYY-MM-DD HH:MM:SS AM/PM, i want to return HH:MM AM/PM
    let timeArray = time.split(" ");
    if (timeArray.length <= 1) {
      return time;
    }
    if (timeArray[1].toString().length === 9) {
      timeArray[1] = timeArray[1].substring(0, 6);
    } else {
      timeArray[1] = timeArray[1].substring(0, 5);
    }

    if (timeArray[2] === "A.") {
      timeArray[2] = "AM";
    } else if (timeArray[2] === "P.") {
      timeArray[2] = "PM";
    }

    let timeString = timeArray[1] + " " + timeArray[2];
    return timeString;
  }

  const checkHandler = (e) => {
    if (e.target.checked) {
      setCheckedData({ [e.target.name]: true })
  }
  else {
      setCheckedData({ [e.target.name]: false })
  }}

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(accountData)
    }, 2500)
    return () => clearInterval(interval)
  })

  return (
    <>
      <>
        <div className="button-section" style={{ justifyContent: "end" }}>
          {permission?.find(event => event.moduleId === 38)?.permissionType?.indexOf('Update') !== -1 ?
            <Button className="edit-btn" onClick={e => { showhandler(e); }} >
              Edit{" "}
              <span>
                <Save />
              </span>
            </Button>
            :
            <Button className="edit-btn disable">
              Edit{" "}
              <span>
                <Save />
              </span>
            </Button>
          }
        </div>
      </>

      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <div className="name-sec">
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="Account">
                  <FloatingLabel controlId="floatingSelectGrid" label="Account type">
                    <Form.Select aria-label="Floating label select example" name="accountType" value={accountData?.accountType} onChange={Addresshandler}>
                      <option>{accountData?.accountType}</option>
                      <option value="Inactive Account">Inactive Account</option>
                      <option value="CSA Account">CSA Account</option>
                      <option value="US Based Account">US Based Account</option>
                      <option value="Freight Broker">Freight Broker</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="accountnumber">
                  <FloatingLabel controlId="floatingInput" label="Account Number">
                    <Form.Control type="text" placeholder="Account Number" name="AccountName" value={accountData?.account_No} onChange={e => Addresshandler(e)} disabled />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <span className="last-order-update">
                  <p>Last order 20/11/2021 08:30:00PM</p>
                </span>
              </Col>
            </Row>
            {/* 2nd Row */}
            <Row className="mb-4">
              {/* <Col sm={4}>
                <Form.Group controlId="Open Time">
                  <FloatingLabel controlId="floatingInput" label="Open Time" >
                    <Form.Control type="time" placeholder="Open time" name="officeHour" value={accountData?.officeHour} onChange={e => Addresshandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="Open Time">
                  <FloatingLabel controlId="floatingInput" label="Close Time" >
                    <Form.Control type="time" placeholder="Close time" name="closeTime" value={accountData?.closeTime} onChange={e => Addresshandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col> */}


              <Col sm={4}>
                <div className="timepicker">
                  <TimePickerComponent id="time" placeholder="Select office hour" onChange={e => Addresshandler(e)} name="OfficeHours" value={timeConverter(accountData?.officeHour)} format={'hh:mm a'} />
                </div>
              </Col>
              <Col sm={4}>
                <div className="timepicker">
                  <TimePickerComponent id="time1" placeholder="Select close time" onChange={e => Addresshandler(e)} name="CloseTime"  value={timeConverter(accountData?.closeTime)} format={'hh:mm a'}/>
                </div>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="customernumber">
                  <FloatingLabel controlId="floatingSelectGrid" label="Customer frequency volume">
                    <Form.Select aria-label="Floating label select example" name="CustomerFrequencyVolumeID" value={accountData?.customerFrequencyVolumeID || ''} onChange={e => Addresshandler(e)}>
                      <option>Select</option>
                      <option>Daily</option>
                      <option>Weekly</option>
                      <option>Semi-monthly</option>
                      <option>Monthly</option>
                      <option>Occasionally</option>
                      <option>Fledged</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* 2nd fieldset */}
      <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 contact_listing" >
        <Tab eventKey="Customerdetails" title="Customer-Details" id="name_address">
          <Row className="mb-4">
            <Col sm={12}>
              <Row className="mb-3">
                <Col sm={3}>
                  <Form.Group controlId="customername">
                    <FloatingLabel controlId="floatingInput" label="Customer Name" >
                      <Form.Control type="text" placeholder="Customer Name" value={accountData?.customer} name="Customer" onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Street">
                    <FloatingLabel controlId="floatingInput" label="Street#" >
                      <Form.Control type="text" placeholder="Street#" name="Street" value={accountData?.street} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Streetname">
                    <FloatingLabel controlId="floatingInput" label="Street Name" >
                      <Form.Control type="text" placeholder="Street Name" name="StreetName" value={accountData?.streetName} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="unit">
                    <FloatingLabel controlId="floatingInput" label="Unit" >
                      <Form.Control type="text" placeholder="Unit" name="Unit" value={accountData?.unit === 0? '': accountData?.unit} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              {/* 2nd Row */}
              <Row className="mb-3">
              <Col sm={3}>
                  <Form.Group controlId="City">
                    <FloatingLabel controlId="floatingInput" label="City" >
                      <Form.Select aria-label="Floating label select example" name="cityId" onChange={e => { Addresshandler(e); setSelectCity(e.target.value) }} value={accountData?.cityName} required>
                        <option>{accountData?.cityName}</option>:
                        <>
                          {
                            city?.data?.data?.map((data, index) => (
                              <option key={index} >{data.city}</option>
                            ))

                          }
                        </>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Province/State">
                    <FloatingLabel controlId="floatingSelectGrid" label="Province/State">
                      <Form.Select aria-label="Floating label select example" name="ProvineStateID" onChange={(e) => { setSelectState(e.target.value); Addresshandler(e); handleCity(e) }} value={accountData?.provineStateID} required>
                        <option>{accountData?.provineSateName}</option>:
                        <>
                          {
                            province?.data?.data?.map((data, index) => (
                              <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                            ))

                          }

                        </>

                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="country">
                    <FloatingLabel controlId="floatingSelectGrid" label="Country">
                      <Form.Select aria-label="Floating label select example" value={accountData?.countryId} name="CountryId" onChange={e => { handlecountry(e); Addresshandler(e) }}>
                        <option>Select</option>:
                        <>
                          {
                            country?.data?.map((data, index) => (
                              <option key={index} value={data.countryId}>{data.countryname}</option>
                            ))

                          }
                        </>



                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Postalcode">
                    <FloatingLabel controlId="floatingInput" label="Postal code" >
                      <Form.Control type="text" placeholder="Postal code" value={accountData?.postalCode} name="PostalCode" onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              {/* 3rd row */}
              <Row className="mb-3">
                <Col sm={3}>
                  <Form.Group controlId="Phone">
                    <FloatingLabel controlId="floatingInput" label="Phone" >
                      <Form.Control type="text" placeholder="Phone" name="Phone" value={accountData?.phone} onChange={e => Addresshandler(e)} />
                      {/* <Form.Control type="text" placeholder="Phone" name="Phone" onChange={e=> {handleInput(e); Addresshandler(e) }} value={account.phone} required /> */}
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Fax">
                    <FloatingLabel controlId="floatingInput" label="Fax" >
                      <Form.Control type="text" placeholder="Fax" name="Fax" value={accountData?.fax} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Phone Ext">
                    <FloatingLabel controlId="floatingInput" label="Phone Ext" >
                      <Form.Control type="text" placeholder="Phone Ext" name="PhoneExtension" value={accountData?.phone_Ext} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId="Contact">
                    <FloatingLabel controlId="floatingInput" label="Contact" >
                      <Form.Control type="text" placeholder="Contact" name="Contact" value={accountData?.contact} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              {/* 4th row */}
              <Row className="mb-3">
                <Col sm={12}>
                  <Form.Group controlId="email">
                    <FloatingLabel controlId="floatingInput" label="Email" >
                      <Form.Control type="email" placeholder="Email" name="EmailAddress" value={accountData?.emailAddress} onChange={e => Addresshandler(e)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

            </Col>
          </Row>
          {/* 3rd section */}
          <Row className="mb-4">
            <Col sm={12}>
              <div className="form-check">
                <input type="checkbox" id="formBasicCheckbox" className="form-check-input" checked={accountData?.isNotesAppearOnOrder} onChange={(e) => checkHandler(e)} />
                <label title="" htmlFor="formBasicCheckbox" className="form-check-label" name="isNotesAppearOnOrder">Notes will appear at order taking system</label>
              </div>
              <FloatingLabel controlId="floatingTextarea2">
                <Form.Control
                  name="notes"
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                  value={accountData?.notes}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="Contactdetails" title="Contact-Details">
          <EditContact />
        </Tab>
      </Tabs>
      {/* <ToastContainer /> */}
    </>
  );
}
export default EditCustomer;