import { Row, Col, Modal, Table, FloatingLabel } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { AddCircle, Edit, Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { addAccessorial, editAccessorial, deleteAccessorial } from "../../../features/accessorial/accessorialDetailSlice";
import useOrder from "../../../context/OrderContext";
import { Save } from "@material-ui/icons";
import { toast } from 'react-toastify';
import { fetchAccessorialDesp, accessorialDespList } from "../../../features/accessorial/accessorialDespSlice"
import { useGetAccessorialQuery } from "../../../features/accessorial/accessorialDetailSlice";

const Addaccessorial = () => {
  const { data: accessorialList } = useGetAccessorialQuery()
  const { accessorialLists } = useOrder();
  const dispatch = useDispatch();
  const textRef = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [addAccessorialList, setAddAccessorialList] = useState([])
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const desp = useSelector(accessorialDespList);

  const Accessorialhandler = (e) => {
    setAddAccessorialList(addAccessorialList => ({ ...addAccessorialList, [e.target.name]: e.target.value }));
  }

  const handleAccessorial = async (e) => {
    const addAccessorialList = e.target.value;
    const selectedAccessorial = await accessorialList(addAccessorialList)
    if (!selectedAccessorial?.data.error) {
      const responseData = selectedAccessorial?.data && selectedAccessorial?.data?.data[0];
      setAddAccessorialList(responseData);
    }
  }

  // const getData = async(addAccessorialList) =>{
  //   dispatch(fetchAccessorialDesp(addAccessorialList))
  // }
  // const handleAccessorial = async (e) => {
  //   const addAccessorialList = e.target.value;
  //       const selectedAccessorial = await getData(addAccessorialList)
  //       if(!selectedAccessorial?.data.error){
  //         const responseData = selectedAccessorial?.data && selectedAccessorial?.data?.data[0];
  //         setAddAccessorialList(responseData)
  //       }
  // }




  const accessorailHandler = (e) => {
    e.preventDefault();
    if (Object.keys(addAccessorialList).length === 0) {
      toast.info("Please fill the form", {
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      if(accessorialLists.some((data)=>data.accessorialID === addAccessorialList.accessorialID)){
        toast.warn("Accessorial charge Added Already !", {
          position: "top-right",
      })
        return;
      }
      else{
        dispatch(addAccessorial(addAccessorialList));
      }
    } else {
      let editingItem = accessorialLists[editingTaskIndex];
      editingItem = addAccessorialList;
      dispatch(editAccessorial(editingItem));
    }
    setAddAccessorialList({accessorialID: "" , fuel:""});
    setEditingTaskIndex(null);
    setIsUpdate(false)
  };


  const editAccessorialList = (id) => {
    let newEditItem = accessorialLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setAddAccessorialList(newEditItem)
    setEditingTaskIndex(id);
  }


  return (
    <>
      {/* <Button onClick={() => setIsShow(true)} variant="outline-primary">
        Add Accessorial
      </Button> */}
      <Modal
        size="lg"
        show={isShow}
        onHide={() => setIsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        id="product-desp"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {" "}
            Add Accessorial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset className="">
            <Row>
            </Row>
            <Row className="mb-3 mt-1">
              <div className="add-access">
                <Row>
                  {/* <Col sm={2}><h6>Select the option </h6></Col>
                    <Col sm={4}>
                      <div className="floating-checbox" name="RateType">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Dropup" name="Type" onChange={(e) => { Accessorialhandler(e) }}/>
                          <label className="form-check-label" htmlFor="inlineCheckbox1">Drop-up</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="Pickup" name="Type" onChange={(e) => { Accessorialhandler(e) }}/>
                          <label className="form-check-label" htmlFor="inlineCheckbox2">Pick-up</label>
                        </div>
                      </div>
                    </Col> */}
                  <Col>
                    {!isUpdate ?
                      <div className="addaccessrial_btn">
                        <button type="button" className="btn" onClick={accessorailHandler}><AddCircle /></button>
                      </div>
                      :
                      <div className="addaccessrial_btn">
                        <button type="button" className="btn" onClick={accessorailHandler}><Save /></button>
                      </div>
                    }
                  </Col>
                </Row>
              </div>
              <Form>
                <div className="section_back">
                  <Row className="mt-2 mb-3" >
                    <Col sm={4}>
                      <Form.Group controlId="Vehicle">
                        <FloatingLabel controlId="floatingSelectGrid" label="Accesorial Charge Description">
                          <Form.Select aria-label="Floating label select example" name="accessorialID" value={addAccessorialList.accessorialID || ""} onChange={(e) => { Accessorialhandler(e) }} required>
                            <option>Select</option>:
                            <>
                              {
                                accessorialList?.data?.map((data, index) => (
                                  <option key={index} value={data?.accessorialID}>{data?.description}</option>
                                ))
                              }
                            </>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <div className="form-floating">
                        <Form.Control type="text" autoComplete="off" placeholder="Amount" name="rate" value={addAccessorialList.rate || ""} onChange={(e) => { Accessorialhandler(e) }} required />
                        <label htmlFor="contact">Amount</label>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="fieldset-inner">
                        <label className="label-heading">Add fuel</label>
                        <div className="floating-checbox">
                          <div className="RadioButton">
                            <div className="usefuel-yes">
                              <input ref={textRef} value="Yes" type="radio" name="fuel" checked={addAccessorialList.fuel === "Yes"} onChange={(e) => { Accessorialhandler(e) }} />
                              <label>YES</label>
                            </div>
                            <div className="usefuel-no">
                              <input ref={textRef} value="No" type="radio" name="fuel" checked={addAccessorialList.fuel === "No"} onChange={(e) => { Accessorialhandler(e) }} />
                              <label>NO</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Accessorial Charge Description</th>
                        <th>Amount</th>
                        <th>Add fuel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accessorialLists?.length > 0 && accessorialLists.map((data, id) => {
                        return (
                          <tr key={data.id}>
                            <td>
                              <div className="action_btn">
                                <span className="edit_btn" onClick={() => { editAccessorialList(data.id) }}><Edit /></span>
                                <span className="del_btn" onClick={() => dispatch(deleteAccessorial(data.id))}><Delete /></span>
                              </div>
                            </td>
                            <td>{data.accessorialID === '1' ? "Appointment" : data.accessorialID === '2' ? "Inside Delivery" : data.accessorialID === '3' ? "Tailgate" : ""}</td>
                            <td>{data.rate}</td>
                            <td>{data.fuel}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Row>
              </Form>

            </Row>
          </fieldset>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Addaccessorial;
