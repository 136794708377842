import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const ButtonModals = ({ show, handleClose, title, content }) => {

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
            <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
f        </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ButtonModals;