import Wrapper from '../../Wrapper'
import OrderentryForm from "./OrderentryForm";


 const Order = ()=>{
     return (
         <>
         <Wrapper>
         <OrderentryForm></OrderentryForm>
         </Wrapper>
         </>
     );
 }
 export default Order;