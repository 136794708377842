import { Button ,FloatingLabel } from "react-bootstrap";
import { Form } from "react-bootstrap";
import emailicon from '../../assets/email-icon.png';
import passwordicon from '../../assets/password-icon.png';
import { useNavigate } from "react-router-dom";
import {useState, useEffect, useRef } from 'react';
import Background from './Background';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authActions";
import { selectCurrentToken } from "../../features/auth/authSlice";
import Spinner from "../../Component/Spinner"


const Login = () => {
  const userRef = useRef();
  const errRef= useRef();
  const [values, setValues] = useState('');
  const [errMsg, setErrMsg] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {setPasswordShown(!passwordShown);};
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [login, {isLoading}] = useLoginMutation()

  useEffect(() =>{
    userRef.current.focus()
  },[])

  useEffect(() =>{
    setErrMsg('')
  },[values])

  useEffect(() =>{
    if(localStorage.getItem('token')) navigate('/');
  }, []);


  const handleSubmit = async (e) =>{
    e.preventDefault()
    try{
      const userData = await login(values).unwrap()
      dispatch(setCredentials({...userData, ...values}))
      setValues('')
      if (userData.statusCode === 200){
        localStorage.setItem("token", userData.data.token)
        localStorage.setItem("emailId", userData.data.emailId)
        localStorage.setItem("Permission",JSON.stringify(userData.data.permissionList))
        localStorage.setItem("role", userData.data.userRole)
        navigate('/')
      }

    } catch(err){
      if(!err.response){
        setTimeout(function () {
          toast.error("Invalid Credential !",{
          position: "top-center",
        })
      }, 1000);
      }
      else if (err.response?.status === 400){
        setTimeout(function () {
          toast.error("Invalid Credential !",{
          position: "top-center",
        })
      }, 1000);
      }
      else if (err.response?.status === 401) {
        setErrMsg("Unauthorized")
      }
      else{
        setErrMsg('Login failed')
      }
    }
  }

  const handleChange = (event) => {
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    
  };

  
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);


  return (
    
    <>
    <Background>
        <div className='login-form'>
          <div className="login-form-back">
            <h2>Login</h2>
            <p className='text-center'>We're open 24/7. Business doesn't stop when the work day ends, and neither do we.</p>
            <p ref={errRef} className={errMsg ? "errmsg" :"offScreen"}>{errMsg}</p>
            <Form className="mt-4" onSubmit={handleSubmit} noValidate>
            <Form.Group className="username" controlId="formBasicUseremail">
            <FloatingLabel controlId="emailinput" label="Email" >
                <Form.Control  placeholder="Email" autoComplete="off" ref={userRef}  type="email" name="emailId" onChange={handleChange} value={values.emailId || ''} required /> 
                <img src={emailicon} alt="user-icon" />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="password" controlId="formBasicPassword">
              <FloatingLabel controlId="passwordinput" label="Password" >
                <Form.Control placeholder="Password" autoComplete="off"  type={passwordShown ? "text" : "password"} name="password" onChange={handleChange}  required />
                <img src={passwordicon} alt="password-icon" onClick={togglePassword} />
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group className="mb-3 remember" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Remember me" />
                <a href="#" onClick={() => navigate('/forgot')}><p className='forgot'>Forgot password?</p></a>
              </Form.Group> */}
              {isLoading ?  
              <div className="login-btn">
                <Button type="submit" variant="secondary"><Spinner/></Button>
              </div> :
              <div className="login-btn">
                <Button type="submit" variant="secondary">SIGN IN <i className="fa fa-arrow-right" aria-hidden="true"></i></Button>
              </div>
              }
            </Form>
          </div>
          {/* <div className="sign-up-link">
            <p>Dont't have an account? <a href="#" onClick={() => navigate('/register')}>SIGN UP</a></p>
          </div> */}
        </div>
      </Background>
        </>
  )
}

export default Login;
