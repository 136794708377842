import { Row,Col,Form, FloatingLabel} from "react-bootstrap";
import InvoiceTable from "./ListofInvoice";


const SelectAccount = () => {
    return (
        <>
        <div className="select-account">
            <Row>
                <Col sm={4}>
                <FloatingLabel controlId="floatingInput" label="Account Name">
                    <Form.Select aria-label="Default select example">
                        <option>Select AccountNo.</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col sm={4}>
                    <Form.Group controlId="accountnumber">
                        <FloatingLabel controlId="floatingInput" label="Account Name">
                            <Form.Control type="text" placeholder="Account Name" name="AccountName" value="" />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group controlId="accountnumber">
                        <FloatingLabel controlId="floatingInput" label="Balance">
                            <Form.Control type="text" placeholder="Balance" name="Balance" value="" />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            </Row>
        </div>
        {/* Invoice table */}
        <InvoiceTable/>
        </>
    )
}
export default SelectAccount