import { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
//import { Autocomplete } from "@react-google-maps/api";
import { Autocomplete } from "@mui/material";
import { TextField, Box } from "@mui/material";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
export default function Places() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDiq3SbPN4ItXtL2ewYPosDsXrgGoAEYTw",
        libraries: ["places"],
    });

    if (!isLoaded) return <div>Loading...</div>;
    return <Map />;
}

function Map() {
    const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
    const [selected, setSelected] = useState(null);

    return (
        <>
        <div className="places-container">
          <PlacesAutocomplete setSelected={setSelected} />
        </div>
  
        <GoogleMap
          zoom={10}
          center={center}
          mapContainerClassName="map-container"
        >
          {selected && <Marker position={selected} />}
        </GoogleMap>
      </>
    );
}

const PlacesAutocomplete = ({ setSelected }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();
  
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
  
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);
      setSelected({ lat, lng });
    };
  
    return (
        <>
      <div onSelect={handleSelect}>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="combobox-input"
          placeholder="Search an address"
        />
        <div>
          <div>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <div key={place_id} value={description} />
              ))}
          </div>
        </div>
      </div>
{/* 
<Autocomplete
                    id="combo-box-demo"
                    options={value}
                    getOptionLabel={(option) => option.customer}
                    onSelect={handleSelect}
                    onInputChange={handleSelect}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.customer}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField {...params} label={"Consignor/Receiver"} fullWidth />
                    )}
                  />  */}
    </>
    );
  };