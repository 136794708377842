import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useUploadDataMutation } from "../../../../../features/cwtRateCard/cwtRateCardSlice";
import FileUploader from "../Fileupload";
import { CloudUpload } from "@material-ui/icons";

export default function UploadRateCard(props) {
    const [excelFile, setExcelFile] = useState(null);
    const [uploadFile] = useUploadDataMutation();

    const handleUpload = async (event) =>
    {
        event.preventDefault();
        if (!excelFile) {
          toast.error("Please upload a file!", {
            position: "top-center",
          })
          return;
        };
        const formData = new FormData();
        formData.append("file", excelFile);
        formData.append("name", excelFile.name);
        const res = await uploadFile({fileName: excelFile.name, body: formData});
        if (res?.data.statusCode === 200) {
          props.addExcelData(res.data.data);
        }
        if (res?.data?.errorData) {
          props.writeToExcel(res.data.errorData, excelFile.name.substring(0,excelFile.name.length - 5));
        }
    } 
    
      return (
        <form className="upload_data">
          <div className="file-uploader-container">
            <FileUploader  onFileSelectSuccess={(file) => setExcelFile(file)}
                onFileSelectError={({ error }) => alert(error)}>
            </FileUploader>
          </div>
          <div>
            <button type="button" className="upload-btn" onClick={handleUpload}>Upload <CloudUpload /></button>
          </div>
        </form>
      );
    }