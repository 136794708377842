import { AddCircle } from '@material-ui/icons'
import React from 'react'
import { Button, Container, Form } from 'react-bootstrap'

const AddActiveDriver = () => {
  return (
   
    <fieldset className=' mt-3 main-driver-container mb-2'>
        <label htmlFor="" className='label-heading '>Add Active Driver To Board</label>
        <div className="driver-container">
            <div className="w-75">
                <Form.Group controlId="Roletype">
                    <Form.Select aria-label="Floating label select example" name="role" >
                        <option>Select Active Driver</option>:
                        <>
                        </>
                    </Form.Select>

                </Form.Group>
            </div>
            <div className="section_action_btn">
                <div className="driverAddButton" ><Button> <AddCircle /> Add</Button></div>
            </div>
        </div>
    </fieldset>

  )
}

export default AddActiveDriver
