import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Background = (props) => {
  const navigate = useNavigate();
  return (
    <div className="login-back">
       <img className="logo-img" src={logo} alt="logo imag" onClick={() => navigate("/login")} />
      <div className="back-top"></div>
      <div className="back-bottom"></div>
      {props.children}     
    </div>
  );
};

export default Background;
