import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddFuelCostMutation, useGetAllFuelListQuery } from "../../../../../features/FuelCost/FuelCostSlice";
import { useForm } from "react-hook-form";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";


const FuelCostTable = () => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
  const [addFuel] = useAddFuelCostMutation()
  const { data: tableListData, refetch } = useGetAllFuelListQuery()
  const [isUpdate, setIsUpdate] = useState(false);
  const tablePricingList = tableListData?.data;

  const fuelCostId = tableListData?.data?.fuelCostId;
  const effectiveDate = tableListData?.data?.effectiveDate;
  const costPerGallon = tableListData?.data?.costPerGallon;

  const onSubmit = async (data) => {
    const addData = await addFuel(data)
    if (addData.data?.statusCode === 400) {
      toast.error(addData?.data.message, {
        position: "top-center",
      })
      return;
    } else if (addData.data?.statusCode === 200) {
      toast.success("Data added successfully", {
        position: "top-center",
      })

    }
    reset({ effectiveDate: '', costPerGallon: '' })
    refetch()
  };

  const columns = [

    { field: "effectiveDate", headerName: "Effective Date", width: 200 },
    { field: "costPerGallon", headerName: "Cost Per Gallon", width: 200 },
  ];

  const rows = tableListData?.data.length >= 0 && tableListData?.data.map((data) => {
    return {
      id: data?.fuelCostId,
      effectiveDate: data?.effectiveDate,
      costPerGallon: data?.costPerGallon
    }
  })




  return (
    <>

      {/* Remote login info */}
      <Row className="mb-4 mt-4">
        <Col sm={4}>
          <fieldset className="less_load_table">
            <label className="label-heading">Fuel Cost Table</label>
            <>
              <Row>
                <Col sm={12} className="justify-content-between">
                  <div className="section_action_btn">
                    {!isUpdate ?
                      <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)}><AddCircle /></button></div>
                      :
                      <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)}><Save /></button></div>
                    }
                  </div>
                </Col>

                <Row className="mt-2 mb-3">
                  <Col sm={12} className="mb-2">
                    <Form.Group controlId="Date">
                      <FloatingLabel controlId="floatingInput" label="Date" >
                        <Form.Control type="date" placeholder="Date" name="effectiveDate" {...register("effectiveDate")} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group controlId="ToCost">
                      <FloatingLabel controlId="floatingInput" label="Fuel Charge" >
                        <Form.Control type="number" placeholder="Fuel Charge" name="costPerGallon" {...register("costPerGallon")} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="user-role-table" style={{ height: "500px", background: "white" }}>
                  {/* <h6>List of Accessorial</h6> */}
                  <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
                </div>

              </Row>
            </>
          </fieldset>
        </Col>




      </Row>
    </>
  );
}
export default FuelCostTable;