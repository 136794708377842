import React from 'react'
import '../../../style.css'

const ActiveTripList = () => {
    return (
        <div>
            <fieldset className='my-3'>
                
                    <h6>Active Trip List</h6>

                <div className="mainContainer">
                    <div className="activeContainer my-2 shadow bg-body rounded flex-wrap">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>

                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>
                    <div className="activeContainer my-2 shadow bg-body rounded">
                        <div className="activeTripBox">
                            <div className="tripBox">
                                <label>Trip Number</label>
                                <h6><span>SW3898</span></h6>
                            </div>
                            <div className="tripBoxStatus">
                                <label>Status</label>
                                <span>0</span>
                            </div>

                        </div>
                        <div className="activeTripBox">
                            <div className="description">
                                <label>Trip Number</label>
                                <h6><span>09 | LVM </span></h6>
                            </div>
                            <div className="descriptionAssigned">
                                <label>Assigned Driver</label>
                                <span>110</span>
                            </div>
                        </div>
                    </div>




                </div>

            </fieldset>
        </div>
    )
}

export default ActiveTripList
