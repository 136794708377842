import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/services/authServices";


export const employeeApiSlice = apiSlice.injectEndpoints({
  tagTypes:["Employee"],
  endpoints:builder => ({
    getDriverNumber: builder.query({
      query: () => ({
        url: 'Employee/GetDriverNumber',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getPayrollCycle: builder.query({
      query: () => ({
        url: 'Employee/GetPayrollCycle',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getPayrollBasedOn: builder.query({
      query: () => ({
        url: 'Employee/GetPayroll',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getPayrollType: builder.query({
      query: () => ({
        url: 'Employee/GetPayrollType',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getGenderCode: builder.query({
      query: () => ({
        url: 'Employee/GetGender',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getUSAccessType: builder.query({
      query: () => ({
        url: 'Employee/GetUsAccess',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getTravelDocument: builder.query({
      query: () => ({
        url: 'Employee/GetTravelDocument',
        method: 'GET',
        providesTags: ['Employee'],
      }),
    }),
    getClassAccess: builder.query({
      query: (provineStateID) => ({
        url: `Employee/GetEmpClassInhancedsByProvienceId?licenseByProvinceId=${provineStateID}`,
        method: 'GET',
        providesTags: ['Country'],
      }),
    }),
    getEmployeeList: builder.query({
      query:() =>({
        url: 'Employee/GetAllEmployees',
        method:"GET",
        providesTags:['Employee']
      })
    }),
    getCertificate:builder.query({
      query:() =>({
        url: 'Employee/GetAllCertificate',
        method:"GET",
        providesTags:['Employee']
      })
    }),
    addCertification: builder.mutation({
      query: (data) => ({
        url: '/Employee/AddCertificate',
        method: 'POST',
        body:data,
      }),
      invalidatesTags: ['Employee'],
    }),
    getCertificationDetails : builder.query({
      query:(certificateId) => ({
        url:`Employee/GetCertificateDetailsById?certifictaeId=${certificateId}`,
        method:"GET",
        providesTags:['Employee']
      })
    }),
    updateCertificate : builder.mutation({
      query:(data) => ({
        url:'/Employee/UpdateCertificate',
        method:"POST",
        body:data,
      }),
      invalidatesTags: ['Employee'],
    }),
    deleteCertificate: builder.mutation({
      query:(certificateId) =>({
        url:`Employee/DeleteCertifictate?certifciateId=${certificateId}`,
        method:"POST",
      })
    }),
    addEmployee : builder.mutation({
      query:(formData) => ({
        url:'/Employee/AddEmployee',
        method:"POST",
        body:formData,
      }),
      invalidatesTags: ['Employee'],
    }),
    getEmployeeDetails : builder.query({
      query:(id) => ({
        url:`Employee/GetEmployeeDetailById?EmployeeId=${id}`,
        method:"GET",
        providesTags:['Employee']
      })
    }),
    updateEmployee : builder.mutation({
      query:(formData) => ({
        url:'/Employee/UpdateEmployeeDetail',
        method:"POST",
        body:formData,
      }),
      invalidatesTags: ['Employee'],
    }),
    deleteEmployee: builder.mutation({
      query:(employeeId) =>({
        url:`/Employee/DeleteEmployee?employeeId=${employeeId}`,
        method:"POST",
      }),
      invalidatesTags: ['Employee'],
    }),
    deleteDocument: builder.mutation({
      query:(documentId) =>({
        url:`/Employee/DeleteEmployeeDocument?documentId=${documentId}`,
        method:"POST",
      }),
      invalidatesTags: ['Employee'],
    })
  }),
})
export const { useGetDriverNumberQuery, useGetPayrollCycleQuery, useGetPayrollBasedOnQuery, useGetPayrollTypeQuery, useGetGenderCodeQuery , useGetUSAccessTypeQuery , useGetTravelDocumentQuery,
   useLazyGetClassAccessQuery , useGetEmployeeListQuery, useGetCertificateQuery, useAddCertificationMutation, useLazyGetCertificationDetailsQuery, useUpdateCertificateMutation, useDeleteCertificateMutation, useAddEmployeeMutation, useLazyGetEmployeeDetailsQuery, 
   useUpdateEmployeeMutation, useDeleteEmployeeMutation, useDeleteDocumentMutation} = apiSlice

const initialState = {
  show:false,
  selected:2,
  certificateList : [],
  uploadedFiles:[],
  fileLimit:false,
  MAX_COUNT : 1,
  uploadImage:''

};
//const MAX_COUNT = 5

const createEmployeeSlice = createSlice({
    name: "createEmployee",
    initialState,
    reducers: {
      toggleShow:(state) =>{
        state.show = !state.show
      },
      changeSelected:(state, action) =>{
        state.selected = action.payload
      },
      uploadEmployeeImage:(state,action) =>{
        state.file = state.file((URL.createObjectURL(action.payload)))
      },
      createCertificate: (state, action) => {
        const lists = {
          certificateId: new Date().getTime().toString(),
          certificateList: action.payload,
        };
        state.certificateList.push(lists);
      },
      deleteCertificateList: (state,action) => {
        state.certificateList = state.certificateList.filter(
            (data) => data.certificateId !== action.payload
        )
      },
      editCertificate : (state, action) => {
        const currentLists = Array.from(state.certificateList);
        const filterData = state.certificateList.findIndex((certificateList) => certificateList.certificateId === action.payload.certificateId);
        currentLists[filterData] = {
          certificateId:action.payload.certificateId,
            certificateList:action.payload
        }
        return { ...state, certificateList: currentLists };
    },
    reset: (state) => {
      state =  {certificateList : []}
    },
    },
})

export const { toggleShow , uploadEmployeeImage , changeSelected, createCertificate, deleteCertificateList, editCertificate, reset} = createEmployeeSlice.actions

export default createEmployeeSlice.reducer