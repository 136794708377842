import { Button,FloatingLabel } from "react-bootstrap";
import { Form } from "react-bootstrap";
import emailicon from '../../assets/email-icon.png';
import Background from './Background';
import FormV from './useForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useState,useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';

const Forgot = () => {
    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    
    useEffect(() => {
      userRef.current.focus();
  }, [])

  // useEffect(() => {
  //   setErrMsg('');
  // }, [values])

  const navigate = useNavigate();
  useEffect(() =>{
    if(localStorage.getItem('userId')) navigate('/');
  }, []);
  
 
  const forgotApiCall = async (values) => {
    // debugger;
    const token = localStorage.getItem('token')

    //const url = "https://lvmtech-tlcm-api.chetu.com/Login/ForgetPassword?EmailId=" + values.emailid;
    const url = "http://api.elitex.lvm.ai/Login/ForgetPassword?EmailId=" + values.emailid;
  
    try {
      const fetchData = await axios.post(url, {}, { headers: {"Authorization" : `Bearer ${token}`} }, {
        EmailId:values.emailId
     
      });
      //const token = localStorage.setItem('token')
      setSuccess(true);
      // localStorage.setItem("token", fetchData.data.data.token)
      if(fetchData.data.statusCode === 400){
        toast.error("Email doesn't exist!",{
          position: "top-center",
        })
      }
      else if (fetchData.data.statusCode === 200){
        toast.success("Reset link sent successfully.Please check your mail !",{
          position: "top-center",
        })
      }
      else{
        toast.error("Email not exist!",{
          position: "top-center",
        })
      }
   
    } catch (err) {
      if (!err?.fetchData) {
          setErrMsg('No Server Response');
      } else if (err.fetchData.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.fetchData.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      errRef.current.focus();
  }
  }
  const { values, errors, handleChange, handleSubmit, } = FormV(() => forgotApiCall(values), validate);
  function validate(values) {
    let errors = {};
    if (!values.emailid) {
      errors.emailid = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.emailid)) {
      errors.emailid = 'Email address is invalid';
    }
    return errors;
  };

  return (
    <>
      <Background>
        <div className='login-form'>
          <div className="forgot-form-back">
            <h2>Forgot Password</h2>
            <p className='text-center'>We’re open 24/7. Business doesn’t stop when the work day ends, and neither do we..</p>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <Form className="mt-4" onSubmit={handleSubmit} noValidate>
              <Form.Group className="username" controlId="formBasicemail">
              <FloatingLabel controlId="floatingInput" label="Email" >
                <Form.Control placeholder="Email" autoComplete="off"  ref={userRef} className={`input ${errors.emailid && 'is-danger'}`} type="email" name="emailid" onChange={handleChange} value={values.emailid || ''} required />
                <img src={emailicon} alt="email-icon" />
                {errors.emailid && (
                  <p className="help is-danger">{errors.emailid}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <div className="login-btn">
                <Button type="submit" variant="secondary"> SEND EMAIL <i className="fa fa-arrow-right" aria-hidden="true"></i></Button>
              </div>
            </Form>
          </div>
        </div>
      </Background>
    </>
  );
}

export default Forgot;
