import logo from '../../assets/logo.png';
import { useEffect, useState } from 'react';
import menuicon from '../../assets/menu-icon.png'
import MenuItem from './Menuitem';
// import menuItems from './menuItems';
import { useNavigate } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import useArr from './menuItems';


const Sidebar = (props)=>{

const [menuItems] =  useArr()
  const navigate = useNavigate();
  const [inactive , setInactive] = useState(false);
  useEffect (() =>{
    props.onCollapse(inactive);
  })

  return (
    <>
<div className={`sidebar-menu ${inactive ? "inactive" : ""}`}>
<div className='top-section'>
  <div className='logo'>
  <a onClick={() => navigate('/')}><img src={logo} alt='logo-image'/></a>  
  </div>
<div className='toggle-menu'>
<label className="burger" htmlFor="burger">
<input type="checkbox" id="burger" onClick={() => setInactive(!inactive)}/>
  <span></span>
  <span></span>
  <span></span>
</label>
</div>
</div>
{/* Sidebar menu */}
<ul className="nav_sidebar_menu">
  {menuItems.map((menuItem, index) =>(
    <MenuItem
     key ={index}
     menuItem={menuItem}
     />
  ))}       
</ul>
</div>  
</>
  );
}

export default Sidebar;
