import Wrapper from "../../Wrapper";
import { Container,Row, Col,Button} from "react-bootstrap";
import InterlinersList from "./InterlinersList";
import { AddCircle } from "@material-ui/icons";
import AddInterliners from "./AddInterliners";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleShow, useLazyGetInterlinersDetailByIdQuery } from "../../../features/Interliners/InterlinersFieldsSlice";



const Interliners = () =>{
const dispatch = useDispatch()
    const rateCardSection = (useSelector((state) => state.interliner.list)).map((el) => {return ({ id: el.id, ...el.list })})
    // const [show, setShow] = useState(false)
    const show = useSelector((state)=>state.interliner.show)
    const toggleHandler = ()=>{dispatch(toggleShow())}
    const [getInterlinerRowData , rowData]=useLazyGetInterlinersDetailByIdQuery()
    const interlinerRowData = rowData?.data?.data
    const abc = interlinerRowData?.getInterlinersRateCardDTOs ? interlinerRowData?.getInterlinersRateCardDTOs : []

    const bb = [...rateCardSection, ...abc]
    
    return(
        <Wrapper>
        <div className="employee">
                    <Container fluid>
                        <div className="role-assignment">
                            <div className="role-assignment-name">
                                <h5>List of Interliners</h5>
                            </div>
                            <div className="add-role-button d-flex">
                                <span ><Button variant="outline-primary" onClick={toggleHandler}> <AddCircle /> Add Interliners</Button></span>
                            </div>
                        </div>
                        <Row>
                            <Col sm={12}>
                                {show ? 
                                <AddInterliners rateCardSection={rateCardSection} interlinerRowData={interlinerRowData} getInterlinerRowData={getInterlinerRowData} bb={bb}/>
                                : null }
                                <InterlinersList getInterlinerRowData={getInterlinerRowData} interlinerRowData={interlinerRowData}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </Wrapper>
    )
}
export default Interliners;