import { Row, Col, Modal } from "react-bootstrap";
import { Button, Form, FloatingLabel, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AddCircle, Delete, Save, Edit } from "@material-ui/icons";
import useOrder from "../../../context/OrderContext";
import { addProduct, editProduct, deleteProduct } from "../../../features/product/productDataSlice";
import { useDispatch } from "react-redux";
import { useGetListOfUnitQuery } from "../../../features/unit/unitSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const Productdesp = () => {
  const [addProducts, setAddProducts] = useState([]);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false)
  const dispatch = useDispatch();

  const { data } = useGetListOfUnitQuery();

  const unit = data?.data;

  const { productLists, ActualWeight, orderInfo } = useOrder();
  const [lgShow, setLgShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [stackable, setStackable] = useState(false);
  const handleChange = (event) => { setChecked(event.currentTarget.checked) }
  // const Producthandler = (e) => {setAddProducts(addProducts => ({ orderId:1,...addProducts, [e.target.name]: e.target.value }))}
  const Producthandler = (e) => { setAddProducts(addProducts => ({ ...addProducts, [e.target.name]: e.target.value })) }


  const productHandler = (e) => {
    e.preventDefault();
    // if (Object.keys(addProducts).length === 0) {
    //   toast.info("Please fill the form", {
    //     position: "top-center",
    //   })
    //   return;
    // }
    //  else if (editingTaskIndex === null) {
    if (editingTaskIndex === null) {
      if(productLists.length + 1 <= orderInfo.pcs) {
        const newProducts = { ...addProducts, IsStackable: stackable }
        dispatch(addProduct(newProducts));
        setStackable(false);
        setChecked(false);
      }
      else {
        toast.info("You have exceeded the limit", {
          position: "top-center",
        })
        return;
      }
    }
    else {
      let editingItem = productLists[editingTaskIndex];
      editingItem = addProducts;
      dispatch(editProduct(editingItem));
    }
    setAddProducts("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
  };


  const editProductList = (id) => {
    let newEditItem = productLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setAddProducts(newEditItem)
    setEditingTaskIndex(id);
  }


  useEffect(() => {
    // dispatch(fetchUnit())
  }, [])


  return (
    <>
      <Button onClick={() => setLgShow(true)} className="product-btn">
        Product Description
      </Button>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        id="product-desp"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {" "}
            Product Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* second section */}

          <Row className="mt-4 mb-2">
            <Col sm={10}>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                  Actual Weight
                </Form.Label>
                <Col sm="4">
                  <Form.Control type="text" placeholder="Actual weight" value={ActualWeight || 0} />
                </Col>
              </Form.Group>
            </Col>
            <Col className="mb-2" sm={2}>
              {!isUpdate ?
                <div className="addaccessrial_btn">
                  <button type="button" className="btn" onClick={productHandler}><AddCircle /></button>
                </div>
                :
                <div className="addaccessrial_btn">
                  <button type="button" className="btn" onClick={productHandler}><Save /></button>
                </div>
              }
            </Col>
          </Row>
          <fieldset className="mb-2">
            <div className="product-input">
              <div className="product-input-box">
                <label htmlFor="unit">Partial</label>
                <input type="checkbox" checked={checked} onChange={handleChange} />
              </div>
              <div className="product-input-box">
                <label htmlFor="unit">Stackable</label>
                <input type="checkbox" name="isStackable" checked={stackable} onChange={() => {setStackable(!stackable) }} />
              </div>
              <div className="product-input-box">
                <label htmlFor="unit">Product Type</label>
                {/* <input type="text" name="unit" value={addProducts.unit || ""} onChange={(e) => { Producthandler(e) }} /> */}
                <select aria-label="Floating label select example" name="unitType" onChange={e => { Producthandler(e) }} required style={{ border: "1px solid #d5d4d4", borderRadius: "4px", padding: "10px 35px 10px 5px" }}>

                  <option>{addProducts?.unitType || ""}</option>:
                  <>
                    {
                      unit && unit.map((data, index) => (
                        <option key={index} value={data?.unitType}>{data?.unitType}</option>
                      ))

                    }
                  </>
                </select>
              </div>
              <div className="product-input-box">
                <label htmlFor="unit">Unit Rate</label>
                <input type="number" name="unitrate" value={addProducts.unitrate || ""} onChange={(e) => { Producthandler(e) }} min="0" />
              </div>
              {
                checked && (
                  <>
                    <div className="product-input-box"> <label htmlFor="unit">Package</label><input type="text" name="pkg" value={addProducts.pkg || ""} onChange={(e) => { Producthandler(e) }} /></div>
                    <div className="product-input-box"> <label htmlFor="unit">Package Type</label><input type="text" name="pkgtype" value={addProducts.pkgtype || ""} onChange={(e) => { Producthandler(e) }} /></div>
                  </>
                )
              }
              <div className="product-input-box"> <label htmlFor="unit">Product name</label><input type="text" name="productname" value={addProducts.productname || ""} onChange={(e) => { Producthandler(e) }} /></div>
              <div className="product-input-box"> <label htmlFor="unit">Product Reference</label><input type="number" name="productref" value={addProducts.productref || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">Product Weight</label><input type="number" name="productweight" value={addProducts.productweight ||""} onChange={(e) => { Producthandler(e) }} min="0"/></div>
              <div className="product-input-box"> <label htmlFor="unit">Length</label><input type="number" name="length" value={addProducts.length || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">Width</label><input type="number" name="width" value={addProducts.width || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">Height</label><input type="number" name="height" value={addProducts.height || ""} onChange={(e) => { Producthandler(e) }} min="0"/></div>
              <div className="product-input-box"> <label htmlFor="unit">Size</label><input type="number" name="size" value={addProducts.size || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">Spots</label><input type="number" name="spots" value={addProducts.spots || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">Chargable Weight</label><input type="number" name="chargebleweight" value={addProducts.chargebleweight || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">Class</label><input type="number" name="class" value={addProducts.class || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
              <div className="product-input-box"> <label htmlFor="unit">NMFC</label><input type="number" name="NMFC" value={addProducts.NMFC || ""} onChange={(e) => { Producthandler(e) }} min="0" /></div>
            </div>
          </fieldset>
          {/* table section */}
          <Row className="product-table">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Unit</th>
                  <th>UnitRate</th>
                  <th>Package</th>
                  <th>Package Type</th>
                  <th>Product name</th>
                  <th>Product Reference</th>
                  <th>Product Weight</th>
                  <th>Length</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Size </th>
                  <th>Spots</th>
                  <th>chargeble weight</th>
                  <th>Class</th>
                  <th>NMFC</th>
                </tr>
              </thead>
              <tbody>
                {/* {productLists.length === orderInfo.pcs ?   */}
                {productLists && productLists.map((data, id) => {
                  return (
                    <tr key={data.id}>
                      <td>
                        <div className="action_btn">
                          <span className="edit_btn" onClick={() => { editProductList(data.id) }}><Edit /></span>
                          <span className="del_btn" onClick={() => dispatch(deleteProduct(data.id))}><Delete /></span>
                        </div>
                      </td>
                      <td>{data.unitType}</td>
                      <td>{data.unitrate}</td>
                      <td>{data.pkg}</td>
                      <td>{data.pkgtype}</td>
                      <td>{data.productname}</td>
                      <td>{data.productref}</td>
                      <td>{data.productweight}</td>
                      <td>{data.length}</td>
                      <td>{data.width}</td>
                      <td>{data.height}</td>
                      <td>{data.size}</td>
                      <td>{data.spots}</td>
                      <td>{data.chargebleweight}</td>
                      <td>{data.class}</td>
                      <td>{data.NMFC}</td>
                    </tr>
                  )
                })
                  // : "Enter only pieces jisna" 
                }

              </tbody>
            </Table>
          </Row>

        </Modal.Body>
      </Modal>
    </>
  );
};
export default Productdesp;
