import { Col, Row, Form, FloatingLabel, Table, Accordion, InputGroup, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Delete, Edit, Save, AddCircle } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { useGetAccessorialQuery } from "../../../../../features/accessorial/accessorialDetailSlice";
import { useUpdateAccessorialDataMutation, useDeleteAccessorialDataMutation, useAddAccessorialSurchargeAfterEditMutation } from "../../../../../features/accessorial/accessorialDetailSlice";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { addAccessorial, editAccessorial } from '../../../../../features/accessorial/accessorialDetailSlice';
import { useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../../../Sidebar/Permission";
import Select from "react-select"
import { useDispatch } from "react-redux";


const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
const EditAccessorialSurcharge = (props) => {
  const [permission] = useCustomData()
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
  const { data: accessorials } = useGetAccessorialQuery()
  const [updateAccessorialData] = useUpdateAccessorialDataMutation()
  const [deleteAccessorialData] = useDeleteAccessorialDataMutation()
  const [addAccessorialSurchargeAfterEdit] = useAddAccessorialSurchargeAfterEditMutation()
  const [modifyHistory] = useModifyHistoryMutation()
  const { singleAccount, handleAccount, defaultCardData, vehicleLists, accessorialLists,getDefaultCard  } = useCustomer();
  const account = singleAccount && singleAccount[0];
  const [message, setMessage] = useState("Modified the accessorial charge section")
  const [isEditItem, setIsEditItem] = useState(null);
  const [isDelete, setIsDelete] = useState();
  const [accessorialData, setAccessorialData] = useState({});
  const [showAcesorialSecondFields, setShowAccesrialSecondFields] = useState(false);
  const [editId, setEditId] = useState('')
  const [accessorialOpen, setAccessorialOpen] = useState(false);
  const handleAccessorialOpen = (data) => { setAccessorialOpen(true); setIsDelete(data)  };
  const handleAccessorialClose = () => { setAccessorialOpen(false); };
  const Accessorialupdate = (e) => { setAccessorialData(accessorialData => ({ ...accessorialData, [e.target.name]: e.target.value })) }
  const prevCount = usePrevious(accessorialData);
  const [showTime, setShowTime] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [show, setShow] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedEditData, setSelectedEditData] = useState([])

  const [statusr, setStatusr] = useState(0);
  const [statust, setStatust] = useState(0);
  const radioHandlerr = (statusr) => { setStatusr(statusr) };
  const radioHandlert = (statust) => { setStatust(statust); setShowTime(false) };

  const disableHandler = () => {
    setShow(true)
  }
  const timeHandler = () => { setShowTime(true) }
  const fTime = watch('FromTime');
  const tTime = watch('ToTime');

  useEffect(() => {
    setAccessorialData(accessorialData)
    reset(accessorialData)
},[accessorialData])

  // const accessorialList = defaultCardData?.mlAccessorialChargeSurchargeList;

  const arr = []
  vehicleLists?.map((user) => {
      return arr.push({ value: user.VehicleType, label: user.VehicleType });
  });

  const EditaccessItem = (rateCardAccessorialID) => {
    setIsUpdate(true);
    setEditId(rateCardAccessorialID)
    const obj = [];
    let newEditItem = accessorialLists.find((data) => {
      return data.RateCardAccessorialID === rateCardAccessorialID
    });
    newEditItem.VehicleTypes.forEach((elem, i) => {
      obj.push({ value: elem, label: elem })
  })
    setAccessorialData(newEditItem);
    setSelectedData(obj)
    //setSelectedEditData(newEditItem?.vehicleTypes)
    setShowAccesrialSecondFields(showAcesorialSecondFields => !showAcesorialSecondFields)
    setIsEditItem(rateCardAccessorialID);
    reset(newEditItem)
    setShow(true)
    setShowTime(accessorialData.RateType === "T" ? true : false)
    //setRateTypeSelected(newEditItem.rateType)

  }

  const editAccessCustomerList = async (accessorialData) => {
    accessorialData.vehicleTypes = selectedData
    if (JSON?.stringify(prevCount) === JSON?.stringify(accessorialData)) {
      setTimeout(function () {
        toast.info("No Changes found on this accessorial surcharge section !", {
            position: "top-right",
        })
    });
    }
    else {
      delete accessorialData.vehicleTypes
      accessorialData.vehicleTypes = selectedEditData
      if (accessorialData.RateType !== "T") accessorialData.Time = "";
      const updateAccessorial = await updateAccessorialData({selectedEditData,...accessorialData, rateCardAccessorialID: accessorialData.RateCardAccessorialID})
      setAccessorialData("")
      //handleAccount(account.account_No)
      if (updateAccessorial.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Accessorial section updated successfully !", {
            position: "top-right",
          })
        });
        const accessorial = accessorials.data.find(accessorial => accessorial.accessorialID == accessorialData.AccessorialID)
        dispatch(editAccessorial({...accessorialData, AccessorialDescription: accessorial.description}));
        setSelectedData('')
        setIsUpdate(false);
        reset({AccessorialID:"", FromTime:"", ToTime:"", TimeRateType:"", TimeRate:"", RateType:"", Rate:"", Time:""});
      }
    }
    if (JSON?.stringify(prevCount) !== JSON?.stringify(accessorialData)) {
      const modifieddata = {
        AccountNo: account.account_No,
        CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
    reset({AccessorialID:"", FromTime:"", ToTime:"", TimeRateType:"", TimeRate:"", RateType:"", Rate:"", Time:""});
  }

  const deleteAccessCustomerList = async () => {
    const deleteAccessorialRecord = await deleteAccessorialData(isDelete)
        if (deleteAccessorialRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Accessorial surcharge record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleAccessorialClose();
  }

  const addNewSection = async (data) => {
    if (Object.keys(data).length === 0) {
      setShowAccesrialSecondFields(true)
      return;
    }
    else {

      delete data.VehicleTypes
      const newData = { VehicleTypes: selectedData, rateCardID: props.selectedCard, ...data }
      const addDataOnUpdate = await addAccessorialSurchargeAfterEdit(newData)
      // handleAccount(account.account_No)
      if (addDataOnUpdate?.data?.statusCode === 200) {
        toast.success("Accessorial Charge section Added successfully !", {
          position: "top-right",
        })
        const accessorialDesc = accessorials?.data?.find((item) => item.accessorialID == newData.AccessorialID)?.description
        dispatch(addAccessorial({...newData, AccessorialDescription: accessorialDesc}));
        getDefaultCard(props.selectedCard)
      }
    }
    reset({ AccessorialID: "", FromTime: "", ToTime: "", TimeRateType: "", TimeRate: "", RateType: "", Rate: "", Time: "" });
    setSelectedData('')
    setShow(false)
    setShowTime(false)
    //setShowAccesrialSecondFields(false)
  }

  const handleSelect = (e) => {
    // if (isEdit) {
    //   const mapData = e?.map((item) => item?.value
    //   )
    //   if (mapData && mapData?.length > 0) {
    //     setSelectedEditData(mapData)
    //   }
    // }
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData?.length > 0) {
      setSelectedData(mapData)
    }
  }


  const [statusT, setStatusT] = useState(0);
  const [statusR, setStatusR] = useState(0);
  const radioHandlerT = (statusT) => { setStatusT(statusT) };
  const radioHandlerR = (statusR) => { setStatusR(statusR) };

  return (
    <>
      {/* Contract info  section*/}
      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
          <div className="section_action_btn">
            { isUpdate?
            <>
              {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Update') !== -1 ?
              <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(editAccessCustomerList)}>Update record</button></div>
                  : <div className="save_btn disable"><button type="button" className="btn">Update record</button></div> }   
            </>       
            :
            <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addNewSection)}>Add new record</button></div>
            }
            </div>
            {showAcesorialSecondFields ?
              <>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="AccessorialID">
                      <FloatingLabel controlId="floatingSelectGrid" label="Accessorial ID">
                      <Form.Select aria-label="Floating label select example" name="AccessorialID" oonChange={onChange}
                                {...register('AccessorialID', { required: 'Field is required' })}>
                                <option value="">Select</option>
                                <>
                              {
                                accessorials?.data?.map((data, index) => (
                                  <option key={index} value={data.accessorialID}>{data.description}</option>
                                ))
                              }
                            </>
                              </Form.Select>
                      </FloatingLabel>
                      <p className="error-message">{errors.AccessorialID?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Controller
                      control={control}
                      name="VehicleTypes"
                      defaultValue={selectedData}
                      {...register('VehicleTypes')}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          isMulti
                          placeholder="Select Vehicle"
                          name={name}
                          defaultValue={selectedData}
                          ref={ref}
                          options={arr}
                          // value = {serviceData.VehicleTypes?.map((d)=>d.value)?.join(' , ')}
                          value={selectedData ? selectedData?.find((e) => e.value === value) : ""}
                          onChange={(e) => handleSelect(e)}
                        />
                      )}
                    />
                    {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="From Time">
                      <FloatingLabel controlId="floatingInput" label="From Time" >
                        <Form.Control type="time" placeholder="From Time" name="FromTime" onChange={onChange} onClick={disableHandler}
                                                                  {...register('FromTime',/*{ required: 'Field is required'}*/)} />
                      </FloatingLabel>
                      <p className="error-message">{errors.FromTime?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="To Time">
                      <FloatingLabel controlId="floatingInput" label="To Time" >
                        <Form.Control type="time" placeholder="To Time" name="ToTime" nChange={onChange} onClick={disableHandler}
                                                                  {...register('ToTime',/*{ required: 'Field is required'}*/)} />
                      </FloatingLabel>
                      <p className="error-message">{errors.ToTime?.message}</p>
                    </Form.Group>
                  </Col>
                </Row>


                <Row className="mt-4 mb-3">
                  <Col sm={6}>
                    <fieldset className="mb-3">
                      <label className="label-heading">Rate Type Section</label>
                      <Row>
                        <Col sm={6}>
                          <div className="fieldset-inner" >
                            <label className="label-heading">Choose the rate type</label>
                            <div className="floating-checbox">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="RateType" {...register("RateType", { required: 'Field is required' })} onChange={e => radioHandlert(1)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="RateType" {...register("RateType", { required: 'Field is required' })} onChange={e => radioHandlert(2)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="T" name="RateType" {...register("RateType", { required: 'Field is required' })} onClick={() => timeHandler()} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Time</label>
                              </div>
                            </div>
                          </div>
                          {errors.RateType?.message && (<p className="error-message">{" * " + errors.RateType?.message}</p>)}
                        </Col>
                        <Col sm={6} className="mt-2">
                          {statust === 0 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                <Form.Control type="text" placeholder="Time Rate" name="Rate" {...register('Rate', { required: 'Please select the options' })} />
                                <label>Rate ($)</label>
                              </div>
                              {errors.Rate?.message && (<p className="error-message">{" * " + errors.Rate?.message}</p>)}
                            </div>
                          }
                          {statust === 1 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control type="number" placeholder="Time Rate" name="Rate" {...register('Rate', { required: 'Field is required' })} min='0' />
                                <label>Rate ($)</label>
                              </div>
                              {errors.Rate?.message && (<p className="error-message">{" * " + errors.Rate?.message}</p>)}
                            </div>
                          }
                          {statust === 2 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text>%</InputGroup.Text>
                                <Form.Control type="number" placeholder="Time Rate" name="Rate" {...register('Rate', { required: 'Field is required' })} min='0' />
                                <label>Rate (%)</label>
                              </div>
                              {errors.Rate?.message && (<p className="error-message">{" * " + errors.Rate?.message}</p>)}
                            </div>
                          }
                        </Col>
                        {showTime ?
                          <Col sm={6} className="mt-2">
                            <div className="form-floating">
                              <Form.Control type="number" placeholder="Time Rate" name="Time" /* defaultValue="0" */ {...register('Time', { required: 'Field is required'})} min='0' />
                              <label>Time (Minutes)</label>
                            </div>
                            {errors.Time?.message && (<p className="error-message">{" * " + errors.Time?.message}</p>)}
                          </Col>
                          : ""}
                      </Row>
                    </fieldset>
                  </Col>
                  {show && (fTime || tTime)?
                  <Col sm={6}>
                    <fieldset className="mb-3">
                      <label className="label-heading">Time Rate Section</label>
                      <Row>
                          <Col sm={6}>
                            <div className="fieldset-inner">
                              <label className="label-heading">Choose the time rate type</label>
                              <div className="floating-checbox" /* onChange={e => Accessorialhandler(e)} */ >

                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="TimeRateType" {...register("TimeRateType")} onChange={e => radioHandlerr(1)} />
                                  <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="TimeRateType" {...register("TimeRateType")} onChange={e => radioHandlerr(2)} />
                                  <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                </div>
                              </div>
                              {errors.TimeRateType?.message && (<p className="error-message">{" * " + errors.TimeRateType?.message}</p>)}
                            </div>
                          </Col>
                          <Col sm={6}>
                            {statusr === 0 &&
                              <div className="btn-status">
                                <div className="form-floating">
                                  <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                  <Form.Control type="number" placeholder="Rate Type" name="TimeRate" {...register('TimeRate', { required: 'Field is required' })}  />
                                  <label> Time Rate ($)</label>
                                </div>
                                {errors.TimeRate?.message && (<p className="error-message">{" * " + errors.TimeRate?.message}</p>)}
                              </div>
                            }
                            {statusr === 1 &&
                              <div className="btn-status">
                                <div className="form-floating">
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control type="number" placeholder="Weight Rate" name="TimeRate" {...register('TimeRate', { required: 'Field is required' })} min='0' />
                                  <label>Time Rate ($)</label>
                                </div>
                                {errors.TimeRate?.message && (<p className="error-message">{" * " + errors.TimeRate?.message}</p>)}
                              </div>
                            }
                            {statusr === 2 &&
                              <div className="btn-status">
                                <div className="form-floating">
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Form.Control type="number" placeholder=" Rate" name="TimeRate" {...register('TimeRate')} min='0' />
                                  <label>Time Rate (%)</label>
                                </div>
                                {errors.TimeRate?.message && (<p className="error-message">{" * " + errors.TimeRate?.message}</p>)}
                              </div>
                            }
                          </Col>
                        </Row>
                    </fieldset>
                  </Col> : "" } 
                </Row>
              </>
              : null}
            {/* table */}
            <Row>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Accessorial ID</th>
                    <th>Vehicle Type</th>
                    <th>Rate Type</th>
                    <th>Rate</th>
                    <th>Time (Minutes)</th>
                    <th>From Time</th>
                    <th>To Time</th>      
                    <th>Time Rate Type</th>
                    <th>Time Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {accessorialLists?.length > 0 ? (
                    accessorialLists?.map((data) => {
                      return (
                        <tr key={data.rateCardAccessorialID}>
                          <td>
                            <div className="action_btn">
                            {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Update') !== -1 ?
                              <span className="edit_btn" onClick={() => EditaccessItem(data.RateCardAccessorialID)}><Edit /></span>
                              :<span className="edit_btn disable"><Edit /></span> }
                              {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Delete') !== -1 ?
                              <span className="del_btn" onClick={() => handleAccessorialOpen(data?.RateCardAccessorialID)}><Delete /></span>
                              :  <span className="del_btn disable"><Delete /></span>}
                              <div>
                                <Dialog open={accessorialOpen} onClose={handleAccessorialClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                  <DialogTitle id="alert-dialog-title">
                                    Delete Confirmation
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      Are you sure you want to delete this row?
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button className="btn-secondary" onClick={handleAccessorialClose}>Cancel</Button>
                                    <Button className="btn-primary" onClick={() => { deleteAccessCustomerList(); }} autoFocus> Confirm</Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                          </td>
                          <td>{data.AccessorialDescription}</td>
                          <td>{data?.VehicleTypes?.join(' , ')}</td>
                          <td>{data.RateType}</td>
                          <td>{data.Rate}</td>
                          <td>{data.Time}</td>
                          <td>{data.FromTime}</td>
                          <td>{data.ToTime}</td>
                          <td>{data.TimeRateType}</td>
                          <td>{data.TimeRate}</td>
                  


                        </tr>
                      )
                    })
                  ) : (
                    <p>No data found </p>
                  )}
                </tbody>
              </Table>
            </Row>
          </fieldset>
        </Col>
      </Row>

    </>
  );
}
export default EditAccessorialSurcharge;