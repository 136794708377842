import Wrapper from "../Wrapper";
import { Tab, Tabs,} from "react-bootstrap"
import BatchInvoice from "./BatchInvoice";
import OneInvoice from "./OneInvoice";
import PrintInvoice from "./PrintBatch";
import PrintPost from "./PrintPost";



 const ProcessCustomerInvoice = ()=>{
     return (
         <>
         <Wrapper>
         <h5 className="ml-3 mb-3">Process Customer Invoices</h5>
         <div className="ageed-section">
            <Tabs defaultActiveKey="Create a batch of invoice for group of customer" id="uncontrolled-tab-example" className="mb-3" >
             {/* Select Create a batch of invoice for group of customer method */}
      <Tab eventKey="Create a batch of invoice for group of customer" title="Create a batch of invoice for group of customer">
        <BatchInvoice/>
        </Tab>
        <Tab eventKey="Create one invoice for one customer" title="Create one invoice for one customer">
            <OneInvoice/>
        </Tab>
        <Tab eventKey="Print batch invoice" title="Print batch invoice">
            <PrintInvoice />
        </Tab>
        <Tab eventKey="Print post invoice" title="Print post invoice">
            <PrintPost />
        </Tab>
        </Tabs>
        </div>
         </Wrapper>
         </>
     );
 }
 export default ProcessCustomerInvoice;