import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import useCustomer from "../../../../../context/CustomerContext";
import { addLessLoad, editLessLoad, deleteLessLoad,  } from "../../../../../features/lessLoad/lessLoadSlice";
import { addFullLoad, editFullLoad, deleteFullLoad } from "../../../../../features/fullLoad/fullLoadSlice";
import { addHeavyLoad, editHeavyLoad, deleteHeavyLoad } from "../../../../../features/heavyLoad/heavyLoadSlice";
const RemoteLogin = () => {
  const dispatch = useDispatch();
  const { lessFuelLists, fullFuelLists, heavyFuelLists } = useCustomer();
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [lessFuel, setLessFuel] = useState({})
  const [fullFuel, setFullFuel] = useState({})
  const [heavyFuel, setHeavyFuel] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);

  const lessFuelhandler = (e) => { setLessFuel(lessFuel => ({ ...lessFuel, [e.target.name]: e.target.value })) }
  const fullFuelhandler = (e) => { setFullFuel(fullFuel => ({ ...fullFuel, [e.target.name]: e.target.value })) }
  const heavyFuelhandler = (e) => { setHeavyFuel(heavyFuel => ({ ...heavyFuel, [e.target.name]: e.target.value })) }

  // Add and update less fuel list
  const addLessFuelList = (e) => {
    e.preventDefault();
    if (Object.keys(lessFuel).length === 0) {
      toast.info("Please fill the form",{
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      dispatch(addLessLoad(lessFuel));
    } else {
      let editingItem = lessFuelLists[editingTaskIndex];
      editingItem = lessFuel;
      dispatch(editLessLoad(editingItem));
    }
    setLessFuel("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
  };

  const addFullFuelList = () => {
    if (Object.keys(fullFuel).length === 0) {
      toast.info("Please fill the form",{
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      dispatch(addFullLoad(fullFuel));
    } else {
      let editingItem = fullFuelLists[editingTaskIndex];
      editingItem = fullFuel;
      dispatch(editFullLoad(editingItem));
    }
    setFullFuel("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
  }

  const addheavyFuelList = () => {
    if (Object.keys(heavyFuel).length === 0) {
      toast.info("Please fill the form",{
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      dispatch(addHeavyLoad(heavyFuel));
    } else {
      let editingItem = heavyFuelLists[editingTaskIndex];
      editingItem = heavyFuel;
      dispatch(editHeavyLoad(editingItem));
    }
    setHeavyFuel("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
  }
  // Edit vehicle list
  const editLessFuelList = (id) => {
    let newEditItem = lessFuelLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setLessFuel(newEditItem)
    setEditingTaskIndex(id);
  }

  const editFullFuelList = (id) => {
    let newEditItem = fullFuelLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setFullFuel(newEditItem)
    setEditingTaskIndex(id);
  }

  const editHeavyFuelList = (id) => {
    let newEditItem = heavyFuelLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setHeavyFuel(newEditItem)
    setEditingTaskIndex(id);
  }
  return (
    <>

      {/* Remote login info */}
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">Remote Login Info</label>
            <Row>
              <Col sm={4}>
                <fieldset className="less_load_table">
                  <label className="label-heading">Effective date on less load (LTL)</label>
                  <>
                  <Row>
                    <Row>
                    {/* <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="maxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="taxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col> */}
                      <Col sm={12} className="justify-content-between">
                        <div className="section_action_btn">
                          {!isUpdate ?
                            <div className="add_btn"><button type="button" className="btn" onClick={e => addLessFuelList(e)}><AddCircle /></button></div>
                            :
                            <div className="save_btn"><button type="button" className="btn" onClick={e => addLessFuelList(e)}><Save /></button></div>
                          }
                        </div>
                      </Col>
                      </Row>
                          <Row className="mt-2 mb-3">
                            <Col sm={12} className="mb-2">
                              <Form.Group controlId="Date">
                                <FloatingLabel controlId="floatingInput" label="Date" >
                                  <Form.Control type="date" placeholder="Date" name="LTLEffectiveDate" onChange={e => lessFuelhandler(e)} value={lessFuel?.LTLEffectiveDate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group controlId="ToCost">
                                <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                                  <Form.Control type="number" placeholder="Fuel Charge" name="LTLRate" onChange={e => lessFuelhandler(e)} value={lessFuel?.LTLRate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
              
                      <Col sm={12}>
                        <Row>
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Date</th>
                                  <th>Fuel Charge</th>
                                  {/* <th>Max Field</th>
                                  <th>Carbon Tax</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {lessFuelLists.map((data) => (
                                  <tr key={data.id}>
                                    <td>
                                      <div className="action_btn">
                                        <span className="edit_btn" onClick={() => editLessFuelList(data.id)}><Edit /></span>
                                        <span className="del_btn" onClick={() => dispatch(deleteLessLoad(data.id))}><Delete /></span>
                                      </div>
                                    </td>
                                    <td>{data.LTLEffectiveDate}</td>
                                    <td>{data.LTLRate}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="full_load_table">
                  <label className="label-heading">Effective date on full load (FTL)</label>
                  <Row>
                  {/* <Col sm={5} className="justify-content-between">
                      <Form.Group controldId="MaxField">
                        <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                          <Form.Control type="maxField" placeholder="Max Field" name="maxField" 
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="taxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col> */}
                  <Col sm={12} className="justify-content-between">
                    <div className="section_action_btn">
                      {!isUpdate ?
                        <div className="add_btn"><button type="button" className="btn" onClick={e => { addFullFuelList(e); }}><AddCircle /></button></div>
                        :
                        <div className="save_btn"><button type="button" className="btn" onClick={e => { addFullFuelList(e); }}><Save /></button></div>
                      }
                    </div>
                  </Col>
                  </Row>
                  <>
                    <Row>
                 
                          <Row className="mt-2 mb-3">
                            <Col sm={12} className="mb-2">
                              <Form.Group controlId="Date">
                                <FloatingLabel controlId="floatingInput" label="Date" >
                                  <Form.Control type="date" placeholder="Date" name="FTLEffectiveDate" onChange={e => fullFuelhandler(e)} value={fullFuel?.FTLEffectiveDate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group controlId="ToCost">
                                <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                                  <Form.Control type="number" placeholder="Fuel Charge" name="FTLRate" onChange={e => fullFuelhandler(e)} value={fullFuel?.FTLRate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                  
                      <Col sm={12}>
                        <Row>
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Date</th>
                                  <th>Fuel Charge</th>
                                  {/* <th>Max Field</th>
                                  <th>Carbon Tax</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {fullFuelLists?.map((data) => (
                                  <tr key={data.id}>
                                    <td>
                                      <div className="action_btn">
                                        <span className="edit_btn" onClick={() => editFullFuelList(data.id)}><Edit /></span>
                                        <span className="del_btn" onClick={() => dispatch(deleteFullLoad(data.id))}><Delete /></span>
                                      </div>
                                    </td>
                                    <td>{data.FTLEffectiveDate}</td>
                                    <td>{data.FTLRate}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="heavy_load_table">
                  <label className="label-heading">Effective date on heavy equipment load (HTL)</label>
                  <Row>
                    {/* <Col sm={5} className="justify-content-between">
                            <Form.Group controldId="MaxField">
                              <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                                <Form.Control type="maxField" placeholder="Max Field" name="maxField" 
                                />
                              </FloatingLabel>
                            </Form.Group>
                          </Col>
                          <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="taxField" 
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col> */}
                  <Col sm={12} className="justify-content-between">
                    <div className="section_action_btn">
                      {!isUpdate ?
                        <div className="add_btn"><button type="button" className="btn" onClick={e => { addheavyFuelList(e); }}><AddCircle /></button></div> :
                        <div className="save_btn"><button type="button" className="btn" onClick={e => { addheavyFuelList(e); }}><Save /></button></div>
                      }
                    </div>
                  </Col>
                  </Row>
                  <>
                    <Row>
                
                          <Row className="mt-2 mb-3">
                            <Col sm={12} className="mb-2">
                              <Form.Group controlId="Date">
                                <FloatingLabel controlId="floatingInput" label="Date" >
                                  <Form.Control type="date" placeholder="Date" name="HTLEffectiveDate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.HTLEffectiveDate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group controlId="ToCost">
                                <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                                  <Form.Control type="number" placeholder="Fuel Charge" name="HTLRate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.HTLRate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                
                      <Col sm={12}>
                        <Row>
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Date</th>
                                  <th>Fuel Charge</th>
                                  {/* <th>Max Field</th>
                                  <th>Carbon Tax</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {heavyFuelLists?.map((data) => (
                                  <tr key={data.id}>
                                    <td>
                                      <div className="action_btn">
                                        <span className="edit_btn" onClick={() => editHeavyFuelList(data.id)}><Edit /></span>
                                        <span className="del_btn" onClick={() => dispatch(deleteHeavyLoad(data.id))}><Delete /></span>
                                      </div>
                                    </td>
                                    <td>{data.HTLEffectiveDate}</td>
                                    <td>{data.HTLRate}</td>
                                    
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
    </>

  );
}
export default RemoteLogin;