import React, { useState, useEffect, useRef } from "react";
import { Form, FloatingLabel, Button, } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ArrowRightAlt, Place } from "@material-ui/icons";
import useOrder from "../../../context/OrderContext";
import CachedIcon from '@mui/icons-material/Cached';
import LocationNotes from "./LocationNotes";
import CountryProvince from "../../CountryProvince";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useGetConsignorQuery } from "../../../features/pickupInfo/pickupInfoSlice";
import phoneNumberMasking from "../../Customer/PhoneNumberMasking";
import postalCodeMasking from "../../Customer/PostalCodeMasking";
import LocationSearch from "./LocationSearch";
import Places from "./Places";
import LocationAutoComplete from "./LocationAutocomplete";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import timeConverter from "./timeConverter";

const Pickupinfo = () => {
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
  const { pickInfo, setPickInfo, fetchPickupInfo, Pickuphandler, pickDisable, reloadHandler, populateAccount, pickupData, setChange, pickErrors,
    pList, cList, country, setSelectState, handlecountry, handleCity, setSelectCity, setAutoPickup, accessorial, pickAccessorial, setPickAccessorial, pickSelection, setPickSelection } = useOrder();
  const { city, handleProvince, newHandleCountry, handleUniqueCity } = CountryProvince();
  //const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
  const { data: pickupInfo } = useGetConsignorQuery()
  const listofaccount = pickupInfo?.data.map((item, index) => ({ ...item, id: index + new Date().getTime().toString() }))
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [postal, setPostal] = useState("")
  const [autocomplete, setAutocomplete] = useState({});
  const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
  const [province, setProvince] = useState([]);
  const [countryList, setCountryList] = useState([]);
  // const [selection, setSelection] = useState([]); // this is old, added pickSelection to order context
  const getAccountInfo1 = async (pickInfo) => { fetchPickupInfo(pickInfo) }
  const handleOnSelect1 = async (item) => {
    console.log("LIST", pList);
    const pickInfo = item?.consignorId;
    const selectedAccount = await getAccountInfo1(pickInfo)

    setPickInfo(selectedAccount)
    setAutoPickup(true)
  };

  const handleSelect = (e) => {
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData.length > 0) {
      setPickAccessorial(mapData)
    }
    else {
      setPickAccessorial([])
    }
  }
  const arr = accessorial?.data?.data?.map((item) => ({ value: item?.accessorialID, label: item?.accessorialDescription })) || [];
  console.log(arr);
  const getCityID = async (cityName, provinceName) => {
    const result = await handleUniqueCity({cityName, provinceName});
    return result?.data?.data;
  }

  useEffect(async () => {
    if (Object.keys(autocomplete).length !== 0) {
      const id = await getCityID(autocomplete.city, autocomplete.province)
      setPickSelection([{name: autocomplete.city, id: id}]);
      const lst = await handleProvince(id)
      const prov = lst.data.data.find((ele) => ele.provineSateName === autocomplete.province)
      setProvince([prov]);
      const country = autocomplete.country === "CAN"? {countryId: 1, countryname: 'CAN'}: {countryId: 2, countryname: 'USA'}
      const countryID = autocomplete.country === "CAN"? 1: 2;
      setCountryList([country]);
      setPickInfo({...pickInfo, streetName: autocomplete.streetName, street: autocomplete.streetNum, unit: autocomplete.unit, 
        provineStateID: prov.provineStateID, provineSateName: autocomplete.province, cityName: autocomplete.city, city: id, 
        countryId: countryID, countryname: autocomplete.country, postalCode: autocomplete.postalCode,});
      setPostal(autocomplete.postalCode)
    }
    else {
      setPickInfo({...pickInfo, streetName: "", street: "", unit: ""});
      setPostal("");
    }
  }, [autocomplete])

  useEffect( async () => {
    if (pickSelection.length > 0) {
      setPickInfo({...pickInfo, city: pickSelection[0].id, cityName: pickSelection[0].name})
      // console.log(selection);
      const provList = await handleProvince(pickSelection[0].id)
      setProvince(provList.data.data);
      if (provList.data.data.length === 1) {
        const cList = await newHandleCountry(provList.data.data[0].provineStateID);
        setCountryList(cList.data.data)
        setPickInfo(pickInfo => ({...pickInfo, provineStateID: provList.data.data[0].provineStateID, 
          provineSateName: provList.data.data[0].provineSateName, countryId: cList.data.data[0].countryId, countryname: cList.data.data[0].countryname}))
      }
    }
    else {
      setPickInfo({...pickInfo, city: null, cityName: null, provineStateID: null, provineSateName: null, countryId: null, countryname: null})
      setProvince([]);
      setCountryList([]);
    }
  }, [pickSelection])

  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
      const cList = await newHandleCountry(e.target.value);
      setCountryList(cList.data.data)
      setPickInfo(pickInfo => ({...pickInfo, countryId: cList.data.data[0].countryId, countryname: cList.data.data[0].countryname}))
    } 
    else {
      setCountryList([]);
    }
  }

  const handleOnClear1 = () => {
    setPickInfo("")
    setPostal("");
    setPickSelection([]);
    setCountryList([]);
    setPickInfo(prevState => ({ ...prevState, appointment: "" }));
  }
  useEffect(() => {
    setPhoneNumber(pickupData?.phone)
    setContactNumber(pickupData?.contact)
    setPostal(pickupData?.postalCode)
  }, [pickupData])

  useEffect(()=>{
     if(pickupData?.provineStateID){
      let target = pickupData?.provineStateID
      handleCity({target:{value:target}})
    }
    if (pickupData?.cityId && pickupData?.cityName) {
      setPickSelection([{name: pickupData?.cityName, id: pickupData?.cityId}]);
    }
    if(pickupData?.countryId){
      let target;
      if (pickupData?.countryId === 1) {
        target = {countryId: 1, countryname: 'CAN'};
      } else {
        target = {countryId: 2, countryname: 'USA'};
      }
      setCountryList([target]);
    }
  },[pickupData])

  const handleInput = e => {
    const formattedPhoneNumber = phoneNumberMasking(e.target.value);
    const formattedPostalCode = postalCodeMasking(e.target.value)
    if (e.target.name === "phone") {
      setPhoneNumber(formattedPhoneNumber);
    }
    // else if (e.target.name === "contact") {
    //     setContactNumber(formattedPhoneNumber)
    // }
    else if (e.target.name === "postalCode") {
      setPostal(formattedPostalCode)
    }
  };

  const formatResult1 = (item) => {
    return (
      <div className="result-wrapper">
        <span className="result-span">{item.address} </span>
        {/* <span className="result-span">{item.customer}</span> */}
      </div>
    );
  };

  const setValues = () => {
    setPickSelection([{name: pickInfoRef.current.cityName, id: pickInfoRef.current.city}]);
  }

  const pickInfoRef = useRef();
  pickInfoRef.current = pickInfo;
  
  return (
    <div>
      <>
        <Row className="mb-3">
          <Col sm={6}>
            <ReactSearchAutocomplete
              className="form-control11"
              placeholder={pickInfo?.address ? pickInfo?.address : "Select"}
              items={listofaccount}
              fuseOptions={{ keys: ["address"] }} // Search on both fields
              resultStringKeyName="address" // String to display in the results
              onSelect={handleOnSelect1}
              onFocus={formatResult1}
              onClear={handleOnClear1}
              onInputChange={handleOnClear1}
              onSearch={handleOnClear1}
              formatResult={formatResult1}
              showItemsOnFocus={formatResult1}
              inputDebounce={100}
              showIcon={false}
              // value={pickInfo}
              styling={{
                height: "48px",
                border: "1px solid #cbcbcb",
                borderRadius: "4px",
                backgroundColor: "white",
                boxShadow: "none",
                hoverBackgroundColor: "#cbcbcb",
                iconColor: "#000",
                lineColor: "#cbcbcb",
                placeholderColor: "#000",
                clearIconMargin: "3px 8px 0 0",
                zIndex: 2,
              }}
            />
          </Col>
          <Col sm={6}>
            <div className="search-form">
              <LocationAutoComplete autocomplete={autocomplete} setAutocomplete={setAutocomplete}/> 
              {/* <span><i className="fa fa-search" aria-hidden="true"></i></span> */}
            </div>
          </Col>
        </Row>
        <fieldset className="mt-4 mb-3">
          <label className="label-heading">Address Info</label>
          <Row className="fieldset-row mb-3">
            <Col sm={3}>
              <Form.Group controlId="st">
                <FloatingLabel controlId="floatingInput" label="Consignor/Shipper" >
                  <Form.Control type="text" placeholder="Consignor/Shipper" name="customer" value={pickInfo?.customer || ""} onChange={e => { Pickuphandler(e); setChange(true) }} className={`input ${pickErrors.customer && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.customer && (<p className="help is-danger">{pickErrors.customer}</p>)}
            </Col>
            <Col sm={3}>
              <Form.Group controlId="st">
                <FloatingLabel controlId="floatingInput" label="Street No." >
                  <Form.Control type="text" placeholder="Street No." name="street" value={pickInfo?.street || ""} onChange={e => { Pickuphandler(e); setChange(true) }} className={`input ${pickErrors.street && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.street && (<p className="help is-danger">{pickErrors.street}</p>)}
            </Col>
            <Col sm={3}>
              <Form.Group controlId="Street">
                <FloatingLabel controlId="floatingInput" label="Street Name" >
                  <Form.Control type="text" autoComplete="off" placeholder="Street Name" name="streetName" onChange={(e) => { Pickuphandler(e); setChange(true) }} value={pickInfo?.streetName || ""} className={`input ${pickErrors.streetName && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.streetName && (<p className="help is-danger">{pickErrors.streetName}</p>)}
            </Col>
            <Col sm={3}>
              <Form.Group controlId="Unit">
                <FloatingLabel controlId="floatingInput" label="Unit" >
                  <Form.Control type="text" autoComplete="off" placeholder="Unit" name="unit" onChange={(e) => { Pickuphandler(e); setChange(true) }} value={(pickInfo?.unit === null || pickInfo?.unit === "null") ? '': pickInfo?.unit} className={`input ${pickErrors.unit && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {/* {pickErrors.unit && (<p className="help is-danger">{pickErrors.unit}</p>)} */}
            </Col>
          </Row>
          <Row className="mb-1">
          <Col sm={3}>
              <Form.Group controlId="city">
                {/* <FloatingLabel controlId="floatingInput" label="City" >
                  <Form.Select aria-label="Floating label select example" name="city" onChange={e => { Pickuphandler(e); setSelectCity(e.target.value); setChange(true) }} value={pickInfo?.city || "Select"} className={`input ${pickErrors.city && 'is-danger'}`}> */}
                  <Typeahead
                    id="cityAutocomplete"
                    labelKey="name"
                    onChange={setPickSelection}
                    options={cityList}
                    placeholder={"Choose a city..."}
                    selected={
                      pickInfo?.city
                        ? cityList.filter(city => city.id === pickInfo.city)
                        : pickSelection
                    }
                  />
                {/* </Form.Select>
                </FloatingLabel> */}
              </Form.Group>
              {pickErrors.city && (<p className="help is-danger">{pickErrors.city}</p>)}
            </Col>
            <Col sm={3}>
              <Form.Group controlId="pr">
                <FloatingLabel controlId="floatingInput" label="Province" >
                  <Form.Select aria-label="Floating label select example" name="provineStateID" onChange={(e) => { setSelectState(e.target.value); Pickuphandler(e); handleCity(e); setChange(true); setCountry(e); }} value={pickInfo?.provineStateID || "Select"} className={`input ${pickErrors.provineStateID && 'is-danger'}`}>

                    <option>Select</option>:
                    <>
                      {
                        province?.map((data, index) => (
                          <option key={index} value={data?.provineStateID}>{data?.provineSateName}</option>
                        ))

                      }
                    </>

                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              {pickErrors.provineStateID && (<p className="help is-danger">{pickErrors.provineStateID}</p>)}
            </Col>
            <Col sm={3}>
              <Form.Group controlId="Country">
                <FloatingLabel controlId="floatingSelectGrid" label="Country">
                  <Form.Select aria-label="Floating label select example" name="countryId" onChange={e => { handlecountry(e); Pickuphandler(e); setChange(true) }} value={pickInfo?.countryId || "Select"} className={`input ${pickErrors.countryId && 'is-danger'}`}>
                    { countryList.length === 0 && 
                    <option>Select</option>
                    }
                    <>
                      {
                        countryList?.map((data, index) => (
                          <option key={index} value={data?.countryId}>{data?.countryname}</option>
                        ))
                      }
                    </>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              {pickErrors.countryId && (<p className="help is-danger">{pickErrors.countryId}</p>)}
            </Col>
            <Col sm={3}>
              <Form.Group controlId="postal/zip">
                <FloatingLabel controlId="floatingInput" label="Postal code" >
                  <Form.Control type="text" autoComplete="off" placeholder="Postal code" name="postalCode" onChange={(e) => { Pickuphandler(e); setChange(true); handleInput(e) }} value={pickInfo?.postalCode || ""} className={`input ${pickErrors.postalCode && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.postalCode && (<p className="help is-danger">{pickErrors.postalCode}</p>)}
            </Col>
          </Row>
        </fieldset>
        <fieldset className="mt-4">
          <label className="label-heading">Personal Info</label>
          <Row className="mb-1 fieldset-row">
            <Col sm={4}>
              <Form.Group controlId="Contact">
                <FloatingLabel controlId="floatingInput" label="Contact Name" >
                  <Form.Control type="text" autoComplete="off" placeholder="Contact Name" name="contact" value={pickInfo?.contact || ""} onChange={e => { Pickuphandler(e); }} className={`input ${pickErrors.contact && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.contact && (<p className="help is-danger">{pickErrors.contact}</p>)}
            </Col>
            <Col sm={4}>
              <Form.Group controlId="phone">
                <FloatingLabel controlId="floatingInput" label="Phone" >
                  <Form.Control type="text" autoComplete="off" placeholder="Phone" name="phone" value={pickInfo?.phone || ""} onChange={e => { Pickuphandler(e); handleInput(e) }} className={`input ${pickErrors.phone && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.phone && (<p className="help is-danger">{pickErrors.phone}</p>)}
            </Col>
            <Col sm={4}>
              <Form.Group controlId="email">
                <FloatingLabel controlId="floatingInput" label="Email" >
                  <Form.Control type="email" autoComplete="off" placeholder="Email" name="emailAddress" onChange={(e) => { Pickuphandler(e); }} value={pickInfo?.emailAddress || ""} className={`input ${pickErrors.emailAddress && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.emailAddress && (<p className="help is-danger">{pickErrors.emailAddress}</p>)}
            </Col>
          </Row>

        </fieldset>
        <fieldset className="mt-4">
          <label className="label-heading">Extra Info</label>
          <Row className="fieldset-row mb-3" >
            <Col sm={4}>
              <Form.Group controlId="pickup">
                <FloatingLabel controlId="floatingInput" label="Pickup(ref/info)" >
                  <Form.Control type="text" placeholder="Pickup(ref/info)" name="pickUpRefence" value={pickInfo?.pickUpRefence || ""} onChange={e => { Pickuphandler(e) }} />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm={8}>
                <Controller
                  control={control}
                  name="accessorialID"
                  // defaultValue={}
                  //value={orderInfo.accessorialID || ""}
                  //onChange={(e) => { OrderHandler(e); }}
                  // {...register('VehicleTypes')}
                  className="contain-accessorial"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "47px",
                      }),
                    }}
                      isMulti
                      placeholder="Accessorial"
                      name={name}
                      // defaultValue={selectedData}
                      ref={ref}
                      options={arr}
                      // value={arr.find((c) => c.value === value)}
                      //value={pickAccessorial ? pickAccessorial?.find((e) => e === value) : ""}
                      value={arr.filter(option => pickAccessorial.includes(option.value))}

                      onChange={(e) => handleSelect(e)}
                    />
                  )}
                />
              </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group controlId="Appointment">
                <FloatingLabel controlId="floatingInput" label="Appointment" > 
                  <Form.Control type="datetime-local" placeholder="Appointment" name="appointment" value={pickInfo?.appointment} onChange={e => { Pickuphandler(e) }} />
                </FloatingLabel>
              </Form.Group>
              {pickErrors.appointment && (<p className="help is-danger">{pickErrors.appointment}</p>)}
            </Col>
            <Col sm={4}>
              <Form.Group controlId="Open Time"> 
                <FloatingLabel controlId="floatingInput" label="Opening Time" >
                  <Form.Control type="time" placeholder="Opening Time" onChange={e => Pickuphandler(e)} name="openingTime" value={timeConverter(pickInfo?.openingTime) || ""} className={`input ${pickErrors.openingTime && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {/* {pickErrors.openingTime && (<p className="help is-danger">{pickErrors.openingTime}</p>)} */}
            </Col>
            <Col sm={4}>
              <Form.Group controldId="Open Time">
                <FloatingLabel controlId="floatingInput" label="Closing Time" >
                  <Form.Control type="time" placeholder="Closing Time" onChange={e => Pickuphandler(e)} name="closeTime" value={timeConverter(pickInfo?.closeTime) || ""} className={`input ${pickErrors.closeTime && 'is-danger'}`} />
                </FloatingLabel>
              </Form.Group>
              {/* {pickErrors.closeTime && (<p className="help is-danger">{pickErrors.closeTime}</p>)} */}
            </Col>
          </Row>
        </fieldset>
        <Row className="mt-3">
          {/* <Col sm={0}></Col><Col sm={0}></Col> */}
          <Col sm={6}>
            <div className="populate-btn d-flex">
              <Button className="populate-account" onClick={async(e) => { await populateAccount(e); setValues() }} disabled={pickDisable} value="populatepick">Populate account <ArrowRightAlt /></Button>
              {/* <Button className="populate-account btn-secondary" value="pickdisable" onClick={e => { reloadHandler(e) }}>Restore Data<CachedIcon /></Button> */}
              <Button className="notes"><LocationNotes /></Button>
            </div>
          </Col>
        </Row>
      </>

    </div>
  );
}
export default Pickupinfo;