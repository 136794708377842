import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { baseUrl } from "../config";
import axios from "axios";
import swal from 'sweetalert';
import React, { useRef } from 'react';
import { useReactToPrint } from "react-to-print";
import { useLazyGetAccountInfoQuery } from "../features/account/accountInfoSlice";
import { useLazyGetPickUpInfoQuery, useLazyGetDropInfoQuery } from "../features/pickupInfo/pickupInfoSlice";
import { useLazyGetDropOffListQuery } from "../features/DropoffInfo/dropoffInfoSlice";
import { useLazyGetAccessorialByVehicleTypeQuery, useSendEmailMutation, useLazyGetListOfAccessorialByCustomerQuery } from "../features/order/orderSlice";
import { useLazyGetQuoteListByIDQuery } from "../features/order/orderSlice"
import { useLazyGetServiceByIdQuery } from "../features/service/serviceDetailSlice";
import { useLazyGetPricingQuery } from "../features/order/orderSlice";
import { useLazyGetVehicleByIdQuery } from "../features/order/orderSlice";
import validateAccount from "../Component/Order/OrderTaking/ValidateAccount"
import validatePickup from "../Component/Order/OrderTaking/ValidatePickup";
import validateDropUp from "../Component/Order/OrderTaking/ValidateDropup";
import { useLazyGetServiceByVehicleTypeQuery } from "../features/order/orderSlice";
import { useGetCountryQuery } from "../features/countryState/countryStateSlice";
import CountryProvince from "../Component/CountryProvince";
import { useGetGoogleMapsAPIKeyQuery } from "../features/order/orderSlice";

const GENERATE_ORDER = "GENERATE_ORDER";
const OrderContext = createContext();
const days = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thrusday', label: 'Thrusday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

export const GenerateOrder = ({ children }) => {
  const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
  const [pList, setPList] = useState([])
  const [cList, setCList] = useState([])
  const provinceData = province?.data;
  const cityData = city?.data;
  useEffect(() => {
    setPList(provinceData)
  }, [provinceData])
  useEffect(() => {
    setCList(cityData)
  }, [cityData])

  useEffect(() => {
    setRadio(1);
  }, [])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const dispatch = useDispatch();
  const [sendEmail] = useSendEmailMutation();
  const [showPricing, pricingResult] = useLazyGetPricingQuery();
  // const [pricingMsg, setPricingMsg] = useState(pricingResult?.data?.Code)
  let temp_pricing = pricingResult?.data?.Code;
  const [fetchQuoteData, quoteList] = useLazyGetQuoteListByIDQuery()
  const [fetchAccountInfo, accountIn] = useLazyGetAccountInfoQuery()
  const [fetchServiceData, serviceData] = useLazyGetServiceByVehicleTypeQuery()
  const [fetchAccessorialData, accessorialData] = useLazyGetAccessorialByVehicleTypeQuery()
  const [fetchAccessorial, accessorial] = useLazyGetListOfAccessorialByCustomerQuery()
  const accessorialbasedOnVehicle = accessorialData?.data?.data
  const serviceBasedOnCustomer = serviceData?.data?.data
  const [fetchVehicleData, vehicleData] = useLazyGetVehicleByIdQuery()
  const vehicleBasedOnCustomer = vehicleData?.data?.data;
  const accountInfoData = accountIn?.data?.data?.getListofQuoteAccountInformation && accountIn?.data?.data?.getListofQuoteAccountInformation[0];
  const quoteListData = accountIn?.data?.data?.getListOfQuoteNo;
  const [fetchPickupInfo, pick] = useLazyGetPickUpInfoQuery()
  const [fetchDropupInfo, drop] = useLazyGetDropInfoQuery()
  const pickupData = pick?.data?.data
  const dropupData = drop?.data?.data
  const [fetchDropOffInfo, dropoff] = useLazyGetDropOffListQuery()
  const dropoffData = dropoff?.data?.data && dropoff?.data?.data[0]
  const [isShow, setIsShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [pickErrors, setPickErrors] = useState({});
  const [dropErrors, setDropErrors] = useState({});
  const [orderErrors, setOrderErrors] = useState({});
  const [orderForm, setOrderForm] = useState("Online");
  const [accountInfo, setAccountInfo] = useState({});
  const [quoteData, setQuoteData] = useState({})
  const [serviceId, setServiceId] = useState({})
  const [orderInfo, setOrderInfo] = useState({});
  const [state, setState] = useState('')

  const [pickInfo, setPickInfo] = useState({});
  const [dropInfo, setDropInfo] = useState({});

  const [pickSelection, setPickSelection] = useState([]);
  const [dropSelection, setDropSelection] = useState([]);

  const [pickDisable, setPickDisable] = useState(false);
  const [dropDisable, setDropDisable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectDays, setSelectDays] = useState([]);
  const [showPrice, setShowPrice] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [selected, setSelected] = useState(false)
  const [isDisabled, setDisabled] = useState(true);
  const [EmailTags, setEmailTags] = useState([]);
  const [orderNumber, setOrderNumber] = useState(null);
  const [change, setChange] = useState(false)
  const [dataa, setDataa] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [countryname, setcountryName] = useState("");
  const [provincename, setProvincename] = useState("");
  const [cityname, setCityName] = useState("");
  //const { data: country } = useGetCountryQuery();
  const [provincelist, setProvinceList] = useState([]);
  const [cities, setCities] = useState([]);
  const [autoPickup, setAutoPickup] = useState(false)
  const [autoDropup, setAutoDropup] = useState(false)
  const [pickAccessorial, setPickAccessorial] = useState([])
  const [dropAccessorial, setDropAccessorial] = useState([])
  const [Pickupvalues, setPickupValues] = useState({
    countryname: "",
    provincename: "",
    cityname: ""
  });
  const [dropvalues, setDropValues] = useState({
    countryname: "",
    provincename: "",
    cityname: ""
  });
  const {data} = useGetGoogleMapsAPIKeyQuery();
  const datePicker = orderInfo?.date && orderInfo?.date.toLocaleDateString("es-CL").split("-").reverse().join("-");
  const timePicker = orderInfo?.time && orderInfo?.time.toLocaleTimeString("es-CL");

  const phoneTxt = pickInfo?.phone && pickInfo?.phone.replace(/\D/g, "")
  const contactTxt = pickInfo?.contact && pickInfo?.contact.replace(/\D/g, "")

  // localStorage.getItem('province_list', province_list)

  const accessorialLists = (useSelector((state) => state.accessorialDetail.list)).map((el) => {
    return ({ id: el.id, customerID: el.customerID, ...el.list })
  })

  const productLists = (useSelector((state) => state.productData.list)).map((el) => {
    return ({ id: el.id, ...el.list })
  })

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (data?.data && !scriptLoaded) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${data.data}&libraries=places&&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onload = () => setScriptLoaded(true);
      document.head.appendChild(script);
    }
  }, [data, scriptLoaded]);

  useEffect(() => {
    setAccountInfo(accountInfoData)
    setQuoteData(quoteListData)
  }, [accountInfoData, quoteListData])

  useEffect(() => {
    setPickInfo(pickupData)
  }, [pickupData])

  useEffect(() => {
    setDropInfo(dropupData)
  }, [dropupData])

  useEffect(() => {
    //setPricingMsg(temp_pricing)
    if (temp_pricing) {
      toast.error(pricingResult.data.Message, {
        position: "top-center",
      })
    }

  }, [temp_pricing])

  // radiohandler
  const radioChangeHandler = (e) => {
    setOrderForm(e.target.value);
  };

  // Populate Account
  const populateAccount = (e) => {
    if (e.target.value === "populatepick") {
      setPickInfo(accountIn?.data?.data?.getListofQuoteAccountInformation[0]);
      setDropDisable(true);
    }
    else if (e.target.value === "populatedrop") {
      setDropInfo(accountIn?.data?.data?.getListofQuoteAccountInformation[0]);
      setPickDisable(true);
    }
  }

  // Reload Handler
  const reloadHandler = (e) => {
    if (e.target.value === "pickdisable") {
      setPickInfo("")
      setDropDisable(false)
    }
    else if (e.target.value === "dropdisable") {
      setDropInfo("")
      setPickDisable(false)
    }
  }
  useEffect(() => {
    console.log("USE EFFECT: " + JSON.stringify(accountInfo, null, 2));
    console.log(accountInfo?.cityName);
  }, [accountInfo]);
  useEffect(() => {
    console.log("USE EFFECT: " + JSON.stringify(pickInfo, null, 2));
    console.log(pickInfo?.cityName);
  }, [pickInfo]);
  useEffect(() => {
    console.log("USE EFFECT: " + JSON.stringify(dropInfo, null, 2));
    console.log(dropInfo?.cityName);
  }, [dropInfo]);
  useEffect(() => {
    console.log("USE EFFECT: " + JSON.stringify(orderInfo, null, 2));
    console.log(orderInfo?.cityName);
  }, [orderInfo]);

  // Hold pickup handler
  const holdPickuphandler = (type) => {
    setDropInfo("")
    validateaddSubmit(type);

    setDropSelection([]);
    setDropInfo(prevState => ({ ...prevState, appointment: "" }));
    //Accessorial fields
    setDropAccessorial([]);
  }
  const holdDropuphandler = (type) => { 
    setPickInfo(""); 
    // setSelection([]);
    validateaddSubmit(type); 

    setPickSelection([]);
    setPickInfo(prevState => ({ ...prevState, appointment: "" }));
    //Accessorial fields
    setPickAccessorial([]);

  }
  const holdDuplicatehandler = (type) => { /*setOrderInfo([]);*/ validateaddSubmit(type); }
  const cancelHandler = (e) => {
    //log all the info before clearing
    console.log("BEFORE");
    console.log(accountInfo);
    console.log(pickInfo);
    console.log(dropInfo);
    console.log(orderInfo);
    
    setAccountInfo({
      account_No: null,
      city: null,
      cityName: null,
      contact: null,
      countryId: null,
      countryname: null,
      customer: null,
      customerID: null,
      emailAddress: null,
      locationCheckbox: null,
      locationNotes: null,
      open: null,
      orderid: 0,
      phone: null,
      phone_Ext: null,
      postalCode: null,
      provineStateID: null,
      provineSateName: null,
      quoteid: 0,
      street: null,
      streetName: null,
      unit: null,
    });

    setAccountInfo("");  // clears the Account Number field.
    setPickInfo({
      account_No: null,
      city: null,
      cityName: null,
      contact: null,
      countryId: null,
      countryname: null,
      customer: null,
      customerID: null,
      emailAddress: null,
      locationCheckbox: null,
      locationNotes: null,
      open: null,
      orderid: 0,
      phone: null,
      phone_Ext: null,
      postalCode: null,
      provineStateID: null,
      provineSateName: null,
      quoteid: 0,
      street: null,
      streetName: null,
      unit: null,
    });
    setDropInfo({
      account_No: null,
      city: null,
      cityName: null,
      contact: null,
      countryId: null,
      countryname: null,
      customer: null,
      customerID: null,
      emailAddress: null,
      locationCheckbox: null,
      locationNotes: null,
      open: null,
      orderid: 0,
      phone: null,
      phone_Ext: null,
      postalCode: null,
      provineStateID: null,
      provineSateName: null,
      quoteid: 0,
      street: null,
      streetName: null,
      unit: null,
    });
    setOrderInfo([]);

    console.log("AFTER");
    console.log(accountInfo);
    console.log(pickInfo);
    console.log(dropInfo);
    console.log(orderInfo);

    setPickSelection([]);
    setDropSelection([]);
    //Appointment fields
    setDropInfo(prevState => ({ ...prevState, appointment: "" }));
    setPickInfo(prevState => ({ ...prevState, appointment: "" }));

    //Accessorial fields
    setPickAccessorial([]);
    setDropAccessorial([]);
  }


  // Return Handler
  const returnHandler = (type) => {
    setPickInfo(dropInfo)
    setDropInfo(pickInfo)
    validateaddSubmit(type)
  }

  //Action to create the dispatch order
  const ActionDispatchOrder = async (request, data) => {
    await axios.post(`${baseUrl}/Order/AddOrder?Type=${request}`, data,
      {
        headers: {
          'authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((res) => {
        if (request === "quote") {
          if (res.data.statusCode === 200) {
            swal(`${res.data.data.quoteNo}`, "Your quote number generated successfully !", "success")
          }
          else if (res.data.statusCode === 404) {
            setTimeout(function () {
              toast.info(res.data.message, {
                position: "top-center",
              })
            }, 500);
          }
          else {
            setTimeout(function () {
              toast.error("Please fill the form", {
                position: "top-center",
              })
            }, 500);
          }
        }
        else if (request === "online") {
          if (res.data.statusCode === 200) {
            swal(`${res.data.data.orderNo}`, "Your order number generated successfully !", "success")
              .then(
                function () {
                  setIsShow(true)
                  setOrderNumber(res.data.data.orderNo);
                  // setAccountInfo("")
                  // setPickInfo("")
                  // setDropInfo("")
                  // setOrderInfo([])
                },
              )
          }
          else if (res.data.statusCode === 404) {
            setTimeout(function () {
              toast.info(res.data.message, {
                position: "top-center",
              })
            }, 500);
          }
          else if (res.data.statusCode === 400) {
            setTimeout(function () {
              toast.error(res.data.message, {
                position: "top-center",
              })
            }, 500);
          }
          else {
            setTimeout(function () {
              toast.error("Please fill the form", {
                position: "top-center",
              })
            }, 500);
          }
        }
        else {
          if (res.data.statusCode === 200) {
            swal(`${res.data.data.orderNo}`, "You order number generated successfully !", "success");
          }
          else if (res.data.statusCode === 404) {
            setTimeout(function () {
              toast.info(res.data.message, {
                position: "top-center",
              })
            }, 500);
          }
          else {
            setTimeout(function () {
              toast.error("Please fill the form", {
                position: "top-center",
              })
            }, 500);
          }
        }
        dispatch({
          type: GENERATE_ORDER,
          payload: data,
        });
      });
  };

  const ActualWeight = productLists.map((item) => {
    return item.productweight
  }).map(Number).reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);

  // Show price handler


  useEffect(() => {
    const temp_cities = localStorage.getItem('city_list');
    const temp_provin = localStorage.getItem('province_list');
    if (temp_provin !== "undefined") {
      let temp_provincelist = JSON.parse(temp_provin);
      setProvinceList(temp_provincelist)
    }
    if (temp_cities !== "undefined") {
      let temp_citylist = JSON.parse(temp_cities);
      setCities(temp_citylist)
    }
  }, [pickInfo, dropInfo])



  const pricingData = {
    customerId: accountInfo?.customerID,
    requestDate: datePicker,
    requestTime: timePicker,
    from: {
      street: pickInfo?.street + ' , ' + pickInfo?.streetName,
      city: pickInfo?.cityName,
      province: pickInfo?.provineSateName,
      postalCode: pickInfo?.postalCode?.slice(0, 3),
      country: pickInfo?.countryname
    },
    to: {
      street: dropInfo?.street + ' , ' + dropInfo?.streetName,
      city:  dropInfo?.cityName,
      province: dropInfo?.provineSateName,
      postalCode: dropInfo?.postalCode?.slice(0, 3),
      country: dropInfo?.countryname
    },
    // pieces: orderInfo.pcs.length > 0 ? orderInfo.pcs :null ,
    // pallets: orderInfo.pcs.length > 0 ? productLists.map((item) => {
    //   return {
    //     weight: item.productweight,
    //     dimenstion: {
    //       length: item?.length,
    //       width: item?.width,
    //       height: item?.height
    //     }
    //   }
    // }) :
    //   [{
    //     weight: null,
    //     dimenstion: {
    //       length: null,
    //       width: null,
    //       height: null
    //     }
    //   }]

    // ,

    ActualWeight: orderInfo.weight,
    pieces: orderInfo.pcs,
    pallets: productLists.map((item)=>{
      return{
      weight:item.productweight,
      dimenstion: {
        length: item?.length,
        width: item?.width,
        height: item?.height
      }
      }
    }),

    vehicleType: dataa,
    waitTime: 0,
    serviceId: orderInfo.serviceID,
    accessorials: selectedData,
  }

  const showPriceHandler = () => {
    if (orderInfo.radio === 1) {
      // set date and time
      const now = new Date().toISOString();
      const time = now.split('T')[1].slice(0, 5);
      let [hours, minutes] = time.split(':').map(Number);
      hours = ((hours - 5 + 24) % 24).toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');
      pricingData.requestDate = now.split('T')[0]
      pricingData.requestTime = `${hours}:${minutes}`;
    }
    if (pricingData?.customerId === undefined) {
      setTimeout(function () {
        toast.error("Account No not found !", {
          position: "top-center",
        })
      }, 500);
    }
    else if (pricingData?.requestDate === undefined && pricingData.requestTime === undefined) {
      setTimeout(function () {
        toast.error("Please select date and time !", {
          position: "top-center",
        })
      }, 500);
    }
    // else if (Object.keys(productLists).length === 0) {
    //   setTimeout(function () {
    //     toast.error("Please fill the product description data !", {
    //       position: "top-center",
    //     })
    //   }, 500);
    // }
    else if (Object.keys(errors).length !== 0 && Object.keys(orderInfo).length === 0 && isSubmitting) {
      setIsSubmitting(true);
      setErrors(validateOrder(orderInfo));

    }
    else {
      // setShowPrice(showPrice => !showPrice)
      // setState("pricing")
      let newPricingData = {
        ...pricingData,
        pallets: pricingData.pallets.map(item => {
          let newDimension = {};
          for (let key in item.dimenstion) {
            let value = Number(item.dimenstion[key]);
            if (!isNaN(value)) {
              newDimension[key] = (value / 12).toString();
            }
          }
          return { ...item, dimenstion: newDimension };
        })
      };
      showPricing(newPricingData)       
    }
  }


  //All event handler
  const Accounthandler = (e) => {
    setAccountInfo(accountInfo => ({ ...accountInfo, [e.target.name]: e.target.value }));
    setErrors(validateAccount(accountInfo))
  }

  const Pickuphandler = (e) => {
    let { name, value } = e.target;
    let country_list = country?.data;

    if (name == "countryId") {
      let country_name = country_list?.find(v => {
        if (v.countryId == value) {
          return v.countryname
        }
      })
      setPickupValues({ ...Pickupvalues, countryname: country_name?.countryname })
    }
    if (name == "provineStateID") {
      let province_name = pList?.find(v => {
        if (v.provineStateID == value) {
          return v.provineSateName
        }
      })
      setPickInfo({ ...pickInfo, provineSateName: province_name?.provineSateName })
      setPickupValues({ ...Pickupvalues, provincename: province_name?.provineSateName })
    }
    if (name == "city") {
      let city_namescvc = cList?.find(v => {
        if (v.id == value) {
          return v.city
        }
      })
      setPickupValues({ ...Pickupvalues, cityname: city_namescvc?.city })
    }
    if (name == "openingTime") {
      setPickInfo({ ...pickInfo, open: value })
    }
    setPickInfo(pickInfo => ({ ...pickInfo, [e.target.name]: e.target.value }))
    setPickErrors(validatePickup(pickInfo))
  }

  const Dropuphandler = (e) => {
    let { name, value } = e.target;
    let country_list = country?.data;
    let provincelist_data = provincelist;
    let citylist_data = cities;
    if (name == "countryId") {
      let country_name = country_list?.find(v => {
        if (v.countryId == value) {
          return v.countryname
        }
      })
      setDropValues({ ...dropvalues, countryname: country_name?.countryname })
      // setcountryName(country_name?.countryname);
    }
    if (name == "provineStateID") {
      let province_name = pList?.find(v => {
        if (v.provineStateID == value) {
          return v.provineSateName
        }
      })
      setDropInfo({ ...dropInfo, provineSateName: province_name?.provineSateName })
      setDropValues({ ...dropvalues, provincename: province_name.provineSateName })
      // setProvincename(province_name.provineSateName)
    }
    if (name == "city") {
      let city_namejl = cList?.find(v => {
        if (v.id == value) {
          return v.city
        }
      })
      setDropValues({ ...dropvalues, cityname: city_namejl?.city })
      // setCityName(city_name.city)
    }
    if (name == "openingTime") {
      setDropInfo({ ...dropInfo, open: value })
    }
    setDropInfo(dropInfo => ({ ...dropInfo, [e.target.name]: e.target.value }));
    setDropErrors(validateDropUp(dropInfo))
  }

  const OrderHandler = (e) => {
    setOrderInfo(orderInfo => ({ ...orderInfo, [e.target.name]: e.target.value }))
    setOrderErrors(validateOrder(orderInfo));
    // if (Object.keys(orderInfo).length > 7) {
      setButtonEnable(true)
    // }
  }

  const setRadio = (option) => {
    setOrderInfo(orderInfo => ({ ...orderInfo, radio: option }))
  }

  const dblhandle = (e) => {
    setSelectDays(Array.isArray(e) ? e.map(x => x.label) : []);
  }


  // Order Form validation
  function validateOrder(orderInfo) {
    let orderErrors = {};
    if (!orderInfo.vehicleID) {
      orderErrors.vehicleID = 'Please select an option';
    }
    if (!orderInfo.serviceID) {
      orderErrors.serviceID = 'Please select an option';
    }
    if (!orderInfo.productID) {
      orderErrors.productID = 'Please select an option';
    }
    if (!orderInfo.weightTypeID) {
      orderErrors.weightTypeID = 'Please select an option';
    }
    if (!orderInfo.weight) {
      orderErrors.weight = 'Please fill the weight';
    } else if (orderInfo.weight.length > 8) {
      orderErrors.weight = 'Please enter less than 8 digit';
    }
    if (!orderInfo.pcs) {
      orderErrors.pcs = 'Please fill the pcs number';
    } else if (orderInfo.pcs.length > 8) {
      orderErrors.pcs = 'Please enter less than 8 digit';
    }
    if (!orderInfo.refernce) {
      orderErrors.refernce = 'Reference number is required';
    } 
    /*
    else if (orderInfo.refernce.length > 8) {
      orderErrors.refernce = 'Please enter less than 8 digit';
    }
      */
    if (!orderInfo.date) {
      orderErrors.date = 'Please select a date';
    }
    if (!orderInfo.time) {
      orderErrors.time = 'Please select a time';
    }
    return orderErrors;
  };

  const pickupInfo = {
    consignorId: change === false ? pickInfo?.consignorId : 0,
    quoteid: 0,
    orderid: 0,
    customer: pickInfo?.customer,
    account_No: pickInfo?.account_No,
    customer: pickInfo?.customer,
    emailAddress: pickInfo?.emailAddress,
    phone: phoneTxt,
    contact: pickInfo?.contact,
    street: pickInfo?.street,
    streetName: pickInfo?.streetName,
    provineStateID: pickInfo?.provineStateID,
    countryId: pickInfo?.countryId,
    provineSateName: pickInfo?.provineSateName,
    unit: pickInfo?.unit,
    cityId: pickInfo?.city || pickInfo?.cityId,
    // cityName: pickInfo?.cityName,
    countryname: pickInfo?.countryname,
    postalCode: pickInfo?.postalCode,
    phone_Ext: pickInfo?.phone_Ext,
    open: null,
    closeTime: pickInfo?.closeTime,
    locationNotes: null,
    locationCheckbox: null,
    pickUpRefence: pickInfo?.pickUpRefence,
    appointment: pickInfo?.appointment,
    openingTime: pickInfo?.openingTime,
    pickupAccessorialID: pickAccessorial,
  }

  const dropInfoData = {
    consignorId: dropInfo?.consignorId,
    customer: dropInfo?.customer,
    emailAddress: dropInfo?.emailAddress,
    phone: dropInfo?.phone,
    contact: dropInfo?.contact,
    dropoffRefence: dropInfo?.dropoffRefence,
    appointment: dropInfo?.appointment,
    street: dropInfo?.street,
    streetName: dropInfo?.streetName,
    unit: dropInfo?.unit,
    cityID: dropInfo?.city,
    cityName: dropInfo?.cityName,
    postalCode: dropInfo?.postalCode,
    provineStateID: dropInfo?.provineStateID,
    countryId: dropInfo?.countryId,
    openingTime: dropInfo?.openingTime,
    closeTime: dropInfo?.closeTime,
    provineSateName: dropInfo?.provineSateName,
    countryname: dropInfo?.countryname,
    locationNotes: dropInfo?.locationNotes,
    locationCheckbox: [
      dropInfo?.locationCheckbox
    ],
    fax: dropInfo?.fax,
    dropoffAccessorialID: dropAccessorial,
  }

  // Button Post API
  var multidays = []
  multidays.push(...selectDays)
  multidays = orderInfo?.days
  const dispatchHandler = (type) => {
    const newPickupInfo = {
      ...pickupInfo,
      unit: pickupInfo?.unit || 0
    }
    const newDropInfo = {
      ...dropInfoData,
      unit: dropInfoData?.unit || 0
    }
    const now = new Date();
    const estDate = new Date(now.toLocaleString("en-US", {timeZone: "America/New_York"}));
    let time24Hour;
    
    if (orderInfo.date) {
      const utcDate = new Date(orderInfo.time.toLocaleString("en-US", {timeZone: "America/New_York"}));
      time24Hour = utcDate.toLocaleTimeString("en-US", {hour12: false}).slice(0,5);
    }

    const nowTime = estDate.toLocaleTimeString("en-US", {hour12: false}).slice(0,5);
    const dateFormatter = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: mm },,{ value: dd },,{ value: yyyy }] = dateFormatter.formatToParts(estDate);
    const date = `${yyyy}-${mm}-${dd}`;;

    const dispatchData = {
      mLOrderAccountInformation: accountInfo,
      mLOrderPickupInformation: newPickupInfo,
      mLOrderDropupInformation: newDropInfo,
      mLOrderInformation: { accessorialID: selectedData, weight: ActualWeight, ...orderInfo, date: orderInfo.date? orderInfo.date.toISOString().split('T')[0] : date, time: orderInfo.time? time24Hour : nowTime},
      mLAccesorialChargeDiscription: accessorialLists,
      mLProductDiscriptions: productLists
    }
    ActionDispatchOrder(type, dispatchData)
  };

  const validateaddSubmit = async (type) => {
    // setOrderInfo(orderInfo => ({...orderInfo, pcs: productLists.length}));
  
    const accountErrors = validateAccount(accountInfo);
    setErrors(accountErrors);
    if (Object.keys(accountErrors).length !== 0) {
      toast.error("Please fill all the data on the account-info section", {
        position: "top-center",
      });
      return;
    }
  
    const pickupErrors = validatePickup(pickInfo);
    setPickErrors(pickupErrors);
    if (Object.keys(pickupErrors).length !== 0) {
      toast.error("Please fill all the data on the pick-info section", {
        position: "top-center",
      });
      return;
    }
  
    const dropoffErrors = validateDropUp(dropInfo);
    setDropErrors(dropoffErrors);
    if (Object.keys(dropoffErrors).length !== 0) {
      toast.error("Please fill all the data on the drop-info section", {
        position: "top-center",
      });
      return;
    }
  
    if (orderInfo.weight && productLists.length > 0 && String(ActualWeight) !== orderInfo.weight) {
      toast.error("Please check the weight in the product description", {
        position: "top-center",
      });
      return;
    }
  
    if (productLists.length > 0 && String(productLists.length) != orderInfo.pcs) {
      toast.error("Piece count does not match product description", {
        position: "top-center",
      });
      return;
    }
  
    dispatchHandler(type);
  }

  // Quote list data Populate
  const handleQuoteData = async (quoteNo) => {
    const data = fetchQuoteData(quoteNo)
    setAccountInfo(data?.data?.mlOrderAccountInformationList);
    setPickInfo(data?.data?.mlOrderPickupInformationList)
    setDropInfo(data?.data?.mlOrderDropupInformationList)
    setOrderInfo(data?.data?.mlOrderInformationList)
    setSelected(true)
  }



  //Email Notification
  const headAccount = accountInfo?.emailAddress;
  const consignor = pickInfo?.emailAddress;
  const consignee = dropInfo?.emailAddress;
  const emailQuote = [headAccount]
  const emailList = [headAccount, consignor, consignee, ...EmailTags]
  const emailHandler = (e) => {
    e.preventDefault();
    const sendEmailData = {
      EmailIds: emailList,
      mLOrderAccountInformation: accountInfo,
      mLOrderPickupInformation: pickInfo,
      mLOrderDropupInformation: dropInfo,
      mLOrderInformation: orderInfo,
      mLProduct: { Dimweight: 41 },
    }
    sendEmail(sendEmailData);
  };

  // Send Email Quote
  const emailQuotehandler = async () => {
    const sendEmailData = {
      EmailIds: emailQuote,
      mLOrderAccountInformation: accountInfo,
      mLOrderPickupInformation: pickInfo,
      mLOrderDropupInformation: dropInfo,
      mLOrderInformation: orderInfo,
      mLProduct: { Dimweight: 41 },
    }
    const sendEmailInfo = await sendEmail(sendEmailData);
    if (sendEmailInfo.data.statusCode === 200) {
      setTimeout(function () {
        toast.success("Email send Successfully!", {
          position: "top-center",
        })
      });
      //window.location.reload(false)
    }
  }


  const value = {
    orderForm, radioChangeHandler, accountInfo, setAccountInfo, pickInfo, setPickInfo, dropInfo, setDropInfo, Accounthandler, Pickuphandler, Dropuphandler, populateAccount,
    OrderHandler, setRadio, orderInfo, pickDisable, dropDisable, errors, pickErrors, dropErrors, orderErrors, validateaddSubmit, dblhandle, days, reloadHandler, holdPickuphandler, holdDropuphandler, holdDuplicatehandler, cancelHandler,
    showPriceHandler, showPrice, setButtonEnable, buttonEnable, returnHandler, handleQuoteData, selected, accessorialLists, quoteData, isShow, setIsShow, handlePrint,
    componentRef, datePicker, timePicker, isDisabled, emailList, headAccount, emailHandler, setEmailTags, emailQuotehandler, productLists, fetchAccountInfo, fetchServiceData, accountInfoData, fetchPickupInfo, fetchDropupInfo, pickupData,
    fetchDropOffInfo, dropoffData, serviceBasedOnCustomer, pricingResult, setChange, fetchVehicleData, vehicleBasedOnCustomer, dropupData, fetchAccessorialData, accessorialbasedOnVehicle, setAutoPickup, setAutoDropup,
    setAccountInfo, setPickInfo, setDropInfo, setOrderInfo, state, setDataa, ActualWeight, setSelectedData, selectedData, pList, cList, country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity,
    scriptLoaded, fetchAccessorial, accessorial, pickAccessorial, setPickAccessorial, dropAccessorial, setDropAccessorial, orderNumber, pickSelection, setPickSelection, dropSelection, setDropSelection
  };
  return <OrderContext.Provider value={value}>{children}<ToastContainer /></OrderContext.Provider>;
};




const useOrder = () => {
  const context = useContext(OrderContext);

  if (context === undefined) {
    throw new Error("useOrder must be used within OrderContext");
  }

  return context;
};

export default useOrder;
