import { Button, Container, Row, Col, Form } from "react-bootstrap";
import CustomerDetailstab from "./CustomerDetails";
import { useState, useEffect, useRef } from "react";
import { Visibility } from "@material-ui/icons";
import { Favorite } from "@material-ui/icons";
import { ArrowForwardIosSharp } from "@material-ui/icons";
import { ArrowBackIosSharp } from "@material-ui/icons";
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../context/CustomerContext";
import Spinner from "../Spinner";
import { useGetCustomerQuery } from "../../features/customer/customerSlice";
import { useFavoriteCustomerMutation } from "../../features/customer/customerSlice";
import { ToastContainer, toast } from 'react-toastify';



const CustomerList = () => {
  const {data , refetch, isLoading} = useGetCustomerQuery()
  const [favoriteCustomer] = useFavoriteCustomerMutation()
  const { handleAccount } = useCustomer();
  const [selectedIndex, setSelectedIndex] = useState();
  const [sortType, setSortType] = useState('');
  const [value, setValue] = useState('');
  const [tableFilter, setTableFilter] = useState([]);
  const customerlistData = data?.data
  const [customerList, setCustomerList] = useState([])


  const alphabeticalSort = (a, b) => {
    return a.customer.toLowerCase() < b.customer.toLowerCase();
  }

  useEffect(() =>{
    setCustomerList(customerlistData)
  },[customerlistData])

  // sort functionality

  useEffect(() => {
    let sortedList = [...customerList];
    if (sortType === 'mostlyviewed') {
      sortedList.sort((a, b) => {
        if(a.numberofView !== b.numberofView) return b.numberofView - a.numberofView;
        else return alphabeticalSort(a, b);
      });
    } else if (sortType === 'favourite') {
      sortedList.sort((a, b) => {
        if(b.isFavourite !== a.isFavourite) return b.isFavourite - a.isFavourite
        else return alphabeticalSort(a, b);
      });
    }
    if (JSON.stringify(sortedList) !== JSON.stringify(customerList)) {
      setCustomerList(sortedList);
    }
  }, [sortType]); 
  
  const handleSorting = (sortBy) => {
    setSortType(sortBy)
  }

  // search funtionality
  const filterData = (e) => {
    if (e.target.value !== "") {
      setValue(e.target.value);
      const filteredTable = customerList.filter((o) => Object.keys(o).some(k => String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      ))
      setTableFilter([...filteredTable])
    } else {
      setValue(e.target.value);
      setTableFilter(customerList)
    }

  }


  //favourite list  
  const favoriteCustomerList = async (account) => {
    const favouritelist = await favoriteCustomer(account)
    refetch()
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  
useEffect(() => {
  const overlay = document.querySelector('.overlay-shadow');
  if (overlay) {
    overlay.addEventListener('animationend', function(e) {
      if (e.animationName === 'fadeOut') {
        this.classList.add('hidden');
      }
    });
  }
}, []);




  return (
        
      <div className="customerlist">
        
      <Container fluid>
        <Button variant="primary" className= {`customer-button ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
          {isOpen ? <ArrowBackIosSharp style={{ width: '100%', minWidth: '10px' }} /> : <ArrowForwardIosSharp style={{ width: '100%' , minWidth: '10px' }} />}
        </Button>
          <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <div className="customerlist-leftsection">
              <Row>
                <Col sm={12} md={12} lg={7} xl={8}>
                  <div className="customer-search-form">
                    <form>
                      <input placeholder="Search" aria-label="Search" type="search" className="me-2 form-control" onChange={filterData} value={value} />
                      <span> <i className="fa fa-search" aria-hidden="true"></i></span>
                    </form>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={5} xl={4}>
                  <div className="customer-search-form">
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Select defaultValue="Choose..." onChange={e => handleSorting(e.target.value)}>
                        <option value="all">All</option>
                        <option value="mostlyviewed">Mostly Viewed</option>
                        <option value="favourite">Favourite</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <div className="list-of-customer">
                {isLoading && <Spinner />}
                <>
                {value.length > 0 ? (
                  tableFilter.map((data, index) => {
                    return (
                      <div className={`customer_list_section ${selectedIndex === index ? "active" : ""}`} key={index} view={data.numberofView} favourite={data.isFavourite}  onClick={() => setSelectedIndex(index)}>
                        <div onClick={e => handleAccount(data.account_No)} >
                          <div className="customer-list" >
                            <div className="account-number">{data.account_No}</div>
                            <div className="customer-name">{data.customer}</div>
                          </div>
                        </div>
                        <div className="sort_icon">
                          <a className="view_count">
                            <Visibility />
                            <span className="badge">{data.numberofView}</span>
                          </a>
                          <a onClick={e => favoriteCustomerList(data.account_No)} className={data.isFavourite ? "favorite active" : "favorite"}>
                            <Favorite />
                          </a>
                        </div>
                      </div>
                    )

                  })
                ) : (
                  <>
                    {customerList?.map((data, index) => (
                      <div className={`customer_list_section ${selectedIndex === index ? "active" : ""}`} key={index} view={data.numberofView} favourite={data.isFavourite} onClick={() => {setSelectedIndex(index); handleAccount(data.account_No)}}>
                          <div className="customer-list" >
                            <div className="account-number">
                              {data.account_No}
                            </div>
                            <div className="customer-name">{data.customer}</div>

                          </div>
                        <div className="sort_icon">
                          <a className="view_count">
                            <Visibility />
                            <span className="badge">{data.numberofView}</span>
                          </a>
                          <a onClick={(e) => {e.stopPropagation(); favoriteCustomerList(data.account_No)}} className={data.isFavourite ? "favorite active" : "favorite"}>
                            <Favorite />
                          </a>
                        </div>
                      </div>
                    ))}
                  </>
                )
                }
                </>   
            </div>
          </div>
        </div>
      <div>
        
      <div onClick={isOpen? toggleSidebar:null} className={`overlay-shadow ${isOpen ? '' : 'out'}`}>
        
      </div>
          {/* <Col sm={12} md={12} lg={8} xl={12}> */}
            <div className="customer-detail">
              <CustomerDetailstab />
            </div> 
          {/* </Col> */}
        
      </div>
      </Container>
      {/* <ToastContainer /> */}
    </div>

  );
};
export default CustomerList;
