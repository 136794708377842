import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const INVOICESTYLE_URL = `${baseUrl}/Customer/GetListOfInvoiceStyle`


const initialState = []

export const fetchInvoiceStyle  = createAsyncThunk('GetListOfInvoiceStyle/fetchInvoiceStyle', async () =>{
    try{
        const response = await axios.get(INVOICESTYLE_URL);
        return[...response.data.data];
    }catch (err){
        return err.message;
    }
})


const invoiceStyleSlice = createSlice({
    name:"invoicestyle",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchInvoiceStyle.fulfilled, (state, action) => {
          return action.payload
        })
      }
})

export const invoiceStyleList  = (state) => state.invoiceStyleList;

export default invoiceStyleSlice.reducer