import React from "react";
import useOrder from "../../../context/OrderContext";

const Print = () => {
    const { accountInfo, componentRef, orderInfo, datePicker, timePicker, pickInfo, dropInfo } = useOrder();
    return (
        <div style={{ display: "none" }}>
            <div ref={componentRef}>
                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                    <tbody>
                        <tr>
                            <td align="center" bgcolor="#fff">

                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                    <tbody>
                                        <tr>
                                            <td width="100%" align="center">
                                                {/* <!-- START CONTENT --> */}
                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center" style={{ paddingLeft: "20px", paddingRight: "20px" }} className="responsive_at_550">
                                                    <tbody>
                                                        <tr>
                                                            <td align="center">
                                                                {/*                                                     
                                                    <!-- START BORDER COLOR --> */}
                                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" height="7" align="center" border="0" bgcolor="#fff" style={{ borderRadius: "10px 10px 0px 0px", background: "#fff" }}></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <!-- END BORDER COLOR -->
                                                    
                                                    <!-- START SPACING --> */}
                                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td height="30">&nbsp;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <!-- END SPACING -->
                                                    
                                                    <!-- START HEADING --> */}
                                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" align="center">
                                                                                <h1 style={{ fontSize: "24px", color: "#244da1", fontWeight: "bold", paddingLeft: "20px", paddingRight: "20px" }}> Sure Track Courier LTD</h1>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <!-- END HEADING -->
                                                    
                                                    <!-- START PARAGRAPH --> */}
                                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center" style={{ width: "33%" }}>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify" }}>Ready Date:{datePicker} </p>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify" }}>Ready Time: {timePicker}</p>
                                                                               
                                                                            </td>
                                                                            <td align="center">
                                                                                <button style={{
                                                                                    width: "60%", background: "#fff",
                                                                                    padding: "10px 0px",
                                                                                    border: "2px solid #e53333",
                                                                                    borderRadius: "6px",
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "600"
                                                                                }}>Delivery Price Quote</button>
                                                                            </td>
                                                                            <td align="center" style={{ width: "33%" }}>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify" }}>Account Number : {accountInfo?.account_No} </p>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify" }}>Quote By : </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <!-- END PARAGRAPH -->                                                               
                                                    
                                                    <!-- START BUTTON --> */}

<table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                                    <tbody>
                                                        <tr>
                                                            <td height="40"> <p style={{ fontSize: "16px", color: "#e53333", paddingLeft: "20px", paddingRight: "20px", textAlign: "center" }}>Quote is valid for 7 days from request time</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                                                <table width="100%" cellPadding="0" cellSpacing="0" align="center" style={{
                                                                    borderTop: "1px solid #000",
                                                                    borderBottom: "1px solid #000", padding: "20px 10px 10px 10px", margin: "20px"
                                                                }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center" style={{ width: "67%" }}>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify",paddingTop:"10px" }}> Customer : {accountInfo?.customer}</p>
                                                                            </td>
                                                                            <td align="center">
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify",paddingTop:"10px" }}> Contact: {accountInfo?.contact}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                {/* <!-- END BUTTON -->
                                                    
                                                    <!-- START SPACING --> */}
                                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center" style={{margin:"20px"}}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify" }}>
                                                                                    Shipper: {pickInfo?.street},
                                                                                    {pickInfo?.streetName},
                                                                                    {pickInfo?.city} ,
                                                                                    {pickInfo?.provineSateName},{pickInfo?.countryname}
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <p style={{ fontSize: "16px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify" }}>
                                                                                    Receiver: {dropInfo?.street},
                                                                                    {dropInfo?.streetName},
                                                                                    {dropInfo?.city} ,
                                                                                    {dropInfo?.provineSateName},{dropInfo?.countryname}
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <!-- END SPACING -->

                                                    <!-- second tab --> */}

                                                                <table width="90%" border="0" cellPadding="0" cellSpacing="0" align="center" style={{ padding: "10px"}}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center">
                                                                                <p style={{ fontSize: "14px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify", border: "1px solid black", borderCollapse: "collapse", padding: "10px" }}>Vehicle:{orderInfo?.vehicleID}</p>
                                                                            </td>
                                                                            <td align="center">
                                                                                <p style={{ fontSize: "14px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify", border: "1px solid black", borderCollapse: "collapse", padding: "10px" }}>Service:{orderInfo?.serviceID}</p>
                                                                            </td>
                                                                            <td align="center">
                                                                                <p style={{ fontSize: "14px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify", border: "1px solid black", borderCollapse: "collapse", padding: "10px" }}>Pcs/Skids:{orderInfo?.pcs}</p>
                                                                            </td>
                                                                            <td align="center">
                                                                                <p style={{ fontSize: "14px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify", border: "1px solid black", borderCollapse: "collapse", padding: "10px" }}>Wgt:{orderInfo?.weight}</p>
                                                                            </td>
                                                                            <td align="center">
                                                                                <p style={{ fontSize: "14px", color: "#000", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify", border: "1px solid black", borderCollapse: "collapse", padding: "10px" }}>Dim Wgt:{orderInfo?.weight}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>


                                                                <table style={{ width: "50%" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid black", borderCollapse: "collapse", color:"#000" }}>Base Price</th>
                                                                        <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>234.43</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid black", borderCollapse: "collapse" ,color:"#000"}}>Fuel Charge</th>
                                                                        <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>55.11</td>
                                                                    </tr>
                                                                </table>

                                                                {/* <!-- START SPACING --> */}
                                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center" style={{ marginTop: "52px" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <p style={{ textAlign: "center", color: "#1616ff" }}>Plus Applicable Taxes</p>
                                                                                <p style={{ textAlign: "center", color: "#1616ff" }}>This quote is valid for 7 days and based on the information, Weight</p>
                                                                                <p style={{ textAlign: "center", color: "#1616ff" }}>and dimensions provided. Any accessorial charge unknown to sure track courier ltd.</p>
                                                                                <p style={{ textAlign: "center", color: "#1616ff" }}>may be subject to additional charges based on the term of carriage</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <!-- END SPACING -->                                                     */}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* <!-- END CONTENT --> */}
                                                {/*                                     
                                    <!-- START SPACING --> */}
                                                <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="center">
                                                    <tbody>
                                                        <tr>
                                                            <td height="40">&nbsp;</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* <!-- END SPACING -->                                     */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}
export default Print;