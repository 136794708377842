import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Wrapper from "../../../Wrapper";
import FunctionalRole from "./FunctionalRole";
import RoleAssignment from "./RoleAssignment";

const AdminRole = () => {
 
    return (
        <>
            <Wrapper>
            <RoleAssignment/>
            </Wrapper>
        </>
    );
}
export default AdminRole;