import RouteIcon from '@mui/icons-material/Route';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useGetDriverNumberListQuery, useAddDriverToBoardMutation } from '../../../features/Dispatch/DispatchSlice';
import { toast } from 'react-toastify';

const Routing = (props) => {
    const [addDriverToBoard] = useAddDriverToBoardMutation();
    const [selectedDriver, setSelectedDriver] = useState("");

    const addToBoard = async (e) => {
        const result = await addDriverToBoard({ divisionID: props.divisionId, driverNumber: e.target.value });
        if (result.data.statusCode === 200) {
            toast.success("Driver added to board successfully", {
                position: "top-center",
              })
            props.fetchDrivers(props.divisionId);
            setSelectedDriver("");
        }
    }

    return (
        <fieldset className='fieldset-routing'>
            <label htmlFor="" className='label-heading '>Add Driver To Active Board</label>
            <div className="routingDriver">
                <div className='activeBoardSelect'>
                    <Form.Group>
                        <Form.Select 
                            aria-label="Floating label select example" 
                            name="Role" 
                            value={selectedDriver}
                            onChange={async (e) => {
                                setSelectedDriver(e.target.value);
                                await addToBoard(e); 
                                props.getDriverList(props.divisionId)}}>
                            <option value="">Select</option>
                            <>
                                {
                                    props.driverListResult?.data?.data?.map((data, index) => (

                                        <option key={index} value={data?.driverNumber}>{data?.driverNumber + " " + data?.name}</option>
                                    ))
                                }
                            </>
                        </Form.Select>
                     </Form.Group>
                 </div>
                <Button className='routing'><RouteIcon /> Routing</Button>
            </div>
        </fieldset >

    )
}
export default Routing