import { Button, Col, Row, Tab, Tabs, Modal, Container } from "react-bootstrap";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Add } from "@material-ui/icons";
import Invoice from "./Invoicing";
import 'react-toastify/dist/ReactToastify.css';
import Contract from "./Contract";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomerInfo from "./NameAddress/CustomerDetails";
import useCustomer from "../../context/CustomerContext";
import EditCustomer from "./NameAddress/EditNameAddress";
import SaveCustomer from "./NameAddress/SaveNameAddress";
import { useDeleteCustomerMutation } from "../../features/customer/customerSlice";
import { useGetCustomerQuery } from "../../features/customer/customerSlice";
import Spinner from "../Spinner";
import { useCustomData } from "../Sidebar/Permission";

const CustomerDetailstab = () => {
  const [permission] = useCustomData()
  const {refetch} = useGetCustomerQuery()
  const [deleteCustomer] = useDeleteCustomerMutation()
  const { onAddsubmit, selected, singleAccount , isLoading, validateAddSubmit , validateNameAddress, validateContractPricing, loading, contractInfo} = useCustomer();
  const account = singleAccount && singleAccount[0] ;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEditName, setShowEditName] = useState(true);
  const [key, setKey] = useState("name");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/customer/CustomerCrud');
    window.location.reload(false);
  }

  function refreshPage() { window.location.reload(false); }
  const deleteCustomerList = async (account) => {
    await deleteCustomer(account?.account_No)
    refetch()
  }
  const showhandler = () => { setShowEditName(!showEditName); }


  return (
    <>
      <Row className="sticky-header">
        <Col sm={3}>
          {
            selected ?
              <h4>{account?.customer}</h4> : <h4>Customer name</h4>

          }
        </Col>
        {/* { !selected &&
        <Col>
          <div className="button-section">
            <button className="add-btn btn btn-primary" onClick={() => validateNameAddress()}  style={{ width: "50%" }}>
            {!loading ? <> Add Customer<span> <Add /></span> </> : <Spinner />}
            </button>
          </div>
        </Col>
        } */}
        {/* {(selected) &&  
        <Col>
          <div className="button-section">
            <button className="add-btn btn btn-primary" onClick={() => validateContractPricing()}  style={{ width: "50%" }}>
            {!loading ? <> Add Rate Card<span> <Add /></span> </> : <Spinner />}
            </button>
          </div>
        </Col>
        } */}
        <Col>
          <div className="button-section">
            {selected ?
              <>
                <Button className="delete-btn" onClick={handleShow} >
                  Delete
                  <span>
                    <Delete />
                  </span>
                </Button>
                {/* <Button className="add-btn" onClick={navigateTo}>
                  Add{" "}
                  <span>
                    <Add />
                  </span>
                </Button> */}
              </>
              :
              <button className="add-btn btn btn-primary" onClick={() => validateNameAddress()}  >
            {!loading ? <> Add Customer <span> <Add /></span> </> : <Spinner />}
            </button>
            }
          </div>
        </Col>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete the selected customer?</Modal.Body>
            <Modal.Footer>
              <Row>
                <Col sm={6}>
                  <Button variant="secondary" onClick={handleClose}>
                    CANCEL
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button variant="primary" onClick={e => { deleteCustomerList(account);refreshPage() }}>
                    DELETE
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </>
      </Row>
      
      
      {isLoading && <Spinner />}
      <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        {showEditName ?
          <Tab eventKey="name" title="Name/Address">
            {selected ?
              <EditCustomer showhandler={showhandler} /> :
              <CustomerInfo />}
          </Tab>
          : <Tab eventKey="name" title="Name/Address">
            <SaveCustomer showhandler={showhandler}/>
          </Tab>}
        <Tab eventKey="contract" title="Contract/Pricing">
          <Contract />
        </Tab>
        <Tab eventKey="invoicing" title="Invoicing" >
          <Invoice />
        </Tab>
      </Tabs>
    </>
  );
};
export default CustomerDetailstab;
