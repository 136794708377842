import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";


export const lessSlice = apiSlice.injectEndpoints({
    endpoints:builder => ({
      editLessFuelEffective: builder.mutation({
      query(data) {
        const {fuelsurchargerateid, ...body} = data
        return {
          url: `Customer/EditFuelEffectivelSection?fuelsurchargerateid=${fuelsurchargerateid}`,
          method: "POST",
          body
        };
      },
      }),
      deleteLessFuel: builder.mutation({
        query : (fuelsurchargerateid) => ({
              url: `/Customer/DeleteFuelEffectiveDateSectionLTLFTLHTL?fuelsurchargerateid=${fuelsurchargerateid}`,
              method: "POST",
          }),
      }), 
      addCustomerFuelLtl: builder.mutation({
        query(body) {
          return {
            url: `Customer/AddCustomerAddFuelLTLHTLFTLafterEdit`,
            method: "POST",
            body
          };
        },
        }),
   
    }),
})

export const { useEditLessFuelEffectiveMutation ,useAddCustomerFuelLtlMutation, useDeleteLessFuelMutation} = apiSlice




const initialState = {
    list: []
};

const lessLoadSlice = createSlice({
    name: "lessLoad",
    initialState,
    reducers: {
        addLessLoad: (state, action) => {
            const lists = {
                id: new Date().getTime().toString(),
                list: action.payload,
            };
            state.list.push(lists);
        },
        deleteLessLoad: (state, action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editLessLoad: (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id: action.payload.id,
                list: action.payload
            }
            return { ...state, list: currentLists };
        },
        editLessLoadAfter: (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.list.fuelsurchargerateid === action.payload.id);
            currentLists[filterData] = {
                id: action.payload.id,
                list: action.payload
            }
            return { ...state, list: currentLists };
        },
        resetLessLoad: () => initialState,
    },
})

export const { addLessLoad, editLessLoad, deleteLessLoad, editLessLoadAfter, resetLessLoad } = lessLoadSlice.actions

export default lessLoadSlice.reducer