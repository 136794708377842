import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";


const initialState = []

export const fetchAccessorialDesp  = createAsyncThunk('GetAccessorialDesp/fetchAccessorialDesp', async(AccesorialChargeid) =>{
    try{
        const response = await axios.get(`${baseUrl}/Order/GetAccesorialChargeDiscriptionRate?AccessorialID=${AccesorialChargeid}`)
        return[...response.data.data];

    }catch (err){
        return err.response.data;
      
    }
})


const accessorialDespSlice = createSlice({
    name:"accessorialdesp",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchAccessorialDesp.fulfilled, (state, action) => {
          return action.payload
        })
      }
})

export const accessorialDespList  = (state) => state.accessorialDesp;

export default accessorialDespSlice.reducer