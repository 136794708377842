import Wrapper from "../../Wrapper";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid-premium";
import { AddCircle, Visibility, Delete, Save } from "@material-ui/icons";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { useCreateCompanyProfileMutation, useGetAllCompanyProfilesQuery } from "../../../features/admin/companyProfile/companyProfileSlice";
import CountryProvince from "../../CountryProvince";
import { Typeahead } from "react-bootstrap-typeahead";

const CompanyProfile = () => {
  const [showEdit, setShowEdit] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [createCompanyProfile] = useCreateCompanyProfileMutation();
  const [companyProfilesList, setCompanyProfilesList] = useState([]);
  const [selection, setSelection] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const companyProfiles = useGetAllCompanyProfilesQuery();
  const { city, setSelectState, handleCity, handleProvince, newHandleCountry } =
    CountryProvince();
  const cityList = city?.data.map((a) => {
    return { name: a.city, id: a.id };
  });

  useEffect(() => {
    if (companyProfiles.data) {
      setCompanyProfilesList(companyProfiles.data.data.map((item, index) => ({...item, id: (index + 1).toString()})));
    }
  }, [companyProfiles]);

  useEffect(async () => {
    if (selection.length > 0) {
      const pList = await handleProvince(selection[0].id);
      setProvinceList(pList.data);
      setSelectedRow({ ...selectedRow, city: selection[0].id });
    } else {
      setProvinceList([]);
      setCountryList([]);
      setSelectedRow({ ...selectedRow, province: "", country: "" });
    }
  }, [selection]);

  const setCountry = async (e) => {
    if (e.target.value !== "Select") {
      const cList = await newHandleCountry(e.target.value);
      setCountryList(cList.data);
      handleChange(e);
    } else {
      setCountryList([]);
      setSelectedRow({ ...selectedRow, country: "" });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSelectedRow({ ...selectedRow, [name]: value });
  };

  const divisionList = [
    { id: 1, name: "Western Division" },
    { id: 2, name: "Eastern Division" },
    { id: 3, name: "USA Division" },
    { id: 4, name: "Local Trucking Division" },
    { id: 5, name: "Local Courier Division" },
  ];

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => (
        <div className="action_btn">
          <span className="view_btn">
            <Visibility />
          </span>
          <span className="del_btn">
            <Delete />
          </span>
        </div>
      ),
    },
    { field: "companyName", headerName: "Name", width: 180 },
    { field: "address", headerName: "Address", width: 180 },
    { field: "mailingAddress", headerName: "Mailing Address", width: 180 },
    { field: "phoneNumber", headerName: "Phone Number", width: 130 },
    { field: "fax", headerName: "Fax", width: 130 },
    { field: "businessNumber", headerName: "Business Number", width: 130 },
    { field: "division", headerName: "Division", width: 130 },
    { field: "website", headerName: "Website", width: 180 },
    { field: "icon", headerName: "Icon", width: 130 },
  ];

  const rows = [
    {
      id: 1,
      Action: "Action",
      companyName: "Suretrack Courier Ltd",
      address: "20 Corrine Court",
      email: "Suretrack@info.com",
      phoneNumber: "785-412-7854",
      faxNumber: "785-412-7854",
      businessNumber: "123-456-9833",
      division: "Western",
      website: "suretrack.com",
      icon: "Test",
    },
    {
      id: 2,
      Action: "Action",
      companyName: "LVM Tech ",
      address: "1788 Drew Road ",
      email: "info@lvmtech.com",
      phoneNumber: "123-456-7890",
      faxNumber: "123-456-7890",
      businessNumber: "123-456-9833",
      division: "Eastern",
      website: "lvmtech.com",
      icon: "Test",
    },
  ];

  return (
    <>
      <Wrapper>
        <h3 style={{ textAlign: "left", position: "relative", top: "50px" }}>
          Company Profile
        </h3>
        <div className="section_action_btn">
          <div className="add_btn">
            <button
              type="button"
              className="btn"
              onClick={() => setShowEdit(true)}
            >
              <AddCircle /> Add Company Profile
            </button>
          </div>
        </div>
        {showEdit && (
          // add row with button, and button is right aligned

          <Row className="mb-4 mt-4">
            <Col sm={12}>
              <fieldset className="mb-3">
                <label className="label-heading">Add company profile</label>
                <>
                  <Row className="mb-3 mt-0">
                    <Col sm={12} className="d-flex justify-content-end">
                      <button
                        type="button"
                        style={{ width: "7%" }}
                        className="btn btn-primary"
                        onClick={async () => {
                          await createCompanyProfile(selectedRow);
                          setShowEdit(false);
                          // create popup saying "Company Profile added successfully"
                          // refetch the get request once its added
                        }}
                      >
                        <Save /> Save
                      </button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={2}>
                      <Form.Group controlId="invoicecare1">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Company Name"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            name="companyName"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecare1">
                        <FloatingLabel controlId="floatingInput" label="Street">
                          <Form.Control
                            type="text"
                            placeholder="Street"
                            name="streetNo"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecare2">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Street Name"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Street Name"
                            name="streetName"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="City">
                        <Typeahead
                          id="cityAutocomplete"
                          labelKey="name"
                          onChange={setSelection}
                          options={cityList}
                          placeholder="Choose a city..."
                          selected={selection}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="Province/State">
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Province/State"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="province"
                            onChange={(e) => {
                              setSelectState(e.target.value);
                              handleCity(e);
                              setCountry(e);
                            }}
                          >
                            <option>Select</option>:
                            <>
                              {provinceList?.data?.map((data, index) => (
                                <option key={index} value={data.provineStateID}>
                                  {data.provineSateName}
                                </option>
                              ))}
                            </>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="country">
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Country"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="country"
                            onChange={handleChange}
                          >
                            <option>Select</option>:
                            <>
                              {countryList?.data?.map((data, index) => (
                                <option key={index} value={data.countryId}>
                                  {data.countryname}
                                </option>
                              ))}
                            </>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={2}>
                      <Form.Group controlId="invoicecare2">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Postal Code"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Postal Code"
                            name="postalCode"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecare22">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            name="mailingAddress"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecare21">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Phone Number"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecareaa">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Fax Number"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Fax Number"
                            name="fax"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecareaa">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Business Number"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Business Number"
                            name="businessNumber"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecareaa">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Tax Number"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Tax Number"
                            name="taxNumber"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={2}>
                      <Form.Group controlId="invoicecareaa">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Select Division"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="division"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select</option>
                            {divisionList.map((division) => (
                              <option key={division.id} value={division.name}>
                                {division.name}
                              </option>
                            ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="invoicecareaa">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Website"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Website"
                            name="website"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group controlId="invoicecareaa">
                        <FloatingLabel controlId="floatingInput" label="Icon">
                          <Form.Control
                            type="text"
                            placeholder="Icon"
                            name="icon"
                            onChange={handleChange}
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
                <></>
              </fieldset>
            </Col>
          </Row>
        )}
        <div
          className="user-role-table"
          style={{ height: "500px", background: "white", marginTop: "20px" }}
        >
          <h6>List of Company Profiles</h6>
          <DataGrid
            rows={companyProfilesList}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[1]}
            components={{ Toolbar: GridToolbar }}
          />
        </div>
      </Wrapper>
    </>
  );
};
export default CompanyProfile;
