import { Row, Col, Form, FloatingLabel, Tab, Tabs, Button, Accordion, Container } from "react-bootstrap";
import { AddCircle, Update, Delete, PersonAddRounded, CalendarToday, Visibility, Publish } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { DoubleArrow } from "@material-ui/icons";
import AssignRole from "./AssignRole";
import { useForm } from 'react-hook-form';
import { useGetApiAdminModuleMutation, useGetPermissionDataQuery } from "../../../../features/admin/role/createRoleSlice";
import { useSelector } from "react-redux";
import { useGetRolesQuery, useAddUserPermissionMutation } from "../../../../features/admin/role/createRoleSlice";
import moment from 'moment'
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../../style.css'
import Spinner from "../../../Spinner";

const RoleAssignment = () => {
    const [isShow, setisShow] = useState(false)
    const onHideShowEvent = () => {
        setisShow(isShow => !isShow)
    }

    const { data: role, isSuccess } = useGetRolesQuery()
    const { register, handleSubmit, watch, formState: { errors }, reset, } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, watch: watch1, setValue, formState: { errors: errors1 }, reset: reset1, } = useForm();
    let subscribe = watch();
    let subscribe1 = watch1();
    const addRoleList = (useSelector((state) => state.createRole.addrolelist)).map((el) => { return ({ id: el.id, ...el.addrolelist }) })
    const [clickedItem, setClickedItem] = useState(null)
    const [addUserPermission, result] = useAddUserPermissionMutation()
    const { data: data } = useGetPermissionDataQuery(localStorage.getItem("role") || "", { refetchOnMountOrArgChange: false, skip: false, })
    const [getApiAdminModule, result1] = useGetApiAdminModuleMutation()

    const [addRole, setAddRole] = useState({})
    const [show, setShow] = useState(true)
    const [key, setKey] = useState(addRoleList[0]?.Role)
    const [checkedData, setCheckedData] = useState({})
    const [disable, setDisable] = useState()
    const [accordian, setAccordian] = useState([]);
    const [roleArray, setRoleArray] = useState([localStorage.getItem("role")])
    const [selecetedIndex, setSelectedIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setCheckedData((p) => ({ ...p, [result?.originalArgs?.role]: result?.originalArgs?.permissionDetails }))
        setDisable(true)
    }, [result])
    const adminFetchData = async (id) => {
        const res = await getApiAdminModule(id);
        let objData = {}
        let arrData = []
        res?.data?.data?.length && res?.data?.data?.map((d) => {
            arrData.push(d.role);

            objData[d.role] = {
                view: d.permissionType.find((el) => el === "View") ? true : false,
                add: d.permissionType.find((el) => el === "Add") ? true : false,
                update: d.permissionType.find((el) => el === "Update") ? true : false,
                delete: d.permissionType.find((el) => el === "Delete") ? true : false,
                upload: d.permissionType.find((el) => el === "Uploac") ? true : false,
                createdBy: d.createdBy,
                createdDate: d.createdDate,
                modifiedBy: d.modifiedBy,
                modifiedDate: d.modifiedDate,
            }
        });
        const firstValue = Object.values(objData)[0]
        setRoleArray(arrData)
        setCheckedData(objData)

        reset1({
            view: firstValue?.view === true ? true : false,
            add: firstValue?.add === true ? true : false,
            update: firstValue?.update === true ? true : false,
            delete: firstValue?.delete === true ? true : false,
            upload: firstValue?.upload === true ? true : false,
        });
        setKey(res?.data?.data[0].role)
    }

    useEffect(() => {
        setIsLoading(true)
        let HeaderArray = [];
        data?.data && data?.data.map((item) => {
            let itemData = JSON.parse(JSON.stringify(item));
            itemData.accordianData = [];
            if (item.parentId == 0) {
                HeaderArray.push(itemData);
            } else
                HeaderArray.map((itemHeader) => {
                    let dataArrayToBeMapped = itemHeader.accordianData;
                    if (itemHeader.moduleId == item.parentId) {
                        let accordItem = JSON.parse(JSON.stringify(item));
                        accordItem.accordianData = [];
                        dataArrayToBeMapped.push(accordItem);
                    }
                })
        })
        data?.data && data?.data.map((mainItem) => {
            if (mainItem.parentId !== 0) {
                HeaderArray.map((item) => {
                    let prasedAccord = JSON.parse(JSON.stringify(item));
                    prasedAccord.accordianData.map((accordItem) => {
                        let subAccord = JSON.parse(JSON.stringify(accordItem));
                        let subAccordArray = subAccord.accordianData;
                        if (mainItem.parentId !== 0) {
                            if (mainItem.parentId == accordItem.moduleId) {
                                subAccordArray.push(mainItem);
                            }
                        }
                        return accordItem;
                        // if(mainItem.parentId == accordItem.moduleId){
                        //     subAccordArray.push(mainItem);
                        // }
                    })
                })
            }
        })
        setAccordian(HeaderArray)
        setClickedItem(HeaderArray[0]?.accordianData[0]);
        adminFetchData(HeaderArray[0]?.accordianData[0]?.moduleId)
        setIsLoading(false)
    }, [data])


    let header = subscribe?.manager;
    let createDate = moment(checkedData[key]?.createdDate).format('MM/D/YYYY')
    let modifiedDate = moment(checkedData[key]?.modifiedDate).format('MM/D/YYYY')

    //function of form submit to add Role
    const onSubmit = async (addRole) => {
        const newManager = roleArray.find((d) => d === addRole.manager)
        if (newManager) {
            toast.warning("Role already Exists !", {
                position: "top-center",
            })
            return
        }
        setIsLoading(true)
        setDisable(true)
        let data = {
            "moduleId": clickedItem?.moduleId || "",
            "moduleName": clickedItem?.moduleName || "",
            "role": addRole?.manager || 'SuperAdmin',
            "permissionDetails":
            {
                "Add": addRole?.Adddata ? true : false,

                "View": addRole?.Viewdata ? true : false,

                "Update": addRole?.Updatedata ? true : false,

                "Delete": addRole?.Deletedata ? true : false,

                "Uploac": addRole?.Rundata ? true : false,
            }
        }
        setAddRole(addRole)
        if (addRole && Object.keys(addRole).length === 0) {
            setShow((show) => !show)
        }
        else {
            await addUserPermission(data)
            toast.success("Role Added Succesfully", {
                position: "top-center",
            })
        }
        reset({ Role: '', Viewdata: "", Adddata: "", Updatedata: "", Deletedata: "", Rundata: "" });
        setRoleArray((p) => ([...p, header]));
        setCheckedData((p) => ({
            ...p, [header]: {
                add: addRole?.Adddata ? true : false,

                view: addRole?.Viewdata ? true : false,

                update: addRole?.Updatedata ? true : false,

                delete: addRole?.Deletedata ? true : false,

                upload: addRole?.Rundata ? true : false,
            }
        }));

        setIsLoading(false)
    }
    //function to add checkboxes after submission
    const onSubmit1 = async (d) => {
        setIsLoading(true)
        let data = {

            "moduleId": clickedItem?.moduleId || "",

            "moduleName": clickedItem?.moduleName || "",

            "role": key || '',

            "permissionDetails":
            {
                "Add": d?.add === true ? true : false,

                "View": d?.view === true ? true : false,

                "Update": d?.update === true ? true : false,

                "Delete": d?.delete === true ? true : false,

                "Uploac": d?.upload === true ? true : false,
            }
        }
        setDisable(true)
        setisShow(false)

        await addUserPermission(data)
        toast.success("Role Updated Succesfully", {
            position: "top-center",
        })
        setIsLoading(false)
    }
    const EditHandler = () => {
        setDisable(false)
    }
    //function to save the changes finally
    const apiHandler = async (id) => {
        setIsLoading(true)
        reset1({ Role: '', view: "", add: "", update: "", delete: "", upload: "" });
        const res = await getApiAdminModule(id);
        let objData = {}
        let arrData = []
        res?.data?.data?.length && res?.data?.data?.map((d) => {
            arrData.push(d.role);

            objData[d.role] = {
                view: d.permissionType.find((el) => el === "View") ? true : false,
                add: d.permissionType.find((el) => el === "Add") ? true : false,
                update: d.permissionType.find((el) => el === "Update") ? true : false,
                delete: d.permissionType.find((el) => el === "Delete") ? true : false,
                upload: d.permissionType.find((el) => el === "Uploac") ? true : false,
                createdBy: d.createdBy,
                createdDate: d.createdDate,
                modifiedBy: d.modifiedBy,
                modifiedDate: d.modifiedDate,
            }
        });
        setDisable(true)
        setRoleArray(arrData)
        setCheckedData(objData);
        const firstValue = Object.values(objData)[0]
        reset1({
            view: firstValue?.view === true ? true : false,
            add: firstValue?.add === true ? true : false,
            update: firstValue?.update === true ? true : false,
            delete: firstValue?.delete === true ? true : false,
            upload: firstValue?.upload === true ? true : false,
        });
        setIsLoading(false)
    }


    return (
        <>

            {isLoading && <Spinner />}
            {/* Top Section */}
            <div className="role-management">
                <Container fluid>
                    <Row>
                        <Col sm={3}>
                            <div className="funtional-role-section">
                                <>
                                    <h6 className="mb-3">Application Functional Role</h6>
                                    {accordian?.map((item, id) =>

                                        <Accordion defaultActiveKey={id === 0}>
                                            <Accordion.Item eventKey={item.parentId === 0} >
                                                <Accordion.Header>{item.moduleName} </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="cover">
                                                        <ul className="liststyle" >
                                                            {item?.accordianData?.length && item?.accordianData?.map((d, ind) => {
                                                                return <li className={selecetedIndex == ind && 'active1'} onClick={() => { setClickedItem(d); setCheckedData({}); setSelectedIndex(ind); apiHandler(d.moduleId) }} >
                                                                    <DoubleArrow />{d.moduleName} </li>
                                                            }
                                                            )
                                                            }
                                                        </ul>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    )
                                    }
                                </>

                            </div>
                        </Col>
                        <Col sm={9}>
                            <div className="role-details-section">
                                <div className="role-assignment">
                                    <div className="role-assignment-name">
                                        <h6>Role Assignment - {clickedItem?.moduleName}</h6>
                                    </div>
                                    <div className="add-role-button d-flex">
                                        {
                                            <>
                                                {!isShow && <span style={{ marginRight: "10px" }}><Button variant="outline-primary" onClick={() => setisShow(true)}> <AddCircle /> Add Role</Button></span>}
                                                {isShow && (<span style={{ marginRight: "10px" }}><Button variant="outline-primary" onClick={handleSubmit((d) => onSubmit(d, true))}> <AddCircle /> Submit Role</Button></span>
                                                )}
                                            </>
                                        }
                                        <span className=""><AssignRole /></span>
                                    </div>
                                </div>
                                {/* A role form section*/}
                                {isShow ?
                                    <fieldset className="user-role-form mt-4 mb-3">
                                        <label className="label-heading">Add New Role</label>
                                        <div className="add-role-form">
                                            <div className="role-assignment mb-2">
                                                <div className="role-assignment-name">
                                                </div>
                                            </div>
                                            {/* 1st section */}

                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="Roletype">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="Role">
                                                            <Form.Select aria-label="Floating label select example" name="Role"
                                                                {...register('manager', { required: 'Field is required', })}>
                                                                <option value="">Select</option>
                                                                {
                                                                    role?.data?.map((data, index) => (
                                                                        <option key={index} >{data}</option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <p className="error-message">{errors.Role?.message}</p>
                                                    </Form.Group>
                                                </Col>

                                                <Col>
                                                    <div className="form-floating">
                                                        <Form.Control type="text" placeholder="Created by" name="Createdby" {...register("Createdby")} value={clickedItem?.createdBy} disabled />
                                                        <label htmlFor="Created by">Created By</label>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-floating">
                                                        <Form.Control type="text" placeholder="Created date" name="Createddate" {...register("Createddate")} value={createDate} disabled />
                                                        <label htmlFor="Created date">Created Date</label>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-floating">
                                                        <Form.Control type="text" placeholder="Modified By" name="Modifiedby" {...register("Modifiedby")} value={clickedItem?.modifiedBy} disabled />
                                                        <label htmlFor="Modified by">Modified By</label>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-floating">
                                                        <Form.Control type="text" placeholder="Modified Date" name="Modifieddate" {...register("Modifieddate")} value={modifiedDate} disabled />
                                                        <label htmlFor="Modified date">Modified Date</label>
                                                    </div>
                                                </Col>

                                            </Row>


                                            {/* 2nd Section */}

                                            <div className="form-check-option mt-3">

                                                <Row>
                                                    <Col>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="Viewdata" {...register("Viewdata")} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>View/Read Data</label>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-check form-check-inline" >
                                                            <input className="form-check-input" disabled={!subscribe?.Viewdata} type="checkbox" id="inlineCheckbox2" name="Adddata" {...register("Adddata")} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2" style={{ fontSize: "15px" }}>Add Data</label>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" disabled={!subscribe?.Viewdata} type="checkbox" id="inlineCheckbox3" name="Updatedata" {...register("Updatedata")} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox3" style={{ fontSize: "15px" }}>Update Data</label>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" disabled={!subscribe?.Viewdata} type="checkbox" id="inlineCheckbox4" name="Deletedata" {...register("Deletedata")} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox4" style={{ fontSize: "15px" }}>Deactivate / Delete Data</label>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" disabled={!subscribe?.Viewdata} type="checkbox" id="inlineCheckbox5" name="view/Read data" {...register("Rundata")} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox5" style={{ fontSize: "15px" }}>Run / Generate Function</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </fieldset>
                                    : null}
                                {<Tabs id="controlled-tab-example" defaultActiveKey={localStorage.getItem("role")} onSelect={(k) => { setKey(k); reset1({ view: checkedData[k].view, add: checkedData[k].add, update: checkedData[k].update, delete: checkedData[k].delete, upload: checkedData[k].upload }); }} className="mb-3" activeClassName="active">
                                    {roleArray && roleArray.map((item, id) =>
                                        <Tab title={item} style={{ marginBottom: "50px" }} eventKey={item}>
                                            {/* role-permission-view */}
                                            <div className="role-permission" >
                                                <div className="permission-role d-flex" >
                                                    <span className="permission-icon"><Visibility /></span>
                                                    <div className="permission-name">
                                                        <span className="permission-name">View Data</span>
                                                        <span className="permission-name-details">View Customer Data</span>
                                                    </div>
                                                </div>
                                                <div className="permission-role-selection">
                                                    <div className="form-check form-check-inline">
                                                        <input disabled={disable} key={checkedData[item]} className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultChecked={checkedData[item]?.view} onClick={(e) => setValue('view', e.target.checked)} />

                                                    </div>
                                                </div>
                                            </div>
                                            {/* role-permission-add */}
                                            <div className="role-permission">
                                                <div className="permission-role d-flex">
                                                    <span className="permission-icon"><AddCircle /></span>
                                                    <div className="permission-name">
                                                        <span className="permission-name">Add Data</span>
                                                        <span className="permission-name-details">Add Customer Info</span>
                                                    </div>
                                                </div>
                                                <div className="permission-role-selection">
                                                    <div className="form-check form-check-inline">
                                                        <input disabled={disable || !subscribe1?.view} key={checkedData[item]} className="form-check-input" type="checkbox" id="inlineCheckbox2" defaultChecked={checkedData[item]?.add} onClick={(e) => setValue('add', e.target.checked)} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Role-permission-update */}
                                            <form onSubmit={handleSubmit1(onSubmit1)}>
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><Update /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Update Data</span>
                                                            <span className="permission-name-details">Update Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-check form-check-inline">
                                                            <input disabled={disable || !subscribe1?.view} key={checkedData[item]} className="form-check-input" type="checkbox" id="inlineCheckbox301" defaultChecked={checkedData[item]?.update} onClick={(e) => setValue('update', e.target.checked)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Role Permission Deactivate */}
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><Delete /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Deactivate/Delete Data</span>
                                                            <span className="permission-name-details">Delete Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-check form-check-inline">
                                                            <input disabled={disable || !subscribe1?.view} className="form-check-input" type="checkbox" id="inlineCheckbox301" defaultChecked={checkedData[item]?.delete} onClick={(e) => { setValue('delete', e.target.checked) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Role Permission generate */}
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><Publish /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Run/Generate/Upload Data</span>
                                                            <span className="permission-name-details">Run/Generate/Upload Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-check form-check-inline">
                                                            <input disabled={disable || !subscribe1?.view} key={checkedData[item]} className="form-check-input" type="checkbox" id="inlineCheckbox5" defaultChecked={checkedData[item]?.upload} onClick={(e) => setValue('upload', e.target.checked)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Role Created by */}
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><PersonAddRounded /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Create by</span>
                                                            <span className="permission-name-details">View Created By Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-floating">
                                                            <Form.Control type="text" placeholder="Created By" name="Createdby" value={checkedData[key]?.createdBy} disabled />
                                                            <label htmlFor="Created by">Created By</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Role Create Date */}
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><CalendarToday /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Created Date</span>
                                                            <span className="permission-name-details">View Create Date Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-floating">
                                                            <Form.Control type="text" placeholder="Created Date" name="Createddate" value={createDate} disabled />
                                                            <label htmlFor="Created date">Created Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Role Modified By */}
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><PersonAddRounded /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Modified By</span>
                                                            <span className="permission-name-details">Modified Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-floating">
                                                            <Form.Control type="text" placeholder="Modified By" name="Modifiedby" value={checkedData[key]?.modifiedBy} disabled />
                                                            <label htmlFor="Modified by">Modified By</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Role modified date */}
                                                <div className="role-permission">
                                                    <div className="permission-role d-flex">
                                                        <span className="permission-icon"><CalendarToday /></span>
                                                        <div className="permission-name">
                                                            <span className="permission-name">Modified Date</span>
                                                            <span className="permission-name-details">Modified Customer Data</span>
                                                        </div>
                                                    </div>
                                                    <div className="permission-role-selection">
                                                        <div className="form-floating">
                                                            <Form.Control type="text" placeholder="Modified Date" name="Modifieddate" value={modifiedDate} disabled />
                                                            <label htmlFor="Modified date">Modified Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Confirm Save and Edit Section */}
                                                <div className="admin-buttons">
                                                    {disable ? <span>
                                                        <Button className="edit_Button btn btn-primary" onClick={EditHandler}> Edit Role</Button>
                                                    </span> :
                                                        <span >
                                                            <Button variant="outline-danger"
                                                                className="btn btn-danger text-white float-end"
                                                                onClick={handleSubmit1((d) => onSubmit1(d, true))}
                                                                style={{ width: "100px", alignItems: "center" }}>Save</Button>
                                                        </span>}
                                                </div>
                                            </form>

                                        </Tab>
                                    )}
                                </Tabs>

                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default RoleAssignment;