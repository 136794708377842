function validateAccount(accountInfo) {
    let errors = {};
    if (!accountInfo?.customer) {
      errors.customer = 'Field is required';
    }
    if (!accountInfo?.street) {
      errors.street = 'Field is required';
    }
    if (!accountInfo?.streetName) {
      errors.streetName = 'Field is required';
    }
    // if (!accountInfo?.unit) {
    //   errors.unit = 'Field is required';
    // }
    if (!accountInfo?.countryId) {
        errors.countryId = 'Field is required';
      } 
      if (!accountInfo?.provineStateID) {
        errors.provineStateID = 'Field is required';
      } 
    if (!accountInfo?.city) {
      errors.city = 'Field is required';
    }
    if (!accountInfo?.postalCode) {
      errors.postalCode = 'Field is required';
    } 
    if (!accountInfo?.contact) {
        errors.contact = 'Field is required';
      }
      if (!accountInfo?.phone) {
        errors.phone = 'Field is required';
      }
      if (!accountInfo?.phone_Ext) {
        errors.phone_Ext = 'Field is required';
      }
      if (!accountInfo?.emailAddress) {
        errors.emailAddress = 'Field is required';
      }
      if (!accountInfo?.open) {
        errors.open = 'Field is required';
      }
      if (!accountInfo?.closeTime) {
        errors.closeTime = 'Field is required';
      }
    return errors;
  };

  export default validateAccount