import { Row, Col, Modal, FloatingLabel } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { SpeakerNotes } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import useOrder from "../../../context/OrderContext";
import { useGetCityQuery } from "../../../features/city/citySlice";
import { useGetCountryQuery, useLazyGetStateQuery } from "../../../features/countryState/countryStateSlice";

const DropLocationNotes = () => {
  const {data:country} = useGetCountryQuery()
  const [fetchState, province] = useLazyGetStateQuery()
  const {data:city} = useGetCityQuery
  const {dropInfo,Dropuphandler} = useOrder();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [selectState, setSelectState] = useState([]);

  const getData = async (selectState) =>{
      dispatch(fetchState(selectState))
  }
  const handlecountry = async (e) => {
          const addressData = e.target.value;
          const selectedState = await getData(addressData)
          setSelectState(selectedState)
  }


  return (
    <>
      <div onClick={() => setIsShow(true)}>
      <div><SpeakerNotes /> Notes</div>
      </div>
      <Modal
        size="md"
        show={isShow}
        onHide={() => setIsShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
        id="product-desp"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            {" "}
            LocationNotes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <>
        <fieldset>
                <label  className="label-heading">Location Notes</label>
              <Row className="mb-3">
                <Col sm={4}>
                <Form.Group controlId="date">
                  <FloatingLabel controlId="floatingInput" label="Previous Date" >
                      <Form.Control type="date" placeholder="Previous Date" name="previousdate" value={dropInfo?.previousdate} onChange={e => { Dropuphandler(e); }}/>
                   </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="st">
                  <FloatingLabel controlId="floatingInput" label="Street No." >
                      <Form.Control type="text" placeholder="Street No." name="street" value={dropInfo?.street} onChange={e => { Dropuphandler(e); }}/>
                   </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="Street">
                  <FloatingLabel controlId="floatingInput" label="Street Name" >
                      <Form.Control type="text" autoComplete="off" placeholder="Street Name" name="streetName" value={dropInfo?.streetName} onChange={e => { Dropuphandler(e); }} required />
                        </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={4}>
                  <Form.Group controlId="Unit">
                  <FloatingLabel controlId="floatingInput" label="Unit" >
                      <Form.Control type="text" autoComplete="off" placeholder="Unit" name="unit" value={(dropInfo?.unit && dropInfo?.unit !== "null") ? dropInfo?.unit : ""} onChange={e => { Dropuphandler(e); }} required />
                     </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="Country">
                    <FloatingLabel controlId="floatingSelectGrid" label="Country">
                    <Form.Select aria-label="Floating label select example" name="countryId" onChange={e => { handlecountry(e); Dropuphandler(e) }}
                            // value={dropInfo?.countryId || ''}
                             required disabled>

                            <option>Select</option>:
                            {/* <>
                              {
                                country.map((data, index) => (
                                  <option key={index} value={data.countryId}>{data.countryname}</option>
                                ))

                              }
                            </> */}
                          </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="pr">
                    <FloatingLabel controlId="floatingInput" label="Province" >
                    <Form.Select aria-label="Floating label select example" name="provineStateID" onChange={(e) => { setSelectState(e.target.value); Dropuphandler(e) }}
                            value={dropInfo?.provineStateID || ''} required disabled>

                            <option>{dropInfo?.provineSateName ? dropInfo?.provineSateName : "Select"}</option>:
                            {/* <>
                              {
                                province?.map((data, index) => (
                                  <option key={index} value={data.provineSateName}>{data.provineSateName}</option>
                                ))

                              }
                            </> */}

                          </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={4}>
                  <Form.Group controlId="city">
                    <FloatingLabel controlId="floatingInput" label="City" >
                      <Form.Select aria-label="Floating label select example" name="city"
                        value={dropInfo?.city ? dropInfo.city : ""} onChange={e => { Dropuphandler(e); }} required >

                        <option>Select</option>:
                        {/* <>
                          {
                            city.map((data, index) => (
                              <option key={index} >{data.city}</option>
                            ))

                          }
                        </> */}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="postal/zip">
                  <FloatingLabel controlId="floatingInput" label="Postal code" >
                      <Form.Control type="text" autoComplete="off" placeholder="Postal code" name="postalCode"  value={dropInfo?.postalCode || ""} onChange={e => { Dropuphandler(e); }} required/>
                     </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="phone">
                  <FloatingLabel controlId="floatingInput" label="Phone" >
                      <Form.Control type="text" autoComplete="off" placeholder="Phone" name="phone" value={dropInfo?.phone || ""} onChange={e => { Dropuphandler(e); }}/>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={4}>
                  <Form.Group controlId="Contact">
                  <FloatingLabel controlId="floatingInput" label="Contact" >
                      <Form.Control type="text" autoComplete="off" placeholder="Contact" name="contact" value={dropInfo?.contact || ""} onChange={e => { Dropuphandler(e); }}/>
                     </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="fax">
                  <FloatingLabel controlId="floatingInput" label="Fax" >
                      <Form.Control type="text" autoComplete="off" placeholder="Fax" name="fax" value={dropInfo?.fax || ""} onChange={e => { Dropuphandler(e); }}/>
                   </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="email">
                  <FloatingLabel controlId="floatingInput" label="Email" >
                      <Form.Control type="text" autoComplete="off" placeholder="Email" name="emailAddress" value={dropInfo?.emailAddress || ""} onChange={e => { Dropuphandler(e); }}/>
                   </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={12}>
                <div className="floating-checbox" name="RateType">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="appointment" />
                          <label className="form-check-label" htmlFor="inlineCheckbox1">Appointment Required</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="tailgate"/>
                          <label className="form-check-label" htmlFor="inlineCheckbox2">Tailgate Required</label>
                        </div>
                      </div>
                </Col>
                <Col sm={12}>
                <FloatingLabel controlId="floatingTextarea2" label="Comments" >
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
          name="locationNotes" value={(dropInfo?.locationNotes && dropInfo?.locationNotes !== "null") || ""} onChange={e => { Dropuphandler(e); }}
        />
      </FloatingLabel>
                </Col>
              </Row>
              </fieldset>
              {/* <Row className="mt-3">
                <Col sm={10}></Col>
                <Col sm={2}><Button className="btn-primary">Submit</Button></Col>
              </Row> */}

            </>

        </Modal.Body>
      </Modal>
    </>
  );
};
export default DropLocationNotes;
