import { apiSlice } from "../../app/services/authServices";

export const dropOfApiSlice = apiSlice.injectEndpoints({
    endpoints:builder => ({ 
        getDropOffList: builder.query({
            query(data) {
                return {
                    url: `/Order/GetOrderDropoffInformation?CustomerName=${data}`,
                  method: "GET",
                };
              },
          }), 
         
    })    
})

export const {useLazyGetDropOffListQuery} = apiSlice;






