import React from 'react';

const postalCodeMasking = (value) => { 
    if (!value)return value;
    const phoneNumber =value.replace(/[^0-9a-zA-Z]/g,"");
    const phoneNumberLength =phoneNumber.length;
    if(phoneNumberLength <4) return phoneNumber;
    if(phoneNumberLength <7) {return `${phoneNumber.slice(0,3)} ${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0,3)} - ${phoneNumber.slice(3,6)}`;
  }

  export default postalCodeMasking;