import React from "react";
import Topbar from "./Sidebar/Topbar";
import Sidebar from "./Sidebar/Sidebar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";

const Wrapper = (props) => {
  const token = useSelector(selectCurrentToken)
  const [inactive, setInactive] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() =>{
    if(!localStorage.getItem('token')) navigate('/login');
  }, []);

  return (
    <>
      <div className="wrapper">
        <Topbar />
        <div className={`body-overlay ${inactive ? "inactive" : ""} `}></div>
        <Sidebar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
        />
        <div className={`page-wrapper ${inactive ? "inactive" : ""}`}>
        {props.children}
        </div>
      </div>
    </>
  );
};
export default Wrapper;
