import Wrapper from "../../Wrapper";
import { Tab, Tabs, Row, Col,Form, FloatingLabel,Button } from "react-bootstrap"
import ChequeList from "./ChequeList";
import DetailsCheque from "./DetailsCheque";
import SelectedCheque from "./SelectedCheque";

 const DepostDistribution = ()=>{
     return (
         <>
         <Wrapper>
            <div className="deposit-section">
                <div> <h5 className="ml-3 mb-3">Depoit and cheque distribution</h5></div>
                <div>
                    <div className="date-range row" style={{marginRight:"0px"}}>
                        <div className="date-to col-sm-4">
                        <Form.Group controlId="accountnumber" className="mb-2">
                        <FloatingLabel controlId="floatingInput" label="Date to">
                            <Form.Control type="date" placeholder="Account Name" name="AccountName" value="" />
                        </FloatingLabel>
                    </Form.Group>
                        </div>
                        <div className="date-from col-sm-4">
                        <Form.Group controlId="accountnumber" className="mb-2">
                        <FloatingLabel controlId="floatingInput" label="Date from">
                            <Form.Control type="date" placeholder="Account Name" name="AccountName" value="" />
                        </FloatingLabel>
                    </Form.Group>
                        </div>
                        <div className="col-sm-4">
                        <Button> Search</Button>
                        </div>
                    </div>
                </div>
            </div>

         <Tabs defaultActiveKey="List of cheque deposit" id="uncontrolled-tab-example" className="mb-3" >
             {/* Select List of chque deposit method */}
      <Tab eventKey="List of cheque deposit" title="List of cheque deposit">
        <Row style={{marginRight:"0px"}}>
            <Col sm={4}>
                <ChequeList/>
            </Col>
            <Col sm={8}>
                <DetailsCheque/>
                <SelectedCheque/>
            </Col>
        </Row>
      </Tab>
       {/* Select USA Sales & Billing Totals method */}
      <Tab eventKey="List of EFT deposit" title="List of EFT deposit">
      </Tab>
    </Tabs>
         </Wrapper>
         </>
     );
 }
 export default DepostDistribution;