import { Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { Delete } from '@material-ui/icons';


export const DeleteUserRole = ({ onDeleteClick, refetch }) =>{
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};
    return(
        <>
        <span onClick={handleClickOpen}><Delete/></span>
        <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
          Deactivate Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to deactivate the account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="btn-secondary" onClick={() => { handleClose(); }}>Cancel</Button>
            <Button className="btn-primary" onClick={
              async() => { await onDeleteClick();
              handleClose();
              refetch(); }} autoFocus> Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
      </>
    )
}

export const ActivateAccount = ({ onDeleteClick, refetch }) =>{
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {setOpen(true);};
  const handleClose = () => {setOpen(false);};
  return(
      <>
      <span onClick={handleClickOpen}><Delete/></span>
      <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
        Activate Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to activate the account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="btn-secondary" onClick={() => { handleClose(); }}>Cancel</Button>
          <Button className="btn-primary" onClick={
            async() => { await onDeleteClick();
            handleClose();
            refetch(); }} autoFocus> Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  )
}

