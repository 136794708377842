import Wrapper from "../../Wrapper";
import { CreateCustomer } from "../../../context/CustomerContext";
import useCustomer from "../../../context/CustomerContext";
import Contract from "./DefaultPricing/Contract";
import { Button, Form, Row } from "react-bootstrap";
import { Save } from "@material-ui/icons";
import { useGetDefaultPricingRateCardListQuery, useDeleteDefaultRatecardMutation } from "../../../features/cwtRateCard/cwtRateCardSlice";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

const Admin = () => {
    const { addRateCardDefaultPricing, getDefaultCard } = useCustomer();
    const { data: rateCards} = useGetDefaultPricingRateCardListQuery();
    const combinations = {1: 'Zone-CWT', 2: 'Region-CWT', 3: 'Zone-Pallet', 4: 'Region-Pallet'}
    const [selectedCard, setSelectedCard] = useState(0);
    const [deleteRateCard] = useDeleteDefaultRatecardMutation();
    
    return (
        <>
            {/* <CreateCustomer> */}
                <Wrapper>
                    <div className="default-pricing-title">
                    <h5>Default Pricing</h5>
                    <Row sm={3}>
                        <Form.Select aria-label="Default select example" 
                            onChange={(e) => {
                                if (e.target.value !== '0') {
                                    getDefaultCard(e.target.value)
                                }
                                setSelectedCard(e.target.value);
                            }}
                        >
                            <option value='0'>Choose a Default Rate Card</option>
                            {rateCards?.data && rateCards.data.map((rateCard) => (
                                <option value={rateCard.rateCardId}>{combinations[rateCard.rateCardId]}</option>
                                ))
                            }
                        </Form.Select>
                    </Row>
                    <div className="default-pricing-btn" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { selectedCard !== 0 &&
                                <Button 
                                    style={{marginRight: '5px'}} 
                                    variant="danger" 
                                    onClick={async () => {
                                        const result = await deleteRateCard(selectedCard);
                                        if (result.statusCode !== 200) {
                                            toast.success('Rate card deleted successfully', {position: 'top-center'});
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 2000);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                            <Button onClick={() => addRateCardDefaultPricing()}><Save/> Save</Button>
                        </div>
                    </div>                    
                    <div className="default-pricing">                      
                        <Contract selectedCard={selectedCard} />
                    </div>
                </Wrapper>
            {/* </CreateCustomer> */}
        </>
    );
}
export default Admin;