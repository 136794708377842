import { apiSlice } from "../../app/services/authServices";


export const authApiSlice = apiSlice.injectEndpoints({
  endpoints:builder => ({
    login: builder.mutation ({
      query:credential =>({
        url: '/Account/UserLogin',
        method:'POST',
        body:{ ...credential }
      })      
    })
  })
})

export const {useLoginMutation} = authApiSlice