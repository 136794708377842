import React from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import Notes from './Notes'

const ArCourier = () => {
  return (
    <>

      {/* <Container fluid> */}
      <Row className='mt-4'>
        <Col>
          <div className="role-assignment d-flex">
            <div className="role-assignment-name">
              <h5>LVMTECH COURIER</h5>
            </div>
            {/* <div className="add-role-button d-flex">
              <span ><Button variant="primary" style={{ width: "100px" }}>Notes</Button></span>
            </div> */}
            <Notes/>
          </div>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col>
          <div className="dynamic-input d-flex">
            <div className="form-floating">
              <Form.Control type="text" autoComplete="off" placeholder="A/P Phone" name="Phone" />
              <label htmlFor="Phone">A/P Phone</label>
            </div>
            <div className="form-floating">
              <Form.Control type="text" autoComplete="off" placeholder="A/P Fax no" name="Fax_no" />
              <label htmlFor="Fax_no">A/P Fax No </label>
            </div>
            <div className="form-floating">
              <Form.Control type="text" autoComplete="off" placeholder="Contact" name="Contact" />
              <label htmlFor="Contact">A/P Contact</label>
            </div>
            <div className="form-floating">
              <select className="form-select" aria-label="Default select example">
                <option selected>Account Status</option>
                <option value="1">Open</option>
                <option value="2">Stopped</option>
                <option value="3">Prepaid Only</option>
                <option value="3">First Payment Hold</option>
                <option value="3">Collection</option>
                <option value="3">Never Open</option>
                <option value="3">VISA</option>
              </select>
              <label htmlFor="Contact">Account Status</label>
            </div>
            <div className="form-floating">
              <Form.Control type="text" autoComplete="off" placeholder="Date" name="Balance" />
              <label htmlFor="Balance">Balance</label>
            </div>
          </div>
        </Col>
      </Row>
      {/* </Container> */}

    </>
  )
}

export default ArCourier
