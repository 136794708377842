import { Button } from "react-bootstrap";
import { Person } from "@material-ui/icons";
import { AddLocation } from "@material-ui/icons";

const DriverDetails = (props) => {
    return (
        <div className="main-container">
            <div className="driver-details mt-2">
                <div className="detail-header">
                    <p>Select driver details</p>
                </div>
                <div className="details-section">
                    <div className="driver-mid-sec">
                        <div className="img-section">
                            {props.selectedDriver?.driverImageUrl ? (<p className="driver-img">
                                <img src={props.selectedDriver.driverImageUrl} 
                                    alt="Driver" 
                                    style={{maxWidth: '44px', maxHeight: '44px'}}/>
                                </p>) : 
                            (<p className="driver-img"><Person /></p>)}
                            <p className="driver-name">{props.selectedDriver?.name || null}</p>
                        </div>
                        <div className="driver-location">
                            <Button className="driver-location"><AddLocation/> Location</Button>
                        </div>
                    </div>
                    <div className="selected-driver-details">
                        <div className="driver-number">
                            <h6>Driver No.</h6>
                            <p>{props.selectedDriver?.driverNumber || null }</p>
                        </div>
                        <div className="pcs">
                            <h6>Pcs</h6>
                            <p>{props.selectedDriver?.totalPieces || null }</p>
                        </div>
                        <div className="weight">
                            <h6>Weight</h6>
                            <p>{props.selectedDriver?.totalWeight || null }</p>
                        </div>
                        <div className="Assigned drive number  AssignedDriver">
                            <h6>Assigned Unit No.</h6>
                            <p>{props.selectedDriver?.assignedUnit || null }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DriverDetails