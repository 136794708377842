import React, { useState } from 'react'
import { DataGridPremium, GridToolbar, useGridApiRef, } from '@mui/x-data-grid-premium';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete } from '@material-ui/icons';

const PaidAmountTable = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const columns = [
    {
      field: "Select",
      headerName: "Select",
      sortable: false,
      width: 70,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen(); // don't select this row after clicking
        };

        return (
          <>
            <>
              <div className="action_btn">
                {/* <span className="view_btn" onClick={onClick}><Visibility /></span> */}
                <span className="del_btn" onClick={onRemove}><Delete /></span>
              </div>
            </>
            <>
              <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                  <DialogTitle id="alert-dialog-title">
                    Delete Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this row?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className="btn-secondary" onClick={() => { handleClose(); }}>Cancel</Button>
                    <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          </>
        )

      },
    },

    { field: "Efft.Date", headerName: "EfftDate", width: 94 },
    { field: "Ref.No", headerName: "RefNo", width: 90 },
    { field: "Customer", headerName: "Customer", width: 90 },
    { field: "Amount", headerName: "Amount", width: 90 },
  ];
  const rows = [
    { id: 1, EfftDate: "05/09/2022 ", RefNo: "13", Customer: "3251", Amount: "1234"}
  ];


  return (
    <>
      <div className="user-role-table " style={{ height: "350px" }}>
        <h6>Paid amount details</h6>
        <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }}
        
        />
      </div>
    </>

  )
}

export default PaidAmountTable;



