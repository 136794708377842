import React from 'react';
import { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import useOrder from "../../../../../context/OrderContext";
import { useForm, Controller } from 'react-hook-form';
import { useUpdateOrderNotificationMutation } from "../../../../../features/order/orderSlice";

const Document = ({ selectedOrder }) => {    
    const [labelAccountValue, setLabelAccountValue] = useState(false)
    const [labelConsignorValue, setLabelConsignorValue] = useState(false)
    const [labelConsigneeValue, setLabelConsigneeValue] = useState(false)
    const [updateOrderNotification] = useUpdateOrderNotificationMutation();
    const [tagsDocSendToEmail, setTagsDocSendToEmail] = useState([]);

    
    const [BOLValue, setBOLValue] = useState(true);
    const [labelValue, setLabelValue] = useState(true);

    const consignor = selectedOrder?.pickupContactEmail;
    const consignee = selectedOrder?.dropoffContactEmail;
    const headAccount = selectedOrder?.emailAddress;

    const handleChange = event => {
        if (event.target.name === "labelheadaccount") {
            if (event.target.checked) {
                setLabelAccountValue(event.target.value);

            }
            else {
                setLabelAccountValue(false)

            }
        }
        else if (event.target.name === "labelconsignor") {
            if (event.target.checked) {
                setLabelConsignorValue(event.target.value);

            }
            else {
                setLabelConsignorValue(false)
            }
        }
        else if (event.target.name === "labelconsignee") {
            if (event.target.checked) {
                setLabelConsigneeValue(event.target.value);

            }
            else {
                setLabelConsigneeValue(false)
            }
        }
    };
    const { register, handleSubmit } = useForm({
        defaultValues: {
            BOLLABEL: ['BOL', 'LABEL'],  // Set the default selected options here
        }
    });
    const radioHandlert = (statust) => {
        if (statust === 2) {
            setBOLValue(!BOLValue);
        } else if (statust === 3) {
            setLabelValue(!labelValue);
        }
    };

    const SubmitHandler = async () => {
        // setAccountInfo("")
        // setPickInfo("")
        // setDropInfo("")
        // setOrderInfo([])
        const data = {
            isBOL: BOLValue,
            isLabel: labelValue,
            docSendToHeadAccount: labelAccountValue ? true : false,
            docSendToConsignor: labelConsignorValue ? true : false,
            docSendToConsignee: labelConsigneeValue ? true : false,
            // docSendToEmail: tagsDocSendToEmail,
        }
        console.log(data);
        await updateOrderNotification(data);
        window.location.reload(false)
    }
    const addTags = (event, type) => {
        if (event.target.value !== "") {
            switch (type) {
                case 'docSendToEmail':
                    setTagsDocSendToEmail(event.target.value);
                    break;
                default:
                    break;
            }
            event.target.value = "";
        }
    };
    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="email-notify">
                        <div className="email-heading">
                            <h6>Document</h6>
                        </div>
                        <div className="fieldset-inner-email">
                            <div className="floating-checboxemail">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="BOL" name="BOLLABEL" {...register("BOLLABEL", { required: 'Field is required' })} onChange={e => radioHandlert(2)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">BOL</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="LABEL" name="BOLLABEL" {...register("BOLLABEL", { required: 'Field is required' })} onClick={() => radioHandlert(3)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Label</label>
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-section">
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to head account" value={headAccount} onChange={handleChange} name="labelheadaccount" />
                                {labelAccountValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.emailAddress})</label> : ""}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="labelconsignor" value={consignor} onChange={handleChange} />
                                {labelConsignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.pickupContactEmail})</label> : ""}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="labelconsignee" value={consignee} onChange={handleChange} />
                                {labelConsigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.dropoffContactEmail})</label> : ""}
                            </Form.Group>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={9}>
                    <div className="tags-input">
                        <input
                            value={tagsDocSendToEmail}
                            onChange={event => addTags(event, 'docSendToEmail')}
                            placeholder="Enter Email Address"
                            className="email-input"
                        />
                    </div>
                </Col>
                <Button variant="primary" className="email-btn" type="submit" name="submit" onClick={SubmitHandler}> Submit </Button>
            </Row>
        </>
        
    );
}

export default Document;