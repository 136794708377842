import { apiSlice } from "../../../app/services/authServices";


export const addFieldVehicleSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addAssetData: builder.mutation({
      query: (data) => ({
        url: 'Asset/AddAssetField',
        method: 'POST',
        body:data,
      }),
    }),
    getAllAssetFields: builder.query({
      query: () => ({
        url: 'Asset/GetAllAssetFields',
        method: 'GET',
      }),
    }),

    addAssetTypeWithFields: builder.mutation({
      query: (data) => ({
        url: 'Asset/AddAssetTypeWithFields',
        method: 'POST',
        body:data,
      }),
    }),

    getAssetTypeList: builder.query({
        query: () => ({
          url: 'Asset/GetAssetTypeList',
          method: 'GET',
        }),
      }),

      getAllFieldsForAssetType: builder.query({
        query: (assetTypeId) => ({
            url: `Asset/GetAllFieldsForAssetType?AssetTypeId=${assetTypeId}`,
            method: 'GET',
        }),
    }),

    AddAssetInventoryWithVehicle: builder.mutation({
        query: (data) => ({
            url: `Asset/AddAssetInventoryWithVehicle`,
            method: 'POST',
            body : data,
        }),
    }),

    getAssetInventoryList: builder.query({
        query: () => ({
            url: `Asset/GetAssetInventoryList`,
            method: 'GET',
        }),
    }),
    deleteInventorydataAsset: builder.mutation({
      query: (data) => ({
          url: `Asset/DeleteInventorydataA?AssetInventoryId=${data}`,
          method: 'POST',
          body:"",
      }),
    }),
    getAssetsInventoryByID: builder.query({
      query: (assetInventoryId) => ({
        url: `Asset/GetassetInventoryDetailByInventoryID?AssetInventoryId=${assetInventoryId}`,
        method: 'GET',
      })
    }),
    updateAssetsInventoryData: builder.mutation({
      query: (data) => ({
        url:'Asset/UpdateAssetInventoryWithVehicle',
        method:'POST',
        body:data
      }),
    }),
    getAssetsTypeByID: builder.query({
      query: (assetTypeId) => ({
        url: `Asset/GetAllFieldsForAssetType?AssetTypeId=${assetTypeId}`,
        method: 'GET',
      })
    }),
    updateAssetsTypeData: builder.mutation({
      query: (data) => ({
        url:'Asset/UpdateAssetTypeWithFields',
        method:'POST',
        body:data
      }),
    }),

  })
})

export const { useAddAssetDataMutation, useGetAllAssetFieldsQuery, useAddAssetTypeWithFieldsMutation ,useGetAssetTypeListQuery , useGetAllFieldsForAssetTypeQuery ,
     useAddAssetInventoryWithVehicleMutation ,useGetAssetInventoryListQuery ,useDeleteInventorydataAssetMutation, useLazyGetAssetsInventoryByIDQuery, 
     useUpdateAssetsInventoryDataMutation, useLazyGetAssetsTypeByIDQuery, useUpdateAssetsTypeDataMutation } = apiSlice;