import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './services/authServices';
import authReducer from '../features/auth/authSlice';
import nameAddressReducer from '../features/nameAddress/nameAddressSlice';
import contactDetailsReducer from '../features/contactDetails/contactDetailsSlice';
import contractDataReducer from '../features/contractInfo/contractInfoSlice';
import vehicleDetailReducer from "../features/vehicle/vehicleDetailSlice"
import serviceDetailReducer from "../features/service/serviceDetailSlice"
import accessorialDetailReducer from '../features/accessorial/accessorialDetailSlice';
import cityDistanceReducer from '../features/cityDistance/cityDistanceSlice';
import distanceFuelReducer from '../features/distanceFuel/distanceFuelSlice';
import lessLoadReducer from '../features/lessLoad/lessLoadSlice';
import fullLoadReducer from '../features/fullLoad/fullLoadSlice';
import heavyLoadReducer from '../features/heavyLoad/heavyLoadSlice';
import FuelCostReducer from '../features/FuelCost/FuelCostSlice';
import cwtRateCardReducer from '../features/cwtRateCard/cwtRateCardSlice';
import invoicingCurrencyReducer from '../features/invoicing/invoicingCurrencySlice';
import invoicingCycleReducer from '../features/invoicing/invoicingCycleSlice';
import invoicingSortonReducer from '../features/invoicing/invoicingSortonSlice';
import invoicingStyleReducer from '../features/invoicing/invoicingStyleSlice';
import quoteListReducer from '../features/quote/quoteListSlice';
import accessorialDespReducer from '../features/accessorial/accessorialDespSlice';
import unitReducer from '../features/unit/unitSlice';
import productDataReducer from '../features/product/productDataSlice';
import createRoleReducer from '../features/admin/role/createRoleSlice';
import employeeReducer from '../features/admin/employee/employeeSlice';
import interlinerRateCardReducer from "../features/Interliners/InterlinersFieldsSlice"

export const store = configureStore({
    reducer :{
        auth:authReducer,
        contactDetails:contactDetailsReducer,
        nameAddress:nameAddressReducer,
        contractData:contractDataReducer,
        vehicleDetail:vehicleDetailReducer,
        serviceDetail: serviceDetailReducer,
        accessorialDetail:accessorialDetailReducer,
        cwtRateCardData:cwtRateCardReducer,
        cityDistance:cityDistanceReducer,
        distanceFuel:distanceFuelReducer,
        lessLoad:lessLoadReducer,
        fullLoad:fullLoadReducer,
        heavyLoad:heavyLoadReducer,
        fuelCost:FuelCostReducer,
        invoiceCurrencyList:invoicingCurrencyReducer,
        invoiceSortonList:invoicingSortonReducer,
        invoiceCycleList:invoicingCycleReducer,
        invoiceStyleList:invoicingStyleReducer,
        productData:productDataReducer,
        quoteList:quoteListReducer,
        accessorialDesp:accessorialDespReducer,
        unit:unitReducer,
        createRole:createRoleReducer,
        employee:employeeReducer,
        interliner:interlinerRateCardReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false,}).concat(apiSlice.middleware),
    devTools: true
})