import React from 'react'
import { Row, Col, Form, FloatingLabel, Button } from "react-bootstrap";
import { useState } from "react";
import { AddCircle, Delete, DonutSmallOutlined, Edit, Visibility } from "@material-ui/icons";
import { Image } from "@material-ui/icons";
import { useForm } from 'react-hook-form';
import { useGetVehicleQuery } from "../../../features/vehicle/vehicleDetailSlice";
import '../../../style.css'
import { useAddFieldDataMutation } from "../../../features/vehicle/AddFieldVehicleSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DisableEditClick from '../RoleManagement/UserRole/DisableEditClick';
import {
    useAddVehicleInventoryDataMutation, useDeleteVehicleInventorydataMutation, useGetAdditionalFieldsForVehicleQuery, useLazyGetVehicleInventoryByInventoryIdQuery,
    useGetVehicleInventoryListQuery, useGetVehicleTypeListQuery, useUpdateVehicleInventoryListMutation
} from '../../../features/vehicle/GetVehicleFieldSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../Spinner';
import { useCustomData } from '../../Sidebar/Permission';
import { useEffect } from 'react';


const ManageInventory = () => {
    const [permission] = useCustomData()
    const [fieldsValue, setFieldsValue] = useState()
    const [deleteId, setDeleteId] = useState()
    const [open, setOpen] = useState(false);
    const [vehicleId, setVehicleId] = useState()
    const [disable, setDisable] = useState(false)
    const [clear, setClear] = useState(false)
    const [vehicleType, setVehicleType] = useState()
    const [loading, setLoading] = useState(false);
    const [editVehicleName, setEditVehicleName] = useState()
    const [inventoryData, setInventoryData] = useState({})
    const [fieldDetails, setFieldDetails] = useState()
    const [fieldArray, setFieldArray] = useState({});
    const [show, setShow] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const { data: vehicleList, refetch: vehicleListRefetch } = useGetVehicleTypeListQuery({ refetchOnMountOrArgChange: true });
    const { data: getAdditionalFieldsForVehicle } = useGetAdditionalFieldsForVehicleQuery((fieldsValue), { refetchOnMountOrArgChange: false, skip: false, });
    const [addVehicleInventoryData] = useAddVehicleInventoryDataMutation();
    const [deleteVehicleInventorydata] = useDeleteVehicleInventorydataMutation()
    const { data: inventoryList, isLoading, refetch } = useGetVehicleInventoryListQuery()
    const [updateVehicleInventoryList] = useUpdateVehicleInventoryListMutation()
    const tableList = inventoryList?.data;
    const [dataInventory, result] = useLazyGetVehicleInventoryByInventoryIdQuery()
    const editInventoryData = result?.data?.data
    const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
    const subscribe = watch()

    useEffect(() => {
        setVehicleType(vehicleList?.data)
        vehicleListRefetch()
    }, [vehicleList])

    useEffect(() => {
        let arrayField = [];
        if (getAdditionalFieldsForVehicle?.data?.fieldDetailsDTO?.fieldId !== null)

            getAdditionalFieldsForVehicle?.data?.fieldDetailsDTO?.forEach((d) => {
                if (d?.fieldId === null) {
                    return
                }
                arrayField.push(d)
            })
        setFieldDetails(arrayField)
    }, [getAdditionalFieldsForVehicle])


    useEffect(() => {
        const obj = {}
        setInventoryData(editInventoryData?.additionalFieldDetail)
        editInventoryData?.additionalFieldDetail?.forEach((d) => {
            obj[d?.fieldName + "_" + d?.fieldId] = d?.fieldValue
        })
        setFieldArray(obj)
        if (editInventoryData?.additionalFieldDetail) {
            setFieldDetails(editInventoryData?.additionalFieldDetail)
        }
        // setValue("Vehicle_Number", editInventoryData?.vehicleNumber)

        reset(inventoryData)
        setClear(editInventoryData?.vehicleTypeName)
    }, [result])

    useEffect(() => {
        if (editInventoryData?.vehicleNumber) {
            setValue("Vehicle_Number", editInventoryData?.vehicleNumber)
        }
    }, [result])

    const selectHandler = (e) => {
        setFieldsValue(e.target.value)
        const user = vehicleType?.find(u => u.vehicleTypeId == e.target.value);
        setVehicleId(user?.vehicleTypeId)
    }
    const handleField = (e) => {
        setFieldArray((p) => ({ ...p, [e.target.name]: e.target.value }))
    }

    const onSubmit = async (data) => {
        try {
            let array = []
            for (let key in fieldArray) {
                array.push(
                    {
                        fieldId: Number(key?.split("_")[1]),
                        fieldValue: fieldArray[key],
                        fieldName: key?.split("_")[0],
                    }
                )
            }
            setLoading(true)
            const newData = {
                "vehicleTypeId": vehicleId,
                "vehicleNumber": data?.Vehicle_Number,
                "additionalFieldDetail": array
            }
            const submitData = await addVehicleInventoryData(newData);
            const resetData = reset({ Vehicle_Number: "" });
            setLoading(false)
            refetch()
            setVehicleType([""])
            const dataTest = await vehicleListRefetch();
            setVehicleType(dataTest?.data?.data)
            setVehicleId("")
            setFieldDetails("")
            setFieldArray({})
            setFieldsValue("")
            setShow(true)

            if (submitData?.data?.statusCode == 200) {
                toast.success("Vehicle added to inventory succesfully!", {
                    position: "top-center",
                })
            }
            else if (submitData?.data?.statusCode == 400) {
                toast.error("Vehicle number already exists!", {
                    position: "top-center",
                })
            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }

    const updateInventory = async (data) => {
        try {
            let array1 = []
            for (let key in fieldArray) {
                array1.push(
                    {
                        fieldId: Number(key?.split("_")[1]),
                        fieldValue: fieldArray[key],
                    }
                )
            }
            setLoading(true)
            const updateData = {
                "vehicleInventoryId": editInventoryData?.vehicleInventoryId,
                "vehicleNumber": data?.Vehicle_Number,
                "additionalFieldDetail": array1
            }
            const updateInventory = await updateVehicleInventoryList(updateData)
            setLoading(false)
            refetch()
            setFieldDetails("")
            setInventoryData({})
            setFieldsValue("")
            setEditVehicleName("")
            setClear("")
            reset({ Vehicle_Number: "" })
            setIsShow(false)
            if (updateInventory?.data?.statusCode == 200) {
                toast.success("Vehicle Updated to Inventory Succesfully!", {
                    position: "top-center",
                })
                setDisable(false)
            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }

    }

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = async () => {
        try {
            setOpen(false);
            const deleteData = await deleteVehicleInventorydata(deleteId)
            if (deleteData?.data?.statusCode === 200) {
                toast.success("Vehicle Deleted Succesfully!", {
                    position: "top-center",
                })
            }
            refetch()
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    };



    if (!tableList || isLoading) return <><Spinner /></>;
    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 140,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (e) => {
                    dataInventory(data?.id)
                    setIsShow(true)
                    setClear(true)
                    setDisable(true)
                };
                const onRemove = (data) => {
                    setDeleteId(data?.id)
                    setOpen(true);
                };

                return (
                    <>
                        <>
                            <div className="action_btn">
                                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                                    <span className="edit_btn" onClick={onClick}><Edit /></span> :
                                    <span className="edit_btn disable"><Edit /></span>
                                }
                                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Delete') !== -1 ?
                                    <span className="del_btn" onClick={() => onRemove(data)}><Delete /></span> :
                                    <span className="del_btn disable"><Delete /></span>}
                            </div>
                        </>
                        <>
                            <div>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                        Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                                        <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </>
                    </>
                )
            },
        },
        { field: "Vehicle_Type", headerName: "Vehicle Type", width: 200 },
    ];
    const rows =
        tableList?.map((data) => {
            return {
                id: data?.vehicleInventoryId,
                Vehicle_Type: data?.vehicleTypeName
            }
        })

    return (
        <div>
            {loading && <Spinner />}
            <Row>
                <Col>
                    <div className="orderform mt-2">
                        <Row>
                            <Col sm={7}>
                                <Row className='mt-2'>
                                    <Col sm={4}>
                                        <Form.Group controlId="Vehicletype">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Select a vehicle type">
                                                <Form.Select aria-label="Floating label select example" name="VehicleType" disabled={disable} onChange={selectHandler}
                                                >
                                                    <option value="">{clear || "Select "}</option>:
                                                    <>
                                                        {
                                                            vehicleType?.map((data) => (
                                                                <option value={data?.vehicleTypeId}
                                                                    selected={data?.vehicleTypeId === inventoryData?.vehicleInventoryId ? true : false}
                                                                >
                                                                    {data?.vehicleTypeDescription} </option>
                                                            ))
                                                        }
                                                    </>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors.VehicleType?.message}</p>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3}></Col>
                            <Col sm={2}>

                                {isShow ?
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                            <Button onClick={handleSubmit(updateInventory)} className='save-btn mt-1'> Update</Button>
                                            : <Button className='save-btn disable'> Update</Button>
                                        }

                                    </> :
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                            <Button onClick={handleSubmit(onSubmit)} className='save-btn mt-1'> Submit</Button>
                                            : <Button className='save-btn disable'> Submit</Button>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>

                        {/* 2nd Row */}
                        <fieldset className=" mt-4">
                            <label className="label-heading">Inventory descriptions</label>
                            <div className="dynamic-input d-flex flex-wrap">
                                <div className="form-floating mr-2">
                                    <Form.Control type="text" autoComplete="off" placeholder="Vehicle Number" name="Vehicle_Number" {...register('Vehicle_Number', { required: !result && 'Field is required', pattern: /^[a-zA-Z0-9]{1,10}$/, message: "special character not allowed" })}
                                        disabled={disable} />
                                    <label htmlFor="Item">Vehicle Number</label>
                                    <p className="error-message">{errors.Vehicle_Number?.message}</p>
                                </div>
                                {editInventoryData?.vehicleInventoryId ?
                                    <>
                                        {fieldDetails && fieldDetails?.map((data, index) =>
                                            <div className="form-floating mr-2" key={index} >
                                                <Form.Control type="text" value={fieldArray[data?.fieldName + "_" + data?.fieldId] || inventoryData?.fieldName}
                                                    autoComplete="off" placeholder="" name={data?.fieldName + "_" + data?.fieldId}
                                                    onChange={handleField} min="0" />
                                                <label htmlFor="Item">{data?.fieldName}</label>
                                                <p className="error-message">{errors.Item?.message}</p>
                                            </div>
                                        )}
                                    </>
                                    : ""}

                                {!editInventoryData?.vehicleInventoryId ?
                                    <>
                                        {fieldDetails && fieldDetails?.map((data, index) =>
                                            <div className="form-floating mr-2" key={index} >
                                                <Form.Control type="text" value={fieldArray[data?.fieldName + "_" + data?.fieldId] || inventoryData?.fieldName}
                                                    autoComplete="off" placeholder="" name={data?.fieldName + "_" + data?.fieldId}
                                                    onChange={handleField} min="0" />
                                                <label htmlFor="Item">{data?.fieldName}</label>
                                                <p className="error-message">{errors.Item?.message}</p>
                                            </div>
                                        )}
                                    </>
                                    : ""}
                            </div>
                        </fieldset>
                    </div>
                </Col>
                <Row>
                    {tableList ?
                        <div className="user-role-table addVehicleTable" style={{ height: "730px" }}>
                            <h6>Manage Inventory</h6>
                            <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} componentsProps={{
                                toolbar: { showQuickFilter: true, },
                            }} checkboxSelection />
                        </div>
                        : "Data not Found"}
                </Row>
            </Row>
        </div>
    )
}

export default ManageInventory;
