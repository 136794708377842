import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const QUOTELIST_URL = `${baseUrl}/Order/GetListOfQuoteNo`


const initialState = []

export const fetchQuoteList  = createAsyncThunk('GetQuotelist/fetchQuoteList', async () =>{
    try{
        const response = await axios.get(QUOTELIST_URL);
        return[...response.data.data];
    }catch (err){
        return err.message;
    }
})


const quoteListSlice = createSlice({
    name:"quotelist",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchQuoteList.fulfilled, (state, action) => {
          return action.payload
        })
      }
})

export const quoteList  = (state) => state.quotelist;

export default quoteListSlice.reducer