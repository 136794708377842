import { Accordion, Tab, Tabs, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Save } from "@material-ui/icons";
import { Cached } from "@material-ui/icons";
import { useUpdateRegisteredUserPermissionMutation } from "../../../../features/admin/role/createRoleSlice";
import { useGetRolesQuery } from "../../../../features/admin/role/createRoleSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateUserPermission from "./UpdateUserPermission";
import { useGetRegisterUserQuery } from "../../../../features/admin/role/createRoleSlice";



const UpdateUserRole = ({ userData, accessUserName, showhandler, userRole, onChangeUserRole, abc,updateUser }) => {
    const { refetch } = useGetRegisterUserQuery()
    const { data: role } = useGetRolesQuery({}, { refetchOnMountOrArgChange: true })
    const [updateRegisteredUserPermission] = useUpdateRegisteredUserPermissionMutation();   
    const [state, setState] = useState(userData)
    const [permission, setPermission] = useState(userData)
    const [changeRole, setChangeRole] = useState("")
    const [update, setUpdate] = useState("")
    
  
    const handleSelect = (moduleId) => {
        setState(state?.moduleId)
        let userlist = userData?.find(x => x.moduleId == moduleId);
        let userGivenPermission = userData?.find(x => x.moduleId == moduleId)?.permissionType;
        setState(userlist);
        setPermission(userGivenPermission)
    }

    const [updatePermission, setUpdatePermission] = useState({})
    const permissionDetails =
    {
        Add:permission.indexOf("Add") !== -1 ? true : false, 
        View:permission.indexOf("View") !== -1 ? true : false, 
        Update: permission.indexOf("Update") !== -1 ? true : false, 
        Delete: permission.indexOf("Delete") !== -1 ? true : false,
        Upload:permission.indexOf("Upload") !== -1 ? true : false,
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const updatedper = {
            moduleId: state?.moduleId,
            moduleName: state?.moduleName,
            userName: accessUserName,
            permissionDetails
        }
        try {
                const userPermission = await updateRegisteredUserPermission(updatedper).unwrap()
                setUpdate(updateUser)
                abc()
                setUpdatePermission('')
                if (userPermission.statusCode === 200) {
                    setTimeout(function () {
                        toast.success("Permission updated successfully !", {
                            position: "top-center",
                        })
                    });
                }

        } catch (err) {
            if (!err.response) {
            }
            else {
            }
        }
        showhandler();
    }

    const handleChange = (e)=>{
        if(e.target.checked === true){
          setPermission([...permission, e.target.value]);
        }
        else if(e.target.checked === false){
          let freshArray = permission.filter(val => val !== e.target.value);
          setPermission([...freshArray]);
        }
      }



    const handleRoleChange = (event) => { setChangeRole(changeRole => ({ ...changeRole, [event.target.name]: event.target.value })) };
    const onSubmit = async () => {
        await onChangeUserRole(changeRole);
        setTimeout(function () {
            toast.success("Changed the user role !", {
                position: "top-center",
            })
        }, 1000);
        refetch()
    }
    
useEffect(() =>{
   setUpdate(update)
}, [update])

    return (
        <>
            <div className="role-assignment-name" style={{ marginBottom: "9px" }}>
                <Row>
                    <Col sm={8}>
                        <div className="change-role">
                            <p>ASSIGNED USER ROLE</p>
                            <div className="role-selection">
                                <Form.Group controlId="Roletype">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Role">
                                        <Form.Select aria-label="Floating label select example" name="role" value={changeRole.role || " "} onChange={handleRoleChange}>
                                            <option value="">{userRole}</option>
                                            {
                                                role?.data?.map((data, index) => (
                                                    <option key={index} >{data}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </div>
                            <span className="change-role-btn" onClick={onSubmit} ><button ><Cached /></button> </span>
                        </div>
                    </Col>
                    <Col sm={2}></Col>
                    <Col className="add-role-button-btn">
                        <span onClick={handleSubmit}><Save /> Save</span>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col sm={4}>
                    <div className="user-funtional-role">
                        <Accordion defaultActiveKey="0">
                            {/* AR Section */}
                            {userData?.find(event => event.parentId === 1) ?
                            <>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>AR Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                            {userData?.filter(event => event.parentId === 1).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName} >
                                                    <UpdateUserPermission data={data} handleChange={handleChange} updatePermission={updatePermission}/>
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            : "" }
                            {/* Billing Section */}
                            {userData?.find(event => event.parentId === 2) ?
                            <>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Billing Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                            {userData?.filter(event => event.parentId === 2).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                    <UpdateUserPermission data={data} handleChange={handleChange} updatePermission={updatePermission}/>
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            : "" }
                            {/* Customer Section */}
                            {userData?.find(event => event.parentId === 3) ?
                                <>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Customer Section</Accordion.Header>
                                        <Accordion.Body>
                                        <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                            {userData?.filter(event => event.parentId === 3).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                    <UpdateUserPermission data={data} handleChange={handleChange} updatePermission={updatePermission}/>
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                            {/* Dispatch Section */}
                            {userData?.find(event => event.parentId === 4) ?
                                <>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Dispatch Section</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="user-permission">
                                                <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                                    {userData?.filter(event => event.parentId === 4).map((data, index) => {
                                                        return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                            <UpdateUserPermission data={data} handleChange={handleChange} />
                                                        </Tab>
                                                    })}
                                                </Tabs>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                            {/* Report Section */}
                            {userData?.find(event => event.parentId === 5) ?
                                <>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Report Section</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="user-permission">
                                                <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                                    {userData?.filter(event => event.parentId === 5).map((data, index) => {
                                                        return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                            <UpdateUserPermission data={data} handleChange={handleChange} />
                                                        </Tab>
                                                    })}
                                                </Tabs>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                            {/* Order Section */}
                            {userData?.find(event => event.parentId === 6) ?
                                <>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Order Section</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="user-permission">
                                                <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                                    {userData?.filter(event => event.parentId === 6).map((data, index) => {
                                                        return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                            <UpdateUserPermission data={data} handleChange={handleChange} />
                                                        </Tab>
                                                    })}
                                                </Tabs>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                            {/* Admin Section */}
                            {userData?.find(event => event.parentId === 7) ?
                                <>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Admin Section</Accordion.Header>
                                        <Accordion.Body>
                                        <div className="user-permission">
                                                <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                                    {userData?.filter(event => event.parentId === 7).map((data, index) => {
                                                        return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                            <UpdateUserPermission data={data} handleChange={handleChange} />
                                                        </Tab>
                                                    })}
                                                </Tabs>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                                  {/* PAyroll section Section */}
                            {userData?.find(event => event.parentId === 36) ?
                                <>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>Payroll Section</Accordion.Header>
                                        <Accordion.Body>
                                        <div className="user-permission">
                                                <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                                    {userData?.filter(event => event.parentId === 36).map((data, index) => {
                                                        return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                            <UpdateUserPermission data={data} handleChange={handleChange} />
                                                        </Tab>
                                                    })}
                                                </Tabs>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                                         {/* PAyroll section Section */}
                            {userData?.find(event => event.parentId === 37) ?
                                <>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>Role Management Section</Accordion.Header>
                                        <Accordion.Body>
                                        <div className="user-permission">
                                                <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example" onSelect={(moduleId) => handleSelect(moduleId)}>
                                                    {userData?.filter(event => event.parentId === 37).map((data, index) => {
                                                        return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                            <UpdateUserPermission data={data} handleChange={handleChange} />
                                                        </Tab>
                                                    })}
                                                </Tabs>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                        </Accordion>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default UpdateUserRole