import { Row,Col } from "react-bootstrap";
import Wrapper from "../../Wrapper";
import SelectAccount from "./SelectAccount";
import PaymentDetails from "./PaymentDetails";

 const EnterABankDeposit = ()=>{
     return (
         <>
         <Wrapper>
            <h5 className="ml-3">Enter a bank deposit</h5>
            <Row className="m-0 pt-3">
                <Col sm={7}>
                    <SelectAccount/>
                </Col>
                <Col sm={5}>
                    <PaymentDetails/>
                </Col>
            </Row>
         </Wrapper>
         </>
     );
 }
 export default EnterABankDeposit;