import { apiSlice } from "../../app/services/authServices";



export const cycleListApiSlice = apiSlice.injectEndpoints({
    tagTypes: [],
    endpoints: builder => ({

        getCycleListInfo: builder.query({
            query: () => ({
                url: 'Order/GetCycleList',
                method: 'GET',
                keepUnusedDataFor: 0,
                // providesTags: [],
            }),
        }),

    })
})
export const { useGetCycleListInfoQuery } = apiSlice;

