import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Delete, Edit, Save, AddCircle } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { useGetVehicleQuery } from "../../../../../features/vehicle/vehicleDetailSlice";
import { useUpdateVehicleDataMutation, useDeleteVehicleDataMutation, addVehicle, editVehicle, useAddVehicleSurchargeAfterEditMutation } from "../../../../../features/vehicle/vehicleDetailSlice";
import { useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../../../Sidebar/Permission";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetAllVehicleRateTypeQuery } from "../../../../../features/vehicle/vehicledespSlice";
import useDeleteData from "../../../../Customer/useDeleteData";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const EditVehicleSurcharge = (props) => {
    const [permission] = useCustomData()
    const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
    // const { data: vehicleList } = useGetVehicleQuery()
    const [updateVehicle] = useUpdateVehicleDataMutation()
    const [deleteVehicle] = useDeleteVehicleDataMutation()
    const [modifyHistory] = useModifyHistoryMutation()
    const { vehicleSurchargeList, singleAccount, handleAccount, vehicleLists, getDefaultCard } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [message, setMessage] = useState("Modified the vehicle surcharge section")
    const [isEditItem, setIsEditItem] = useState(null);
    const [isDelete, setIsDelete] = useState()
    const [vehicleData, setVehicleData] = useState({});
    const [showSecondFields, setShowSecondFields] = useState(false);
    const [editId, setEditId] = useState('')
    const [vehicleOpen, setVehicleOpen] = useState(false);
    const handleVehicleOpen = (data) => { setVehicleOpen(true);setIsDelete(data) };
    const handleVehicleClose = () => { setVehicleOpen(false); };
    const prevCount = usePrevious(vehicleData);
    const { data: getVehicleType, refetch } = useGetAllVehicleRateTypeQuery()
    const [selected, setSelected] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const dispatch = useDispatch();
    const [addVehicleSurchargeAfterEdit] = useAddVehicleSurchargeAfterEditMutation()
    const [handleClose, handleOpen, open, deleteData] = useDeleteData(deleteVehicle)

    // Add and update vehicle list 
    const addVehicleList = async (vehicleSurchargefield) => {
        // e.preventDefault();
        if (Object.keys(vehicleSurchargefield).length === 0) {
            setShowSecondFields(showSecondFields => !showSecondFields);
            return;
        }
        const result = await addVehicleSurchargeAfterEdit({...vehicleSurchargefield, rateCardID: props.selectedCard })
        if (result.data.statusCode === 200) {
            toast.success("Vehicle Surcharge section Added successfully !", {
                position: "top-right",
              })
            dispatch(addVehicle(vehicleSurchargefield));
            getDefaultCard(props.selectedCard)
        }
        setIsUpdate(false)
        reset({BaseRateType:'',BaseRate:'',AfterHourPercent:'',VehicleType:'', PieceInlcuded:"" ,BaseRateType:"",CrossoverRateType:"", CrossoverRate:"",PieceRate:"", WeightRate:"",
         WeightIncluded:"", WeightRange:"",WeightCalcType:"",WaitingTimeUnitRate:"",WaitingtimeUnit:"", FreeWaitingTime:"", PieceRateType:"", WeightRateType:"", WaitingTimeUnitRateType:""});
        setSelected({});
    };

    useEffect(() => {
        setVehicleData(vehicleData)
        reset(vehicleData)
    },[vehicleData])

    
    const Vehicleupdate = (e) => { setVehicleData(vehicleData => ({ ...vehicleData, [e.target.name]: e.target.value })) }

    const EditvehicleItem = (rateCardVehicleID) => {
        setIsUpdate(true)
        setEditId(rateCardVehicleID)
        let newEditItem = vehicleLists.find((data) => {
            return data.RateCardVehicleID === rateCardVehicleID
        });
        let editVehicle = getVehicleType.data.find((data) => {
            return data.vehicleType === newEditItem.VehicleType
        })
        setSelected(editVehicle);
        setVehicleData(newEditItem);
        setShowSecondFields(showSecondFields => !showSecondFields)
        setIsEditItem(rateCardVehicleID);
        reset(vehicleData)
    }

    const editCustomerList = async (vehicleData) => {
        if (JSON?.stringify(prevCount) === JSON?.stringify(vehicleData)) {
            setTimeout(function () {
                toast.info("No Changes found on this vehicle surcharge section !", {
                    position: "top-right",
                })
            });
            reset({ BaseRateType: '', BaseRate: '', VehicleType: '', PieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
            WeightIncluded: "", WeightRange:"", WeightCalcType: "", WaitingTimeUnitRate: "", WaitingtimeUnit: "", FreeWaitingTime: "", PieceRateType: "", WeightRateType: "", WaitingtimeUnit: "", 
            WaitingTimeUnitRateType: "", AfterHourPercent: ""})
        }
        else {
            const mapVehicleData = { ...vehicleData, rateCardVehicleID: vehicleData.RateCardVehicleID }
            const updateVehicleData = await updateVehicle(mapVehicleData)
            setVehicleData("");
            // handleAccount(account?.account_No)
            if (updateVehicleData.data.statusCode === 200) {
                setTimeout(function () {
                    toast.success("Vehicle surcharge section updated successfully !", {
                        position: "top-right",
                    })
                });
                dispatch(editVehicle(vehicleData));
                reset({ BaseRateType: '', BaseRate: '', VehicleType: '', PieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
                    WeightIncluded: "", WeightRange:"", WeightCalcType: "", WaitingTimeUnitRate: "", WaitingtimeUnit: "", FreeWaitingTime: "", PieceRateType: "", WeightRateType: "", WaitingtimeUnit: "", 
                    WaitingTimeUnitRateType: "", AfterHourPercent: ""})
                setIsUpdate(false)
            }
        }
        if (JSON?.stringify(prevCount) !== JSON?.stringify(vehicleData)) {
            const modifieddata = {
                AccountNo: account.account_No,
                CustomerName: account.customer,
                Modifiedsection: message
            }
            await modifyHistory(modifieddata)
        }
        reset({
            BaseRateType: '', BaseRate: '', VehicleType: '', PieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
            WeightIncluded: "", WeightRange:"", WeightCalcType: "", WaitingTimeUnitRate: "", WaitingtimeUnit: "", FreeWaitingTime: "", PieceRateType: "", WeightRateType: "", WaitingTimeUnitRateType: ""
        });
    }

    const deleteVehicleCustomerList = async () => {
        //console.log("id",isDelete);
        const deleteVehicleRecord = await deleteVehicle(isDelete)
        if (deleteVehicleRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Vehicle surcharge record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleVehicleClose();
    }


    const [status, setStatus] = useState(0);
    const [statusc, setStatusc] = useState(0);
    const [statusp, setStatusp] = useState(0);
    const [statusw, setStatusw] = useState(0);
    const [statuswt, setStatuswt] = useState(0);
    const radioHandler = (status) => { setStatus(status) };
    const radioHandlerc = (statusc) => { setStatusc(statusc) };
    const radioHandlerp = (statusp) => { setStatusp(statusp) };
    const radioHandlerw = (statusw) => { setStatusw(statusw) };
    const radioHandlerwt = (statuswt) => { setStatuswt(statuswt) };

    // Delete vehicle
    const deleteDVehicle =  () => {
        deleteData(); 
        getDefaultCard(props.selectedCard); 
        handleClose();
      }

    return (
        <>
            {/* Vehicle surcharge section*/}
            <Row className="mb-2 mt-2">
                <Col sm={12}>
                    <fieldset className="pb-2">
                        { isUpdate?
                        <div className="section_action_btn">
                            {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Update') !== -1 ?
                                <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(editCustomerList)}><Save /></button></div>
                                : <div className="save_btn disable"><button type="button" className="btn" ><Save /></button></div>}
                        </div> :
                        <div className="section_action_btn">
                            <> {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Add') !== -1 ?
                                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addVehicleList)}><AddCircle /></button></div>
                                : <div className="add_btn disable"><button type="button" className="btn"><AddCircle /></button></div>}
                            </>
                        </div> }
                        {showSecondFields ?
                            <div className="form-bg">
                                <Row className="mt-2 mb-3">
                                    <Col sm={6}>
                                        <Controller
                                            name="VehicleType"
                                            control={control}
                                            rules={{
                                                required: "Field is requried"
                                            }}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <>
                                                        <Autocomplete
                                                            value={
                                                                value
                                                                    ? getVehicleType?.data.find((option) => {
                                                                        return value === option.vehicleType;
                                                                    }) ?? null
                                                                    : null
                                                            }
                                                            isOptionEqualToValue={(option, value) => option?.vehicleType === value?.vehicleType}
                                                            getOptionLabel={(option) => option?.vehicleType || ""}
                                                            onChange={(event, newValue) => {setSelected(newValue);
                                                                onChange(newValue ? newValue.vehicleType : null);
                                                            }}
                                                            
                                                            id="controllable-states-demo"
                                                            options={getVehicleType?.data}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Vehicle Type"
                                                                    inputRef={ref}
                                                                />
                                                            )}
                                                        />
                                                        {error ? (
                                                            <span style={{ color: "red" }}>{error.message}</span>
                                                        ) : null}
                                                    </>
                                                );
                                            }}
                                        />
                                            </Col>
                                                {selected?.rateType &&
                                                <Col sm={3}>
                                                    <Form.Group controlId="Afterhour">
                                                        <FloatingLabel controlId="floatingInput" label="After Hour Percent" >
                                                            <Form.Control type="number" placeholder="After Hour Percent" name="AfterHourPercent" onChange={onChange}
                                                                {...register('AfterHourPercent', {required: 'Field is required'})} min='0' />

                                            </FloatingLabel>
                                            {errors.AfterHourPercent?.message && (<p className="error-message">{" * " + errors.AfterHourPercent?.message}</p>)}
                                        </Form.Group>
                                    </Col> }
                                </Row>
                                { selected?.rateType === 'LOCAL' &&
                                <Row className="mt-2 mb-3">
                                    <Col sm={6}>
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Base Rate Section</label>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Choose the base rate type</label>
                                                        <div className="floating-checbox">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="BaseRateType" {...register("BaseRateType")} onChange={e => radioHandler(1)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="BaseRateType" {...register("BaseRateType")} onChange={e => radioHandler(2)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    {status === 0 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="BaseRate" {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="Baserate">Base Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.BaseRate?.message}</p>
                                                        </div>
                                                    }
                                                    {status === 1 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="BaseRate"  {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="Baserate">Base Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.BaseRate?.message}</p>
                                                        </div>
                                                    }
                                                    {status === 2 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="BaseRate"  {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="Baserate">Base Rate (%)</label>
                                                            </div>
                                                            <p className="error-message">{errors.BaseRate?.message}</p>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Crossover Rate Section</label>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Choose the crossover rate type</label>
                                                        <div className="floating-checbox" name="CrossoverRateType">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="CrossoverRateType" {...register("CrossoverRateType")} onChange={e => radioHandlerc(1)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="CrossoverRateType" {...register("CrossoverRateType")} onChange={e => radioHandlerc(2)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    {statusc === 0 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="CrossoverRate">Crossover Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                        </div>
                                                    }
                                                    {statusc === 1 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="CrossoverRate">Crossover Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                        </div>
                                                    }
                                                    {statusc === 2 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="CrossoverRate">Crossover Rate (%)</label>
                                                            </div>
                                                            <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                        </div>
                                                    }
                                                </Col>

                                            </Row> 
                                        </fieldset>
                                    </Col>
                                </Row> }
                                { selected?.rateType === 'LOCAL' &&
                                <Row className="row m-0">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Piece Rate Section</label>
                                        <Row>
                                            <Col sm={4}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Choose the piece rate type</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="PieceRateType"  {...register("PieceRateType")} onChange={e => radioHandlerp(1)} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="PieceRateType"  {...register("PieceRateType")} onChange={e => radioHandlerp(2)} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={4}>
                                                {statusp === 0 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="PieceRate"  {...register('PieceRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="PieceRate">Piece Rate($)</label>
                                                        </div>
                                                        <p className="error-message">{errors.PieceRate?.message}</p>
                                                    </div>
                                                }
                                                {statusp === 1 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="PieceRate">Piece Rate($)</label>
                                                        </div>
                                                        <p className="error-message">{errors.PieceRate?.message}</p>
                                                    </div>
                                                }
                                                {statusp === 2 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="PieceRate">Piece Rate (%)</label>
                                                        </div>
                                                        <p className="error-message">{errors.PieceRate?.message}</p>
                                                    </div>
                                                }
                                            </Col>
                                            <Col sm={4}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Piece Included" name="PieceInlcuded"   {...register('PieceInlcuded', { required: 'Field is required' })} min='0' />
                                                    <label htmlFor="PieceIncluded">Piece Included</label>
                                                </div>
                                                <p className="error-message">{errors.PieceInlcuded?.message}</p>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                                { selected?.rateType === 'LOCAL' &&
                                <Row className="row m-0">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Weight Rate Section</label>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Choose the weight rate type</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="WeightRateType" {...register("WeightRateType")} onChange={e => radioHandlerw(1)} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="WeightRateType" {...register("WeightRateType")} onChange={e => radioHandlerw(2)} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={3}>
                                                {statusw === 0 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Weight Rate" name="WeightRate"    {...register('WeightRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WeightRate">Weight Rate ($)</label>
                                                        </div>
                                                        <p className="error-message">{errors.WeightRate?.message}</p>
                                                    </div>
                                                }
                                                {statusw === 1 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Weight Rate" name="WeightRate"    {...register('WeightRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WeightRate">Weight Rate ($)</label>
                                                        </div>
                                                        <p className="error-message">{errors.WeightRate?.message}</p>
                                                    </div>
                                                }
                                                {statusw === 2 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Weight Rate" name="WeightRate"    {...register('WeightRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WeightRate">Weight Rate (%)</label>
                                                        </div>
                                                        <p className="error-message">{errors.WeightRate?.message}</p>
                                                    </div>
                                                }
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Included" name="WeightIncluded"
                                                        {...register('WeightIncluded', { required: 'Field is required' })} required min='0' />
                                                    <label htmlFor="WeightIncluded" >Weight Included</label>
                                                </div>
                                                {errors.WeightIncluded?.message && (<p className="error-message">{" * " + errors.WeightIncluded?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Range" name="WeightRange"
                                                        {...register('WeightRange', { required: 'Field is required' })} required min='0' />
                                                    <label htmlFor="WeightRange">Weight Range</label>
                                                </div>
                                                {errors.WeightRange?.message && (<p className="error-message">{" * " + errors.WeightRange?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating" >
                                                    <Form.Select aria-label="Floating label select example" name="WeightCalcType"  {...register('WeightCalcType', { required: 'Field is required' })}>
                                                        <option>Select</option>
                                                        <option value="Total">Total</option>
                                                        <option value="Average">Average</option>
                                                    </Form.Select>
                                                    <label htmlFor="WeightCalcType">Weight Calc Type</label>
                                                </div>
                                                {errors.WeightCalcType?.message && (<p className="error-message">{" * " + errors.WeightCalcType?.message}</p>)}
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                                { selected?.rateType === 'LOCAL' &&
                                <Row className="row m-0">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Waiting Time Unit Section</label>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Choose the waiting time unit rate</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="WaitingTimeUnitRateType" {...register("WaitingTimeUnitRateType")} onChange={e => radioHandlerwt(1)} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="WaitingTimeUnitRateType" {...register("WaitingTimeUnitRateType")} onChange={e => radioHandlerwt(2)} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={3}>
                                                {statuswt === 0 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="WaitingTimeUnitRate"   {...register('WaitingTimeUnitRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate ($)</label>
                                                        </div>
                                                        <p className="error-message">{errors.WaitingTimeUnitRate?.message}</p>
                                                    </div>
                                                }
                                                {statuswt === 1 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="WaitingTimeUnitRate"   {...register('WaitingTimeUnitRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate ($)</label>
                                                        </div>
                                                        <p className="error-message">{errors.WaitingTimeUnitRate?.message}</p>
                                                    </div>
                                                }
                                                {statuswt === 2 &&
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="WaitingTimeUnitRate"   {...register('WaitingTimeUnitRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate (%)</label>
                                                        </div>
                                                        <p className="error-message">{errors.WaitingTimeUnitRate?.message}</p>
                                                    </div>
                                                }
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Group controlId="Free Waiting time">
                                                    <FloatingLabel controlId="floatingInput" label="Free Waiting time" >
                                                        <Form.Control type="number" placeholder="Free Waiting time" name="FreeWaitingTime" {...register('FreeWaitingTime', { required: 'Field is required' })} required />
                                                    </FloatingLabel>
                                                    <p className="error-message">{errors.FreeWaitingTime?.message}</p>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={3}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Waiting Time Unit" name="WaitingtimeUnit"   {...register('WaitingtimeUnit', { required: 'Field is required' })} required />
                                                    <label htmlFor="WaitingtimeUnit">Waiting Time Unit</label>
                                                </div>
                                                <p className="error-message">{errors.WaitingtimeUnit?.message}</p>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                            </div>
                            : null}
                        {/* table */}
                        <Row>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Vehicle Type</th>
                                        <th>After Hour Percent</th>
                                        <th>BaseRateType</th>
                                        <th>BaseRate</th>
                                        <th>CrossoverRateType</th>
                                        <th>CrossoverRate</th>
                                        <th>PieceRateType</th>
                                        <th>PieceRate</th>
                                        <th>Piece Included</th>
                                        <th>Weight Rate Type</th>
                                        <th>Weight Rate</th>
                                        <th>Weight Included</th>                                                                           
                                        <th>Weight Range</th>
                                        <th>Weight Calc Type</th>
                                        <th>Waiting time unit rate type</th>
                                        <th>Waiting time unit rate</th>
                                        <th>Waiting time unit</th>
                                        <th>Free Waiting time</th>
                                        
                                        
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {vehicleLists?.length > 0 ? (

                                        vehicleLists.map((data, index,) => {

                                            return (
                                                <tr key={data?.RateCardVehicleID}>
                                                    <td>
                                                        <div className="action_btn">
                                                            {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Update') !== -1 ?
                                                                <span className="edit_btn" onClick={() => EditvehicleItem(data?.RateCardVehicleID)}><Edit /></span>
                                                                : <span className="edit_btn disable"><Edit /></span>}
                                                            {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                <span className="del_btn" onClick={() => handleVehicleOpen(data?.RateCardVehicleID)} ><Delete /></span>
                                                                : <span className="del_btn disable"><Delete /></span>}
                                                            <div>
                                                                <Dialog open={vehicleOpen} onClose={handleVehicleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        Delete Confirmation
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText id="alert-dialog-description">
                                                                            Are you sure you want to delete this row?
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button className="btn-secondary" onClick={handleVehicleClose}>Cancel</Button>
                                                                        <Button className="btn-primary" onClick={() => { deleteVehicleCustomerList();  }}  autoFocus> Confirm</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{data.VehicleType}</td>
                                                    <td>{data.AfterHourPercent}</td>
                                                    <td>{data.BaseRateType}</td>
                                                    <td>{data.BaseRate}</td>
                                                    <td>{data.CrossoverRateType}</td>
                                                    <td>{data.CrossoverRate}</td>
                                                    <td>{data.PieceRateType}</td>
                                                    <td>{data.PieceRate}</td>
                                                    <td>{data.PieceInlcuded}</td>
                                                    <td>{data.WeightRateType}</td>
                                                    <td>{data.WeightRate}</td>
                                                    <td>{data.WeightIncluded}</td>                                                                                                      
                                                    <td>{data.WeightRange}</td>
                                                    <td>{data.WeightCalcType}</td>
                                                    <td>{data.WaitingTimeUnitRateType}</td>
                                                    <td>{data.WaitingTimeUnitRate}</td>
                                                    <td>{data.WaitingtimeUnit}</td>
                                                    <td>{data.FreeWaitingTime}</td>
                                                    
                                                    
                                                    
                                                </tr>
                                            )
                                        })

                                    ) : (
                                        <p>No data found </p>
                                    )}
                                </tbody>
                            </Table>
                        </Row>
                    </fieldset>
                </Col>
            </Row>

           
        </>
    );
}
export default EditVehicleSurcharge;