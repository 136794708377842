import React from 'react'
import { Col } from 'react-bootstrap';

const Outbound = () => {
  return (
    <Col sm={8}>
    <div className="">
    </div>
</Col>
  )
}

export default Outbound;