import { useState , useEffect } from "react"
import {useGetCountryQuery, useLazyGetStateQuery,useLazyGetCityListQuery, useGetProvinceWithoutCountryQuery, useGetCityWithoutProvinceQuery, useLazyGetProvinceByCityQuery, useLazyGetCountryByProvinceQuery, useLazyGetUniqueCityQuery} from "../features/countryState/countryStateSlice"

const CountryProvince = () => {
    const { data: country } = useGetCountryQuery()
    const { data: province } = useGetProvinceWithoutCountryQuery()
    const { data: city} = useGetCityWithoutProvinceQuery()
    const [selectState, setSelectState] = useState("Select")
    const [selectCity, setSelectCity] = useState("Select")
    const [fetchProvince] = useLazyGetProvinceByCityQuery();
    const [fetchCountry] = useLazyGetCountryByProvinceQuery();
    const [fetchUniqueCity] = useLazyGetUniqueCityQuery();
    const getState = province;
    const getCity = city; 
    
    const handlecountry = async (e) => {
        const addressData = e.target.value;
        await getState(addressData)
        setSelectState("Select")
    }
    const handleCity = async (e) => {
        const addressData = e.target.value;
        await getCity(addressData)
        setSelectCity("Select")
    }

    const handleProvince = async (cityId) => {
        const province = await fetchProvince(cityId);
        return province;
    }

    const handleUniqueCity = async ({cityName, provinceName}) => {
        const id = await fetchUniqueCity({cityName, provinceName});
        return id;
    }
    
    const newHandleCountry = async (provinceId) => {
        const country = await fetchCountry(provinceId);
        return country;
    }
    
    return{selectState, setSelectState, handlecountry, province, handleCity, city,selectCity, setSelectCity, country, handleProvince, newHandleCountry, handleUniqueCity}

}
export default CountryProvince