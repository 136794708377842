import { useState } from "react"

export const useCustomData = () =>{
const [permission , setState]=useState(JSON.parse(localStorage.getItem("Permission")));

return [permission]

}

export const permission = JSON.parse(localStorage.getItem("Permission"))
