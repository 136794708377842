import { Col, Container, Row } from "react-bootstrap";
import Wrapper from "../../../Wrapper";
import AddUserRole from "./AddUserRole";
import AssignedUserRole from "./AssignedUserRole";


 const UserRole = ()=>{
     return (
         <>
         <Wrapper>
            <div className="user-role-management">
            <Container fluid>
            <Row>
                <Col sm={12}>
                <AddUserRole/>
                </Col>
                <Col sm={12}>
                <AssignedUserRole />
                </Col>
            </Row>
            </Container>
            </div>
         </Wrapper>
         </>
     );
 }
 export default UserRole;