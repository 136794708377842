import { Row, Col, Modal, FloatingLabel } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { SpeakerNotes } from "@material-ui/icons";
import useOrder from "../../../context/OrderContext";
import { useGetAccountListQuery } from "../../../features/account/accountListSlice";

const PersionalInfoNotes = () => {
  const { data: accountdata } = useGetAccountListQuery();
  const { Pickuphandler , accountInfo } = useOrder();
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <div onClick={() => setIsShow(true)}>
        <div><SpeakerNotes /> Notes</div>
      </div>
      <Modal
        size="md"
        show={isShow}
        onHide={() => setIsShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
        id="product-desp"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            {" "}
            Account Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
              <Row>
                <Col sm={12}>
                  <FloatingLabel controlId="floatingTextarea2" label="Comments" >
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: '100px' }}
                      name="locationNotes" 
                      value={!accountInfo || accountInfo?.locationNotes === "null"? "" : accountInfo.locationNotes } onChange={e => { Pickuphandler(e); }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
          

          </>

        </Modal.Body>
      </Modal>
    </>
  );
};
export default PersionalInfoNotes;
