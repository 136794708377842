import { useState } from "react"
import { Button, Form, FloatingLabel, } from "react-bootstrap"
import { Note } from "@material-ui/icons"

const Notes = () => {
    const [showNotes, setShowNotes] = useState(false)
    const showHandler = () => {
        setShowNotes(showNotes => !showNotes);
    }
    return (
        <>
            <div className="abc">
                <div className="add-role-button d-flex" onClick={showHandler}>
                    <span ><Button variant="primary" style={{ width: "100px" }}><Note/> Notes</Button></span>
                </div>
                {showNotes ?
                    <div className="show-notes">
                        <FloatingLabel controlId="floatingTextarea2" label="Type your comment here...">
                            <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '100px' }} name="Tittle" />
                        </FloatingLabel>
                        <div className="add-role-button d-flex mt-2">
                            <span ><Button variant="primary" style={{ width: "100px" }}> Add Notes</Button></span>
                        </div>
                        {/* List of comment here */}
                        <div className="comment-list mt-2 pt-2">
                            <ul>
                                <li className="mb-4">
                                    <p>Received payment  - Send it to all account</p>
                                    <div className="date-time">
                                        <span>Nov 23, 2023</span>
                                        <span> 09:15 PM</span>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <p>Received payment  - Send it to all account</p>
                                    <div className="date-time">
                                        <span>Nov 23, 2023</span>
                                        <span> 09:15 PM</span>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <p>Received payment  - Send it to all account</p>
                                    <div className="date-time">
                                        <span>Nov 23, 2023</span>
                                        <span> 09:15 PM</span>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <p>Received payment  - Send it to all account</p>
                                    <div className="date-time">
                                        <span>Nov 23, 2023</span>
                                        <span> 09:15 PM</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p className="view-more">+ View More</p>
                    </div>
                    : ""}
            </div>
        </>
    )
}
export default Notes