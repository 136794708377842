import {Col, Row, Form,Table,Button} from "react-bootstrap";
import { useState } from "react";
import { AddCircle,Save, Delete, Edit} from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { useDispatch } from "react-redux";
import { addContact , editContact, deleteContact} from "../../../features/contactDetails/contactDetailsSlice";
import {useForm, Controller} from 'react-hook-form';
import InputMask from "react-input-mask";
import { useCustomData } from "../../Sidebar/Permission";


const ContactInfo = ()=>{
  const [permission] = useCustomData()
  const { register, handleSubmit, watch, formState: { errors }, reset, control, onChange} = useForm();
  const dispatch  = useDispatch();
  const {contactLists,setTempDisplay} = useCustomer()
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [contactList, setContactList] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [deleteItem, setDeleteItem] = useState();
  const handleClickOpen = (data) => {setOpen(true); setDeleteItem(data)};
  const handleClose = () => { setOpen(false);};



let subscribe = watch()

const onSubmit = (contactList) => {
  setContactList(contactList)
  // return
  if (Object.keys(contactList).length === 0) {
    setIsShow(isShow => !isShow);
}
else if (editingTaskIndex === null) {
  setTempDisplay(false)
  dispatch(addContact(contactList));
} else {
  let editingItem = contactLists[editingTaskIndex];
  editingItem = contactList;
  dispatch(editContact(editingItem));
}
setEditingTaskIndex(null);
setIsUpdate(false)
reset({EmailID:'',ContactNumber:'',ContactName:''});
}


// Edit vehicle list
const editContactList = (id) => {
    let newEditItem = contactLists.find((data) => {
        return data.id === id
    });
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
   setContactList(newEditItem)
   setEditingTaskIndex(id);
   let data ={
    
   }
   reset(contactList)
}

const confirmDelete = () => {
  dispatch(deleteContact(deleteItem));
  handleClose();
}

return(
<>
{/* <p> Data: {JSON.stringify(contactList)}</p> */}
  <>
  <Row className="mb-4 mt-5">
       <Col sm={12}>  
   <div className="section_action_btn">
   {!isUpdate ? 
      <>
   {permission?.find(event => event.moduleId === 39)?.permissionType?.indexOf('Add') !== -1 ? 
    <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)}><AddCircle/></button></div>
    :<div className="add_btn disable"><button type="button" className="btn"><AddCircle/></button></div>
    }
    </>
    : 
    <>
    <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)}><Save/></button></div>
    </>
     }
     </div>
     {isShow ? 
    <Row className="mt-2 mb-3">
    <Col sm={4}>
    <div className="form-floating">
    <Form.Control type="email" placeholder="Email" name="EmailID" autoComplete="off"
    onChange={onChange}
       {...register('EmailID',{ required: 'Field is required',   pattern: {
        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        message: "Invalid email address"
      } })}/>
      <label htmlFor="contact">Email ID</label>
    </div>
    {errors.EmailID?.message && (<p className="error-message">{" * " + errors.EmailID?.message}</p>)}
    </Col>
    <Col sm={4}>
    <div className="form-floating">
    <Form.Control type="text" placeholder="Email" name="ContactName" autoComplete="off"
    onChange={onChange}
       {...register('ContactName',{ required: 'Field is required',
       pattern: { value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g, message: "Please enter a valid name" }})}/>
      <label htmlFor="contact">Contact Name</label>
    </div>
    {errors.ContactName?.message && (<p className="error-message">{" * " + errors.ContactName?.message}</p>)}
    </Col>
    <Col sm={4}>
    <div className="form-floating">
    <Controller type="text" placeholder="ContactNumber" 
    name="ContactNumber"
    control={control}
    render={({ field: { onChange, value } }) => (
      <InputMask mask="(999) - 999 9999" value={value || ""} onChange={onChange}>
        {(inputProps) => (
          <input
            {...inputProps}
            type="tel"
            className="form-control"
            placeholder="Contact number"
            name="Contact number"
          />
        )}
      </InputMask>
    )}
     {...register("ContactNumber", { required:'Field is required', minLength: {value: 4, message:'Please enter 10 digit contact number' }})} />
    <label htmlFor="contact">Contact Number</label>
    </div>
    {errors.ContactNumber?.message && (<p className="error-message">{" * " + errors.ContactNumber?.message}</p>)}
    </Col>
    </Row>
    : null }
  <Row>
    <Table striped bordered hover responsive>
    <thead>
      <tr>
        <th>Action</th>
        <th>Email ID</th>
        <th>Contact Name</th>
        <th>Contact Number</th>
      </tr>
    </thead>
    <tbody>
      {contactLists.length > 0 ? (
        <>
      {contactLists.map((data,id) => (
        <tr key={data.id}>
        <td>
          <div className="action_btn">
          {permission.find(event => event.moduleId === 39)?.permissionType?.indexOf('Update') !== -1 ? 
            <span className="edit_btn" onClick={() => editContactList(data.id)}><Edit /></span>:<span className="edit_btn disable"><Edit/></span>}
            {permission.find(event => event.moduleId === 39)?.permissionType?.indexOf('Delete') !== -1 ? 
            <span className="del_btn"  onClick={() => handleClickOpen(data?.id)}><Delete /></span> : <span className="del_btn disable"><Delete/></span>}
                <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title">
            Delete Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this row?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
              <Button className="btn-primary" onClick={() => confirmDelete()} autoFocus> Confirm</Button>
            </DialogActions>
          </Dialog>
        </div>
            </div>
        </td>
        <td>{data.EmailID}</td>
        <td>{data.ContactName}</td>         
        <td>{data.ContactNumber}</td>         
        </tr>
        ))}
        </>
      ):(
        <p>No data found </p>
    )}
    </tbody>
  </Table>
    </Row>

  </Col>
  </Row>  
  </>
</>
    );
}
export default ContactInfo;