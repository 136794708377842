import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {  GridToolbar, } from '@mui/x-data-grid-premium';
import Wrapper from "../../Wrapper";
import { useGetHistoryDataQuery } from "../../../features/customer/history/historySlice";
import Spinner from "../../Spinner";
import { Rnd } from "react-rnd";

export default function HistoryManagement() {
  const {data: history, isLoading} = useGetHistoryDataQuery()
  if (!history || isLoading) return <><Spinner /></>;
  
  const columns = [
    { field: "accountNo", headerName: "Account No.", width: 200 },
    { field: "customerName", headerName: "Customer Name", width: 280 },
    { field: "modifiedSection", headerName: "Modified Section", width: 550 },
    { field: "modifiedBy", headerName: "Modified By", width: 260 },
    { field: "modifiedDate", headerName: "Modified Date", width: 260 },
  ];
  
  const rows = history?.data?.map((data) => {
    return {
      id: data?.id,
      accountNo: data?.accountNo,
      customerName: data?.customerName,
      modifiedSection: data?.modifiedSection,
      modifiedBy: data?.modifiedBy,
      modifiedDate: new Date(data?.modifiedDate).toLocaleString('en-GB', { timeZone: 'UTC' }),
    }
  })
  return (
    <>
      <Wrapper>
        <h6>History Management</h6>
        <Rnd default={{x: 10, y: 120,width: 1632,height: 100}} bounds="window" >
        <div className="user-role-table">
          <h6>List of history</h6>
          <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} componentsProps={{
            toolbar: { showQuickFilter: true, },
          }} checkboxSelection />
        </div>
        </Rnd>
      </Wrapper>
    </>
  );
}
