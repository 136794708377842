import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from '@mui/x-data-grid-premium';


const ChequeList = () => {

    const columns = [
     
        { field: "DepositNo", headerName: "Deposit No.", width: 120 },
        { field: "PostDate", headerName: "Post Date", width: 120 },
        { field: "DepositAmount", headerName: "Deposit Amount", width: 140 },
        { field: "Currency", headerName: "Currency", width: 120 },
    ];
    const rows = [
        { id: 1, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 2, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 3, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 4, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 5, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 6, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 7, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 8, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 9, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 10, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 11, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 12, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 13, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 14, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 15, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 16, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 17, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 18, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 19, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
        { id: 20, DepositNo: '785426', PostDate: '22/10/2022', DepositAmount: "32522.98", Currency: "C" },
    ];
    return (
        <>
                <div className="user-role-table">
                {/* <h6>List of Canadian Sales</h6> */}
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} />
                </div>
                </>
        )
}
export default ChequeList;

