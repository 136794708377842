import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from '@mui/x-data-grid-premium';


const UsaSalesTable = () => {

    const columns = [
     
        { field: "Monthly", headerName: "Monthly", width: 90 },
        { field: "Pcs", headerName: "Pcs", width: 100 },
        { field: "Price", headerName: "Price", width: 80 },
        { field: "FuelCharge", headerName: "FuelCharge", width: 120 },
        { field: "GST", headerName: "GST", width: 120 },
        { field: "PST/HST", headerName: "PST/HST", width: 120 },
        { field: "HST", headerName: "HST", width: 120 },
        { field: "Billing", headerName: "Billing", width: 120 },
        { field: "WIP", headerName: "WIP", width: 120 },
        { field: "Sales", headerName: "Sales", width: 120 },
        { field: "Count", headerName: "Count", width: 120 },
        { field: "Total Orders", headerName: "TotalOrders", width: 120 },
        { field: "Accum Sales", headerName: "Accum Sales", width: 120 },
        { field: "Outstanding", headerName: "Outstanding", width: 120 }
    ];
    const rows = [
        { id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },
    { id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },
    { id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },
    { id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },
    { id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },
    { id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },{ id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },{ id: 1, Monthly: 'January', Pcs: '11', Price: "32522.98", FuelCharge: "1234.43", GST: "2012.0", PSTHST: "0.25", HST:"6523.23",Billing:"1111.22",
    WIP:"5656.22", Sales:"1111.22", Count:"1111",TotalOrder:"22222", AccoumSales:"12233", Outstanding:"0.00" },
    ];
    return (
        <>
                <div className="user-role-table">
                <h6>List of USA Sales</h6>
                    {/* <h6>Inventory list by Account</h6> */}
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} componentsProps={{
          toolbar: { showQuickFilter: true, }, }}/>
                </div>
                </>
        )
}
export default UsaSalesTable;

