import { Accordion, Tab, Tabs, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Save } from "@material-ui/icons";
import { Cached } from "@material-ui/icons";
import UserPermission from "./UserPermission";
import { useGetRolesQuery } from "../../../../features/admin/role/createRoleSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetRegisterUserQuery } from "../../../../features/admin/role/createRoleSlice";




const UserFunctionalRole = ({ userData, showhandler ,onChangeUserRole, userRole,isLoading}) => {
    const [changeRole, setChangeRole] = useState("")
    const [isDisable, setIsDisable] = useState()
    const { refetch } = useGetRegisterUserQuery()
    const { data: role} = useGetRolesQuery()
    const moduleID_admin = [31, 32, 33, 34, 35]
    const disableHandler = () => { setIsDisable(!isDisable); }

    const handleChange = (event) => {setChangeRole(changeRole=> ({ ...changeRole, [event.target.name]: event.target.value }))};
    const onSubmit = async() => {
        await onChangeUserRole(changeRole);
        refetch()
        setTimeout(function () {
                    toast.success("Changed the user role !",{
                    position: "top-center",
                  })
        }, 1000);     
       }

       useEffect(() =>{
        setIsDisable(true)
       },[userData])

    return (
        <>
            <div className="role-assignment-name" style={{ marginBottom: "9px" }}>
                <Row>
                    <Col sm={8}>
                        <div className="change-role">
                            <p>ASSIGNED USER ROLE</p>
                            <div className="role-selection">
                            <Form.Group controlId="Roletype">
                                <FloatingLabel controlId="floatingSelectGrid" label="Role">
                                    <Form.Select aria-label="Floating label select example" name="role" value={changeRole.role || " "} onChange={handleChange}>
                                        <option value="">{userRole}</option>
                                        {
                                            role?.data?.map((data, index) => (
                                                <option key={index} >{data}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                            </div>
                            <span className="change-role-btn" onClick={onSubmit} ><button ><Cached/></button> </span>
                        </div>
                    </Col>
                    <Col sm={2}></Col>
                    <Col className="add-role-button-btn">
                        <span onClick={showhandler}><Save /> Edit</span>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col sm={4}>
                    <div className="user-funtional-role">
                        <Accordion defaultActiveKey="0">
                            {/* AR Section */}
                            {userData?.find(event => event.parentId === 1) ?
                            <>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>AR Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 1).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName} >
                                                    <UserPermission data={data} isLoading={isLoading} disableHandler={disableHandler} isDisable={isDisable}/>
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            : "" }
                            {/* Billing Section */}
                            {userData?.find(event => event.parentId === 2) ?
                            <>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Billing Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 2).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} isLoading={isLoading}/>
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            : "" }
                            {/* Customer Section */}
                            {userData?.find(event => event.parentId === 3) ?
                            <>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Customer Section</Accordion.Header>
                                <Accordion.Body>
                                <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 3).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} isLoading={isLoading}/>
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>                                
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                            {/* Dispatch Section */}
                            {userData?.find(event => event.parentId === 4) ?
                            <>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Dispatch Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 4).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                    <UserPermission data={data} />
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                            {/* Report Section */}
                            {userData?.find(event => event.parentId === 5) ?
                            <>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Report Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 5).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} />
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                            {/* Order Section */}
                            {userData?.find(event => event.parentId === 6) ?
                            <>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Order Section</Accordion.Header>
                                <Accordion.Body>
                                    <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 6).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} />
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                            {/* Admin Section */}
                            {userData?.find(event => event.parentId === 7) ?
                            <>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Admin Section</Accordion.Header>
                                <Accordion.Body>
                                <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 7).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} />
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                              {/* Payroll Section */}
                              {userData?.find(event => event.parentId === 36) ?
                            <>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>Payroll Section</Accordion.Header>
                                <Accordion.Body>
                                <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 36).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} />
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                              {/* Role MAangement Section */}
                              {userData?.find(event => event.parentId === 37) ?
                            <>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Role Management Section</Accordion.Header>
                                <Accordion.Body>
                                <div className="user-permission">
                                        <Tabs defaultActiveKey={userData?.moduleId} transition={false} id="noanim-tab-example">
                                        {userData?.filter(event => event.parentId === 37).map((data, index) => {
                                                return <Tab key={index} eventKey={data.moduleId} title={data.moduleName}>
                                                     <UserPermission data={data} />
                                                </Tab>
                                            })}
                                        </Tabs>
                                    </div>
                                
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                            :""}
                            
                        </Accordion>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default UserFunctionalRole