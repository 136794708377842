import { Form,Row,Col,FloatingLabel } from "react-bootstrap"


const Pickup  = () =>{
    return(
        <div className="pickup-section">
            <h6>Pick-up</h6>
            <>
            <Row className="mb-1 mt-3">
                <Col sm={6}>
                <Form.Group controlId="customer">
                <FloatingLabel controlId="floatingInput" label="Customer Name" >
                    <Form.Control type="text" placeholder="Customer Name" name="customer" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={6}>
                <Form.Group controlId="customer">
                <FloatingLabel controlId="floatingInput" label="Customer Name" >
                    <Form.Control type="text" placeholder="Customer Name" name="customer" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm={3}>
                <Form.Group controlId="St#">
                <FloatingLabel controlId="floatingInput" label="St#" >
                    <Form.Control type="text" placeholder="St#" name="St#" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={6}>
                <Form.Group controlId="Street Name">
                <FloatingLabel controlId="floatingInput" label="Street Name" >
                    <Form.Control type="text" placeholder="Street Name" name="StreetName" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={3}>
                <Form.Group controlId="Unit">
                <FloatingLabel controlId="floatingInput" label="Unit" >
                    <Form.Control type="text" placeholder="Unit" name="unit" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm={4}>
                <Form.Group controlId="city">
                <FloatingLabel controlId="floatingInput" label="City" >
                    <Form.Control type="text" placeholder="City" name="City" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group controlId="Province">
                <FloatingLabel controlId="floatingInput" label="Province" >
                    <Form.Control type="text" placeholder="Province" name="Province" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group controlId="Country">
                <FloatingLabel controlId="floatingInput" label="Country" >
                    <Form.Control type="text" placeholder="Country" name="Country" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm={4}>
                <Form.Group controlId="Postal Code">
                <FloatingLabel controlId="floatingInput" label="Postal Code" >
                    <Form.Control type="text" placeholder="Postal Code" name="Postal Code" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group controlId="Contact">
                <FloatingLabel controlId="floatingInput" label="Contact" >
                    <Form.Control type="text" placeholder="Contact" name="Contact" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group controlId="Driver">
                <FloatingLabel controlId="floatingInput" label="Driver" >
                    <Form.Control type="text" placeholder="Driver" name="Driver" />
                   </FloatingLabel>
                </Form.Group>
                </Col>
            </Row>
            {/* Pickup info Section */}
            <div className="pickup-info-section">
                <p>Pick-up Info</p>
                <div className="pick-info">
                <Row className="mb-1">
                    <Col sm={6}>
                    <Form.Group controlId="opentime">
                <FloatingLabel controlId="floatingInput" label="Open Time" >
                    <Form.Control type="time" placeholder="Open Time" name="Opentime" />
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group controlId="opentime">
                <FloatingLabel controlId="floatingInput" label="IN" >
                    <Form.Control type="date" placeholder="IN" name="In" />
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col sm={6}>
                    <Form.Group controlId="opentime">
                <FloatingLabel controlId="floatingInput"  >
                    <Form.Control type="text"/>
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group controlId="opentime">
                <FloatingLabel controlId="floatingInput" label="OUT" >
                    <Form.Control type="date" placeholder="OUT" name="Out" />
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col sm={6}>
                    <Form.Group controlId="appointment">
                <FloatingLabel controlId="floatingInput" label="Appointment" >
                    <Form.Control type="date" placeholder="Appointment" name="appointment" />
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group controlId="eta">
                <FloatingLabel controlId="floatingInput" label="ETA" >
                    <Form.Control type="date" placeholder="ETA" name="ETA" />
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col sm={6}>
                        <div className="form-check">
                                                <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                                                <label title="" className="form-check-label">Tail Gate (p)</label>
                                            </div>
                    </Col>
                    <Col sm={6}>
                    <Form.Group controlId="detention time at pick-up">
                <FloatingLabel controlId="floatingInput" label="Detention time at pick-up" >
                    <Form.Control type="time" placeholder="Detention time at pick-up" name="Detention time at pick-up" />
                   </FloatingLabel>
                </Form.Group>
                    </Col>
                </Row>
                </div>
            </div>
            </>
        </div>
    )
}
export default Pickup