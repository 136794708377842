import { Row, Col, Modal, Table, FloatingLabel } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AddCircle, Edit, Delete, Height } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar, } from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { Save } from "@material-ui/icons";
import { useAddVehicleTypePricingMutation, useDeleteVehiclePricingDataMutation, useGetAllVehicleTypePricingQuery, useGetDivisionListPricingQuery } from "../../../../../features/vehicle/vehicledespSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetVehicleQuery } from "../../../../../features/vehicle/vehicleDetailSlice";

const CreateVehicle = () => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
  const { data: divisionList } = useGetDivisionListPricingQuery()
  const { data: vehicleList, refetch: vehicleData } = useGetVehicleQuery()
  const [addVehicleType] = useAddVehicleTypePricingMutation()
  const { data: getVehicleType, refetch } = useGetAllVehicleTypePricingQuery()
  const [deleteData] = useDeleteVehiclePricingDataMutation()
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)
  const [isAddVehicle, setIsAddVehicle] = useState()
  const [isRowId, setIsRowId] = useState('')
  // const [isChecked, setIsChecked] = useState({})
  const [isChecked, setIsChecked] = useState(true);
  let row_id;

  const onSubmit = async (addVehicleList) => {
    setIsAddVehicle(addVehicleList)
    const addVehicleData = await addVehicleType(addVehicleList)
    refetch()
    if (addVehicleData?.data.statusCode === 200) {
      toast.success(addVehicleData?.data.message, {
        position: "top-center",
      })
    }
    else if (addVehicleData?.data.statusCode === 400) {
      toast.error(addVehicleData?.data.message, {
        position: "top-center",
      })
    }
    
    reset({ vehicleType: '', description: '', divisionId: '', RateType: '' });
    vehicleData()
  }

  const checkHandler = async (e) => {
    let checkedvalue;
    if (e.target.checked) {
      setIsChecked(false);
      checkedvalue = false;
    }
    else {
      setIsChecked(true);
      checkedvalue = true
    }

    const deleteList = await deleteData({
      "vehicleID": row_id,
      "isActive": checkedvalue
    })
    refetch()
  }

  const columns = [
    { field: "vehicleType", headerName: "Vehicle Type", width: 200 },
    { field: "description", headerName: "Description Type", width: 200 },
    { field: "divisionName", headerName: "Division Name", width: 200 },
    { field: "rateType", headerName: "Rate Type", width: 200 },
    // { field: "isActive", headerName: "Status", width: 200 },

    {
      field: "actions",
      headerName: "Disabled",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          row_id = data?.row?.id;
          setIsRowId(data?.row?.id)
          e.stopPropagation();

        };
        const onRemove = (e) => {
          e.stopPropagation();
          setOpen(true);
        };

        return (
          <>
            <div className="action_btn">
              <Col>
                <Col sm={3}>
                  <div className="form-group">
                    <input className="form-check-input" type="checkbox" value="" onChange={(e) => checkHandler(e)} onClick={onClick} />
                    {/* <input className="form-check-input" type="checkbox" checked={isChecked}  onChange={checkHandler} onClick={onClick} />  checked={!data?.row?.isActive} */}
                    <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "17px", marginLeft: "10px" }}>Disable</label>
                  </div></Col>
              </Col>

            </div>
          </>
        )

      },
    },

  ];

  const rows = (getVehicleType?.data || []).map((data) => {
    return {
      id: data?.vehicleID,
      vehicleType: data?.vehicleType,
      description: data?.description,
      divisionName: data?.divisionName,
      rateType: data?.rateType,
    }
  })

  const rateTypes = ['LOCAL', 'CWT', 'PALLET', 'DISTANCE-BASED'];


  return (
    <>
      <Button onClick={() => setIsShow(true)} variant="primary" style={{ width: "20%" }}>
        Create Vehicle
      </Button>
      <Modal
        size="xl"
        show={isShow}
        onHide={() => setIsShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            {" "}
            Add Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <div className="form-floating">
                <Form.Control type="text" placeholder="Vehicle Type" name="vehicleType" {...register('vehicleType', { required: 'Field is required' })} />
                <label htmlFor="Vehicle Type">Vehicle Type</label>
              </div>
            </Col>
            <Col sm={2}>
              <div className="form-floating">
                <Form.Control type="text" placeholder="Vehicle Type" name="description" {...register('description', { required: 'Field is required' })} />
                <label htmlFor="Vehicle Type">Description Type</label>
              </div>
            </Col>
            <Col sm={2}>
              <div className="form-floating">
                <Form.Group controlId="drivernumber">
                  <FloatingLabel controlId="floatingSelectGrid" label="Select division">
                    <Form.Select aria-label="Floating label select example" name="divisionId" {...register('divisionId')}>
                      <option value="">Select</option>
                      {divisionList?.data?.map((data, index) => (
                        <option key={index} value={data?.divisionId} >{data?.divisionName}</option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </div>
            </Col>
            <Col sm={3}>
              <div className="form-floating">
                <Form.Group controlId="rateType">
                  <FloatingLabel controlId="floatingSelectGrid" label="Select Rate Type">
                    <Form.Select aria-label="Floating label select example" name="RateType" {...register('RateType')}>
                      <option value="">Select</option>
                      {rateTypes.map((data, index) => (
                        <option key={index} value={data} >{data}</option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </div>
            </Col>
            <Col sm={2}>
              <div className="section_action_btn">
                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)} ><AddCircle /> Add</button></div>
              </div>
            </Col>
          </Row>
          <div className="user-role-table" style={{ height: "500px" }}>
            <h6>List of Vehicle</h6>
            <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateVehicle;
