import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";


export const serviceSlice = apiSlice.injectEndpoints({
  tagTypes: ['Service'],
  endpoints: builder => ({
    getService: builder.query({
      query: () => ({
        url: 'Customer/GetListOfService',
        method: 'GET',
        providesTags: ['Service'],
      }),
    }),
    getServiceById: builder.query({
      query: (account_No) => ({
        url: `Customer/GetListOfServiceByCustomerId?accountID=${account_No}`,
        method: 'GET',
        providesTags: ['Service'],
      }),
    }),
    updateServiceData: builder.mutation({
      query(data) {
        const { rateCardServiceID, ...body } = data
        return {
          url: `Customer/EditServiceSurchargeSection?RateCardServiceID=${rateCardServiceID}`,
          method: "POST",
          body
        };
      },
    }),
    deleteServiceData: builder.mutation({
      query: (rateCardServiceID) => ({
        url: `Customer/DeleteServiceSurchargeSection?RateCardServiceID=${rateCardServiceID}`,
        method: "POST",
      }),
    }),
    addServiceSurchargeAfterEdit: builder.mutation({
      query: (body) => ({
        url: `Customer/AddCustomerServiceSurchargeAfterEdit`,
        method: "POST",
        body,
      }),
    }),
    createService: builder.mutation({
      query: (body) => ({
        url: `Admin/AddService`,
        method: "POST",
        body,
      }),
    }),
    getServiceDetailsByID : builder.query({
      query:(serviceId) => ({
        url:`Admin/GetServiceDetailById?ServiceId=${serviceId}`,
        method:"GET",
        providesTags:['Service']
      })
    }),
    updateServiceList : builder.mutation({
      query:(data) => ({
        url:'Admin/UpdateService',
        method:"POST",
        body:data,
      }),
      invalidatesTags: ['Service'],
    }),
    deleteServiceList: builder.mutation({
      query: (serviceId) => ({
        url: `Admin/deleteSrevice?ServiceId=${serviceId}`,
        method: "POST",
      }),
    }),
    getAllService: builder.query({
      query: () => ({
        url: 'Admin/GetAllServiceList',
        method: 'GET',
        providesTags: ['Service'],
      }),
    }),

  }),
})

export const { useGetServiceQuery, useUpdateServiceDataMutation, useDeleteServiceDataMutation, useLazyGetServiceByIdQuery, useAddServiceSurchargeAfterEditMutation ,
  useCreateServiceMutation,useLazyGetServiceDetailsByIDQuery,useUpdateServiceListMutation,useDeleteServiceListMutation , useGetAllServiceQuery} = apiSlice

const initialState = {
  list: []
};

const serviceDetailSlice = createSlice({
  name: "serviceDetails",
  initialState,
  reducers: {
    addService: (state, action) => {
      const lists = {
        id: new Date().getTime().toString(),
        list: action.payload,
      };
      state.list.push(lists);
    },
    deleteService: (state, action) => {
      state.list = state.list.filter(
        (data) => data.id !== action.payload
      )
    },
    editService: (state, action) => {
      const currentLists = Array.from(state.list);
      const filterData = state.list.findIndex((list) => list.id === action.payload.id);
      currentLists[filterData] = {
        id: action.payload.id,
        list: action.payload
      }
      return { ...state, list: currentLists };
    },
    resetService: () => initialState,
  },
})

export const { addService, editService, deleteService, resetService } = serviceDetailSlice.actions

export default serviceDetailSlice.reducer