import { Row, Col, Modal, Table, FloatingLabel } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AddCircle, Edit, Delete, Height } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar, } from '@mui/x-data-grid-premium';
import { useCreateAccessorialMutation, useLazyGetAccessorialDetailsByIDQuery, useUpdateAccessorialListMutation, useDeleteAccessorialListMutation, useGetAllAccessorialQuery, useGetAccessorialQuery } from "../../../../../features/accessorial/accessorialDetailSlice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Save } from "@material-ui/icons";
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Createaccessorial = () => {
    const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    let subscribe = watch()
    const { data: accessorialList, refetch } = useGetAllAccessorialQuery()
    const { data: accessor , refetch: accessorialData} = useGetAccessorialQuery()
    const [addAccessorial] = useCreateAccessorialMutation()
    const [deleteAccessorialList] = useDeleteAccessorialListMutation()
    const [updateAccessorialList] = useUpdateAccessorialListMutation()
    const [getAccessorialDetails, Results, isLoading] = useLazyGetAccessorialDetailsByIDQuery()
    const accessorialDetails = Results?.data?.data
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState()
    const handleClose = async () => { setOpen(false); await deleteAccessorialList(deleteId); refetch() };
    const [isShow, setIsShow] = useState(false);
    const [show, setShow] = useState(false)
    const [accessorial, setAccessorial] = useState('')
    const [addAccessorialList, setAddAccessorialList] = useState([])

    useEffect(() => {
        reset(accessorialDetails)
        setAccessorial(accessorialDetails)
    }, [accessorialDetails])

    useEffect(() => {
        if (accessorial) {
            setValue("description", accessorial.description)
        }
    }, [accessorial])

    // const Accessorialhandler = (e) => {
    //     setAddAccessorialList(addAccessorialList => ({ ...addAccessorialList, [e.target.name]: e.target.value }));
    // }

    const onSubmit = async (addAccessorialList) => {
        setAddAccessorialList(addAccessorialList)
        const addData = await addAccessorial(addAccessorialList)
        refetch()
        if (addData?.data.statusCode === 200) {
            toast.success(addData?.data.message, {
                position: "top-center",
            })
        }
        else if (addData?.data.statusCode === 400) {
            toast.error(addData?.data.message, {
                position: "top-center",
            })
        }
        reset({ description: '', useFuel: '', accessorialCode: ''});
        accessorialData()
    }


    const onUpdate = async (addAccessorialList) => {
        setAddAccessorialList(addAccessorialList)
        const updateData = await updateAccessorialList(addAccessorialList);
        setShow(false);
        refetch()
        if (updateData?.data.statusCode === 200) {
            toast.success("Data Updated Sccessfully !", {
                position: "top-center",
            })
        }
        reset({ description: '', useFuel: '', accessorialCode: ''});
        accessorialData()
    }




    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (e) => {
                    e.stopPropagation();
                    getAccessorialDetails(data.row.id)
                    setShow(true)

                };
                const onRemove = (e) => {
                    e.stopPropagation();
                    setDeleteId(data?.id)
                    setOpen(true);
                };

                return (
                    <>
                        <>
                            <div className="action_btn">
                                <span className="edit_btn" onClick={onClick}><Edit /></span>
                                <span className="del_btn" onClick={onRemove}><Delete /></span>
                            </div>
                        </>
                        <>
                            <div>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                        Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                                        <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </>
                    </>
                )

            },
        },
        { field: "accessorialName", headerName: "Accesssorial Name", width: 180 },
        { field: "useFuel", headerName: "Use Fuel", width: 180 },
        { field: "accessorialCode", headerName: "Accessorial Code", width: 180}
    ];

    const rows = accessorialList?.data.map((data) => {
        return {
            id: data?.accessorialID,
            accessorialName: data?.description,
            useFuel: data?.useFuel,
            accessorialCode: data?.accessorialCode,
        }
    })






    return (
        <>
            <Button onClick={() => setIsShow(true)} variant="primary" style={{ width: "20%" }}>
                Create Accessorial
            </Button>
            <Modal
                size="lg"
                show={isShow}
                onHide={() => setIsShow(false)}
                aria-labelledby="example-modal-sizes-title-md"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-md">
                        {" "}
                        Add Accessorial
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" placeholder="Accessorial Name" name="description" {...register('description', { required: 'Field is required' })} />
                                <label htmlFor="Accessorial Name">Accessorial Name</label>
                                <p className="error-message">{errors.description?.message}</p>
                            </div>
                        </Col>
                        <Col sm={3} >
                            <div className="form-floating">
                                <Form.Select aria-label="Floating label select example" name="useFuel" {...register('useFuel', { required: 'Field is required' })}>
                                    <option value="">Select</option>
                                    <option value="true">true</option>
                                    <option value="false">false</option>
                                </Form.Select>
                                <label htmlFor="accessoial">Use Fuel</label>
                            </div>
                            <p className="error-message">{errors.distanceBased?.message}</p>
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" placeholder="Accessorial Code" name="accessorialCode" {...register('accessorialCode', { required: 'Field is required' })} />
                                <label htmlFor="Accessorial Code">Accessorial Code</label>
                                <p className="error-message">{errors.accessorialCode?.message}</p>
                            </div>
                        </Col>
                        <Col></Col>
                        <Col sm={2}>
                            {!show ?
                                <div className="section_action_btn">
                                    <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)} ><AddCircle /> Add</button></div>
                                </div>
                                :
                                <div className="section_action_btn">
                                    <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(onUpdate)} ><Save /> Save</button></div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <div className="user-role-table" style={{ height: "500px" }}>
                        <h6>List of Accessorial</h6>
                        <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
                    </div>
                </Modal.Body>
            </Modal>
            
        </>
    );
};
export default Createaccessorial;
