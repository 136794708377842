import { createSlice } from "@reduxjs/toolkit";
import { registerUser, loginUser } from "./authActions";

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
    loading:false,
    user: null,
    token:null,
    error:null,
    success:false,
}
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{
          setCredentials: (state, action) => {
            state.user = action.payload.data.emailId
            state.token = action.payload.data.token
          },
          logout: (state, action) => {
            localStorage.clear(); // deletes token from storage
            state.user = null
            state.token = null
          },
    },

})

export const {logout, setCredentials} = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token