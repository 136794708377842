import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: []
};

const productDataSlice = createSlice({
    name: "productdata",
    initialState,
    reducers: {
        addProduct: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteProduct: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editProduct : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            return { ...state, list: currentLists };
        },
    },
})

export const { addProduct, editProduct, deleteProduct } = productDataSlice.actions

export default productDataSlice.reducer