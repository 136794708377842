import { apiSlice } from "../../../app/services/authServices";

export const historyApiSlice = apiSlice.injectEndpoints({
    tagTypes:['History'],
    endpoints:builder => ({
      getHistoryData: builder.query({
        query: () => ({
          url: 'Customer/GetModificationHistory',
          method: 'GET',
          providesTags: ['History'],
        }),
      }),
      modifyHistory: builder.mutation ({
        query: (data) => ({
          url:'Customer/AddModificationRecordToHistory',
          method:'POST',
          body:data
        })
      })
    })
})
export const { useGetHistoryDataQuery , useModifyHistoryMutation} = apiSlice
