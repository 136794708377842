import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

const initialState = {
    list: {}
};


export const nameAddressApiSlice = apiSlice.injectEndpoints({
    tagTypes:['Contract'],
    endpoints:builder => ({ 
        updateNameAddressData: builder.mutation({
            query(data) {
                const {account_No, ...body} = data
                return {
                    url: `Customer/EditCustomer?AccountNo=${account_No}`,
                  method: "POST",
                  body
                };
              },
          }),
     
    })
    
})

export const {useUpdateNameAddressDataMutation} = apiSlice

const nameAddressSlice = createSlice({
    name: "nameAddress",
    initialState,
    reducers: {
        addNameAddress(state, action) {
            return {
                ...state,
                list: action.payload
            }
          },
    }
})
export const { addNameAddress } = nameAddressSlice.actions

export default nameAddressSlice.reducer