import React from "react";
import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button } from "react-bootstrap";
import {  useState } from "react";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import {  useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { addVehicle, editVehicle, deleteVehicle } from "../../../../../features/vehicle/vehicleDetailSlice";
import { useForm, Controller } from 'react-hook-form';
import { useGetVehicleQuery } from "../../../../../features/vehicle/vehicleDetailSlice";
import { useCustomData } from "../../../../Sidebar/Permission";
import Createvehicle from "./CreateVehicle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetAllVehicleRateTypeQuery } from "../../../../../features/vehicle/vehicledespSlice";




const VehicleSurcharge = () => {
    const [permission] = useCustomData()
    //const { data: vehicleList } = useGetVehicleQuery()
    const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
    const { vehicleLists } = useCustomer();
    const [isShow, setIsShow] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false);
    const [vehicleSurchargefield, setvehicleSurchargefield] = useState([])
    const [editingTaskIndex, setEditingTaskIndex] = useState(null);
    const [deleteItem, setDeleteItem] = useState()
    const [open, setOpen] = useState(false);
    const handleClickOpen = (data) => { setOpen(true);  setDeleteItem(data)};
    const handleClose = () => { setOpen(false); };
    const dispatch = useDispatch();
    const { data: getVehicleType, refetch } = useGetAllVehicleRateTypeQuery()
    const [selected, setSelected] = useState("");

    const handleVehicleSurchargefield = (e) => {
        setvehicleSurchargefield(vehicleSurchargefield => ({ ...vehicleSurchargefield, [e.target.name]: e.target.value }));
    }

    // Add and update vehicle list 
    const addVehicleList = (vehicleSurchargefield) => {
        // e.preventDefault();
        if (Object.keys(vehicleSurchargefield).length === 0) {
            setIsShow(isShow => !isShow);
        }
        else if (editingTaskIndex === null) {
            dispatch(addVehicle(vehicleSurchargefield));
        } else {
            let editingItem = vehicleLists[editingTaskIndex];
            editingItem = vehicleSurchargefield;
            dispatch(editVehicle(editingItem));
        }
        setvehicleSurchargefield("");
        setEditingTaskIndex(null);
        setIsUpdate(false)
        reset({BaseRateType:'',BaseRate:'',AfterHourPercent:'',VehicleType:'', PieceInlcuded:"" ,BaseRateType:"",CrossoverRateType:"", CrossoverRate:"",PieceRate:"", WeightRate:"",
         WeightIncluded:"",WeightRange:"",WeightCalcType:"",WaitingTimeUnitRate:"",WaitingtimeUnit:"", FreeWaitingTime:"", PieceRateType:"", WeightRateType:"", WaitingTimeUnitRateType:""});
        setSelected({});
    };

    // Edit vehicle list
    const editvehicleList = (id) => {
        let newEditItem = vehicleLists.find((data) => {
            return data.id === id
        });
        let editVehicle = getVehicleType.data.find((data) => {
            return data.vehicleType === newEditItem.VehicleType
        })
        setSelected(editVehicle);
        setIsUpdate(true);
        setIsShow(isShow => !isShow);
        setvehicleSurchargefield(newEditItem)
        setEditingTaskIndex(id);
        reset(vehicleSurchargefield)
    }


    const [status, setStatus] = useState(0);
    const [statusc, setStatusc] = useState(0);
    const [statusp, setStatusp] = useState(0);
    const [statusw, setStatusw] = useState(0);
    const [statuswt, setStatuswt] = useState(0);
    const radioHandler = (status) => { setStatus(status);};
    const radioHandlerc = (statusc) => { setStatusc(statusc) };
    const radioHandlerp = (statusp) => { setStatusp(statusp) };
    const radioHandlerw = (statusw) => { setStatusw(statusw) };
    const radioHandlerwt = (statuswt) => { setStatuswt(statuswt) };
    
    const confirmDelete = () => {
        dispatch(deleteVehicle(deleteItem))
        handleClose();
    }

    return (
        <>
            {/* Vehicle surcharge section*/}
               
                        <Row className="mb-2 mt-2">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <div className="section_action_btn">
                                        <Createvehicle/>
                                        {!isUpdate ?
                                            <> {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Add') !== -1 ?
                                                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addVehicleList)}><AddCircle /></button></div>
                                                : <div className="add_btn disable"><button type="button" className="btn"><AddCircle /></button></div>}
                                            </>
                                            :
                                            <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(addVehicleList)}><Save /></button></div>
                                        }

                                    </div>
                                    {isShow ?
                                        <div className="form-bg">
                                            <Row className="mt-2 mb-3">
                                            <Col sm={6}>
                                        <Controller
                                            name="VehicleType"
                                            control={control}
                                            rules={{
                                                required: "Field is requried"
                                            }}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <>
                                                        <Autocomplete
                                                            value={
                                                                value
                                                                    ? getVehicleType?.data.find((option) => {
                                                                        return value === option.vehicleType;
                                                                    }) ?? null
                                                                    : null
                                                            }
                                                            isOptionEqualToValue={(option, value) => option?.vehicleType === value?.vehicleType}
                                                            getOptionLabel={(option) => option?.vehicleType || ""}
                                                            onChange={(event, newValue) => {setSelected(newValue);
                                                                onChange(newValue ? newValue.vehicleType : null);
                                                            }}
                                                            id="controllable-states-demo"
                                                            options={getVehicleType?.data}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Vehicle Type"
                                                                    inputRef={ref}
                                                                />
                                                            )}
                                                        />
                                                        {error ? (
                                                            <span style={{ color: "red" }}>{error.message}</span>
                                                        ) : null}
                                                    </>
                                                );
                                            }}
                                        />
                                    </Col>
                                                {selected?.rateType &&
                                                <Col sm={3}>
                                                    <Form.Group controlId="Afterhour">
                                                        <FloatingLabel controlId="floatingInput" label="After Hour Percent" >
                                                            <Form.Control type="number" placeholder="After Hour Percent" name="AfterHourPercent" onChange={onChange}
                                                                {...register('AfterHourPercent', {required: 'Field is required'})} min='0' />
                                                        </FloatingLabel>
                                                        {errors.AfterHourPercent?.message && (<p className="error-message">{" * " + errors.AfterHourPercent?.message}</p>)}
                                                    </Form.Group>
                                                </Col>}
                                            </Row>
                                            { selected?.rateType === 'LOCAL' && 
                                            <Row className="mt-2 mb-3">
                                                <Col sm={6}>
                                                    <fieldset className="mb-3">
                                                        <label className="label-heading">Base Rate Section</label>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="fieldset-inner">
                                                                    <label className="label-heading">Choose the base rate type</label>
                                                                    <div className="floating-checbox" onChange={e => handleVehicleSurchargefield(e)}>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="BaseRateType" {...register("BaseRateType")}  onChange={e => radioHandler(1)} />
                                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="BaseRateType" {...register("BaseRateType")} onChange={e => radioHandler(2)} />
                                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                {status === 0 &&
                                                                    <div className="btn-status">
                                                                        <div className="form-floating">
                                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                            <Form.Control type="text" placeholder="Base Rate" name="BaseRateActive" {...register('BaseRateActive',{ required: 'Please select the options'})} disabled/>
                                                                            <label htmlFor="Baserate">Base Rate ($)</label>
                                                                        </div>
                                                                        <p className="error-message">{errors.BaseRateActive?.message}</p>
                                                                    </div>
                                                                }
                                                                {status === 1 &&
                                                                    <div className="btn-status">
                                                                        <div className="form-floating">
                                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                            <Form.Control type="number" placeholder="Base Rate" name="BaseRate"   {...register('BaseRate',{ required: 'Field is required'})} min='0' />
                                                                            <label htmlFor="Baserate">Base Rate ($)</label>                                                                           
                                                                        </div>
                                                                        <p className="error-message">{errors.BaseRate?.message}</p>
                                                                    </div>
                                                                }
                                                                {status === 2 &&
                                                                    <div className="btn-status">
                                                                        <div className="form-floating">
                                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                            <Form.Control type="number" placeholder="Base Rate" name="BaseRate" {...register('BaseRate',{ required: 'Field is required'})} min='0'/>
                                                                            <label htmlFor="Baserate">Base Rate (%)</label>                                                                           
                                                                        </div>
                                                                        <p className="error-message">{errors.BaseRate?.message}</p>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                                <Col sm={6}>
                                                    <fieldset className="mb-3">
                                                        <label className="label-heading">Crossover Rate Section</label>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="fieldset-inner">
                                                                    <label className="label-heading">Choose the crossover rate type</label>
                                                                    <div className="floating-checbox" onChange={e => handleVehicleSurchargefield(e)} name="CrossoverRateType">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="CrossoverRateType" {...register("CrossoverRateType")}  onChange={e => radioHandlerc(1)} />
                                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="CrossoverRateType" {...register("CrossoverRateType")}  onChange={e => radioHandlerc(2)} />
                                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                {statusc === 0 &&
                                                                    <div className="btn-status">
                                                                        <div className="form-floating">
                                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                            <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRateActive" 
                                                                           {...register('CrossoverRateActive',{ required: 'Please select the options'})} disabled />
                                                                            <label htmlFor="CrossoverRate">Crossover Rate ($)</label>                                                                           
                                                                        </div>
                                                                        <p className="error-message">{errors.CrossoverRateActive?.message}</p>
                                                                    </div>
                                                                }
                                                                {statusc === 1 &&
                                                                    <div className="btn-status">
                                                                        <div className="form-floating">
                                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                            <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" 
                                                                            {...register('CrossoverRate',{ required: 'Field is required'})} min='0' />
                                                                            <label htmlFor="CrossoverRate">Crossover Rate ($)</label>                                                                          
                                                                        </div>
                                                                        <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                                    </div>
                                                                }
                                                                {statusc === 2 &&
                                                                    <div className="btn-status">
                                                                        <div className="form-floating">
                                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                            <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" 
                                                                          {...register('CrossoverRate',{ required: 'Field is required'})} min='0' />
                                                                            <label htmlFor="CrossoverRate">Crossover Rate (%)</label>                                                                          
                                                                        </div>
                                                                        <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                                    </div>
                                                                }
                                                            </Col>

                                                        </Row>
                                                    </fieldset>
                                                </Col> 
                                            </Row> }
                                            { selected?.rateType === 'LOCAL' && 
                                            <Row className="row m-0">
                                                <fieldset className="mb-3">
                                                    <label className="label-heading">Piece Rate Section</label>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <div className="fieldset-inner">
                                                                <label className="label-heading">Choose the piece rate type</label>
                                                                <div className="floating-checbox" onChange={e => handleVehicleSurchargefield(e)}>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="PieceRateType" {...register("PieceRateType")} onChange={e => radioHandlerp(1)} />
                                                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="PieceRateType" {...register("PieceRateType")}onChange={e => radioHandlerp(2)} />
                                                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            {statusp === 0 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Piece Rate" name="PieceRateActive" 
                                                                         {...register('PieceRateActive',{ required: 'Please select the options'})} disabled />
                                                                        <label htmlFor="PieceRate">Piece Rate($)</label>                                                                      
                                                                    </div>
                                                                    <p className="error-message">{errors.PieceRateActive?.message}</p>
                                                                </div>
                                                            }
                                                            {statusp === 1 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" 
                                                                          {...register('PieceRate',{ required: 'Field is required'})} min='0' />
                                                                        <label htmlFor="PieceRate">Piece Rate($)</label>                                                                       
                                                                    </div>
                                                                    <p className="error-message">{errors.PieceRate?.message}</p>
                                                                </div>
                                                            }
                                                            {statusp === 2 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" 
                                                                         {...register('PieceRate',{ required: 'Field is required'})} min='0' />
                                                                        <label htmlFor="PieceRate">Piece Rate (%)</label>                                                                   
                                                                    </div>
                                                                    <p className="error-message">{errors.PieceRate?.message}</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className="form-floating">
                                                                <Form.Control type="number" placeholder="Piece Included" name="PieceInlcuded" onChange={onChange}
                                                                  {...register('PieceInlcuded',{ required: 'Field is required'})} min='0'/>
                                                                <label htmlFor="PieceInlcuded">Piece Included</label>                                                               
                                                            </div>
                                                            <p className="error-message">{errors.PieceInlcuded?.message}</p>
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </Row> }
                                            { selected?.rateType === 'LOCAL' && 
                                            <Row className="row m-0">
                                                <fieldset className="mb-3">
                                                    <label className="label-heading">Weight Rate Section</label>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <div className="fieldset-inner">
                                                                <label className="label-heading">Choose the weight rate type</label>
                                                                <div className="floating-checbox" onChange={e => handleVehicleSurchargefield(e)}>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="WeightRateType" {...register("WeightRateType")} onChange={e => radioHandlerw(1)} />
                                                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="WeightRateType" {...register("WeightRateType")} onChange={e => radioHandlerw(2)} />
                                                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={3}>
                                                            {statusw === 0 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Weight Rate" name="WeightRateActive" 
                                                                          {...register('WeightRateActive',{ required: 'Please select the options'})} disabled />
                                                                        <label htmlFor="WeightRate">Weight Rate ($)</label>                                                                      
                                                                    </div>
                                                                    <p className="error-message">{errors.WeightRateActive?.message}</p>
                                                                </div>
                                                            }
                                                            {statusw === 1 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Weight Rate" name="WeightRate"
                                                                         {...register('WeightRate',{ required: 'Field is required'})} min='0' />
                                                                        <label htmlFor="WeightRate">Weight Rate ($)</label>                                                                      
                                                                    </div>
                                                                    <p className="error-message">{errors.WeightRate?.message}</p>
                                                                </div>
                                                            }
                                                            {statusw === 2 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Weight Rate" name="WeightRate"
                                                                         {...register('WeightRate',{ required: 'Field is required'})} min='0' />
                                                                        <label htmlFor="WeightRate">Weight Rate (%)</label>                                                                     
                                                                    </div>
                                                                    <p className="error-message">{errors.WeightRate?.message}</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div className="form-floating">
                                                                <Form.Control type="number" placeholder="Weight Included" name="WeightIncluded"
                                                                {...register('WeightIncluded',{ required: 'Field is required'})} required min='0'/>
                                                                <label htmlFor="WeightIncluded">Weight Included</label>                                                            
                                                            </div>
                                                            <p className="error-message">{errors.WeightIncluded?.message}</p>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div className="form-floating">
                                                                <Form.Control type="number" placeholder="Weight Range" name="WeightRange"
                                                                    {...register('WeightRange', { required: 'Field is required' })} required min='0' />
                                                                <label htmlFor="WeightRange">Weight Range</label>
                                                            </div>
                                                            {errors.WeightRange?.message && (<p className="error-message">{" * " + errors.WeightRange?.message}</p>)}
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div className="form-floating">
                                                                <Form.Select aria-label="Floating label select example" name="WeightCalcType"  {...register('WeightCalcType', { required: 'Field is required' })}>
                                                                    <option>Select</option>
                                                                    <option value="Total">Total</option>
                                                                    <option value="Average">Average</option>
                                                                </Form.Select>
                                                                {/* <Form.Control type="text" placeholder="Weight Calc Type" name="WeightCalcType"
                                                                 {...register('WeightCalcType',{ required: 'Field is required'})} required /> */}
                                                                <label htmlFor="WeightCalcType">Weight Calc Type</label>                                                             
                                                            </div>
                                                            <p className="error-message">{errors.WeightCalcType?.message}</p>
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </Row> }
                                            { selected?.rateType === 'LOCAL' && 
                                            <Row className="row m-0">
                                                <fieldset className="mb-3">
                                                    <label className="label-heading">Waiting Time Unit Section</label>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <div className="fieldset-inner">
                                                                <label className="label-heading">Choose the waiting time unit rate</label>
                                                                <div className="floating-checbox" onChange={e => handleVehicleSurchargefield(e)}>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="WaitingTimeUnitRateType" {...register("WaitingTimeUnitRateType")} onChange={e => radioHandlerwt(1)} />
                                                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="WaitingTimeUnitRateType" {...register("WaitingTimeUnitRateType")}  onChange={e => radioHandlerwt(2)} />
                                                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={3}>
                                                            {statuswt === 0 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                        <Form.Control type="text" placeholder="Waiting Time Unit Rate" name="WaitingTimeUnitRateActive"
                                                                           {...register('WaitingTimeUnitRateActive',{ required: 'Please select the options'})} disabled />
                                                                        <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate ($)</label>                                                                     
                                                                    </div>
                                                                    <p className="error-message">{errors.WaitingTimeUnitRateActive?.message}</p>
                                                                </div>
                                                            }
                                                            {statuswt === 1 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="WaitingTimeUnitRate"
                                                                         {...register('WaitingTimeUnitRate',{ required: 'Field is required'})} min='0' />
                                                                        <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate ($)</label>                                                                   
                                                                    </div>
                                                                    <p className="error-message">{errors.WaitingTimeUnitRate?.message}</p>
                                                                </div>
                                                            }
                                                            {statuswt === 2 &&
                                                                <div className="btn-status">
                                                                    <div className="form-floating">
                                                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                        <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="WaitingTimeUnitRate"
                                                                           {...register('WaitingTimeUnitRate',{ required: 'Field is required'})} min='0' />
                                                                        <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate (%)</label>                                                         
                                                                    </div>
                                                                    <p className="error-message">{errors.WaitingTimeUnitRate?.message}</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col sm={3}>
                                                            <div className="form-floating">
                                                                <Form.Control type="text" placeholder="Waiting Time Unit" name="WaitingtimeUnit"
                                                                {...register('WaitingtimeUnit',{ required: 'Field is required'})} required />
                                                                <label htmlFor="WaitingTimeUnit">Waiting Time Unit</label>                                        
                                                            </div>
                                                            <p className="error-message">{errors.WaitingtimeUnit?.message}</p>
                                                        </Col>
                                                        <Col sm={3}>
                                                        <div className="form-floating">
                                                                <Form.Control type="text" placeholder="Free Waiting time" name="FreeWaitingTime"  {...register('FreeWaitingTime',{ required: 'Field is required'})} required />
                                                                <label htmlFor="WaitingTimeUnit">Free Waiting time</label>                                        
                                                            </div>
                                                            <p className="error-message">{errors.FreeWaitingTime?.message}</p>
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </Row> }

                                        </div>
                                        : null}

                                    {/* table */}
                                    <Row>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Vehicle</th>
                                                    <th>After Hour Percent</th>
                                                    <th>Base Rate Type</th>
                                                    <th>Base Rate</th>
                                                    <th>Crossover Rate Type</th>
                                                    <th>Crossover Rate</th>
                                                    <th>Piece Rate Type</th>
                                                    <th>Piece Rate</th>
                                                    <th>Piece Included</th>
                                                    <th>Weight Rate Type</th>
                                                    <th>Weight Rate</th>
                                                    <th>Weight Included</th>
                                                    <th>Weight Range</th>
                                                    <th>Weight Calc Type</th>
                                                    <th>Waiting Time Unit Rate Type</th>
                                                    <th>Waiting Time Unit Rate</th>
                                                    <th>Waiting Time Unit</th>
                                                    <th>Free Waiting Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vehicleLists?.length > 0 && vehicleLists.map((data, id) => {
                                                    return (
                                                        <tr key={data.id}>
                                                            <td>
                                                                <div className="action_btn">
                                                                        <span className="edit_btn" onClick={() => editvehicleList(data.id)}><Edit /></span>                                                               
                                                                        <span className="del_btn" onClick={() => handleClickOpen(data?.id)}><Delete /></span>
                                                                    <div>
                                                                        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                            <DialogTitle id="alert-dialog-title">
                                                                                Delete Confirmation
                                                                            </DialogTitle>
                                                                            <DialogContent>
                                                                                <DialogContentText id="alert-dialog-description">
                                                                                    Are you sure you want to delete this row?
                                                                                </DialogContentText>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                                                                <Button className="btn-primary" onClick={() => { confirmDelete() }} autoFocus> Confirm</Button>
                                                                            </DialogActions>
                                                                        </Dialog>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{data.VehicleType}</td>
                                                            <td>{data.AfterHourPercent}</td>
                                                            <td>{data.BaseRateType}</td>
                                                            <td>{data.BaseRate}</td>
                                                            <td>{data.CrossoverRateType}</td>
                                                            <td>{data.CrossoverRate}</td>  
                                                            <td>{data.PieceRateType}</td>   
                                                            <td>{data.PieceRate}</td>                                                                                                              
                                                            <td>{data.PieceInlcuded}</td>  
                                                            <td>{data.WeightRateType}</td> 
                                                            <td>{data.WeightRate}</td>                                                                                                                    
                                                            <td>{data.WeightIncluded}</td>
                                                            <td>{data.WeightRange}</td>                                                          
                                                            <td>{data.WeightCalcType}</td>
                                                            <td>{data.WaitingTimeUnitRateType}</td>
                                                            <td>{data.WaitingTimeUnitRate}</td>                                                          
                                                            <td>{data.WaitingtimeUnit}</td>                                                       
                                                            <td>{data.FreeWaitingTime}</td>                                                       
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </Table>
                                    </Row>
                                </fieldset>
                            </Col>
                        </Row>
                 
        </>
    );
}
export default VehicleSurcharge;