import { Col, Form, Row, FloatingLabel } from "react-bootstrap"

const PackageInfo = () => {
    return (
        <div className="package-info-section">
            <p className="section-header">Package Info</p>
            <div className="package-form">
                <>
                    <Row className="mb-1">
                        <Col sm={3}>
                            <Form.Group controlId="vehicle">
                                <FloatingLabel controlId="floatingInput" label="Vehicle" >
                                    <Form.Control type="text" placeholder="Vehicle" name="vehicle" />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="service">
                                <FloatingLabel controlId="floatingInput" label="Service" >
                                    <Form.Control type="text" placeholder="Service" name="service" />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="Pcs">
                                        <FloatingLabel controlId="floatingInput" label="Pcs" >
                                            <Form.Control type="text" placeholder="SKD" name="pcs" />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="pcs">
                                        <FloatingLabel controlId="floatingInput" label="SKD" >

                                            <Form.Select aria-label="Floating label select example" name="Pcs">
                                                <option value="red">Select</option>
                                                <option value="blue">Blue</option>
                                                <option value="green">Green</option>
                                                <option value="black">Black</option>
                                                <option value="orange">Orange</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="Wgt">
                                        <FloatingLabel controlId="floatingInput" label="Wgt" >
                                            <Form.Control type="text" placeholder="Wgt" name="wgt" />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="wgt">
                                        <FloatingLabel controlId="floatingInput" label="L" >

                                            <Form.Select aria-label="Floating label select example" name="L">
                                                <option value="red">Select</option>
                                                <option value="blue">Blue</option>
                                                <option value="green">Green</option>
                                                <option value="black">Black</option>
                                                <option value="orange">Orange</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* 2nd row  */}
                    <div className="package-section2">
                        <div className="flex-container">

                            {/* <div className="flex-item">
                    <Form.Group controlId="Unit">
                            <FloatingLabel controlId="floatingInput" label="Unit" >
                                <Form.Control type="text" placeholder="Unit" name="unit"/>
                            </FloatingLabel>
                        </Form.Group>
                    </div>
                    <div className="flex-item">
                    <Form.Group controlId="Spot">
                            <FloatingLabel controlId="floatingInput" label="Spot" >
                                <Form.Control type="text" placeholder="Spot" name="spot"/>
                            </FloatingLabel>
                        </Form.Group>
                    </div>
                    <div className="flex-item">
                    <Form.Group controlId="FT">
                            <FloatingLabel controlId="floatingInput" label="FT" >
                                <Form.Control type="text" placeholder="FT" name="ft"/>
                            </FloatingLabel>
                        </Form.Group>
                    </div>
                    <div className="flex-item">
                    <Form.Group controlId="Dim Wgt">
                            <FloatingLabel controlId="floatingInput" label="Dim Wgt" >
                                <Form.Control type="text" placeholder="Dim Wgt" name="dim wgt"/>
                            </FloatingLabel>
                        </Form.Group>
                    </div>
                    <div className="flex-item">
                    <Form.Group controlId="Reference">
                            <FloatingLabel controlId="floatingInput" label="Reference" >
                                <Form.Control type="text" placeholder="Reference" name="reference"/>
                            </FloatingLabel>
                        </Form.Group>
                    </div> */}



                            <div className="trailer-container mt-1">
                                <div className="sample">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Unit">
                                        <Form.Control type="text" placeholder="Unit" name="Unit" />
                                    </FloatingLabel>
                                </div>
                                <div className="sample">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Spot">
                                        <Form.Control type="text" placeholder="Spot" name="Spot" />
                                    </FloatingLabel>
                                </div>
                                <div className="sample">
                                    <FloatingLabel controlId="floatingSelectGrid" label="FT">
                                        <Form.Control type="text" placeholder="FT" name="FT" />
                                    </FloatingLabel>
                                </div>
                                <div className="sample">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Dim Wgt">
                                        <Form.Control type="text" placeholder="Dim Wgt" name="Dim Wgt" />
                                    </FloatingLabel>
                                </div>
                                <div className="sample">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Reference">
                                        <Form.Control type="text" placeholder="Reference" name="Reference" />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}
export default PackageInfo