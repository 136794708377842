import { Button, Col, Container, Row } from "react-bootstrap";
import Wrapper from "../../Wrapper";
import '../../../style.css'
import OpenOrderTable from "./OpenOrderTable";
import ActiveTripList from "./ActiveTripList";
import DriverList from "./DriverList";
import Draggable from 'react-draggable';
import { useState } from "react";
import OpenPage from "./OpenPage";
import LoadingPage from "./LoadingPage";
import Outbound from "./Outbound";
import InBound from "./InBound";
import AddActiveDriver from "./AddActiveDriver";

const TripDispatch = () => {
    const [isOpen , setisOpen]=useState(1)
    // const [isOpen, setisOpen] = useState(false)
    // const [isLoading, setisLoading] = useState(false)
    // const [isOutbound, setisOutbound] = useState(false)
    // const [isInbound, setisInbound] = useState(false)
    // const [isAll, setisAll] = useState(false)
    const openHandler=()=>{
        setisOpen(2)
    }

    const loadingHandler=()=>{
        setisOpen(3)
    }
    const outboundHandler=()=>{
        setisOpen(4)
    }
    const inboundHandler=()=>{
        setisOpen(5)
    }
    const allHandler=()=>{
        setisOpen(1)    }
    return (
        <div>
            <Wrapper>
                {/* <div className="role-management"> */}
                    <Container>
                        <Row>
                            <Col >
                            <fieldset className="mt-3 shadow bg-body">
                                <div className="dispatch">
                                    <div className="dispatchItems">
                                        <div className="longHaulTrip">
                                            <h5>Trip Dispatch</h5>
                                        </div>
                                        <div className=" tripButtons" role="group" aria-label="Basic example">
                                            <button type="button" className={`btn active px-4  ${isOpen === 1 && "activenew"}`} onClick={() =>allHandler(1)}>All</button>
                                            <button type="button" className={`btn active px-4  ${isOpen === 2 && "activenew"}`} onClick={() =>openHandler(2)} >Open</button>
                                            <button type="button" className={`btn active px-4  ${isOpen === 3 && "activenew"}`} onClick={() =>loadingHandler(3)} >Loading</button>
                                            <button type="button" className={`btn active px-4  ${isOpen === 4 && "activenew"}`} onClick={() =>outboundHandler(4)} >Outbound</button>
                                            <button type="button" className={`btn active px-4  ${isOpen === 5 && "activenew"}`} onClick={() =>inboundHandler(5)} >Inbound</button>
                                        </div>
                                    </div>
                                    <div className="dispatchItemsButton">
                                        <div className="">
                                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search by order or Reference" />
                                            {/* <form className="nosubmit">
                                                <input className="nosubmit" type="search" placeholder="Search by order or Reference"/>
                                            </form> */}
                                        </div>
                                        <div className="createButton">
                                            <button type="button" className="btn btn-primary px-5 mx-4 w-100">Create new order</button>
                                            <button type="button" className="btn btn-secondary px-5  ">New Trip</button>

                                        </div>
                                    </div>
                                </div>

                            </fieldset>
                            </Col>
                        </Row>

                        <Row>

                            <Col sm={8}>
                                <Draggable>
                                <div>
                                    <ActiveTripList />
                                </div>
                                </Draggable>
                            </Col>

                            <Col sm={8}>
                                <div>
                                    <ActiveTripList />
                                </div>
                            </Col>

                            {isOpen === 2? <OpenPage/>: isOpen === 3? <LoadingPage/>: isOpen === 4 ? <Outbound/> : isOpen=== 5 ? <InBound/>:
                            
                            
                            <Col sm={8}>                            
                                    <ActiveTripList/>                              
                                    <OpenOrderTable />                              
                            </Col>}
                            

                            <Col sm={4}>
                                <AddActiveDriver/>
                                <DriverList />
                            </Col>
                           
                        </Row>

                    </Container>
                {/* </div> */}
            </Wrapper>
        </div>
    )
}
export default TripDispatch;

