import { Edit } from "@material-ui/icons"
import { Modal} from "react-bootstrap";
import { useEffect, useState } from "react";
import UserFunctionalRole from "./UserFuntionalRole";
import UpdateUserPermission from "./UpdateUserPermission";
import UpdateUserRole from "./UpdateUserRole"
import Spinner from "../../../Spinner";



const ViewUserAssignedRole = ({onEditClick, userData, accessUserName, onChangeUserRole, userRole,updateUser}) =>{
  const [isLoading, setIsLoading] = useState(false)
    const [isShow, setIsShow] = useState(false);    
    const [showEditName, setShowEditName] = useState(true);
    const showhandler = () => { setShowEditName(!showEditName); }
    const abc = async() =>{
      setIsLoading(true)
      await onEditClick()
      setIsLoading(false)
  }

  useEffect(() =>{
    setIsLoading(isLoading)
  },[isLoading])

    return(
        <>
        <div onClick={() => { setIsShow(true); onEditClick()}} >
         <Edit/>
        </div>        
        <Modal size="xl" show={isShow} onHide={() => setIsShow(false)} aria-labelledby="example-modal-sizes-title-sm" className="right">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              {" "}  User Access Right - {accessUserName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
         {/* User role permission */}
         {isLoading && <Spinner />}
         {showEditName ? 
         <UserFunctionalRole userData={userData} showhandler={showhandler} onChangeUserRole={onChangeUserRole} userRole={userRole} isLoading={isLoading}/>
         :
         <UpdateUserRole userData={userData} accessUserName={accessUserName} showhandler={showhandler} userRole={userRole} onChangeUserRole={onChangeUserRole}  abc={abc} isLoading={isLoading} updateUser={updateUser}/>
         }
          </Modal.Body>
        </Modal>
      </>
    )
}
export default ViewUserAssignedRole