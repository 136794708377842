import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { calendarPickerClasses } from '@mui/x-date-pickers';
import { DataGridPremium, GridToolbar, useGridApiRef, } from '@mui/x-data-grid-premium';
import { Button } from 'react-bootstrap';
import { useGetAccessorialCodesQuery, useLazyGetSelectedOrderDetailsQuery, useUpdateDriversOnOrderMutation } from '../../../features/Dispatch/DispatchSlice';
import { Resizable } from 're-resizable';

const OrdersTable = (props) => {
    const { fetchRegionalOrders, ordersResult } = props;
    const { data: accessorialCodes } = useGetAccessorialCodesQuery();
    const [cols, setCols] = useState([])
    const [getDetails] = useLazyGetSelectedOrderDetailsQuery();
    const [updateDriversOnOrder] = useUpdateDriversOnOrderMutation();
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (accessorialCodes) {
            const codes = accessorialCodes?.data?.map((code) => {
                return {
                    field: code?.accessorialCode,
                    headerName: code?.accessorialCode,
                    width: 80,
                    editable: true,
                }
            })
            setCols([...columns, ...codes])
        }
    }, [accessorialCodes])

    const assignDriver = async (cell) => {
        if(cell.field === 'DDrive' || cell.field === "PDriv"){
            // const orderNumber = ordersResult?.data?.data?.find((order) => order?.orderId === cell.id)?.orderNumber;
            // await props.assignDriverToOrder(cell.field, cell.id, orderNumber)
            // await fetchRegionalOrders(props.divisionId);
        }
    }

    const handleEditCellChangeCommitted = async (params) => {
        const data = params.value; // New value
        const id = params.id; // id of the row
        const field = params.field; // column field name
        const row = rows.find((row) => row.id === id);

        if (data) {
            if (field === 'PDriv' || field === 'DDrive') {
                const orderStatus = row.orderStatus === 'Dispatched' ? 2 : 3;
                const pickupDriver = field === 'PDriv' ? data : row.pickUpDriver || null;
                const deliveryDriver = field === 'DDrive' ? data : row.deliveryDriver || null;
                const DTO = {orderId: id, divisionId: props.divisionId, pickupDriverNumber: pickupDriver, dropOffDriverNumber: deliveryDriver, orderStatusID: orderStatus} 
                await updateDriversOnOrder(DTO); 
            }
        }   
    }

    const columns = [
        {
            field: 'ReadyTime',
            headerName: 'Ready Time',
            type: calendarPickerClasses,
            width: 150,
            editable: true,
        },
        {
            field: 'BookingTime',
            headerName: 'Booking Time',
            type: calendarPickerClasses,
            width: 150,
            editable: true,
        },
        {
            field: 'St',
            headerName: 'St#',
            type: 'number',
            width: 60,
            editable: true,
        },
        {
            field: 'StreetName',
            headerName: 'Street Name',
            width: 110,
            editable: true,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 120,
            editable: true,
        },
        {
            field: 'PDropNumber',
            headerName: 'Drop #',
            width: 60,

        },
        {
            field: 'Map',
            headerName: 'Map',
            width: 80,
            editable: true,
        },
        {
            field: 'Vehicle',
            headerName: 'Vehicle',
            width: 150,
            editable: true,
        },
        {
            field: 'Service',
            headerName: 'Service',
            width: 150,
            editable: true,
        },
        {
            field: 'PDriv',
            headerName: 'P-Driv',
            width: 80,
            editable: true,
        },
        {
            field: 'DDrive',
            headerName: 'D-Driv',
            width: 80,
            editable: true,
        },
        {
            field: 'DSt',
            headerName: 'St#',
            type: 'number',
            width: 60,
            editable: true,
        },
        {
            field: 'DStreetName',
            headerName: 'Street Name',
            width: 110,
            editable: true,
        },
        {
            field: 'DCity',
            headerName: 'City',
            width: 120,
            editable: true,
        },
        {
            field: 'DDropNumber',
            headerName: 'Drop #',
            width: 60,
            
        },
        {
            field: 'DMap',
            headerName: 'Map',
            width: 40,
            editable: true,
        },
        {
            field: 'Pcs',
            headerName: 'Pcs',
            width: 40,
            editable: true,
        },
        {
            field: 'Wgt',
            headerName: 'Wgt',
            width: 40,
            editable: true,
        },
        {
            field: 'Expirytime',
            headerName: 'Expiry time',
            width: 120,
            editable: true,
        },
    
    ];

    const rows = props.ordersTable?.map((order) => {
        return {
            id: order?.orderId,
            Action: 1,
            ReadyTime: order?.readyDate,
            St: order?.pickupStreetNumber,
            StreetName: order?.pickupStreetName,
            City: order?.pickupCity,
            Map: order?.map,
            A: order?.a,
            TG: order?.tg,
            Vehicle: order?.vehicle,
            Service: order?.service,
            PDriv: order?.pickUpDriver,
            DDrive: order?.deliveryDriver,
            DSt: order?.dropoffStreetNumber,
            DStreetName: order?.dropoffStreetName,
            DCity: order?.dropoffCity,
            DMap: order?.dMap,
            DA: order?.dA,
            TC: order?.tc,
            HV: order?.hv,
            RS: order?.rs,
            DTG: order?.dtg,
            HZ: order?.hz,
            Pcs: order?.pcs,
            Wgt: order?.weight,
            Expirytime: order?.expiryDate,
            BookingTime: order?.orderBookingDate
        }
    })

    const nowOrders = rows?.filter((order) => {
        const bookingTime = new Date(order?.ReadyTime);
        const currentTime = new Date();
        const timeDifference = bookingTime - currentTime;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference <= 4;
    });

    const laterOrders = rows?.filter((order) => {
        const bookingTime = new Date(order?.ReadyTime);
        const currentTime = new Date();
        const timeDifference = bookingTime - currentTime;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference > 4;
    });
    
    const visibilityJSON = localStorage.getItem("bonds_visibility")
    const visibilityModel = (visibilityJSON) ? JSON.parse(visibilityJSON) : {}
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel)
    const filterJSON = localStorage.getItem("bonds_filter")
    const initialFilterModel = (filterJSON) ? JSON.parse(filterJSON) : {items: []}
    const [filterModel, setFilterModel] = useState(initialFilterModel)
    

    return (
        <Resizable defaultSize={{ width: "auto", height: "auto" }}>
            <div className="table-back">
                <div className="table-header-section">
                    <div className='table-header'><h5>Orders</h5></div>
                </div>
                <div>
                    <button className="float-end" style={{fontSize: '11px', marginTop: '5px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', padding: '5px'}} onClick={() => props.setShowAssignedOrders(false)}>Exit</button>
                </div>
                <Box sx={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={props.nowLater === 'now' ? nowOrders : laterOrders}
                        columns={cols}
                        pageSize={10}
                        rowsPerPageOptions={[1]}
                        components={{ Toolbar: GridToolbar }}
                        onRowClick={async (r) => {
                            setSelectedRow(r.row.id);
                            const result = await getDetails({ orderId: r.row.id, divisionId: props.divisionId });
                            props.setSelectedOrder(result.data.data);
                        }}
                        getRowClassName={(params) => {
                            return params.id === selectedRow ? 'selected-row' : '';
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => {
                            localStorage.setItem("bonds_visibility", JSON.stringify(newModel))
                            setColumnVisibilityModel(newModel)
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) => {
                            localStorage.setItem("bonds_filter", JSON.stringify(newModel))
                            setFilterModel(newModel)
                        }}
                    />
                </Box>
            </div>
        </Resizable>
    );
}
export default OrdersTable;