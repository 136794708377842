import Wrapper from "../../Wrapper";
import { FloatingLabel, Row, Col, Form, Button } from "react-bootstrap";



 const AgedReceivableReport = ()=>{
     return (
         <>
         <Wrapper>
         <h5 className="ml-3 mb-3">AG Aged Receivable Report</h5>
         <div className="ageed-section">
         <fieldset>
         <label className="label-heading">Select customer for generating report</label>
         <Row>
            <Col sm={3}>
            <FloatingLabel controlId="floatingInput" label="Select Customer" className="mb-2">
                    <Form.Select aria-label="Default select example">
                        <option>Customer..</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Select Division" className="mb-2">
                    <Form.Select aria-label="Default select example">
                        <option>Division</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Select Currency" className="mb-2">
                    <Form.Select aria-label="Default select example">
                        <option>Currency</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    </FloatingLabel>
                    <Form.Group controlId="accountnumber" className="mb-2">
                        <FloatingLabel controlId="floatingInput" label="Select aging date">
                            <Form.Control type="date" placeholder="Account Name" name="AccountName" value="" />
                        </FloatingLabel>
                    </Form.Group>
            </Col>
            <Col sm={9}>
            <fieldset>
         <label className="label-heading">Select customer for generating report</label>
         <div className="d-flex"  style={{justifyContent:"space-between"}}>         
            <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Report for all invoices
                    </label>
                  </div>
                <div className="d-flex align-items-center">
            <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Report only invoices over :
                    </label>
                  </div>
                  <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Days">
                            <Form.Control type="number" placeholder="Days" name="Days" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
                    </div>
            <div className="d-flex align-items-center">
            <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Report only invoices over :
                    </label>
                  </div>
                  <Form.Group controlId="effecttiveDate">
                        <FloatingLabel controlId="floatingInput" label="Days">
                            <Form.Control type="number" placeholder="Days" name="Days" value="" min="0"/>
                        </FloatingLabel>
                    </Form.Group>
                    </div>
         </div>
         </fieldset>
         {/* 2nd fieldset */}
         <fieldset className="mt-3 mb-3">
         <label className="label-heading">Select report details type</label>
         <div className="d-flex">         
            <div className="form-check mr-3">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label mr-3" htmlFor="flexRadioDefault1">
                     Total
                    </label>
                  </div>
            <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Summary
                    </label>
                  </div>
            <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Details
                    </label>
                  </div>
         </div>
         </fieldset>
         {/* Pring section */}
         <div className="d-flex mt-3">
         <div className="form-check">
                                                <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                                                <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Print report in PDF format</label>
                                            </div>
                                            <div className="form-check" style={{marginLeft:"20px"}}>
                                                <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                                                <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Create AR_report.xls in local elite20</label>
                                            </div>
         </div>
         {/* Button section */}
         <div className="generate-report">
         <Button> Generate report</Button>
         </div>
            </Col>
         </Row>
         </fieldset>
         </div>
         </Wrapper>
         </>
     );
 }
 export default AgedReceivableReport;