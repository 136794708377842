import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const INVOICECURRENCY_URL = `${baseUrl}/Customer/GetListOfInvoiceCurrency`


const initialState = []

export const fetchInvoiceCurrency  = createAsyncThunk('GetListOfInvoiceCurrency/fetchInvoiceCurrency', async () =>{
    try{
        const response = await axios.get(INVOICECURRENCY_URL);
        return[...response.data.data];
    }catch (err){
        return err.message;
    }
})


const invoiceCurrencySlice = createSlice({
    name:"invoicecurrency",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchInvoiceCurrency.fulfilled, (state, action) => {
          return action.payload
        })
      }
})

export const invoiceCurrencyList  = (state) => state.invoiceCurrencyList;

export default invoiceCurrencySlice.reducer