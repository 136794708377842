import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Row, Col, FloatingLabel, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useOrder from "../../../context/OrderContext";
import Select from 'react-select';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import Addaccessorial from "./Addaccessorial";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useGetCycleListInfoQuery } from "../../../features/cycle/cycleSlice";
import { useGetDriverListQuery } from "../../../features/driver/driverSlice";
import { useGetProductListQuery } from "../../../features/product/productSlice";
import { useGetVehicleQuery } from "../../../features/vehicle/vehicleDetailSlice";
import { useGetServiceQuery } from "../../../features/service/serviceDetailSlice";
import { Controller, useForm } from "react-hook-form";


const Orderinfo = () => {
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
  // const { productLists,ActualWeight } = useOrder();
  const { data: vehicleList } = useGetVehicleQuery()
  const { data: serviceList } = useGetServiceQuery()
  const dispatch = useDispatch();
  const { orderInfo, setAccountInfo, accountInfo, orderForm, OrderHandler, setRadio, orderErrors, days, dblhandle, showPriceHandler, selected, datePicker, timePicker,
    fetchVehicleData, accountInfoData, serviceBasedOnCustomer, vehicleBasedOnCustomer, fetchServiceData, accessorial, fetchAccessorialData, accessorialbasedOnVehicle, state, setDataa, 
    ActualWeight, setSelectedData, selectedData, pickAccessorial, dropAccessorial } = useOrder();
  const orderDetails = orderInfo[0];
  const { data: productlist } = useGetProductListQuery();

  const { data: driverlist } = useGetDriverListQuery()

  const { data: data } = useGetCycleListInfoQuery();
  const cyclelist = data?.data
  const current = new Date();
  let time = current.toLocaleTimeString();
  const [status, setStatus] = React.useState(0);
  const [vehiclename, setvehicleName] = useState();

  const radioHandler = (status) => { setStatus(status); };

  useEffect(() => {
    const intersection = pickAccessorial.filter(element => dropAccessorial.includes(element));
    setSelectedData(intersection);
  }, [pickAccessorial, dropAccessorial])

  const arr = accessorial?.data?.data?.map((item) => ({ value: item?.accessorialID, label: item?.accessorialDescription })) || [];

  accessorialbasedOnVehicle?.map((user) => {
    return arr.push({ value: user.accessorialID, label: user.accessorialDescription });
  });
  const handleOnSelect = async (e) => {
    const addressData = e.target.value;
    const selectedAccount = await fetchServiceData({ AccountNo: accountInfoData?.account_No, VehicleType: addressData })
    //const selectedAccessorial = await fetchAccessorialData({ AccountNo: accountInfoData?.account_No, VehicleType: addressData })
    //setAccountInfo(selectedAccount)
  };

  const handleApiInfo = async (e) => {
    // setDataa(e.target.value);
    let valuecxc = e.target.value;
    vehicleBasedOnCustomer?.map(v => {
      if (v.vehicleID == valuecxc) {
        setDataa(v.vehicleType)
        return;
      }
    });
  }
  const handleSelect = (e) => {
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData.length > 0) {
      setSelectedData(mapData)
    }
    else {
      setSelectedData([])
    }
  }
  return (
    <>
      <>
        {orderForm === "Online" && (
          <>
            {/* <p>Current time is {time}</p> */}
            <Row className="mb-3">
              <Col sm={4}>
                <div className="select-time d-flex">
                  <div className="RadioButton">
                    <input
                      value={time}
                      type="radio"
                      name="day" checked={status === 0 || status === 1} onChange={(e) => { radioHandler(1); setRadio(1) }} />
                    <label>Current Time</label>
                  </div>
                  <div className="RadioButton">
                    <input
                      checked={status === 2} onChange={(e) => { radioHandler(2); setRadio(2) }}
                      value={time}
                      type="radio"
                      name="day" />
                    <label>Select Time</label>
                  </div>
                </div>
              </Col>
              {status === 2 &&
                <>
                  <Col sm={4}>
                    <div className="timepicker">
                      <DatePickerComponent id="datepicker" placeholder="Select Date" format="yyyy-MM-dd" name="date" value={datePicker} onChange={(e) => { OrderHandler(e); }} />
                    </div>
                    { orderErrors.date && (
                      <p className="help is-danger">{orderErrors.date}</p>
                    )}
                  </Col>
                  <Col sm={4}>
                    <div className="timepicker">
                      <TimePickerComponent id="time" placeholder="Select Time" format="hh:mm" name="time" value={timePicker} onChange={(e) => { OrderHandler(e) }} />
                    </div>
                    {orderErrors.time && (
                      <p className="help is-danger">{orderErrors.time}</p>
                    )}
                  </Col>
                </>
              }
            </Row>
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="Vehicle">
                  <FloatingLabel controlId="floatingSelectGrid" label="Vehicle">
                    <Form.Select aria-label="Floating label select example" className={`input ${orderErrors.vehicleID && 'is-danger'}`} name="vehicleID" value={orderInfo.vehicleID || ""} onChange={(e) => { OrderHandler(e); handleOnSelect(e); handleApiInfo(e) }} required>
                      <option>Select</option>:
                      <>
                        {
                          vehicleBasedOnCustomer?.map((data, index) => (
                            <option key={index} value={data.vehicleID} >{data.vehicleType}</option>
                          ))
                        }
                      </>
                    </Form.Select>
                    {orderErrors.vehicleID && (
                      <p className="help is-danger">{orderErrors.vehicleID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="Service">
                  <FloatingLabel controlId="floatingSelectGrid" label="Service">
                    <Form.Select aria-label="Floating label select example" name="serviceID" className={`input ${orderErrors.serviceID && 'is-danger'}`} value={orderInfo.serviceID || ""} onChange={(e) => { OrderHandler(e); }} required>
                      <option>Select</option>:
                      <>
                        {
                          serviceBasedOnCustomer?.map((data, index) => (
                            <option key={index} value={data.serviceID}>{data.description}</option>
                          ))

                        }
                      </>
                    </Form.Select>
                    {orderErrors.serviceID && (
                      <p className="help is-danger">{orderErrors.serviceID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              {/* <Col sm={4}>
                <Form.Group controlId="Accessorial">
                  <FloatingLabel controlId="floatingSelectGrid" label="Accessorial">
                    <Form.Select aria-label="Floating label select example" name="accessorialID" className={`input ${orderErrors.accessorialID && 'is-danger'}`} value={orderInfo.accessorialID || ""} onChange={(e) => { OrderHandler(e); }} required>
                      <option>Select</option>:
                      <>
                        {
                          accessorialbasedOnVehicle?.map((data, index) => (
                            <option key={index} value={data.accessorialID}>{data.accessorialDescription}</option>
                          ))

                        }
                      </>
                    </Form.Select>
                    {orderErrors.accessorialID && (
                      <p className="help is-danger">{orderErrors.accessorialID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col> */}
              <Col sm={4}>
                <Controller
                  control={control}
                  name="accessorialID"
                  // defaultValue={selectedData}
                  value={orderInfo.accessorialID || ""}
                  onChange={(e) => { OrderHandler(e); }}
                  // {...register('VehicleTypes')}

                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "47px",
                      }),
                    }}
                      isMulti
                      placeholder="Accessorial"
                      name={name}
                      // defaultValue={selectedData}
                      ref={ref}
                      // options={arr}
                      value={selectedData ? arr.filter(option => selectedData.includes(option.value)) : []}
                      // onChange={(e) => handleSelect(e)}
                    />
                  )}
                />
                {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}

              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Row>
                  {/* <Col sm={8}>
                    <Form.Group controlId="pcs">
                      <FloatingLabel controlId="floatingSelectGrid" label="Product Type">
                        <Form.Select aria-label="Floating label select example" name="productID" className={`input ${orderErrors.productID && 'is-danger'}`} value={orderInfo.productID || ""} onChange={(e) => { OrderHandler(e); }} required>
                          <option>Select</option>:
                          <>
                            {
                              productlist?.data.map((data, index) => (
                                <option key={index} value={data.productID}>{data.description}</option>
                              ))

                            }
                          </>
                        </Form.Select>
                        {orderErrors.productID && (
                          <p className="help is-danger">{orderErrors.productID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col> */}
                  <Col sm={6}>
                    <Form.Group controlId="pcs">
                      <FloatingLabel controlId="floatingInput" label="Pcs" >
                        <Form.Control type="text" placeholder="Pcs" name="pcs" className={`input ${orderErrors.pcs && 'is-danger'}`} value={orderInfo.pcs || ""} onChange={(e) => { OrderHandler(e); }} />
                        {/* {orderErrors.pcs && (
                        <p className="help is-danger">{orderErrors.pcs}</p>
                      )} */}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="wgt">
                      <FloatingLabel controlId="floatingInput" label="Weight" >
                        <Form.Control type="text" placeholder="Weight" name="weight" className={`input ${orderErrors.weight && 'is-danger'}`} value={orderInfo.weight || ""} onChange={(e) => { OrderHandler(e); }} />
                        {/* {orderErrors.weight && (
                        <p className="help is-danger">{orderErrors.weight}</p>
                      )} */}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col sm={12}>
                    <Form.Group controlId="wgt">
                      <FloatingLabel controlId="floatingSelectGrid" label="Weight Type">
                        <Form.Select aria-label="Floating label select example" name="weightTypeID" className={`input ${orderErrors.weightTypeID && 'is-danger'}`} value={orderInfo.weightTypeID || ""} onChange={(e) => { OrderHandler(e); }}>
                          <option>Choose the weight type</option>
                          <option value="1">L</option>
                        </Form.Select>
                        {orderErrors.weightTypeID && (
                          <p className="help is-danger">{orderErrors.weightTypeID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                 
                </Row>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="reference">
                  <FloatingLabel controlId="floatingInput" label="Reference" >
                    <Form.Control type="text" placeholder="Reference" name="refernce" className={`input ${orderErrors.refernce && 'is-danger'}`} value={orderInfo.refernce || ""} onChange={(e) => { OrderHandler(e); }} />
                  </FloatingLabel>
                  {orderErrors.refernce && (
                    <p className="help is-danger">{orderErrors.refernce}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={8}></Col>
              <Col sm={2}>
                <Addaccessorial />
              </Col>
              <Col sm={2}>
                <div>
                  <Button variant="primary" className="icon-button" onClick={showPriceHandler}>Price</Button>
                </div>
              </Col>
            </Row>


          </>
        )}
      </>
      <>
        {orderForm === "Batch" && (
          <>
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="Select Day">
                  <FloatingLabel controlId="floatingSelectGrid" label="Select Day">
                    <Form.Select aria-label="Floating label select example" name="day" value={orderInfo.day || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.day && 'is-danger'}`}>
                      <option>Select</option>
                      <option value="1">Monday</option>
                      <option>Tuesday</option>
                      <option>Wednesday</option>
                      <option>Thrusday</option>
                      <option>Friday</option>
                      <option>Saturday</option>
                      <option>Sunday</option>
                    </Form.Select>
                    {orderErrors.day && (
                      <p className="help is-danger">{orderErrors.day}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <div className="timepicker">
                  <DatePickerComponent id="datepicker" placeholder="Select Date" name="date" value={orderInfo.date || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.date && 'is-danger'}`} />
                </div>
                {orderErrors.date && (
                  <p className="help is-danger">{orderErrors.date}</p>
                )}
              </Col>
              <Col sm={4}>
                <div className="timepicker">
                  <TimePickerComponent id="time" placeholder="Select Time" name="time" value={orderInfo.time || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.vehicleID && 'is-danger'}`} />
                </div>
                {orderErrors.time && (
                  <p className="help is-danger">{orderErrors.time}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Form.Group controlId="Vehicle">
                  <FloatingLabel controlId="floatingSelectGrid" label="Vehicle">
                    <Form.Select aria-label="Floating label select example" className={`input ${orderErrors.vehicleID && 'is-danger'}`} name="vehicleID" value={orderInfo.vehicleID || ""} onChange={(e) => { OrderHandler(e); handleOnSelect(e) }} required>
                      <option>Select</option>:
                      <>
                        {
                          vehicleBasedOnCustomer?.map((data, index) => (
                            <option key={index} value={data.vehicleID}>{data.vehicleType}</option>
                          ))
                        }
                      </>
                    </Form.Select>
                    {orderErrors.vehicleID && (
                      <p className="help is-danger">{orderErrors.vehicleID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="Service">
                  <FloatingLabel controlId="floatingSelectGrid" label="Service">
                    <Form.Select aria-label="Floating label select example" name="serviceID" className={`input ${orderErrors.serviceID && 'is-danger'}`} value={orderInfo.serviceID || ""} onChange={(e) => { OrderHandler(e); }} required>
                      <option>Select</option>:
                      <>
                        {
                          serviceBasedOnCustomer?.map((data, index) => (
                            <option key={index} value={data.serviceID}>{data.description}</option>
                          ))

                        }
                      </>
                    </Form.Select>
                    {orderErrors.serviceID && (
                      <p className="help is-danger">{orderErrors.serviceID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Controller
                  control={control}
                  name="accessorialID"
                  // defaultValue={selectedData}
                  value={orderInfo.accessorialID || ""}
                  onChange={(e) => { OrderHandler(e); }}
                  // {...register('VehicleTypes')}

                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      isMulti
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "47px",
                        }),
                      }}
                      placeholder="Select Accessorial"
                      name={name}
                      // defaultValue={selectedData}
                      ref={ref}
                      options={arr}
                      // value={arr.find((c) => c.value === value)}
                      value={selectedData ? selectedData?.find((e) => e === value) : ""}
                      onChange={(e) => handleSelect(e)}
                    />
                  )}
                />
                {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}
              </Col>
              
            </Row>
            <Row className="mt-3">
              <Col sm={4}>
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="Driverid">
                      <FloatingLabel controlId="floatingSelectGrid" label="Driver ID">
                        <Form.Select aria-label="Floating label select example" name="driverid" value={orderInfo.driverid || ""} className={`input ${orderErrors.driverid && 'is-danger'}`} onChange={(e) => { OrderHandler(e); }} required>
                          <option>Select</option>:
                          <>
                            {
                              driverlist?.data.map((data, index) => (
                                <option key={index} value={data.driverid}>{data.driverNo}</option>
                              ))

                            }
                          </>
                        </Form.Select>
                        {orderErrors.driverid && (
                          <p className="help is-danger">{orderErrors.driverid}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="pcs">
                      <FloatingLabel controlId="floatingInput" label="Pcs" >
                        <Form.Control type="text" placeholder="Pcs" name="pcs" className={`input ${orderErrors.pcs && 'is-danger'}`} value={orderInfo.pcs || ""} onChange={(e) => { OrderHandler(e); }} />
                        {/* {orderErrors.pcs && (
                          <p className="help is-danger">{orderErrors.pcs}</p>
                        )} */}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col sm={4}>
                    <Form.Group controlId="wgt">
                      <FloatingLabel controlId="floatingInput" label="Weight" >
                        <Form.Control type="text" placeholder="Weight" name="weight" className={`input ${orderErrors.weight && 'is-danger'}`} value={orderInfo.weight || ""} onChange={(e) => { OrderHandler(e); }} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={8}>
                    <Form.Group controlId="wgt">
                      <FloatingLabel controlId="floatingSelectGrid" label="WeightType">
                        <Form.Select aria-label="Floating label select example" name="weightTypeID" value={orderInfo.weightTypeID || ""} className={`input ${orderErrors.weightTypeID && 'is-danger'}`} onChange={(e) => { OrderHandler(e); }}>
                          <option>Choose the weight type</option>
                          <option value="1">L</option>
                        </Form.Select>
                        {orderErrors.weightTypeID && (
                          <p className="help is-danger">{orderErrors.weightTypeID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  
                </Row>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="reference">
                  <FloatingLabel controlId="floatingInput" label="Reference" >
                    <Form.Control type="text" placeholder="Reference" name="refernce" className={`input ${orderErrors.refernce && 'is-danger'}`} value={orderInfo.refernce || ""} onChange={(e) => { OrderHandler(e); }} />
                  </FloatingLabel>
                  {orderErrors.refernce && (
                    <p className="help is-danger">{orderErrors.refernce}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={8}></Col>
              <Col sm={2}>
                <Addaccessorial />
              </Col>
              <Col sm={2}>
                <div>
                  <Button variant="primary" className="icon-button" onClick={showPriceHandler}>Price</Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
      <>
        {orderForm === "Contract" && (
          <>
            <Row className="mb-3">
              <Col sm={4}>
                <Select
                  isMulti
                  name="day"
                  classNamePrefix="select"
                  options={days}
                  value={orderInfo.day}
                  onChange={(e) => { dblhandle(e); }}
                />
              </Col>
              <Col sm={4}>
                <Form.Group controlId="Recurringday">
                  <FloatingLabel controlId="floatingSelectGrid" label="Recurring day">
                    <Form.Select aria-label="Floating label select example" name="cycleid" value={orderInfo.cycleid || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.cycleid && 'is-danger'}`} required >
                      <option>Select</option>:
                      <>
                        {
                          cyclelist.map((data, index) => (
                            <option key={index} value={data.cycleid}>{data.cycle}</option>
                          ))

                        }
                      </>
                    </Form.Select>
                    {orderErrors.cycleid && (
                      <p className="help is-danger">{orderErrors.cycleid}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <div className="timepicker">
                  <TimePickerComponent id="time" placeholder="Select Time" name="time" value={orderInfo.time || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.time && 'is-danger'}`} />
                </div>
                {orderErrors.time && (
                  <p className="help is-danger">{orderErrors.time}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Form.Group controlId="Vehicle">
                  <FloatingLabel controlId="floatingSelectGrid" label="Vehicle">
                    <Form.Select aria-label="Floating label select example" className={`input ${orderErrors.vehicleID && 'is-danger'}`} name="vehicleID" value={orderInfo.vehicleID || ""} onChange={(e) => { OrderHandler(e); handleOnSelect(e) }} required>
                      <option>Select</option>:
                      <>
                        {
                          vehicleBasedOnCustomer?.map((data, index) => (
                            <option key={index} value={data.vehicleID}>{data.vehicleType}</option>
                          ))
                        }
                      </>
                    </Form.Select>
                    {orderErrors.vehicleID && (
                      <p className="help is-danger">{orderErrors.vehicleID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="Service">
                  <FloatingLabel controlId="floatingSelectGrid" label="Service">
                    <Form.Select aria-label="Floating label select example" name="serviceID" className={`input ${orderErrors.serviceID && 'is-danger'}`} value={orderInfo.serviceID || ""} onChange={(e) => { OrderHandler(e); }} required>
                      <option>Select</option>:
                      <>
                        {
                          serviceBasedOnCustomer?.map((data, index) => (
                            <option key={index} value={data.serviceID}>{data.description}</option>
                          ))

                        }
                      </>
                    </Form.Select>
                    {orderErrors.serviceID && (
                      <p className="help is-danger">{orderErrors.serviceID}</p>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Controller
                  control={control}
                  name="accessorialID"
                  // defaultValue={selectedData}
                  value={orderInfo.accessorialID || ""}
                  onChange={(e) => { OrderHandler(e); }}
                  // {...register('VehicleTypes')}

                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      isMulti
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "47px",
                        }),
                      }}
                      placeholder="Select Accessorial"
                      name={name}
                      // defaultValue={selectedData}
                      ref={ref}
                      options={arr}
                      // value={arr.find((c) => c.value === value)}
                      value={selectedData ? selectedData?.find((e) => e === value) : ""}
                      onChange={(e) => handleSelect(e)}
                    />
                  )}
                />
                {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}


              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={4}>
                <Row>
                  {/* <Col sm={8}>
                    <Form.Group controlId="pcs">
                      <FloatingLabel controlId="floatingSelectGrid" label="Product Type">
                        <Form.Select aria-label="Floating label select example" name="productID" value={orderInfo.productID || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.productID && 'is-danger'}`} required>
                          <option>Select</option>:
                          <>
                            {
                              productlist?.data.map((data, index) => (
                                <option key={index} value={data.productID}>{data.description}</option>
                              ))

                            }
                          </>
                        </Form.Select>
                        {orderErrors.productID && (
                          <p className="help is-danger">{orderErrors.productID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col> */}
                  <Col sm={4}>
                    <Form.Group controlId="pcs">
                      <FloatingLabel controlId="floatingInput" label="Pcs" >
                        <Form.Control type="text" placeholder="Pcs" name="pcs" className={`input ${orderErrors.pcs && 'is-danger'}`} value={orderInfo.pcs || ""} onChange={(e) => { OrderHandler(e); }} />
                        {/* {orderErrors.pcs && (
                          <p className="help is-danger">{orderErrors.pcs}</p>
                        )} */}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                <Col sm={4}>
                    <Form.Group controlId="wgt">
                      <FloatingLabel controlId="floatingInput" label="Weight" >
                        <Form.Control type="text" placeholder="Weight" name="weight" className={`input ${orderErrors.weight && 'is-danger'}`} value={orderInfo.weight || ""} onChange={(e) => { OrderHandler(e); }} />
                        {/* {orderErrors.weight && (
                          <p className="help is-danger">{orderErrors.weight}</p>
                        )} */}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={8}>
                    <Form.Group controlId="wgt">
                      <FloatingLabel controlId="floatingSelectGrid" label="Weight Type">
                        <Form.Select aria-label="Floating label select example" name="weightTypeID" value={orderInfo.weightTypeID || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.weightTypeID && 'is-danger'}`} >
                          <option>Choose the weight type</option>
                          <option value="1">L</option>
                        </Form.Select>
                        {orderErrors.weightTypeID && (
                          <p className="help is-danger">{orderErrors.weightTypeID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  
                </Row>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="reference">
                  <FloatingLabel controlId="floatingInput" label="Reference" >
                    <Form.Control type="text" placeholder="Reference" name="refernce" value={orderInfo.refernce || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.refernce && 'is-danger'}`} />
                  </FloatingLabel>
                  {orderErrors.refernce && (
                    <p className="help is-danger">{orderErrors.refernce}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={8}></Col>
              <Col sm={2}>
                <Addaccessorial />
              </Col>
              <Col sm={2}>
                <div>
                  <Button variant="primary" className="icon-button" onClick={showPriceHandler}>Price</Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
      <>
        {orderForm === "Quote" && (
          <>
            {selected ?
              <>
                {/* <p>Current time is {time}</p> */}
                <Row className="mb-3">
                  <Col sm={4}>
                    <div className="select-time d-flex">
                      <div className="RadioButton">
                        <input
                          value={time}
                          type="radio"
                          name="day" checked={status === 1} onChange={(e) => { radioHandler(1); OrderHandler(e) }} />
                        <label>Current Time</label>
                      </div>
                      <div className="RadioButton">
                        <input
                          checked={status === 2} onChange={(e) => { radioHandler(2); OrderHandler(e) }}
                          value={time}
                          type="radio"
                          name="day" />
                        <label>Select Time</label>
                      </div>
                    </div>
                  </Col>
                  {status === 2 &&
                    <>
                      <Col sm={4}>
                        <div className="timepicker">
                          <DatePickerComponent id="datepicker" placeholder="Select Date" name="date" value={orderDetails.date} onChange={(e) => { OrderHandler(e); }} />
                        </div>
                        { orderErrors.date && (
                          <p className="help is-danger">{orderErrors.date}</p>
                        )}
                      </Col>
                      <Col sm={4}>
                        <div className="timepicker">
                          <TimePickerComponent id="time" placeholder="Select Time" name="time" value={orderDetails.time} onChange={(e) => { OrderHandler(e) }} />
                        </div>
                        {orderErrors.time && (
                          <p className="help is-danger">{orderErrors.time}</p>
                        )}
                      </Col>
                    </>
                  }
                </Row>
                <Row className="mb-3">
                  <Col sm={4}>
                    <Form.Group controlId="Vehicle">
                      <FloatingLabel controlId="floatingSelectGrid" label="Vehicle">
                        <Form.Select aria-label="Floating label select example" className={`input ${orderErrors.vehicleID && 'is-danger'}`} name="vehicleID" value={orderInfo.vehicleID || ""} onChange={(e) => { OrderHandler(e); handleOnSelect(e) }} required>
                          <option>Select</option>:
                          <>
                            {
                              vehicleBasedOnCustomer?.map((data, index) => (
                                <option key={index} value={data.vehicleID}>{data.vehicleType}</option>
                              ))
                            }
                          </>
                        </Form.Select>
                        {orderErrors.vehicleID && (
                          <p className="help is-danger">{orderErrors.vehicleID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="Service">
                      <FloatingLabel controlId="floatingSelectGrid" label="Service">
                        <Form.Select aria-label="Floating label select example" name="serviceID" className={`input ${orderErrors.serviceID && 'is-danger'}`} value={orderInfo.serviceID || ""} onChange={(e) => { OrderHandler(e); }} required>
                          <option>Select</option>:
                          <>
                            {
                              serviceBasedOnCustomer?.map((data, index) => (
                                <option key={index} value={data.serviceID}>{data.description}</option>
                              ))

                            }
                          </>
                        </Form.Select>
                        {orderErrors.serviceID && (
                          <p className="help is-danger">{orderErrors.serviceID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Controller
                      control={control}
                      name="accessorialID"
                      // defaultValue={selectedData}
                      value={orderInfo.accessorialID || ""}
                      onChange={(e) => { OrderHandler(e); }}
                      // {...register('VehicleTypes')}

                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "47px",
                          }),
                        }}
                          isMulti
                          placeholder="Select Accessorial"
                          name={name}
                          // defaultValue={selectedData}
                          ref={ref}
                          options={arr}
                          // value={arr.find((c) => c.value === value)}
                          value={selectedData ? selectedData?.find((e) => e === value) : ""}
                          onChange={(e) => handleSelect(e)}
                        />
                      )}
                    />
                    {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}


                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Row>
                      <Col sm={8}>
                        <Form.Group controlId="pcs">
                          <FloatingLabel controlId="floatingSelectGrid" label="Product Type">
                            <Form.Select aria-label="Floating label select example" name="productID" className={`input ${orderErrors.productID && 'is-danger'}`} value={orderDetails.productID || ""} onChange={(e) => { OrderHandler(e); }} required>
                              <option>Select</option>:
                              <>
                                {
                                  productlist?.data.map((data, index) => (
                                    <option key={index} value={data.productID}>{data.description}</option>
                                  ))

                                }
                              </>
                            </Form.Select>
                            {orderErrors.productID && (
                              <p className="help is-danger">{orderErrors.productID}</p>
                            )}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group controlId="pcs">
                          <FloatingLabel controlId="floatingInput" label="Pcs" >
                            <Form.Control type="text" placeholder="Pcs" name="pcs" className={`input ${orderErrors.pcs && 'is-danger'}`} value={orderDetails.pcs || ""} onChange={(e) => { OrderHandler(e); }} />
                            {/* {orderErrors.pcs && (
                              <p className="help is-danger">{orderErrors.pcs}</p>
                            )} */}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col sm={8}>
                        <Form.Group controlId="wgt">
                          <FloatingLabel controlId="floatingSelectGrid" label="Weight Type">
                            <Form.Select aria-label="Floating label select example" name="weightTypeID" className={`input ${orderErrors.weightTypeID && 'is-danger'}`} value={orderDetails.weightTypeID || ""} onChange={(e) => { OrderHandler(e); }}>
                              <option>Choose the weight type</option>
                              <option value="1">L</option>
                            </Form.Select>
                            {orderErrors.weightTypeID && (
                              <p className="help is-danger">{orderErrors.weightTypeID}</p>
                            )}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group controlId="wgt">
                          <FloatingLabel controlId="floatingInput" label="Weight" >
                            <Form.Control type="text" placeholder="Weight" name="weight" className={`input ${orderErrors.weight && 'is-danger'}`} value={orderDetails.weight || ""} onChange={(e) => { OrderHandler(e); }} />
                            {/* {orderErrors.weight && (
                              <p className="help is-danger">{orderErrors.weight}</p>
                            )} */}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="reference">
                      <FloatingLabel controlId="floatingInput" label="Reference" >
                        <Form.Control type="text" placeholder="Reference" name="refernce" className={`input ${orderErrors.refernce && 'is-danger'}`} value={orderDetails.refernce || ""} onChange={(e) => { OrderHandler(e); }} />
                      </FloatingLabel>
                      {orderErrors.refernce && (
                        <p className="help is-danger">{orderErrors.refernce}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={8}></Col>
                  <Col sm={2}>
                    <Addaccessorial />
                  </Col>
                  <Col sm={2}>
                    <div>
                      <Button variant="primary" className="icon-button" onClick={showPriceHandler}>Price</Button>
                    </div>
                  </Col>
                </Row>
              </>
              :
              <>
                <Row className="mb-3">
                  <Col sm={4}>
                    <div className="select-time d-flex">
                      <div className="RadioButton">
                        <input
                          value={time}
                          type="radio"
                          name="day" checked={status === 1} onChange={(e) => { radioHandler(1); OrderHandler(e) }} />
                        <label>Current Time</label>
                      </div>
                      <div className="RadioButton">
                        <input
                          checked={status === 2} onChange={(e) => { radioHandler(2); OrderHandler(e) }}
                          value={time}
                          type="radio"
                          name="day" />
                        <label>Select Time</label>
                      </div>
                    </div>
                  </Col>
                  {status === 2 &&
                    <>
                      <Col sm={4}>
                        <div className="timepicker">
                          <DatePickerComponent id="datepicker" placeholder="Select Date" name="date" value={orderInfo.date || ""} onChange={(e) => { OrderHandler(e); }} />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="timepicker">
                          <TimePickerComponent id="time" placeholder="Select Time" name="time" value={orderInfo.time || ""} onChange={(e) => { OrderHandler(e); }} />
                        </div>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={4}>
                    <Form.Group controlId="Vehicle">
                      <FloatingLabel controlId="floatingSelectGrid" label="Vehicle">
                        <Form.Select aria-label="Floating label select example" className={`input ${orderErrors.vehicleID && 'is-danger'}`} name="vehicleID" value={orderInfo.vehicleID || ""} onChange={(e) => { OrderHandler(e); handleOnSelect(e) }} required>
                          <option>Select</option>:
                          <>
                            {
                              vehicleBasedOnCustomer?.map((data, index) => (
                                <option key={index} value={data.vehicleID}>{data.vehicleType}</option>
                              ))
                            }
                          </>
                        </Form.Select>
                        {orderErrors.vehicleID && (
                          <p className="help is-danger">{orderErrors.vehicleID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="Service">
                      <FloatingLabel controlId="floatingSelectGrid" label="Service">
                        <Form.Select aria-label="Floating label select example" name="serviceID" className={`input ${orderErrors.serviceID && 'is-danger'}`} value={orderInfo.serviceID || ""} onChange={(e) => { OrderHandler(e); }} required>
                          <option>Select</option>:
                          <>
                            {
                              serviceBasedOnCustomer?.map((data, index) => (
                                <option key={index} value={data.serviceID}>{data.description}</option>
                              ))

                            }
                          </>
                        </Form.Select>
                        {orderErrors.serviceID && (
                          <p className="help is-danger">{orderErrors.serviceID}</p>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Controller
                      control={control}
                      name="accessorialID"
                      // defaultValue={selectedData}
                      value={orderInfo.accessorialID || ""}
                      onChange={(e) => { OrderHandler(e); }}
                      // {...register('VehicleTypes')}

                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "47px",
                            }),
                          }}
                          placeholder="Select Accessorial"
                          name={name}
                          // defaultValue={selectedData}
                          ref={ref}
                          options={arr}
                          // value={arr.find((c) => c.value === value)}
                          value={selectedData ? selectedData?.find((e) => e === value) : ""}
                          onChange={(e) => handleSelect(e)}
                        />
                      )}
                    />
                    {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}


                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <Row>
                      {/* <Col sm={8}>
                        <Form.Group controlId="pcs">
                          <FloatingLabel controlId="floatingSelectGrid" label="Product Typse">
                            <Form.Select aria-label="Floating label select example" name="productID" required value={orderInfo.productID || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.productID && 'is-danger'}`}>
                              <option>Select</option>:
                              <>
                                {
                                  productlist?.data.map((data, index) => (
                                    <option key={index} value={data.productID}>{data.description}</option>
                                  ))

                                }
                              </>
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col> */}
                      <Col sm={4}>
                        <Form.Group controlId="pcs">
                          <FloatingLabel controlId="floatingInput" label="Pcs" >
                            <Form.Control type="text" placeholder="Pcs" name="pcs" className={`input ${orderErrors.pcs && 'is-danger'}`} value={orderInfo.pcs || ""} onChange={(e) => { OrderHandler(e); }} />
                            {/* {orderErrors.pcs && (
                          <p className="help is-danger">{orderErrors.pcs}</p>
                        )} */}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                    <Col sm={4}>
                        <Form.Group controlId="pcs">
                          <FloatingLabel controlId="floatingInput" label="Weight" >
                            <Form.Control type="text" placeholder="Pcs" name="weight" className={`input ${orderErrors.weight && 'is-danger'}`} value={orderInfo.weight || ""} onChange={(e) => { OrderHandler(e); }} />
                            {/* {orderErrors.pcs && (
                          <p className="help is-danger">{orderErrors.pcs}</p>
                        )} */}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col sm={8}>
                        <Form.Group controlId="wgt">
                          <FloatingLabel controlId="floatingSelectGrid" label="Weight Type">
                            <Form.Select aria-label="Floating label select example" name="weightTypeID" value={orderInfo.weightTypeID || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.weightTypeID && 'is-danger'}`}>
                              <option>Choose the weight type</option>
                              <option value="1">L</option>
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="reference">
                      <FloatingLabel controlId="floatingInput" label="Reference" >
                        <Form.Control type="text" placeholder="Reference" name="refernce" value={orderInfo.refernce || ""} onChange={(e) => { OrderHandler(e); }} className={`input ${orderErrors.refernce && 'is-danger'}`} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={8}></Col>
                  <Col sm={2}>
                    <Addaccessorial />
                  </Col>
                  <Col sm={2}>
                    <div>
                      <Button variant="primary" className="icon-button" onClick={showPriceHandler}>Price</Button>
                    </div>
                  </Col>
                </Row>
              </>
            }
          </>
        )}
      </>
    </>
  );
}
export default Orderinfo;