import { Row, Col, Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { AddCircle } from "@material-ui/icons";
import { OpenInNew } from "@material-ui/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRole } from "../../../../features/admin/role/createRoleSlice";
import { useForm } from 'react-hook-form';
import { useGetRolesQuery, useCreateRolesMutation } from "../../../../features/admin/role/createRoleSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssignRole = () => {
  const {data:role, refetch} = useGetRolesQuery()
  const [createRoles, {isLoading}] = useCreateRolesMutation()
  const { register, handleSubmit, formState: { errors }, reset, } = useForm();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [newRole, setNewRole] = useState("")

  const onSubmit = async(data) => {
   await createRoles(data);
   refetch()
    reset({ role: '' });
         setTimeout(function () {
          toast.success("You have created a new Role !",{
          position: "top-center",
        })
      }, 1000);
  }


  return (
    <>
      <Button onClick={() => setIsShow(true)} className="btn btn-secondary">
        <OpenInNew /> Create Role
      </Button>
      <Modal
        size="md"
        show={isShow}
        onHide={() => setIsShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {" "}
            Create New Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          </Row>
          <Row className="mb-4 mt-3">
            <Col sm={12}>
              <>
                <div className="assign-role">
                  <Row className="mt-2" >
                    <Col sm={10}>
                      <div className="form-floating">
                        <Form.Control type="text" autoComplete="off" placeholder="Enter a new Role" name="role"
                          {...register('role', {
                            required: 'Field is required', pattern: {
                              value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g,
                              message: "Please enter a valid name"
                            }
                          })} />
                        <label htmlFor="newRole">Enter a new Role</label>
                        {errors.role?.message && (<p className="error-message">{" * " + errors.role?.message}</p>)}
                      </div>
                    </Col>
                    <Col sm={2}>
                      {isLoading ? 
                      <Button className="btn btn-secondary isDisabled"> <AddCircle /></Button>
                      :
                      <Button className="btn btn-secondary" onClick={handleSubmit(onSubmit)}> <AddCircle /></Button>
                      }
                      
                    </Col>
                  </Row>
                </div>
                {/* List of created role */}
                <Row>
                  {role?.data?.map((data, index) => (
                    <>
                      <div className="created-role" key={index} >
                        <div className="role-name">{data}</div>
                      </div>
                    </>
                  ))}
                </Row>
              </>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default AssignRole;