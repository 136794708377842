import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

const initialState = {
    list: []
};

export const cityDistanceApiSlice = apiSlice.injectEndpoints({
    tagTypes:['Vehicle'],
    endpoints: builder =>({
        updateCityDistanceData: builder.mutation({
            query(data) {
                const {citydistanceID, ...body} = data
                return {
                    url: `Customer/EditFuelCityDistanceSection?CitydistanceID=${citydistanceID}`,
                  method: "POST",
                  body
                };
              },
          }),
          deleteCityDistanceData: builder.mutation({
            query : (citydistanceID) => ({
              url: `Customer/DeleteFuelCityDistanceSection?CitydistanceID=${citydistanceID}`,
              method: "POST",
          }),
          }),

          addCustomerCityDistanceAfterEdit: builder.mutation({
            query : (body) => ({
              url: `Customer/AddCustomerCityDistanceAfterEdit`,
              method: "POST",
              body
          }),
          })
    })
})

export const { useUpdateCityDistanceDataMutation, useDeleteCityDistanceDataMutation ,useAddCustomerCityDistanceAfterEditMutation} = apiSlice

const cityDistanceSlice = createSlice({
    name: "cityDistance",
    initialState,
    reducers: {
        addCityDistance: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteCityDistance: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editCityDistance : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            return { ...state, list: currentLists };
        },
        resetCityDistance: () => initialState,
    },
})

export const { addCityDistance, editCityDistance, deleteCityDistance, resetCityDistance } = cityDistanceSlice.actions

export default cityDistanceSlice.reducer