import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { useGetCityQuery } from "../../../../../features/city/citySlice";
import { useGetCountryQuery, useLazyGetStateQuery } from "../../../../../features/countryState/countryStateSlice";
import { useUpdateCityDistanceDataMutation, useDeleteCityDistanceDataMutation } from "../../../../../features/cityDistance/cityDistanceSlice";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useCustomData } from "../../../../Sidebar/Permission";
import CountryProvince from "../../../../CountryProvince";


const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
const EditCityDistance = (props) => {
    const [permission] = useCustomData()
    const { data: fromCountry } = useGetCountryQuery()
    const { data: toCountry } = useGetCountryQuery()
    const { city, province } = CountryProvince();
    const [fetchState, fromProvince] = useLazyGetStateQuery()
    const [fetchToState, toProvince] = useLazyGetStateQuery()
    const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
    const [updateCityDistance] = useUpdateCityDistanceDataMutation()
    const [deleteCityDistance] = useDeleteCityDistanceDataMutation()
    const [modifyHistory] = useModifyHistoryMutation()
    const { handleAccount, singleAccount, cityDistanceLists,getDefaultCard } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [message, setMessage] = useState("Modified the city distance section")
    const [isEditItem, setIsEditItem] = useState(null);
    const [cityDistance, setCityDistance] = useState({})
    const [showDistanceSecondFields, setShowDistanceSecondFields] = useState(false);
    const [editId, setEditId] = useState('');
    const [isDelete, setIsDelete] = useState();
    const [selectState, setSelectState] = useState([]);
    const [selectToState, setSelectToState] = useState([]);
    const [distanceOpen, setDistanceOpen] = useState(false);
    const handleDistanceOpen = (data) => { setDistanceOpen(true); setIsDelete(data) };
    const handleDistanceClose = () => { setDistanceOpen(false); };
    const prevCount = usePrevious(cityDistance);

    const getCityName = (id) => { const city = cityList.find(d => d.id == id); return city.name; }

    const getProvinceName = (id) => { return province.data[parseInt(id) - 1].provineSateName }

    const getData = async (selectState) => {
        fetchState(selectState)
    }
    const getStateData = async (selectToState) => {
        fetchToState(selectToState)
    }
    const handlecountry = async (e) => {
        const addressData = e.target.value;
        const selectedState = await getData(addressData)
        setSelectState(selectedState)
    }

    const handletocountry = async (e) => {
        const addressData = e.target.value;
        const selectedState = await getStateData(addressData)
        setSelectToState(selectedState)
    }

    const Cityupdate = (e) => { setCityDistance(cityDistance => ({ ...cityDistance, [e.target.name]: e.target.value })) }

    const EditcityItem = (citydistanceID) => {
        setEditId(citydistanceID)
        let newEditItem = cityDistanceLists.find((data) => {
            return data.citydistanceID === citydistanceID
        });
        setCityDistance(newEditItem);
        setShowDistanceSecondFields(showDistanceSecondFields => !showDistanceSecondFields)
        setIsEditItem(citydistanceID);
    }

    const editCityCustomerList = async () => {
        if (JSON?.stringify(prevCount) === JSON?.stringify(cityDistance)) {
            setTimeout(function () {
                toast.info("No Changes found on this City distance section !", {
                    position: "top-right",
                })
            });
        }
        else {
            const updateCityDistanceData = await updateCityDistance(cityDistance)
            setCityDistance("");
            handleAccount(account.account_No)
            if (updateCityDistanceData.data.statusCode === 200) {
                setTimeout(function () {
                  toast.success("City distance updated successfully !", {
                    position: "top-right",
                  })
                });
              }
        }
        if (JSON?.stringify(prevCount) !== JSON?.stringify(cityDistance)) {
            const modifieddata = {
                AccountNo: account.account_No,
                CustomerName: account.customer,
                Modifiedsection: message
            }
            await modifyHistory(modifieddata)
        }
    }

    const deleteCityCustomerList = async () => {
        console.log(isDelete);
        const deleteCityDistanceRecord = await deleteCityDistance(isDelete)
        if (deleteCityDistanceRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("City distance record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleDistanceClose();
    }

    console.log(cityDistanceLists)

    return (
        <>
            {/* City Distance section */}
            <Row className="mb-4 mt-4">
                <Col sm={12}>
                    <fieldset className="mb-3">
                        <label className="label-heading">City Distance Table</label>
                        <Row className="mb-2 mt-2">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <div className="section_action_btn">
                                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ? 
                                        <div className="save_btn"><button type="button" className="btn" onClick={e => { editCityCustomerList(e); }}><Save /></button></div>
                                        : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div> }
                                    </div>
                                    {showDistanceSecondFields ?
                                        <>
                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handlecountry(e); Cityupdate(e) }} placeholder="From Country" name="fromCountry" value={cityDistance?.fromCountry || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromCountry?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="fromProvince" onChange={(e) => { setSelectState(e.target.value); Cityupdate(e) }} value={cityDistance?.fromProvince || ""} required>

                                                                <option>{cityDistance?.fromProvince || "Select"}</option>:
                                                                <>
                                                                    {
                                                                        fromProvince?.data?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineSateName}>{data.provineSateName}</option>
                                                                        ))

                                                                    }
                                                                </>

                                                            </Form.Select>

                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From City">
                                                            <Form.Select aria-label="Floating label select example" name="fromCity" onChange={e => Cityupdate(e)} value={cityDistance?.fromCity || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        city?.data?.map((data, index) => (
                                                                            <option key={index} >{data.city}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Miles">
                                                        <FloatingLabel controlId="floatingInput" label="Miles" >
                                                            <Form.Control type="text" placeholder="Miles" name="miles" onChange={e => Cityupdate(e)} value={cityDistance?.miles || ""} min='0'/>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* 2nd Row */}

                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handletocountry(e); Cityupdate(e) }} placeholder="To Country" name="toCountry" value={cityDistance?.toCountry || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toCountry?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="toProvince" placeholder="To Province/Statey" onChange={(e) => { setSelectState(e.target.value); Cityupdate(e) }} value={cityDistance?.toProvince || ""} required>
                                                                <option>{cityDistance?.toProvince || "Select"}</option>:
                                                                <>
                                                                    {
                                                                        toProvince?.data?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineSateName}>{data.provineSateName}</option>
                                                                        ))

                                                                    }
                                                                </>

                                                            </Form.Select>

                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To City">
                                                            <Form.Select aria-label="Floating label select example" name="toCity" onChange={e => Cityupdate(e)} value={cityDistance?.toCity || ""} placeholder="To City" required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        city?.data?.map((data, index) => (
                                                                            <option key={index} >{data.city}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="In Minutes">
                                                        <FloatingLabel controlId="floatingInput" label="In Minutes" >
                                                            <Form.Control type="text" placeholder="In Minutes" name="timeInMinutes" onChange={e => Cityupdate(e)} value={cityDistance?.timeInMinutes || ""} required min='0'/>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                    {/* table */}
                                    <Row>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>From Country</th>
                                                    <th>To Country</th>
                                                    <th>From Province</th>
                                                    <th>To Province</th>
                                                    <th>From City</th>
                                                    <th>To City</th>
                                                    <th>Miles</th>
                                                    <th>In Minutes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cityDistanceLists?.length > 0 ? (
                                                    cityDistanceLists?.map((data) => {
                                                        return (
                                                            <tr key={data.citydistanceID}>
                                                                <td>
                                                                    <div className="action_btn">
                                                                    {/* {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ? 
                                                                        <span className="edit_btn" onClick={() => EditcityItem(data?.citydistanceID)}><Edit /></span>
                                                                        :  <span className="edit_btn disable"><Edit /></span> } */}
                                                                        {/*permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ? 
                                                                        <span className="del_btn" onClick={() => handleDistanceOpen(data?.CitydistanceID)}><Delete /></span>
                                                                        :           <span className="del_btn disable" ><Delete /></span> */}
                                                                        <div>
                                                                            <Dialog open={distanceOpen} onClose={handleDistanceClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                                <DialogTitle id="alert-dialog-title">
                                                                                    Delete Confirmation
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                        Are you sure you want to delete this row?
                                                                                    </DialogContentText>
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <Button className="btn-secondary" onClick={handleDistanceClose}>Cancel</Button>
                                                                                    <Button className="btn-primary" onClick={() => { deleteCityCustomerList();  }} autoFocus> Confirm</Button>
                                                                                </DialogActions>
                                                                            </Dialog>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{data.FromCountry === 1 ? "CAN" : "USA"}</td>
                                                                <td>{data.ToCountry === 1 ? "CAN" : "USA"}</td>
                                                                <td>{getProvinceName(data?.FromProvince) || ""}</td>
                                                                <td>{getProvinceName(data?.ToProvince) || ""}</td>
                                                                <td>{getCityName(data.FromCity) || ""}</td>
                                                                <td>{getCityName(data?.ToCity) || ""}</td>
                                                                <td>{data.Miles}</td>
                                                                <td>{data.TimeInMinutes}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <p>No data found </p>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </fieldset>
                            </Col>
                        </Row>



                    </fieldset>
                </Col>
            </Row>


        </>
    );
}
export default EditCityDistance;