import { apiSlice } from "../../app/services/authServices";


export const citySlice = apiSlice.injectEndpoints({
    tagTypes:['City'],
    endpoints:builder => ({
      getCity: builder.query({
        query: () => ({
          url: 'Customer/GetCityList',
          method: 'GET',
          providesTags: ['City'],
        }),
      }),
    }),
})

export const {useGetCityQuery} = apiSlice