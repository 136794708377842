import React from "react";

const Spinner = () => {
    return (
        <>
            <div className="text-center">Loading...
            
                <div class="spinner"></div>

             
            </div>


            
        </>
    )
}
export default Spinner
{/* DEFAULT
            <div className="text-center">
                Loading...
                <span className="spinner-grow spinner-grow-sm text-dark" role="status" aria-hidden="true"></span>
                <span className="spinner-grow spinner-grow-sm text-dark" role="status" aria-hidden="true"></span>
                <span className="spinner-grow spinner-grow-sm text-dark" role="status" aria-hidden="true"></span>
            </div> */}
{/* Newton's Cradle <div className="text-center">Loading...
            <div class="newtons-cradle">
            <div class="newtons-cradle__dot"></div>
            <div class="newtons-cradle__dot"></div>
            <div class="newtons-cradle__dot"></div>
            <div class="newtons-cradle__dot"></div>
            </div>
        </div> */}

        // <div class="banter-loader">
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     <div class="banter-loader__box"></div>
        //     </div>

            