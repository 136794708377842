import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from '@mui/x-data-grid-premium';


const SelectedCheque = () => {

    const columns = [
     
        { field: "Date", headerName: "Date", width: 100 },
        { field: "Type", headerName: "Type", width: 100 },
        { field: "InvoiceCredit", headerName: "Invoice/Credit", width: 140 },
        { field: "Amount", headerName: "Amount", width: 140 },
        { field: "PaidAmount", headerName: "PaidAmount", width: 140 },
        { field: "Discount", headerName: "Discount", width: 140 },
        { field: "Balance", headerName: "Balance", width: 140 },
        { field: "Division", headerName: "Division", width: 140 },
    ];
    const rows = [
        { id: 1, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 2, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 3, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 4, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 5, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 6, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 7, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        { id: 8, Date: '22/10/2022', Type: 'Invoice', InvoiceCredit: "32522.98", Amount: "1234.43", PaidAmount: "2012.0", Discount: "0.25", Balance:"6523.23",Division:"Western" },
        
    ];
    return (
        <>
                <div className="user-role-table" style={{ height: "700px" }}>
                    <h6>Selected cheque deposit - 785426</h6>
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]}/>
                </div>
                </>
        )
}
export default SelectedCheque;

