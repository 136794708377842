import { Button, FloatingLabel } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from "react-bootstrap";
import usericon from '../../assets/user-icon.png';
import passwordicon from '../../assets/password-icon.png';
import emailicon from '../../assets/email-icon.png'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import FormV from './useForm';
import validate from './loginformvalidation';
import Background from './Background';
import axios from 'axios';
import { baseUrl } from "../../config";

const Register = () => {
  const navigate = useNavigate();
  useEffect(() =>{
    if(localStorage.getItem('userId')) navigate('/');
  }, []);
  const registerApiCall = async (values) => {
    const url = `${baseUrl}/Login/Signup/`;
    //const url = "http://api.elitex.lvm.ai/Login/Signup";
    try {
      const fetchData = await axios.post(url, {
        UserName: values.username,
        EmailId: values.emailid,
        Password: values.password

      });
      if(fetchData.data.statusCode === 200 ){
        setTimeout(function () {
          toast.success("Registration Completed successfully !",{
          position: "top-center",
        })
      }, 1000);
        navigate('/login');
      }
      else if(fetchData.data.message === "Already exist with same EmailId" ){
        toast.info("Already registered ", {
          position:"top-center",
        })
      }
    } catch (error) {
    }
  }

  const { values, errors, handleChange, handleSubmit, } = FormV(() => registerApiCall(values), validate);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  return (
    <>
      <Background>
        <div className='login-form'>
          <div className="register-form-back">
            <h2>Get Started</h2>
            <p className='text-center'>We’re open 24/7. Business doesn’t stop when the work day ends, and neither do we..</p>
            <Form className="mt-4" onSubmit={handleSubmit} noValidate>
              <Form.Group className="username" controlId="formBasicUsername">
              <FloatingLabel controlId="username" label="Full Name" >
                <Form.Control placeholder="Full Name" autoComplete="off" className={`input ${errors.username && 'is-danger'}`} type="text" name="username" onChange={handleChange} value={values.username || ''} required />
  
                <img src={usericon} alt="user-icon" />
                {errors.username && (
                  <p className="help is-danger">{errors.username}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="username" controlId="formBasicUseremail">
              <FloatingLabel controlId="emailinput" label="Email" >
                <Form.Control placeholder="Email" autoComplete="off" className={`input ${errors.email && 'is-danger'}`} type="email" name="emailid" onChange={handleChange} value={values.emailid || ''} required />
        
                <img src={emailicon} alt="user-icon" />
                {errors.emailid && (
                  <p className="help is-danger">{errors.emailid}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="password" controlId="formBasicPassword">
              <FloatingLabel controlId="password" label="Password" >
                <Form.Control placeholder="Password" autoComplete="off" className={`input ${errors.password && 'is-danger'}`} type={passwordShown ? "text" : "password"} name="password" onChange={handleChange} value={values.password || ''} required />

                <img src={passwordicon} alt="password-icon" onClick={togglePassword} />
                {errors.password && (
                  <p className="help is-danger">{errors.password}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="password" controlId="formBasicPassword2">
              <FloatingLabel controlId="cpassword" label="Confirm Password" >
                <Form.Control placeholder="Confirm Password" autoComplete="off" className={`input ${errors.password && 'is-danger'}`} type={passwordShown2 ? "text" : "password"} name="password2" onChange={handleChange} value={values.password2 || ''} required />

                <img src={passwordicon} alt="password-icon" onClick={togglePassword2} />
                {errors.password2 && (
                  <p className="help is-danger">{errors.password2}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <div className="login-btn">
                <Button type="submit" variant="secondary">REGISTER <i className="fa fa-arrow-right" aria-hidden="true"></i></Button>
              </div>
            </Form>
          </div>
          <div className="sign-up-link">
            <p>Already have an account? <a href="#" onClick={() => navigate('/login')}>SIGN IN</a></p>
          </div>
        </div>
      </Background>
    </>
  );
}

export default Register;
