import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const INVOICESORTON_URL = `${baseUrl}/Customer/GetListOfInvoiceSortOn`


const initialState = []

export const fetchInvoiceSorton  = createAsyncThunk('GetListOfInvoiceSortOn/fetchInvoiceSorton', async () =>{
    try{
        const response = await axios.get(INVOICESORTON_URL);
        return[...response.data.data];
    }catch (err){
        return err.message;
    }
})


const invoiceSortonSlice = createSlice({
    name:"invoicesorton",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchInvoiceSorton.fulfilled, (state, action) => {
          return action.payload
        })
      }
})

export const invoiceSortonList  = (state) => state.invoiceSortonList;

export default invoiceSortonSlice.reducer