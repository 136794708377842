import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { useUpdateDistanceFuelDataMutation, useDeleteDistanceFuelDataMutation, useAddFuelDistanceAfterEditMutation } from "../../../features/distanceFuel/distanceFuelSlice";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useCustomData } from "../../Sidebar/Permission";
import useDeleteData from "../useDeleteData";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const EditDistanceFuel = () => {
  const [updateDistanceFuel] = useUpdateDistanceFuelDataMutation()
  const [deleteDistanceFuel] = useDeleteDistanceFuelDataMutation()
  const [addFuelAfterEdit] = useAddFuelDistanceAfterEditMutation()
  const [handleClose,handleOpen,open,deleteData] = useDeleteData(deleteDistanceFuel)
  const { selected, contractInfo } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0]
  const [permission] = useCustomData()
  const [modifyHistory] = useModifyHistoryMutation()
  const { handleAccount, singleAccount, fuelDistanceList } = useCustomer();
  const account = singleAccount && singleAccount[0];
  const [message, setMessage] = useState("Modified the distance fuel section")
  const [isEditItem, setIsEditItem] = useState(null);
  const [fuelDistance, setFuelDistance] = useState({})
  const [showFuelSecondFields, setShowFuelSecondFields] = useState(false);
  const [editId, setEditId] = useState('');
  const prevCount = usePrevious(fuelDistance);

  const Fuelupdate = (e) => { setFuelDistance(fuelDistance => ({ ...fuelDistance, [e.target.name]: e.target.value })) }

  const EditfuelItem = (distanceFuelID) => {
    setEditId(distanceFuelID)
    let newEditItem = fuelDistanceList.find((data) => {
      return data.distanceFuelID === distanceFuelID
    });
    setFuelDistance(newEditItem);
    setShowFuelSecondFields(showFuelSecondFields => !showFuelSecondFields)
    setIsEditItem(distanceFuelID);
  }


  const editfuelCustomerList = async () => {
    if (JSON?.stringify(prevCount) === JSON?.stringify(fuelDistance)) {
      setTimeout(function () {
        toast.info("No Changes found on this distance fuel section !", {
          position: "top-right",
        })
      });
    }
    else {
      const updateDistanceFuelData = await updateDistanceFuel(fuelDistance)
      setFuelDistance("");
      handleAccount(account.account_No)
      if (updateDistanceFuelData.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Distance fuel section updated successfully !", {
            position: "top-right",
          })
        });
      }
    }
    if (JSON?.stringify(prevCount) !== JSON?.stringify(fuelDistance)) {
      const modifieddata = {
        AccountNo: account.account_No,
        CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
  }

  const addNewRecord = () => {
    if (Object.keys(fuelDistance).length === 0) {
      setShowFuelSecondFields(true)
    }
    else {
      const updateDistanceFuel = addFuelAfterEdit({ rateCardID: ratecardaccount?.rateCardId, ...fuelDistance })
      setFuelDistance("");
      handleAccount(account.account_No)
      if (updateDistanceFuel?.data?.statusCode === 200) {
        toast.success("Distance fuel record added successfully !", {
          position: "top-right",
        })
        setFuelDistance("");
        handleAccount(account.account_No)
      }
    }
  }

  return (
    <>
      {/* Distance fuel surcharge table */}
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">Distance Fuel Table</label>
            <Row className="mb-2 mt-2">
              <Col sm={12}>
                <fieldset className="pb-2">
                  <div className="d-flex justify-content-end">
                    <div className="section_action_btn">
                      {fuelDistance?.distanceFuelID ?
                        <div className="save_btn"><button type="button" className="btn" onClick={e => { editfuelCustomerList(e); }}>Update record</button></div>
                        :
                        <>
                          {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                            <div className="add_btn"><button type="button" className="btn" onClick={e => { addNewRecord(e); }}>Add new record</button></div>
                            : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div>}
                        </>
                      }
                      {/* {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                     <div className="save_btn"><button type="button" className="btn" onClick={e => { editfuelCustomerList(e); }}><Save /></button></div>
                      : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div>}
                  </div>
                  <div className="section_action_btn">
                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                      <div className="save_btn"><button type="button" className="btn" onClick={e => { addNewRecord(e); }}>Add new record</button></div>
                      : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div>} */}
                    </div>
                  </div>
                  {showFuelSecondFields ?
                    <>
                      <Row className="mt-2 mb-3">
                        <Col sm={4}>
                          <Form.Group controlId="FromCost">
                            <FloatingLabel controlId="floatingInput" label="From Cost" >
                              <Form.Control type="number" placeholder="From Cost" name="fromCost" value={fuelDistance?.fromCost || ""} onChange={e => Fuelupdate(e)} required min='0' />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="To Cost" >
                              <Form.Control type="number" placeholder="To Cost" name="toCost" value={fuelDistance?.toCost || ""} onChange={e => Fuelupdate(e)} required min='0' />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="Surcharge">
                            <FloatingLabel controlId="floatingInput" label="Surcharge" >
                              <Form.Control type="number" placeholder="Surcharge" name="surcharge" value={fuelDistance?.surcharge || ""} onChange={e => Fuelupdate(e)} required min='0' />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    : null}
                  {/* table */}
                  <Row>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>From Cost</th>
                          <th>To Cost</th>
                          <th>Surcharge</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fuelDistanceList?.length > 0 ? (
                          fuelDistanceList?.map((data) => {
                            return (
                              <tr key={data?.distanceFuelID}>
                                <td>
                                  <div className="action_btn">
                                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                      <span className="edit_btn" onClick={() => EditfuelItem(data?.distanceFuelID)}><Edit /></span>
                                      : <span className="edit_btn disable"><Edit /></span>}
                                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ?
                                      <span className="del_btn" onClick={() => handleOpen(data?.distanceFuelID)}><Delete /></span>
                                      : <span className="del_btn disable"><Delete /></span>}
                                    <div>
                                      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                        <DialogTitle id="alert-dialog-title">
                                          Delete Confirmation
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                          <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                          <Button className="btn-primary" onClick={() => { deleteData(data.distanceFuelID); handleClose(); }} autoFocus> Confirm</Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>
                                  </div>
                                </td>
                                <td>{data.fromCost}</td>
                                <td>{data.toCost}</td>
                                <td>{data.surcharge}</td>

                              </tr>
                            )
                          })
                        ) : (
                          <p>No data found </p>
                        )}
                      </tbody>
                    </Table>
                  </Row>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>

      {/* <ToastContainer /> */}

    </>
  );
}
export default EditDistanceFuel;