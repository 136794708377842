import { Button ,Form, FloatingLabel, } from "react-bootstrap"
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import { Email } from "@material-ui/icons";
import { Print } from "@material-ui/icons";


const PrintPost = () =>{    

    // Previously Processed inovice
    const column = [
     
        {
            field: "Select",
            headerName: "Select",
            sortable: false,
            width: 200,
            disableClickEventBubbling: true,
            renderCell: (data) => {
          
      
              return (
                <>
                  <>
                    <div className="action_btn">
                      <div className="floating-checbox">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="CWT" name="RateType" />
                                      <label className="form-check-label" htmlFor="inlineCheckbox1"></label>
                                    </div>
                                  </div>
                    </div>
                  </>
                </>
              )
      
            },
          },
        { field: "Batch", headerName: "Batch", width: 200 },
        { field: "Cycle", headerName: "Cycle", width: 200 },
        { field: "Invoicedate", headerName: "Invoicedate", width: 200 },
        { field: "Process", headerName: "Process", width: 200 },
        { field: "Start", headerName: "Start", width: 200 },
        { field: "End", headerName: "End", width: 200 },
        { field: "Total", headerName: "Total", width: 200 }
    ];

    const row = [
        { id: 1, Batch: '20532', Cycle: 'Bombard', Invoicedate: "22/10/2022", Process: "01/11/2022", Start: "20/10/2023" , End: "20/10/2023", Total : "1450.00"},
    ];

    return(
        <>
      <div className="print-invoice">
                <div className="d-flex">
                    <Form.Group controlId="accountnumber" style={{ marginRight: "10px" }}>
                        <FloatingLabel controlId="floatingInput" label="Start Invoice">
                            <Form.Control type="text" placeholder="Account Name" name="AccountName" value="" />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="accountnumber" style={{ marginRight: "10px" }}>
                        <FloatingLabel controlId="floatingInput" label="Ending Invoice">
                            <Form.Control type="text" placeholder="Account Name" name="AccountName" value="" />
                        </FloatingLabel>
                    </Form.Group>
                    <div className="invoice-btn">
                        <Button>Search</Button>
                    </div>
                </div>
                {/* print button */}
                <div className="email-section d-flex">
                    <div className="invoice-btn">
                        <Button variant="outline-primary"> <Email/> Email invoice to user</Button>
                    </div>
                    <div className="invoice-btn">
                        <Button variant="outline-primary"> <Print/> Print Register</Button>
                    </div>
                    <div className="invoice-btn">
                        <Button> <Print/> Print Invoice</Button>
                    </div>
                </div>
            </div>
      {/* Previously Inovides */}
      <div className="user-role-table " style={{ height: "400px" }}>
        <h6>List of posted invoice</h6>
        <DataGrid rows={row} columns={column} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
      </div>
        </>
    )
}
export default PrintPost