import { useState, useEffect } from "react";
import { Button, Col, Row, Form, FloatingLabel } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import useCustomer from "../../context/CustomerContext";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { Typeahead } from "react-bootstrap-typeahead";
import CountryProvince from "../CountryProvince";

const Specialaddress = ()=>{
    const { addSpecialAddress, specialAddress, setSpecialAddress, vehicleSurchargeList, serviceSurchargeList, specialAddressList, singleAccount, handleAccount } = useCustomer();
    const { city, handleProvince, newHandleCountry } = CountryProvince();
    const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
    const toCityList = cityList;
    const fromCityList = cityList;
    const [toSelection, setToSelection] = useState([]);
    const [fromSelection, setFromSelection] = useState([]);
    const [toProvince, setToProvince] = useState([]);
    const [fromProvince, setFromProvince] = useState([]);
    const [toCountryList, setToCountryList] = useState([]);
    const [fromCountryList, setFromCountryList] = useState([]); 
    const { control, register } = useForm();
    const [fuelSurcharge, setFuelSurcharge] = useState(false);
    const [selectedData, setSelectedData] = useState(null)
    const [service, setService] = useState([]);
    const account= singleAccount && singleAccount[0];

    useEffect(() => {
      if (selectedData) {
        setSpecialAddress({...specialAddress, vehicleType: selectedData.value});
      }
    }, [selectedData])

    useEffect(() => {
      if (fuelSurcharge) {
        setSpecialAddress({...specialAddress, fuelSurcharge: fuelSurcharge});
      }
    }, [fuelSurcharge])

    // hook for To City
    useEffect(async () => {
      if (toSelection.length > 0) {
          setSpecialAddress({...specialAddress, toCity: toSelection[0].id})
          const pList = await handleProvince(toSelection[0].id);
          setToProvince(pList.data);
      }
      else {
          setToProvince([]);
          setToCountryList([]);
      }
  }, [toSelection])
    // hook for From City
    useEffect(async () => {
        if (fromSelection.length > 0) {
            setSpecialAddress({...specialAddress, fromCity: fromSelection[0].id})
            const pList = await handleProvince(fromSelection[0].id);
            setFromProvince(pList.data);
        }
        else {
            setFromProvince([]);
            setFromCountryList([]);
        }
    }   , [fromSelection])
    const setCountry = async (e) => {
        if (e.target.value !== 'Select') {
            let cList;
            if (e.target.name === "fromProvince") {
                cList = await newHandleCountry(fromProvince.data[0].provineStateID);
                setFromCountryList(cList.data);
            }
            else if (e.target.name === "toProvince") {
                cList = await newHandleCountry(toProvince.data[0].provineStateID);
                setToCountryList(cList.data);
            }
        }
        else {
            if (e.target.name === "fromProvince") {
                setFromCountryList([]);
            }
            else {
                setToCountryList([]);
            }
        }
    }

    const servicesByVehicle = selectedData? serviceSurchargeList.filter((service) => service.vehicleTypes_String === selectedData.value) : [];

    var arr = []
    vehicleSurchargeList.map((user) => {
      return arr.push({ value: user.vehicleType, label: user.vehicleType });
    });

    const rows = specialAddressList?.map((data) => {
      return {
        id: data.rateCardServiceLocationOverrideId,
        quotePrice: data.flatRate,
        fuelSurcharge: data.fuelSurcharge,
        shipperName: data.shipper,
        shipperStreetNumber: data.fromStreetNo,
        shipperStreetName: data.fromStreet,
        shipperUnit: data.fromUnit || "",
        shipperCity: data.fromCity,
        shipperProvince: data.fromProvince,
        shipperCountry: data.fromCountry,
        shipperPostalCode: data.fromPostalCode,
        deliverToName: data.deliver,
        deliverToStreetNumber: data.toStreetNo,
        deliverToStreetName: data.toStreet,
        deliverToUnit: data.toUnit || "",
        deliverToCity: data.toCity,
        deliverToProvince: data.toProvince,
        deliverToCountry: data.toCountry,
        deliverToPostalCode: data.toPostalCode,
        vehicle: data.vehicleType,
        services: data.serviceName,
        pcsSched: data.pcs,
        wgtSched: data.weight, 
        reference: data.reference,
      } 
    });

    const columns = [    
      { field: 'quotePrice', headerName: 'Quote Price', width: 100 },
      { field: 'fuelSurcharge', headerName: 'Fuel Surcharge', width: 130 },
      { field: 'shipperName', headerName: 'Shipper Name', width: 130 },
      { field: 'shipperStreetNumber', headerName: 'Street No.', width: 100 },
      { field: 'shipperStreetName', headerName: 'Street Name', width: 110 },
      { field: 'shipperUnit', headerName: 'Unit', width: 60 },
      { field: 'shipperCity', headerName: 'City', type: 'number', width: 80 },
      { field: 'shipperProvince', headerName: 'Province', type: 'number', width: 100 },
      { field: 'shipperCountry', headerName: 'Country', type: 'number', width: 100 },
      { field: 'shipperPostalCode', headerName: 'Postal Code', type: 'number', width: 100 },
      { field: 'deliverToName', headerName: 'Deliver To Name', type: 'number', width: 130 },
      { field: 'deliverToStreetNumber', headerName: 'Street No.', width: 100 },
      { field: 'deliverToStreetName', headerName: 'Street Name', width: 110 },
      { field: 'deliverToUnit', headerName: 'Unit', width: 60 },
      { field: 'deliverToCity', headerName: 'City', type: 'number', width: 80 },
      { field: 'deliverToProvince', headerName: 'Province', type: 'number', width: 100 },
      { field: 'deliverToCountry', headerName: 'Country', type: 'number', width: 100 },
      { field: 'deliverToPostalCode', headerName: 'Postal Code', type: 'number', width: 100 },      
      { field: 'vehicle', headerName: 'Vehicle', type: 'number', width: 100 },
      { field: 'services', headerName: 'Services', type: 'number', width: 100 },
      { field: 'pcsSched', headerName: 'Pcs. Sched', type: 'number', width: 100 },
      { field: 'wgtSched', headerName: 'Wgt. Sched', type: 'number', width: 100 },
    { field: 'reference', headerName: 'Reference', type: 'number', width: 100 },
    ];

    const updateSpecialAddress = (e) => {setSpecialAddress(specialAddress => ({...specialAddress, [e.target.name]: e.target.value}))}

    const addSpecialAddressRecord = async () => {
      await addSpecialAddress();
      handleAccount(account?.account_No);
      setSpecialAddress({});
      setToSelection([]);
      setFromSelection([]);
      setSelectedData(null);
    }

    return(
        <>
        {/* Special address */}
        <Row>
            <Col sm={6}>
                <Row>
                <Col sm={4}><h6 className="input-heading">Enter quote price</h6></Col>
  <Col sm={6}>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Filler Code" >
  <Form.Control type="text" name="flatRate" placeholder="Filler code" value={specialAddress.flatRate || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
                </Row>
            </Col>
            <Col sm={6}>
            <Row>
                <Col sm={3}></Col>
                <Col sm={5}>
                <div className="form-check input-heading">
         <input type="checkbox" id="formBasicCheckbox" checked={fuelSurcharge} className="form-check-input" onChange={() => {setFuelSurcharge(!fuelSurcharge);}} />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label"> Do not add a fuel surcharge</label>
         </div>
                </Col>
  <Col sm={4}>
  <Button variant="secondary" onClick={() => addSpecialAddressRecord()}>Add</Button>
  </Col>
                </Row>
            </Col>
        </Row>
        {/* shipper and deliver info */}
        <Row>
            <Col sm={6}>
            <fieldset className="mt-3 mb-3">
 <label className="label-heading">Shipper</label>
      <>
  <Row className="mt-2 mb-2">
  <Col sm={12} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Shipper" >
  <Form.Control type="text" name="shipper" placeholder="Shipper" value={specialAddress.shipper || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="St#" >
  <Form.Control type="text" name="fromStreetNo" placeholder="St#" value={specialAddress.fromStreetNo || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Street" >
  <Form.Control type="text" name="fromStreet" placeholder="Street" value={specialAddress.fromStreet || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Unit" >
  <Form.Control type="text" name="fromUnit" placeholder="Unit" value={specialAddress.fromUnit || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
    <Typeahead 
      id='cityAutocomplete'
      labelKey="name"
      onChange={setFromSelection}
      options={fromCityList}
      placeholder="Choose a city..."
      selected={fromSelection}
    />
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Province" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="From Province" className="province-selector">
      <Form.Select aria-label="Floating label select example" name="fromProvince" onChange={(e) => {updateSpecialAddress(e); setCountry(e)}} value={specialAddress.fromProvince || ""} required>
        <option>Select</option>:
        <>
          {
            fromProvince?.data?.map((data, index) => (
              <option key={data.provineStateID} value={data.provineStateID}>{data.provineSateName}</option>
            ))

          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="country" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="From Country">
      <Form.Select aria-label="Floating label select example" name="fromCountry" value={specialAddress.fromCountry || ''} onChange={(e) => {updateSpecialAddress(e)}} required>
        <option>Select</option>:
        <>
          {
              fromCountryList?.data?.map((data, index) => (
                <option key={data.countryId} value={data.countryId} >{data.countryname}</option>
              ))
          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Postal Code" >
  <Form.Control type="text" name="fromPostalCode" placeholder="Postal Code" value={specialAddress.fromPostalCode || ""} onChange={(e) => updateSpecialAddress(e)} />
  </FloatingLabel>    
  </Form.Group>
  </Col>
  </Row>
  <Row>
  </Row>
</>
</fieldset>
{/* Deliver to */}
            </Col>
            <Col sm={6}>
            <fieldset className="mt-3 mb-3">
 <label className="label-heading">Deliver to</label>
      <>
  <Row className="mt-2 mb-2">
  <Col sm={12} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Deliver to" >
  <Form.Control type="text" name="deliver" placeholder="Deliver to" value={specialAddress.deliver || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="St#" >
  <Form.Control type="text" name="toStreetNo" placeholder="St#" value={specialAddress.toStreetNo || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Street" >
  <Form.Control type="text" name="toStreet" placeholder="Street" value={specialAddress.toStreet || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Unit" >
  <Form.Control type="text" name="toUnit" placeholder="Unit" value={specialAddress.toUnit || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
    <Typeahead 
      id='cityAutocomplete'
      labelKey="name"
      onChange={setToSelection}
      options={toCityList}
      placeholder="Choose a city..."
      selected={toSelection}
    />
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Province" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="To Province">
      <Form.Select aria-label="Floating label select example" name="toProvince" onChange={(e) => {updateSpecialAddress(e); setCountry(e)}} value={specialAddress.toProvince || ""} required>
        <option>Select</option>:
        <>
          {
            toProvince?.data?.map((data, index) => (
              <option key={data.provineStateID} value={data.provineStateID}>{data.provineSateName}</option>
            ))

          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="country" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="To Country">
      <Form.Select aria-label="Floating label select example" name="toCountry" value={specialAddress.toCountry || ''} onChange={(e) => {updateSpecialAddress(e)}} required>
        <option>Select</option>:
        <>
          {
              toCountryList?.data?.map((data, index) => (
                <option key={data.countryId} value={data.countryId} >{data.countryname}</option>
              ))
          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Postal Code" >
  <Form.Control type="text" name="toPostalCode" placeholder="Postal Code" value={specialAddress.toPostalCode || ""} onChange={(e) => updateSpecialAddress(e)} />
  </FloatingLabel>    
  </Form.Group>
  </Col>
  </Row>
  <Row>
  </Row>
</>
</fieldset>
            </Col>
        </Row>
{/* Remote login info */}
<Row className="mb-4 mt-5">
     <Col sm={12}>
 <fieldset >
 <label className="label-heading">Vehicle Type</label>
      <>
  <Row className="mt-2 mb-2">
  <Col>
    <Controller
      control={control}
      name="VehicleTypes"
      defaultValue={selectedData || ''}
      {...register('VehicleTypes')}
      render={({ field: { onChange, value, name, ref } }) => (
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: "47px",
            }),
          }}
          placeholder="Select Vehicle"
          name={name}
          defaultValue={selectedData || ''}
          ref={ref}
          options={arr}
          // value = {serviceData.VehicleTypes?.map((d)=>d.value)?.join(' , ')}
          value={selectedData || ""}
          onChange={(e) => {setSelectedData(e);}}
        />
      )}
    />
  </Col>
  <Col>
    <Form.Group controlId="Service">
      <FloatingLabel controlId="floatingSelectGrid" label="Service">
        <Form.Select aria-label="Floating label select example" name="serviceID" className="input" value={service} onChange={(e) => {setService(e.target.value);setSpecialAddress({...specialAddress, rateCardServiceId: parseInt(e.target.value)})}}>
          <option>Select</option>:
          <>
            {
              servicesByVehicle?.map((data, index) => (
                <option key={index} value={data.rateCardServiceID}>{data.serviceDescription}</option>
              ))

            }
          </>
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Pcs.Sched" >
  <Form.Control type="text" name="pcs" placeholder="Discount" value={specialAddress.pcs || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Wgt.Sched" >
  <Form.Control type="text" name="weight" placeholder="Remote login" value={specialAddress.weight || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Reference" >
  <Form.Control type="text" name="reference" placeholder="Remote login" value={specialAddress.reference || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  </Row>
  <Row>
    <div className="datatable_section">
      <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} checkboxSelection/>
    </div>
  </Row>
</>
</fieldset>
</Col>
</Row>
</>
    );
}
export default Specialaddress;