import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";


export const heavyLoadsSlice = apiSlice.injectEndpoints({
    endpoints:builder => ({
      editHeavyFuelEffective: builder.mutation({
      query(data) {
        const {fuelsurchargerateid, ...body} = data
        return {
          url: `Customer/EditFuelEffectivelSection?fuelsurchargerateid=${fuelsurchargerateid}`,
          method: "POST",
          body
        };
      },
      }),
      deleteHeavyFuel: builder.mutation({
        query : (fuelsurchargerateid) => ({
              url: `/Customer/DeleteFuelEffectiveDateSectionLTLFTLHTL?fuelsurchargerateid=${fuelsurchargerateid}`,
              method: "POST",
          }),
      }), 
      addCustomerFuelHtl: builder.mutation({
        query(body) {
          return {
            url: `Customer/AddCustomerAddFuelLTLHTLFTLafterEdit`,
            method: "POST",
            body
          };
        },
        }),
   
    }),
})

export const { useEditHeavyFuelEffectiveMutation ,useAddCustomerFuelHtlMutation,useDeleteHeavyFuelMutation } = apiSlice
const initialState = {
    list: []
};

const heavyLoadSlice = createSlice({
    name: "heavyLoad",
    initialState,
    reducers: {
        addHeavyLoad: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteHeavyLoad: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editHeavyLoad : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            return { ...state, list: currentLists };
        },
        editHeavyLoadAfter: (state, action) => {
          const currentLists = Array.from(state.list);
          const filterData = state.list.findIndex((list) => list.list.fuelsurchargerateid === action.payload.id);
          currentLists[filterData] = {
              id: action.payload.id,
              list: action.payload
          }
          return { ...state, list: currentLists };
      },
        resetHeavyLoad: () => initialState, 
    },
})

export const { addHeavyLoad, editHeavyLoad, deleteHeavyLoad, editHeavyLoadAfter, resetHeavyLoad } = heavyLoadSlice.actions

export default heavyLoadSlice.reducer