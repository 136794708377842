import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import AssetsType from './AssetsType';
import ManageInventory from './ManageInventory';
import AddVehicleType from './AddVehicleType';
import AssetsInventory from './AssetsInventory';

const VehicleTabs = () => {
    const [showInventory, setShowInventory] = useState("Manage inventory");
    const [activeTab, setActiveTab] = useState("first");
    // const [key, setKey] = useState("name");
    const showhandler = () => { setShowInventory(!showInventory); }
    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);

    }
    return (
        <div>
            <Tabs id="controlled-tab-example" activeKey={activeTab} className="mb-3" onSelect={handleTabSelect}>

                <Tab eventKey="first" title="Add Vehicle Type">
                    {activeTab === 'first' && <AddVehicleType />}
                </Tab>
                <Tab eventKey="second" title="Manage inventory">
                    {activeTab === 'second' && <ManageInventory />}
                </Tab>
                <Tab eventKey="third" title="Add Assets Type">
                    {activeTab === 'third' && <AssetsType />}
                </Tab>
                <Tab eventKey="fourth" title="Assets Inventory">
                    {activeTab === 'fourth' && <AssetsInventory />}
                </Tab>

            </Tabs>
        </div>
    )
}

export default VehicleTabs
