import { Row, Col, Button, FloatingLabel, Form } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import Pickup from "./Pickup";
import Dropup from "./Dropup";
import PackageInfo from "./PackageInfo";
import ButtonsLocalDipatch from "./ButtonsLocalDipatch";
// import AdditionalInfo from "./Additionalinfo";


const OrderDetails = ({ setOpen }) => {
    return (
        <div className="order-details-section mb-2">
            <Row className="pb-2">
                <Col sm={5}>
                    <div className="basic-order-info">
                        <div className="ready-at">
                            <span> Ready at:</span>
                            <span> 01/11/20222 08:00PM</span>
                        </div>
                        <div className="order-number">
                            <span> Order number:</span>
                            <span> 876532</span>
                        </div>
                    </div>
                </Col>
                <Col sm={5}></Col>
                <Col sm={2}><Button className="btn btn-outline" onClick={() => setOpen(false)}>BACK TO ORDER</Button></Col>
            </Row>
            {/* 2nd row */}
            <Row>
                <hr />
                <Col sm={3}>
                    <h6>Order Details</h6>
                </Col>
                <Col sm={6}></Col>
                <Col sm={3}>
                    <div className="edit-section">
                        <div className="form-check">
                            <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                            <label title="" className="form-check-label statusUpdate">Auto Status Update</label>
                        </div>
                        <div className="edit-order"><Edit /></div>
                    </div>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col sm={6}><Pickup /></Col>
                <Col sm={6}><Dropup /></Col>
            </Row>
            <Row>
                <Col sm={12}><PackageInfo /></Col>
            </Row>
            <Row>
                <FloatingLabel controlId="floatingTextarea2" label="Type something...">
                    <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '80px', padding: "10px" }} name="Tittle" />
                </FloatingLabel>
            </Row>
            <Row>
                <Col sm={12}>
                <ButtonsLocalDipatch/>
                </Col>
            </Row>
        </div>
    )
}
export default OrderDetails