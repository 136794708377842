import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

export const accessorialSlice = apiSlice.injectEndpoints({
  tagTypes: ['Accessorial'],
  endpoints: builder => ({
    getAccessorial: builder.query({
      query: () => ({
        url: 'Customer/GetListOfAccessorial',
        method: 'GET',
        providesTags: ['Accessorial'],
      }),
    }),
    updateAccessorialData: builder.mutation({
      query(data) {
        const { rateCardAccessorialID, ...body } = data
        return {
          url: `Customer/EditAccesorialSurchargeSection?RateCardAccessorialID=${rateCardAccessorialID}`,
          method: "POST",
          body
        };
      },
    }),
    deleteAccessorialData: builder.mutation({
      query: (rateCardAccessorialID) => ({
        url: `Customer/DeleteAccesorialSurchargeSection?RateCardAccessorialID=${rateCardAccessorialID}`,
        method: "POST",
      }),
    }),
    addAccessorialSurchargeAfterEdit: builder.mutation({
      query: (body) => ({
        url: `Customer/AddCustomerAccessorialSurchargeAfterEdit`,
        method: "POST",
        body,
      }),
    }),
    createAccessorial: builder.mutation({
      query: (body) => ({
        url: `Admin/AddAccessorial`,
        method: "POST",
        body,
      }),
    }),
    getAccessorialDetailsByID : builder.query({
      query:(accessorialID) => ({
        url:`Admin/GetAccessorialDetailById?AccessorialID=${accessorialID}`,
        method:"GET",
        providesTags:['Accessorial']
      })
    }),
    updateAccessorialList : builder.mutation({
      query:(data) => ({
        url:'Admin/UpdateAccessorial',
        method:"POST",
        body:data,
      }),
      invalidatesTags: ['Accessorial'],
    }),

    getAllAccessorial: builder.query({
      query:() => ({
        url:`Admin/GetAllAccessorial`,
        method:"GET",
      })
    }),
    deleteAccessorialList: builder.mutation({
      query: (accessorialID) => ({
        url: `Admin/DeleteAccessorial?AccessorialID=${accessorialID}`,
        method: "POST",
      }),
    }),
  }),
})

export const { useGetAccessorialQuery, useUpdateAccessorialDataMutation, useDeleteAccessorialDataMutation ,useAddAccessorialSurchargeAfterEditMutation,
useCreateAccessorialMutation,useLazyGetAccessorialDetailsByIDQuery,useUpdateAccessorialListMutation,useDeleteAccessorialListMutation , useGetAllAccessorialQuery} = apiSlice

const initialState = {
  list: []
};

const accessorialDetailSlice = createSlice({
  name: "accessorialDetails",
  initialState,
  reducers: {
    addAccessorial: (state, action) => {
      const lists = {
        id: new Date().getTime().toString(),
        list: action.payload,
      };
      state.list.push(lists);
    },
    deleteAccessorial: (state, action) => {
      state.list = state.list.filter(
        (data) => data.id !== action.payload
      )
    },
    editAccessorial: (state, action) => {
      const currentLists = Array.from(state.list);
      const filterData = state.list.findIndex((list) => list.id === action.payload.id);
      currentLists[filterData] = {
        id: action.payload.id,
        list: action.payload
      }
      return { ...state, list: currentLists };
    },
    resetAccessorial: () => initialState,
  },
})

export const { addAccessorial, editAccessorial, deleteAccessorial, resetAccessorial } = accessorialDetailSlice.actions

export default accessorialDetailSlice.reducer