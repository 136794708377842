import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from '@mui/x-data-grid-premium';


const DetailsCheque = () => {

    const columns = [
     
        { field: "AccountNo", headerName: "Account No.", width: 260 },
        { field: "ChequeNo", headerName: "Cheque No", width: 260 },
        { field: "Chequedate", headerName: "Cheque Date", width: 260 },
        { field: "ChequeAmount", headerName: "Cheque Amount", width: 260 },
    ];
    const rows = [
        { id: 1, AccountNo: '785426', ChequeNo: '22/10/2022',Chequedate: "22/10/2022", ChequeAmount: "500000.00" },
        { id: 2, AccountNo: '785426', ChequeNo: '22/10/2022',Chequedate: "22/10/2022", ChequeAmount: "500000.00" },
        { id: 3, AccountNo: '785426', ChequeNo: '22/10/2022',Chequedate: "22/10/2022", ChequeAmount: "500000.00" },
        { id: 4, AccountNo: '785426', ChequeNo: '22/10/2022',Chequedate: "22/10/2022", ChequeAmount: "500000.00" },
    ];
    return (
        <>
                <div className="user-role-table" style={{ height: "400px" }}>
                {/* <h6>List of Canadian Sales</h6> */}
                    {/* <h6>Inventory list by Account</h6> */}
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]}/>
                </div>
                </>
        )
}
export default DetailsCheque;

