import { createSlice} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/services/authServices";


export const roleApiSlice = apiSlice.injectEndpoints({
  tagTypes:['Role','User'],
  endpoints:builder => ({
    getRoles: builder.query({
      query: () => ({
        url: 'Role/GetRoleList',
        method: 'GET',
        keepUnusedDataFor: 0,
        providesTags: ['Role'],
      }),
    }),
    createRoles: builder.mutation({
      query: (data) => ({        
        url: 'Role/CreateRole?newRole=' + data.role,
        method: 'POST',
      }),
      invalidatesTags: ['Role'],
    }),
    getRoleDetails: builder.query({ 
      query: (role) =>({
        url:`/Account/GetPermissionListofUser?userName=${role}`,
        method:'GET',
      }),
    }),
    addUserPermission: builder.mutation({
      query: (data) => ({
        url: 'Role/UpdatePermissionToRole',
        method: 'POST',
        body: data,
        // keepUnusedDataFor: 300,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),

    getPermissionData: builder.query({
      query: (data) => ({
        url: `Role/GetPermissionsListForRole?role=${data}` ,
        method: 'GET',
        keepUnusedDataFor: 300,
      }),
    }),

    getApiAdminModule:builder.mutation({
      query: (id) =>({
        url:`/Role/GetRolePermissionsForSelectedModule?ModuleId=${id}`,
        method:'POST',
        // keepUnusedDataFor: 300,
    }),
  }),

    getUserData: builder.query({
      query: (data) => ({
        url: `Role/GetRoleDetail?role=${data}` ,
        method: 'GET',
        keepUnusedDataFor: 300,
      }),
    }),
    // User Role module
    addUserRoles: builder.mutation({
      query: (data) => ({
        url: 'Account/UserRegisteration',
        method: 'POST',
        body:data
      }),
      invalidatesTags: ['User'],
    }),
    getRegisterUser: builder.query({
      query: () => ({
        url: 'account/getuserlist',
        method: 'GET',
        providesTags: ['User'],
      }),
    }),
    deleteRegisterUser: builder.mutation({
      query: ( user = { email: 'email', isActive: 'isActive' } ) => ({
          url: '/Account/ActivateUser',
          method:'POST',
          params: user
      }),
      invalidatesTags: ['User'],
    }),
    getRegisteredUserPermission: builder.query({ 
      query: (email) =>({
        url:`/Account/GetPermissionListofUser?userName=${email}`,
        method:'GET',
        providesTags: ['User'],
      }),
    }),
    updateRegisteredUserPermission: builder.mutation({
      query: (data) => ({
        url:'Account/UpdateUserPermission',
        method:'POST',
        body:data
      }),
      invalidatesTags: ['User'],
    }),
    changeUserRole:builder.mutation({
      query:( user = { email: 'email', userRole: 'role' } ) => ({
        url:'/Account/ChangeUserRole',
        method:'POST',
        params:user
      })
    }),
  })
})
export const { useGetRolesQuery, useCreateRolesMutation , useLazyGetRoleDetailsQuery, useAddUserPermissionMutation ,useGetUserDataQuery,
  useAddUserRolesMutation, useGetRegisterUserQuery, useDeleteRegisterUserMutation ,useLazyGetRegisteredUserPermissionQuery , useUpdateRegisteredUserPermissionMutation,
   useChangeUserRoleMutation , useGetPermissionDataQuery ,useGetApiAdminModuleMutation } = apiSlice




const initialState = {
  show:false,
    addrolelist: [],
 
};

const createRoleSlice = createSlice({
    name: "createRole",
    initialState,
    reducers: {
      toggleShow:(state) =>{
        state.show = !state.show
      },
        addNewRole: (state, action) => {
            const rolelists = {
              id: new Date().getTime().toString(),
              addrolelist: action.payload,
            };
            state.addrolelist.push(rolelists);
          },
        },
})

export const { addNewRole, toggleShow } = createRoleSlice.actions

export default createRoleSlice.reducer