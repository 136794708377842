import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

const initialState = {
    list: []
};

export const contactApiSlice = apiSlice.injectEndpoints({
    tagTypes:['Contact'],
    endpoints:builder => ({ 
        updateContactData: builder.mutation({
            query(data) {
                const {contactID, ...body} = data
                return {
                    url: `Customer/EditMultipleContacts?ContactID=${contactID}`,
                  method: "POST",
                  body
                };
              },
          }), 
          deleteContactData: builder.mutation({
            query : (contactID) => ({
                  url: `Customer/DeleteMultipleContact?ContactID=${contactID}`,
                  method: "POST",
              }),
          }),   
          
          AddCustomerDetailsAfterEdit: builder.mutation({
            query : (body) => ({
                  url: `Customer/AddCustomerDetailsAfterEdit`,
                  method: "POST",
                  body,
              }),
          }),  
    })    
})

export const {useUpdateContactDataMutation, useDeleteContactDataMutation , useAddCustomerDetailsAfterEditMutation} = apiSlice


const contactDetailsSlice = createSlice({
    name: "contactDetails",
    initialState,
    reducers: {
        addContact: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        // addContact: (state, action) => {
        //     return {
        //         ...state,
        //         list: [...state.list,                 
        //             {id: new Date().getTime().toString(),...action.payload,}
        //         ]
        //     }
        // },
        deleteContact: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        // editContact: (state, action) =>{
        //     debugger
        //     let {list} = state;
        //     state.list = list.map((data) =>
        //     data.id === action.payload.id ? action.payload : data
        //     )
        // }
        editContact : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            //.log("currentlisss",currentLists);
            return { ...state, list: currentLists };
        },
    },
})

export const { addContact, editContact, deleteContact } = contactDetailsSlice.actions

export default contactDetailsSlice.reducer