import React, { useState } from 'react'
import {GridToolbar, } from '@mui/x-data-grid-premium';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Col, Row } from 'react-bootstrap';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete } from '@material-ui/icons';

const InvoiceListTable = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  // useEffect(() => {
  //     dispatch(fetchVehicle());
  // }, [])
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 70,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen(); // don't select this row after clicking
        };

        return (
          <>
            <>
              <div className="action_btn">
                {/* <span className="view_btn" onClick={onClick}><Visibility /></span> */}
                <span className="del_btn" onClick={onRemove}><Delete /></span>
              </div>
            </>
            <>
              <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                  <DialogTitle id="alert-dialog-title">
                    Delete Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this row?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className="btn-secondary" onClick={() => { handleClose(); }}>Cancel</Button>
                    <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          </>
        )

      },
    },

    { field: "Date", headerName: "Date", width: 94 },
    { field: "Transaction", headerName: "Transaction", width: 90 },
    { field: "Invoice_No", headerName: "Invoice No", width: 90 },
    { field: "Amount", headerName: "Amount", width: 90 },
    { field: "Paid_Date", headerName: "Paid Date", width: 90 },
    { field: "Paid", headerName: "Paid", width: 90 },
    { field: "Discount", headerName: "Discount", width: 90 },
    { field: "Balance", headerName: "Balance", width: 90 },
    { field: "Posted_to_AR", headerName: "Posted to A/R", width: 90 },
    { field: "Cheaque", headerName: "Chq # Order #", width: 90 },
  ];
  const rows = [
    { id: 1, Date: "05/09/2022 ", Transaction: "13", Invoice_No: "3251", Amount: "1234", Paid_Date: "111", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 2, Date: "05/09/2022 ", Transaction: "23", Invoice_No: "3251", Amount: "1234", Paid_Date: "211", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 3, Date: "05/09/2022 ", Transaction: "390", Invoice_No: "3251", Amount: "1234", Paid_Date: "101", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 4, Date: "05/09/2022 ", Transaction: "123", Invoice_No: "3251", Amount: "1234", Paid_Date: "200", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 5, Date: "05/09/2022", Transaction: "7899", Invoice_No: "3251", Amount: "1234", Paid_Date: "123", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 6, Date: "05/09/2022", Transaction: "345", Invoice_No: "3251", Amount: "1234", Paid_Date: "112", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 7, Date: "05/09/2022", Transaction: "577", Invoice_No: "3251", Amount: "1234", Paid_Date: "115", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 8, Date: "05/09/2022", Transaction: "222", Invoice_No: "3251", Amount: "1234", Paid_Date: "145", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 9, Date: "05/09/2022", Transaction: "565", Invoice_No: "3251", Amount: "1234", Paid_Date: "198", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
    { id: 10, Date: "05/09/2022", Transaction: "233", Invoice_No: "3251", Amount: "1234", Paid_Date: "221", Paid: "0.00", Discount: "252", Balance: "110", Posted_to_AR: "01/11/2022", Cheaque: "45123" },
  ];


  return (
    <>
      <div className="invoice-table " style={{ height: "500px" }}>
        <h6>List of invoice</h6>
        <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }}
       style={{ fontSize: "14px", paddingRight: "10px" }}
        />
      </div>
      <>       
          <Row>
            <Col sm={9} >
              <div className="radioButton ">
                <div className="multiple-radios" style={{width:"30%"}}>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Statement
                    </label>
                  </div>
                  <div className="form-check ">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Invoice
                    </label>
                  </div>
                </div>

                <div className="multiple-radios" style={{width:"70%"}}>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      On Screen
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      PDF
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      XLS
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Printer
                    </label>
                  </div>
                </div>

              </div>
              {/* email text area */}
              <textarea style={{width:"100%", height:"141px"}}></textarea>
            </Col>

            <Col sm={3}>
              <div className='multi-email-box'></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <fieldset className='mt-2'>
                <label className="label-heading">Check-pickup instructions</label>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col>
                <div className="account-EDI d-flex my-2 mt-2">
              <Button className="mx-2"> EDI </Button>
              <Button className="mx-2"> Email PDF </Button>
              <Button className="mx-2"> Dispatch </Button>
              <Button className="mx-2"> Adjustment </Button>
              </div>
             
            </Col>
          </Row>
      </>
    </>

  )
}

export default InvoiceListTable;



