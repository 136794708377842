import { Row, div, Form, FloatingLabel } from "react-bootstrap"

const AdditionalInfo = () => {
    return (
        <div className="additional-info">
            <p>Additional Info</p>
            <div className="additional-info-form">
            <div className="additional-section2">
            <div className="flex-container">
                <div className="flex-item">
                    <Form.Group controlId="collect">
                        <FloatingLabel controlId="floatingInput" label="Collect" >
                            <Form.Control type="text" placeholder="Collect" name="Collect" />
                        </FloatingLabel>
                    </Form.Group>
                </div>
                <div className="flex-item">
                    <Form.Group controlId="cod">
                        <FloatingLabel controlId="floatingInput" label="C.O.D" >
                            <Form.Control type="text" placeholder="C.O.D" name="C.O.D" />
                        </FloatingLabel>
                    </Form.Group>
                </div>
                <div className="flex-item">
                    <Form.Group controlId="inside delivery">
                        <FloatingLabel controlId="floatingInput" label="Inside Delivery" >
                            <Form.Control type="text" placeholder="Inside Delivery" name="Insidedeliry" />
                        </FloatingLabel>
                    </Form.Group>
                </div>
                <div className="flex-item">
                    <Form.Group controlId="blanket">
                        <FloatingLabel controlId="floatingInput" label="Blanket" >
                            <Form.Control type="text" placeholder="Blanket" name="Blanket" />
                        </FloatingLabel>
                    </Form.Group>
                </div>
                <div className="flex-item">
                    <Form.Group controlId="straps">
                        <FloatingLabel controlId="floatingInput" label="Straps" >
                            <Form.Control type="text" placeholder="Straps" name="Straps" />
                        </FloatingLabel>
                    </Form.Group>
                </div>
            </div>
            </div>
            {/* 2nd Row */}
            <div className="additional-section2">
            <div className="flex-container">
                <div className="flex-item">
                    <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">DK Plate</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">P Truck</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">DK Plate</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label"> Air Ride</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">F.D.A</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">Scale</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">CSA</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">Temp</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">Cnti.</label>
                    </div>
                </div>
                <div className="flex-item">
                <div className="form-check">
                        <input type="checkbox" id="formBasicCheckbox" className="form-check-input" />
                        <label title=""  className="form-check-label">Haz MAt</label>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    )
}
export default AdditionalInfo