import React from 'react';
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Extrainfo from "./Extrainfo";
import OrderForm from "./OrderForm";
import { GenerateOrder } from "../../../context/OrderContext";
import EmailNotification from "./EmailNotification";
import Print from "./PrintQuoteData";
import { Rnd } from 'react-rnd';

const OrderentryForm = React.memo(() => {
  return (
    <GenerateOrder>
      <div className="orderentryform">
        <Container fluid>
          <Row>
            <EmailNotification/>
            <Print />
            <Col sm={12} md={12} lg={9}>
              <OrderForm />
              </Col>
            <Col sm={12} md={12} lg={3}><Extrainfo /></Col>
          </Row>
        </Container>
      </div>
    </GenerateOrder>
  );
})
export default OrderentryForm;