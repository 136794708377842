import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Delete } from "@material-ui/icons";
import { useState } from "react";
import { Button, } from "react-bootstrap";
import { GridToolbar } from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Visibility } from "@material-ui/icons";
import { useGetInterlinerAgentQuery, useDeleteInterlinersMutation, toggleShow } from "../../../features/Interliners/InterlinersFieldsSlice";
import Spinner from "../../Spinner";
import { useDispatch } from "react-redux";



export default function InterlinersList({ getInterlinerRowData }) {
    const dispatch = useDispatch()
    const { data: interlinerList, isLoading, refetch } = useGetInterlinerAgentQuery()
    const [deleteInterliners] = useDeleteInterlinersMutation()
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = async() => {  await deleteInterliners(deleteId); refetch();setOpen(false); };
    const toggleHandler = () => { dispatch(toggleShow()) }

    if (!interlinerList && isLoading) return <><Spinner /></>;

    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (e) => {
                    getInterlinerRowData(data?.id)
                    const toggle = toggleHandler()
                    e.stopPropagation(); // don't select this row after clicking
                };
                const onRemove = (e) => {
                    setDeleteId(data.id)
                    setOpen(true)
                };

                return (
                    <>
                        <>
                            <div className="action_btn">
                                <span className="view_btn" onClick={onClick}><Visibility /></span>
                                <span className="del_btn" onClick={onRemove}><Delete /></span>
                            </div>
                        </>
                        <>
                            <div>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                        Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                                        <Button className="btn-primary" onClick={ () => { handleClose()}} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </>
                    </>
                )

            },
        },
        { field: "payNo", headerName: "Pay No", width: 160 },
        { field: "driverNumber", headerName: "Driver", width: 140 },
        { field: "companyName", headerName: "Company Name", width: 240 },
        { field: "email", headerName: "Email", width: 240 },
        { field: "primarynumber", headerName: "Primary Number", width: 200 },
        { field: "secondarynumber", headerName: "Secondary Number", width: 200 },
        { field: "switchTypename", headerName: "Switch Type", width: 200 },



    ];
    const rows = interlinerList?.data?.map((data) => {
        return {
            id: data?.interlinersId,
            payNo: data?.payNo,
            driverNumber: data?.driverNumber,
            companyName: data?.companyName,
            email: data?.email,
            primarynumber: data?.primarynumber,
            secondarynumber: data?.secondarynumber,
            switchTypename: data?.switchTypename,
        }
    })

    return (

        <>
            {interlinerList?.data?.length > 0 ? (
                <div className="user-role-table">
                    <h6>List of Interliners</h6>
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} componentsProps={{
                        toolbar: { showQuickFilter: true, },
                    }} checkboxSelection />
                </div>
            ) : (
                <div className="user-role-table">
                    <h6>List of Interliners</h6>
                    <p style={{ textAlign: "center", marginTop: "20px", }}>No data found </p>
                </div>
            )}
        </>
    );
}
