import { useState, useEffect } from "react";
import { Accordion, Form, FloatingLabel, Row, Col, Button } from "react-bootstrap";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { addRateCard, editRateCard, deleteRateCard ,useDeleteCwtRateMutation, useUpdateRateCardMutation,useLazyGetCwtRateCardDetailByIdQuery,} from "../../../features/Interliners/InterlinersFieldsSlice";




const RateCard = ({rateCardSection, interlinerRowData, getInterlinerRowData}) => {
  const ref = useRef(null);
  const [getCwtRateCardDetailById, Result] = useLazyGetCwtRateCardDetailByIdQuery()
  const getCwtRateCardDetailData = Result?.data?.data
  const [deleteCwtRateList] = useDeleteCwtRateMutation()
  const [updateRateCard] = useUpdateRateCardMutation()
  const dispatch = useDispatch()
  const [cwtStatus, setCwtStatus] = useState(0);
  const [rateCardData, setRateCardData] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const CwtstatusHandler = (status) => { setCwtStatus(status) };
  const [isUpdate, setIsUpdate] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const rateCardHandler = (e) => { setRateCardData(rateCardData => ({ ...rateCardData, [e.target.name]: e.target.value })); }
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  //const handleClose = async() => {setOpen(false); await deleteEmployee(deleteId);refetch()};

  const nameInput = useRef(null);
  const clear = () => {
    nameInput.current.value = "";
  };


  useEffect(() =>{
    setRateCardData(getCwtRateCardDetailData)
  },[getCwtRateCardDetailData])


  const addCwtratecardList = async(e) => {
    e.preventDefault();
    if (Object.keys(rateCardData).length === 0) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      getCwtRateCardDetailData?.rateCardId ? await(updateRateCard(getCwtRateCardDetailData?.rateCardId ? {...getCwtRateCardDetailData, ...rateCardData} : ""),setTimeout(function () {
        getInterlinerRowData(interlinerRowData?.interlinersId)
    }, 1000)) : dispatch(addRateCard(rateCardData));
    } 
    else {
      let editingItem = rateCardSection[editingTaskIndex];
      editingItem = rateCardData;
      dispatch(editRateCard(editingItem));
    }
    setRateCardData("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
  };



  const editCwtratecardList = (id) => {
    let newEditItem = rateCardSection.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
    setRateCardData(newEditItem)
    setEditingTaskIndex(id);
  }


  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          {!interlinerRowData?.interlinersId ? 
          editCwtratecardList(data.id) : getCwtRateCardDetailById(data.row.rateCardId);setIsShow(isShow => !isShow);}
        };
        const onRemove = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          handleClickOpen();
        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  {!interlinerRowData?.interlinersId ? 
                  <Button className="btn-primary" onClick={() => {
                   dispatch(deleteRateCard(data.id)); handleClose(); }} autoFocus> Confirm</Button>
                   :
                       <Button className="btn-primary" onClick={() => (

                        (deleteCwtRateList(data.row.rateCardId), setTimeout(function () {
                          getInterlinerRowData(interlinerRowData?.interlinersId)
                      }, 1000)),handleClose() )} autoFocus> Confirm</Button>
                       }
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              <span className="edit_btn" onClick={onClick}><Edit /></span>
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'rateType', headerName: 'Rate Type', type: 'number', width: 150 },
    { field: 'cwtMinimumRate', headerName: 'CWT MinimumRate', type: 'number', width: 180 },
    { field: 'averageWeight', headerName: 'CWT Average Weight', type: 'number', width: 180 },
    { field: 'ftlRate', headerName: 'FTL Rate', type: 'number', width: 100 },
    { field: 'rangeFrom0To500', headerName: 'Weight range(0-500)lbs', type: 'number', width: 220 },
    { field: 'rangeFrom500To1000', headerName: 'Weight range(500-1000)lbs', type: 'number', width: 220 },
    { field: 'rangeFrom1000To2000', headerName: 'Weight range(1000-2000)lbs', type: 'number', width: 220 },
    { field: 'rangeFrom2000To5000', headerName: 'Weight range(2000-5000)lbs', type: 'number', width: 220 },
    { field: 'rangeFrom5000To10000', headerName: 'Weight range(5000-10000)lbs', type: 'number', width: 220 },
    { field: 'rangeFrom10000To20000', headerName: 'Weight range(10000-20000)lbs', type: 'number', width: 220 },
    // { field: 'pallet', headerName: 'Pallet Rate Type', type: 'number', width: 150 },
    { field: 'palletMinimumRate', headerName: 'Pallet MinimumRate', type: 'number', width: 200 },
    { field: 'palletAverageWeight', headerName: 'Pallet Average Weight', type: 'number', width: 200 },
    { field: 'rangeFrom0To1', headerName: 'Pallet range(0-1)', type: 'number', width: 200 },
    { field: 'rangeFrom1To5', headerName: 'Pallet range(1-5)', type: 'number', width: 200 },
    { field: 'rangeFrom5To10', headerName: 'Pallet range(5-10)', type: 'number', width: 200 },
    { field: 'rangeFrom10To16', headerName: 'Pallet range(10-16)', type: 'number', width: 200 },
    { field: 'rangeFrom16To21', headerName: 'Pallet range(16-21)', type: 'number', width: 200 },
    { field: 'rangeFrom21To24', headerName: 'Pallet range(21-24)', type: 'number', width: 200 },
    { field: 'rangeFrom0To100', headerName: 'Pallet range(0-100)', type: 'number', width: 200 },
    { field: 'rangeFrom100To200', headerName: 'Pallet range(100-200)', type: 'number', width: 200 },
    { field: 'rangeFrom200To300', headerName: 'Pallet range(200-300)', type: 'number', width: 200 },
    { field: 'rangeFrom300To400', headerName: 'Pallet range(300-400)', type: 'number', width: 200 },
    { field: 'rangeFrom400To500', headerName: 'Pallet range(400-500)', type: 'number', width: 200 },
    { field: 'rangeFrom500To600', headerName: 'Pallet range(500-600)', type: 'number', width: 200 },

  ];
  const rows = rateCardSection?.map((data) => {
    return {
      id: data?.id,
      rateType:data?.rateType,
      cwtMinimumRate: data?.cwtMinimumRate,
      averageWeight: data?.averageWeight,
      ftlRate: data?.ftlRate,
      rangeFrom0To500: data?.rangeFrom0To500,
      rangeFrom500To1000: data?.rangeFrom500To1000,
      rangeFrom1000To2000: data?.rangeFrom1000To2000,
      rangeFrom2000To5000: data?.rangeFrom2000To5000,
      rangeFrom5000To10000: data?.rangeFrom5000To10000,
      rangeFrom10000To20000: data?.rangeFrom10000To20000,
      palletMinimumRate: data?.palletMinimumRate,
      palletAverageWeight: data?.palletAverageWeight,
      rangeFrom0To1: data?.rangeFrom0To1,
      rangeFrom1To5: data?.rangeFrom1To5,
      rangeFrom5To10: data?.rangeFrom5To10,
      rangeFrom10To16: data?.rangeFrom10To16,
      rangeFrom16To21: data?.rangeFrom16To21,
      rangeFrom21To24: data?.rangeFrom21To24,
      rangeFrom0To100:  data?.rangeFrom0To100,
      rangeFrom100To200: data?.rangeFrom100To200,
      rangeFrom200To300: data?.rangeFrom200To300,
      rangeFrom300To400: data?.rangeFrom300To400,
      rangeFrom400To500: data?.rangeFrom400To500,
      rangeFrom500To600: data?.rangeFrom500To600

    }
  }
  )
  const rowss = interlinerRowData?.getInterlinersRateCardDTOs.map((data) => {
    return {
      id: data?.interlinersId,
      rateCardId: data?.rateCardId,
      rateType:data?.rateType,
      cwtMinimumRate: data?.cwtMinimumRate,
      averageWeight: data?.averageWeight,
      ftlRate: data?.ftlRate,
      rangeFrom0To500: data?.rangeFrom0To500,
      rangeFrom500To1000: data?.rangeFrom500To1000,
      rangeFrom1000To2000: data?.rangeFrom1000To2000,
      rangeFrom2000To5000: data?.rangeFrom2000To5000,
      rangeFrom5000To10000: data?.rangeFrom5000To10000,
      rangeFrom10000To20000: data?.rangeFrom10000To20000,
      palletMinimumRate: data?.palletMinimumRate,
      palletAverageWeight: data?.palletAverageWeight,
      rangeFrom0To1: data?.rangeFrom0To1,
      rangeFrom1To5: data?.rangeFrom1To5,
      rangeFrom5To10: data?.rangeFrom5To10,
      rangeFrom10To16: data?.rangeFrom10To16,
      rangeFrom16To21: data?.rangeFrom16To21,
      rangeFrom21To24: data?.rangeFrom21To24,
      rangeFrom0To100:  data?.rangeFrom0To100,
      rangeFrom100To200: data?.rangeFrom100To200,
      rangeFrom200To300: data?.rangeFrom200To300,
      rangeFrom300To400: data?.rangeFrom300To400,
      rangeFrom400To500: data?.rangeFrom400To500,
      rangeFrom500To600: data?.rangeFrom500To600

    }
  }
  )
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Rate Card</Accordion.Header>
        <Accordion.Body>
          <div className="rate-button">
          <div className="rate-card-section d-flex">
            <p>Please select the rate card item type</p>
            <div className="ratecard-selection" style={{ marginLeft: "40px" }} onChange={e => rateCardHandler(e)} name="RateType">
              <div className="form-check form-check-inline" >
                <input className="form-check-input" type="radio" id="inlineRadio1"name="rateType" value="cwt" checked={cwtStatus === 1} onChange={e => CwtstatusHandler(1)} />
                <label className="form-check-label" htmlFor="inlineRadio1" style={{ fontSize: "15px" }}>CWT</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" id="inlineRadio2"name="rateType" value="pallet" checked={cwtStatus === 2}  onChange={e => CwtstatusHandler(2)} />
                <label className="form-check-label" htmlFor="inlineRadio2" style={{ fontSize: "15px" }}>PALLET</label>
              </div>
            </div>
         
          </div>
          <div className="section_action_btn">
                {!isUpdate ?
                  <>                   
                      <div className="add_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><AddCircle /></button></div>                    
                  </>
                  :
                  <div className="save_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><Save /></button></div>
                }
              </div>
              </div>
          {/* CWT Section */}
          {cwtStatus === 1 && <Form className="cwt_form mt-3">
            <Row className=" mb-3">
              <Col sm={4}>
                <Form.Group controlId="minimumrate">
                  <FloatingLabel controlId="floatingInput" label="CWT Minimum Rate" >
                    <Form.Control type="number" placeholder="CWT Minimum Rate" name="cwtMinimumRate" value={rateCardData?.cwtMinimumRate || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="Average Weight">
                  <FloatingLabel controlId="floatingInput" label="Average Weight" >
                    <Form.Control type="number" placeholder="Average Weight" name="averageWeight" value={rateCardData?.averageWeight || " "} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
                {/* <Form.Group controlId="minimumrate">
                  <FloatingLabel controlId="floatingInput" label="Average Weight" >
                    <Form.Control type="number" placeholder="Average Weight" name="cwtMinimumRate" value={rateCardData?.cwtMinimumRate || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group> */}
              </Col>
              <Col sm={4}>
                <Form.Group controlId="FTL Rate">
                  <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                    <Form.Control type="number" placeholder="FTL Rate" name="ftlRate" value={rateCardData?.ftlRate || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2 range_label">
              <Col sm={2}>
                <h6>Range from (0-500) </h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom0To500" value={rateCardData?.rangeFrom0To500 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range from (500-1000) </h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom500To1000" value={rateCardData?.rangeFrom500To1000 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range from (1000-2000) </h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom1000To2000" value={rateCardData?.rangeFrom1000To2000 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range from (2000-5000) </h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom2000To5000" value={rateCardData?.rangeFrom2000To5000 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range from (5000-10000) </h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom5000To10000" value={rateCardData?.rangeFrom5000To10000 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range from (10000-20000) </h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom10000To20000" value={rateCardData?.rangeFrom10000To20000 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          </Form>}



          {/* Pallet Section */}
          {cwtStatus === 2 && <Form className="cwt_form mt-3">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="PalletMinimumRate">
                  <FloatingLabel controlId="floatingInput" label="Pallet Minimum Rate" >
                    <Form.Control type="number" placeholder="Pallet Minimum Rate" name="palletMinimumRate" value={rateCardData?.palletMinimumRate || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="palletaverageweight">
                  <FloatingLabel controlId="floatingInput" label="Pallet Average Weight" >
                    <Form.Control type="number" placeholder="Pallet Average Weight" name="palletAverageWeight" value={rateCardData?.palletAverageWeight || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 range_label">
              <Col sm={2}>
                <h6>Range From (0-1)</h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom0To1" value={rateCardData?.rangeFrom0To1 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range From (1-5)</h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom1To5" value={rateCardData?.rangeFrom1To5 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range From (5-10)</h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom5To10" value={rateCardData?.rangeFrom5To10 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range From (10-16)</h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom10To16" value={rateCardData?.rangeFrom10To16 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range From (16-21)</h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom16To21" value={rateCardData?.rangeFrom16To21 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <h6>Range From (21-24)</h6>
                <Form.Group controlId="RangeFrom">
                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                    <Form.Control type="number" placeholder="Unit Rate" name="rangeFrom21To24" value={rateCardData?.rangeFrom21To24 || ""} onChange={e => rateCardHandler(e)} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <fieldset className="pb-2">
                  <label className="label-heading">Pallet OverWeight Charge Section</label>
                  <Row className="range_label">
                    <Col sm={2}>
                      <h6>Range From (0-100)</h6>
                      <Form.Group controlId="RangeFrom">
                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                          <Form.Control type="number" placeholder="Overweight Charge" name="rangeFrom0To100" value={rateCardData?.rangeFrom0To100 || ""} onChange={e => rateCardHandler(e)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <h6>Range From (100-200)</h6>
                      <Form.Group controlId="RangeFrom">
                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                          <Form.Control type="number" placeholder="Overweight Charge" name="rangeFrom100To200" value={rateCardData?.rangeFrom100To200 || ""} onChange={e => rateCardHandler(e)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <h6>Range From (200-300)</h6>
                      <Form.Group controlId="RangeFrom">
                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                          <Form.Control type="number" placeholder="Overweight Charge" name="rangeFrom200To300" value={rateCardData?.rangeFrom200To300 || ""} onChange={e => rateCardHandler(e)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <h6>Range From (300-400)</h6>
                      <Form.Group controlId="RangeFrom">
                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                          <Form.Control type="number" placeholder="Overweight Charge" name="rangeFrom300To400" value={rateCardData?.rangeFrom300To400 || ""} onChange={e => rateCardHandler(e)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <h6>Range From (400-500)</h6>
                      <Form.Group controlId="RangeFrom">
                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                          <Form.Control type="number" placeholder="Overweight Charge" name="rangeFrom400To500" value={rateCardData?.rangeFrom400To500 || ""} onChange={e => rateCardHandler(e)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <h6>Range From (500-600)</h6>
                      <Form.Group controlId="RangeFrom">
                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                          <Form.Control type="number" placeholder="Overweight Charge" name="rangeFrom500To600" value={rateCardData?.rangeFrom500To600 || ""} onChange={e => rateCardHandler(e)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
              </Col>
            </Row>
          </Form>}
          {!interlinerRowData?.interlinersId ?    
          <div className="datatable_section">
                    <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} checkboxSelection />
                  </div>
          :                   
          <div className="datatable_section">
                    <DataGrid rows={rowss} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} checkboxSelection />
                  </div>
}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default RateCard