import React from 'react';
import AccountTable from './AccountTable';
import { Col, Container, Row, Button } from "react-bootstrap";
import Wrapper from '../../Wrapper';
import InvoiceListTable from './InvoiceListTable';
import ArCourier from './ArCourier';

const AccountEnquiry = ()=>{
    return(
    <div>
         <Wrapper>
            <div className='customerlist'>
    <Container fluid>
        <Row>
            <Col sm={4}>

                    <AccountTable/>
           
            </Col>
            <Col sm={8}>
                    <ArCourier/>
                    < InvoiceListTable />
            </Col>
        </Row>
    </Container>
    </div>
</Wrapper> 
        
    </div>
    )
}
export default AccountEnquiry;
