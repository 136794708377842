import React from 'react'
import { Col } from 'react-bootstrap'

const InBound = () => {
  return (
    <Col sm={8}>
    <div className="">
      <fieldset>
        
      </fieldset>
    </div>
</Col>
  )
}

export default InBound
