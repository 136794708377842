function validatePickup(pickInfo) {
    let pickErrors = {};
    if (!pickInfo?.customer) {
      pickErrors.customer = 'Field is required';
    }
    if (!pickInfo?.street) {
      pickErrors.street = 'Field is required';
    }
    if (!pickInfo?.streetName) {
      pickErrors.streetName = 'Field is required';
    }
    // if (!pickInfo?.unit) {
    //   pickErrors.unit = 'Field is required';
    // }
    if (!pickInfo?.countryId) {
        pickErrors.countryId = 'Field is required';
      } 
      if (!pickInfo?.provineStateID) {
        pickErrors.provineStateID = 'Field is required';
      } 
    if (!pickInfo?.city) {
      pickErrors.city = 'Field is required';
    }
    if (!pickInfo?.postalCode) {
      pickErrors.postalCode = 'Field is required';
    } 
    if (!pickInfo?.contact) {
        pickErrors.contact = 'Field is required';
      }
      if (!pickInfo?.phone) {
        pickErrors.phone = 'Field is required';
      }
      // if (!pickInfo?.phone_Ext) {
      //   pickErrors.phone_Ext = 'Field is required';
      // }
      if (!pickInfo?.emailAddress) {
        pickErrors.emailAddress = 'Field is required';
      }
      // if (!pickInfo?.appointment) {
      //   pickErrors.appointment = 'Field is required';
      // }
      // if (!pickInfo?.openingTime) {
      //   pickErrors.openingTime = 'Field is required';
      // }
      // if (!pickInfo?.closeTime) {
      //   pickErrors.closeTime = 'Field is required';
      // }
    return pickErrors;
  };

  export default validatePickup