import { AddLocation } from '@material-ui/icons'
import React from 'react'
import { Button } from 'react-bootstrap'

const ButtonsLocalDipatch = () => {
    return (
        <div className="dispatchButtons mt-2">
            <div className="">
                <Button variant="outline-primary" className="btn-Maps"><AddLocation />Map</Button>
            </div>
            <div className="">
                <Button variant="outline-primary" className="btn-distance"> Distance</Button>
            </div>
            <div className="">
                <Button variant="outline-primary" className="btn-yard"> Yard</Button>
            </div>
            <div className="">
                <Button variant="outline-primary" className="btn-future"> Future</Button>
            </div>
            <div className="">
                <Button variant="outline-primary" className="btn-print"> Print Slip</Button>
            </div>
            <div className="">
                <Button variant="outline-primary" className="btn-prices">Price</Button>
            </div>
            <div className="">
                <Button variant="outline-primary" className="btn-access">Accessorial</Button>
            </div>

        </div>

    )
}

export default ButtonsLocalDipatch
