import { Form } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';

const Search = ()=>{
    return(
        <div className="search-form">
    <Form className="d-flex ml-auto" gap={3}>
    <FormControl type="search" placeholder="Search" className="me-2" aria-label="Search"  />
    <span><i className="fa fa-search" aria-hidden="true"></i></span>
      </Form>
        </div>
    );
}
export default Search;