import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { toast } from 'react-toastify';
import useCustomer from "../../../context/CustomerContext";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { addFuelCost, editFuelCost, deleteFuelCost, useGetAllFuelListQuery } from "../../../features/FuelCost/FuelCostSlice";

const FuelCostTable = () => {
    const dispatch = useDispatch();
    const [isUpdate, setIsUpdate] = useState(false);
    const [fuelCost, setFuelCost] = useState({})
    const [editingTaskIndex, setEditingTaskIndex] = useState(null);
    const { data: tableListData, refetch } = useGetAllFuelListQuery()
    const [fuelOpen, setFuelOpen] = useState(false);
    const [isDelete, setIsDelete] = useState()
    const {fuelCostLists, setTempDisplay, setFuelCostLists } = useCustomer();
    const fuelCosthandler = (e) => { setFuelCost(fuelCost => ({ ...fuelCost, [e.target.name]: e.target.value })) }
    
    const beforeEditDelete = (e) => {
        if (e.target.name === "fuelCost-confirm") {
        //   dispatch(deleteFuelCost(isDelete))
          const newArr = fuelCostLists.filter(item => item.id !== isDelete);
          setFuelCostLists(newArr);
          setFuelOpen(false)
          setFuelCost("")
        }
    }

    useEffect(() => {
        if (tableListData?.data) {
            const mappedData = tableListData?.data.map((data) => { return { id: data?.fuelCostId, effectiveDate: data?.effectiveDate, rate: data?.costPerGallon } })
            setFuelCostLists(mappedData)
        }
    }, [tableListData])

    const handleFuelClose = () => { setFuelOpen(false); };

    const deleteHandler = (data) => {
        setIsDelete(data)
        setFuelOpen(true)
    }

    const addFuelCostList = () => {
        if (Object.keys(fuelCost).length === 0) {
          toast.info("Please fill the form", {
            position: "top-center",
          })
        }
        else if (editingTaskIndex === null) {
          setTempDisplay(false)
          const id = new Date().getTime().toString()
            setFuelCost(fuelCost => {
                const updatedFuelCost = {...fuelCost, id: id};
                setFuelCostLists(fuelCostLists => [...fuelCostLists, updatedFuelCost]);
                return updatedFuelCost;
            });
        } else {
            let editingItem = fuelCostLists.find(item => item.id === editingTaskIndex);
            editingItem = fuelCost;
            const newFuelCostList = fuelCostLists.map((item, index) => {
            if (item.id === editingTaskIndex) {
                return fuelCost;
            }
            else {
                return item;
            }
        })
        setFuelCostLists(newFuelCostList);
        }
        setFuelCost("");
        setEditingTaskIndex(null);
        setIsUpdate(false)
    }

    const editFuelCostList = (id) => {
        let newEditItem = fuelCostLists.find((data) => {
          return data.id === id
        });
        setIsUpdate(true);
        setFuelCost(newEditItem)
        setEditingTaskIndex(id);
    }
    
    return(    
        <Col sm={4}>
            <fieldset className="less_load_table">
                <label className="label-heading">Fuel Cost</label>
                <Col sm={12} className="justify-content-between">
                <div className="section_action_btn">
                    {!isUpdate ?
                    <div className="add_btn"><button type="button" className="btn" onClick={e => { addFuelCostList(e); }}><AddCircle /></button></div>
                    :
                    <div className="save_btn"><button type="button" className="btn" onClick={e => { addFuelCostList(e); }}><Save /></button></div>
                    }
                </div>
                </Col>
                <>
                <Row>

                    <Row className="mt-2 mb-3">
                    <Col sm={12} className="mb-2">
                        <Form.Group controlId="Date">
                        <FloatingLabel controlId="floatingInput" label="Date" >
                            <Form.Control type="date" placeholder="Date" name="effectiveDate" onChange={e => fuelCosthandler(e)} value={fuelCost?.effectiveDate || ''} />
                        </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group controlId="ToCost">
                        <FloatingLabel controlId="floatingInput" label="Fuel Charge" >
                            <Form.Control type="number" placeholder="Fuel Charge" name="rate" onChange={e => fuelCosthandler(e)} value={fuelCost?.rate || ''} />
                        </FloatingLabel>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Col sm={12}>
                    <Row>
                        <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                            <th>Action</th>
                            <th>Date</th>
                            <th>Fuel Charge</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fuelCostLists?.map((data) => (
                            <tr key={data?.id}>
                                <td>
                                <div className="action_btn">
                                    {/* <span className="edit_btn" onClick={() => editFuelCostList(data?.id)}><Edit /></span>
                                    <span className="del_btn" onClick={() => deleteHandler(data?.id)}><Delete /></span> */}
                                </div>
                                <Dialog open={fuelOpen} onClose={handleFuelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                    Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this row?
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions >
                                    <Button className="btn-secondary" onClick={handleFuelClose}>Cancel</Button>
                                    <Button className="btn-primary" name="fuelCost-confirm" onClick={beforeEditDelete} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                                </td>
                                <td>{data?.effectiveDate}</td>
                                <td>{data?.rate}</td>
                            </tr>
                            ))}
                        </tbody>
                        </Table>
                    </Row>
                    </Col>
                </Row>
                </>
            </fieldset>
        </Col>
    )
}
export default FuelCostTable;