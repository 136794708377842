import { Button, FloatingLabel } from "react-bootstrap";
import { Form } from "react-bootstrap";
import passwordicon from '../../assets/password-icon.png';
import { useState, useEffect } from 'react';
import Background from './Background';
import FormV from './useForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Reset = () => {
  const [token,setToken]=useState('')
  const checkValidation= async()=>{    
  const verifyUrl=window.location.href.split("=")[1]
 // const tokenUrl=`https://lvmtech-tlcm-api.chetu.com/Login/VerifyToken?Token=${verifyUrl}`;
  const tokenUrl=`http://api.elitex.lvm.ai/Login/VerifyToken?Token=${verifyUrl}`;
      try {
      const result = await axios.post(tokenUrl,{
        Token:tokenUrl
      });
      if(result.data.statusCode === 400){
        setTimeout(function () {
          toast.error("Sorry your token has been expired!",{
          position: "top-center",
        })
      }, 1000);
      }
      else{
        toast.info("Please reset your password",{
          position: "top-center",
        })
      }
      setToken(result)
    } catch (error) {
     
    }
   
  
  }
  const navigate = useNavigate();
  useEffect(() =>{
    checkValidation()
    if(localStorage.getItem('userId')) navigate('/');
  }, []);
  const resetApiCall = async (values) => {
    const Token = localStorage.getItem('token')
    //const url = "https://lvmtech-tlcm-api.chetu.com/Login/ResetPassword?Newpass="+values.password+"&Token="+Token;
    const url = "http://api.elitex.lvm.ai/Login/ResetPassword?Newpass="+values.password+"&Token="+Token;
    try {
      const fetchData = await axios.post(url, {
        Newpass: values.password,
        Token:Token,
      });
      if(fetchData.data.statusCode === 200){
        setTimeout(function () {
          toast.success("Password change successfully !",{
          position: "top-center",
        })
      }, 1000);
      navigate('/login')
      }
      else{
        toast.info("Password already exist !",{
          position: "top-center",
        })
      }
    } catch (error) {
    }
  }
  const { values, errors, handleChange, handleSubmit, } = FormV(() =>resetApiCall(values), validate);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  function validate(values) {
    const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let errors = {};
    if (!reg.test(values.password)) {
      errors.password = 'Password should contain at least one Digit,one Special Character and one Uppercase';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password strength is Low';
    }
    if (!values.password2) {
      errors.password2 = 'Password is required';
    } else if (values.password2 !== values.password) {
      errors.password2 = 'Passwords do not match';
    }
    return errors;
  };
  return (
    <>
      <Background>
        <div className='login-form'>
          <div className="forgot-form-back">
            <h2>Reset Password</h2>
            <p className='text-center'>We’re open 24/7. Business doesn’t stop when the work day ends, and neither do we..</p>
            <Form className="mt-4" onSubmit={handleSubmit} noValidate>
              <Form.Group className="password" controlId="formBasicPassword">
              <FloatingLabel controlId="floatingInput" label="New Password" >
                <Form.Control placeholder="New Password" className={`input ${errors.password && 'is-danger'}`} type={passwordShown ? "text" : "password"} name="password" onChange={handleChange} value={values.password || ''} required />
                <img src={passwordicon} alt="password-icon" onClick={togglePassword} />
                {errors.password && (
                  <p className="help is-danger">{errors.password}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="password" controlId="confirmPassword">
              <FloatingLabel controlId="floatingInput" label="Confirm Password" >
                <Form.Control placeholder="Confirm password" className={`input ${errors.password2 && 'is-danger'}`} type={passwordShown2 ? "text" : "password"} name="password2" onChange={handleChange} value={values.password2 || ''} required />
                <img src={passwordicon} alt="password-icon" onClick={togglePassword2} />
                {errors.password2 && (
                  <p className="help is-danger">{errors.password2}</p>
                )}
                </FloatingLabel>
              </Form.Group>
              <div className="login-btn">
                <Button type="submit" variant="secondary"> RESET PASSWORD <i className="fa fa-arrow-right" aria-hidden="true"></i></Button>
              </div>
            </Form>
          </div>
        </div>
      </Background>
    </>
  );
}

export default Reset;
