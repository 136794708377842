import { Row, Col, Modal, } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import useOrder from "../../../context/OrderContext";
import { ToastContainer, toast } from 'react-toastify';
import { useUpdateOrderNotificationMutation } from "../../../features/order/orderSlice";
import { useForm, Controller } from 'react-hook-form';



const TagsInput = props => {
    // const [tagsSendToEmail, setTagsSendToEmail] = useState("");
    // const [tagsDocSendToEmail, setTagsDocSendToEmail] = useState(props.tagsDocSendToEmail);
    // const [tagsPhoneNumber, setTagsPhoneNumber] = useState(props.tagsPhoneNumber);

    // const removeTags = (index, type) => {
    //     switch (type) {
    //         case 'sendToEmail':
    //             setTagsSendToEmail([...tagsSendToEmail.filter((_, i) => i !== index)]);
    //             break;
    //         case 'docSendToEmail':
    //             setTagsDocSendToEmail([...tagsDocSendToEmail.filter((_, i) => i !== index)]);
    //             break;
    //         case 'phoneNumber':
    //             setTagsPhoneNumber([...tagsPhoneNumber.filter((_, i) => i !== index)]);
    //             break;
    //         default:
    //             break;
    //     }
    // };


    return (
        <Row>
            <Col sm={4}>
                <div className="tags-input">
                    <input
                        value={props.tagsSendToEmail}
                        onChange={event => props.addTags(event, 'sendToEmail')}
                        placeholder="Enter Email Address"
                        className="email-input"
                    />
                </div>
            </Col>
            <Col sm={4}>
                <div className="tags-input">
                    <input
                        value={props.tagsDocSendToEmail}
                        onChange={event => props.addTags(event, 'docSendToEmail')}
                        placeholder="Enter Email Address"
                        className="email-input"
                    />
                </div>
            </Col>
            <Col sm={4}>
                <div className="tags-input">
                    <input
                        value={props.tagsPhoneNumber}
                        onChange={event => props.addTags(event, 'phoneNumber')}
                        placeholder="Enter Phone Number"
                        className="email-input"
                    />
                </div>
            </Col>
        </Row>
    );
};

const EmailNotification = (props) => {
    // const selectedTags = tags => {
    //     setEmailTags(tags)
    //     // return tags
    // // };
    const [tagsSendToEmail, setTagsSendToEmail] = useState(null);
    const [tagsDocSendToEmail, setTagsDocSendToEmail] = useState(null);
    const [tagsPhoneNumber, setTagsPhoneNumber] = useState(null);

    
    const addTags = (event, type) => {
        if (event.target.value !== "") {
            switch (type) {
                case 'sendToEmail':
                    setTagsSendToEmail(event.target.value);
                    break;
                case 'docSendToEmail':
                    setTagsDocSendToEmail(event.target.value);
                    break;
                case 'phoneNumber':
                    setTagsPhoneNumber(event.target.value);
                    break;
                default:
                    break;
            }
            event.target.value = "";
        }
    };

    const { isShow, setIsShow, pickInfo, dropInfo, accountInfo, setEmailTags, emailHandler, setAccountInfo, setPickInfo, setDropInfo, setOrderInfo, orderNumber } = useOrder();
    const [emailValue, setEmailValue] = useState(false);
    const [consignorValue, setConsignorValue] = useState(false)
    const [consigneeValue, setConsigneeValue] = useState(false)
    const [labelAccountValue, setLabelAccountValue] = useState(false)
    const [labelConsignorValue, setLabelConsignorValue] = useState(false)
    const [labelConsigneeValue, setLabelConsigneeValue] = useState(false)
    const [smsAccountValue, setSmsAccountValue] = useState(false)
    const [smsConsignorValue, setSmsConsignorValue] = useState(false)
    const [smsConsigneeValue, setSmsConsigneeValue] = useState(false)
    const [updateOrderNotification] = useUpdateOrderNotificationMutation();
    const headAccount = accountInfo?.emailAddress;
    const consignor = pickInfo?.emailAddress;
    const consignee = dropInfo?.emailAddress;

    const [BOLValue, setBOLValue] = useState(true);
    const [labelValue, setLabelValue] = useState(true);

    const handleChange = event => {
        if (event.target.name === "headaccount") {
            if (event.target.checked) {
                setEmailValue(event.target.value);
            }
            else {
                setEmailValue(false)

            }
        }
        else if (event.target.name === "consignor") {
            if (event.target.checked) {
                setConsignorValue(event.target.value);

            }
            else {
                setConsignorValue(false)

            }
        }
        else if (event.target.name === "consignee") {
            if (event.target.checked) {
                setConsigneeValue(event.target.value);

            }
            else {
                setConsigneeValue(false)

            }
        }
        else if (event.target.name === "labelheadaccount") {
            if (event.target.checked) {
                setLabelAccountValue(event.target.value);

            }
            else {
                setLabelAccountValue(false)

            }
        }
        else if (event.target.name === "labelconsignor") {
            if (event.target.checked) {
                setLabelConsignorValue(event.target.value);

            }
            else {
                setLabelConsignorValue(false)
            }
        }
        else if (event.target.name === "labelconsignee") {
            if (event.target.checked) {
                setLabelConsigneeValue(event.target.value);

            }
            else {
                setLabelConsigneeValue(false)
            }
        }
        else if (event.target.name === "smsheadaccount") {
            if (event.target.checked) {
                setSmsAccountValue(event.target.value);

            }
            else {
                setSmsAccountValue(false)

            }
        }   
        else if (event.target.name === "smsconsignor") {
            if (event.target.checked) {
                setSmsConsignorValue(event.target.value);

            }
            else {
                setSmsConsignorValue(false)

            }
        }
        else if (event.target.name === "smsconsignee") {
            if (event.target.checked) {
                setSmsConsigneeValue(event.target.value);

            }
            else {
                setSmsConsigneeValue(false)

            }
        }
    };
    const SubmitHandler = async () => {
        // setAccountInfo("")
        // setPickInfo("")
        // setDropInfo("")
        // setOrderInfo([])
        const data = {
            orderNumber: orderNumber,
            isBOL: BOLValue,
            isLabel: labelValue,
            statusSendToHeadAccount: emailValue ? true : false,
            statusSendToConsignor: consignorValue ? true : false,
            statusSendToConsignee: consigneeValue ? true : false,
            docSendToHeadAccount: labelAccountValue ? true : false,
            docSendToConsignor: labelConsignorValue ? true : false,
            docSendToConsignee: labelConsigneeValue ? true : false,
            smsSendToHeadAccount: smsAccountValue ? true : false,
            smsSendToConsignor: smsConsignorValue ? true : false,
            smsSendToConsignee: smsConsigneeValue ? true : false,
            statusSendToEmail: !tagsSendToEmail? null : tagsSendToEmail,
            docSendToEmail: !tagsDocSendToEmail? null : tagsDocSendToEmail,
            phoneNumber: !tagsPhoneNumber? null : tagsPhoneNumber
        }
        let newData;
        if (!labelAccountValue && !labelConsignorValue && !labelConsigneeValue && !tagsDocSendToEmail) { 
            newData = {...data, isLabel: false, isBOL: false}
        } else {
            newData = data;
        }
        await updateOrderNotification(newData);
        window.location.reload(false)
        setIsShow(false)
    }
    const closeHandler = () => {
        window.location.reload(false)
        setIsShow(false)
    }
    const { register, handleSubmit } = useForm({
        defaultValues: {
            BOLLABEL: ['BOL', 'LABEL'],  // Set the default selected options here
        }
    });
    const radioHandlert = (statust) => {
        if (statust === 2) {
            setBOLValue(!BOLValue);
        } else if (statust === 3) {
            setLabelValue(!labelValue);
        }
    };


    return (
        <>
            <Button onClick={() => setIsShow(true)} variant="outline-primary" style={{ display: "none" }}>
                Email Notification
            </Button>
            <Modal
                size="lg"
                show={isShow}
                // onClick={closeHandler}
                onHide={closeHandler}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton>
                    Email Notification
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col sm={4}>
                                <div className="email-notify">
                                    <div className="email-heading">
                                        <h6>Status Notification</h6>
                                    </div>
                                    <div className="checkbox-section">
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to head account" value={headAccount} onChange={handleChange} name="headaccount" />
                                            {emailValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({accountInfo?.emailAddress})</label> : ""}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="consignor" value={consignor} onChange={handleChange} />
                                            {consignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({pickInfo?.emailAddress})</label> : ""}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="consignee" value={consignee} onChange={handleChange} />
                                            {consigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({dropInfo?.emailAddress})</label> : ""}
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="email-notify">
                                    <div className="email-heading">
                                        <h6>Document</h6>
                                    </div>
                                    <div className="fieldset-inner-email">
                                        <div className="floating-checboxemail">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="BOL" name="BOLLABEL" {...register("BOLLABEL", { required: 'Field is required' })} onChange={e => radioHandlert(2)} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">BOL</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="LABEL" name="BOLLABEL" {...register("BOLLABEL", { required: 'Field is required' })} onClick={() => radioHandlert(3)} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Label</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkbox-section">
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to head account" value={headAccount} onChange={handleChange} name="labelheadaccount" />
                                            {labelAccountValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({accountInfo?.emailAddress})</label> : ""}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="labelconsignor" value={consignor} onChange={handleChange} />
                                            {labelConsignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({pickInfo?.emailAddress})</label> : ""}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="labelconsignee" value={consignee} onChange={handleChange} />
                                            {labelConsigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({dropInfo?.emailAddress})</label> : ""}
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="email-notify">
                                    <div className="email-heading">
                                        <h6>SMS Notification</h6>
                                    </div>
                                    <div className="checkbox-section">
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to head account" value={headAccount} onChange={handleChange} name="smsheadaccount" />
                                            {smsAccountValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({accountInfo?.phone})</label> : ""}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="smsconsignor" value={consignor} onChange={handleChange} />
                                            {smsConsignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({pickInfo?.phone})</label> : ""}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="smsconsignee" value={consignee} onChange={handleChange} />
                                            {smsConsigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({dropInfo?.phone})</label> : ""}
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                            <div className="col-sm-12">
                                <TagsInput 
                                    tagsSendToEmail={tagsSendToEmail}
                                    setTagsSendToEmail={setTagsSendToEmail}
                                    tagsDocSendToEmail={tagsDocSendToEmail}
                                    setTagsDocSendToEmail={setTagsDocSendToEmail}
                                    tagsPhoneNumber={tagsPhoneNumber}
                                    setTagsPhoneNumber={setTagsPhoneNumber}
                                    addTags={addTags}
                                />
                            </div>
                        </Row>
                        <Button variant="primary" className="email-btn" type="submit" name="submit" onClick={SubmitHandler}> Submit </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>

    );
};
export default EmailNotification;
