import { Row, Col, Modal, Table, FloatingLabel } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AddCircle, Edit, Delete, Height } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar, } from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Save } from "@material-ui/icons";
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetAllServiceQuery, useCreateServiceMutation, useLazyGetServiceDetailsByIDQuery, useUpdateServiceListMutation, useDeleteServiceListMutation, useGetServiceQuery } from "../../../../../features/service/serviceDetailSlice";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';


const CreateService = () => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
  const { data: serviceList, refetch } = useGetAllServiceQuery()
  const { data: serviceD , refetch:serviceData } = useGetServiceQuery()

  const [addService] = useCreateServiceMutation()
  const [deleteServiceList] = useDeleteServiceListMutation()
  const [updateServiceList] = useUpdateServiceListMutation()
  const [getServiceDetails, Results, isLoading] = useLazyGetServiceDetailsByIDQuery()
  const serviceDetails = Results?.data?.data
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const handleClose = async () => { setOpen(false); await deleteServiceList(deleteId); refetch() };
  const [isShow, setIsShow] = useState(false);
  const [show, setShow] = useState(false)
  const [service, setService] = useState('')
  const [addServiceList, setAddServiceList] = useState([])
  const [rateTypeValue, setRateTypeValue] = useState("");
  const [cutoffChecked, setCutoffChecked] = useState(false);
  const [deliveryDay, setDeliveryDay] = useState("Same Day");
  const [serviceBookingCutoff, setServiceBookingCutoff] = useState("")
  const [deliverBy, setDeliverBy] = useState("");

  useEffect(() => {
    reset(serviceDetails)
    setService(serviceDetails)
  }, [serviceDetails])

  useEffect(() => {
    if (service) {
      setValue("description", service?.description)
      setValue("rateType", service?.rateType)
      setValue("distanceBased", service?.distanceBased)
      setValue("serviceTimeframeUnit", service?.serviceTimeframeUnit)
      setValue("serviceTimeframe", service?.serviceTimeframe)
    }
  }, [service])

  const onSubmit = async (addServiceList) => {
    const newService = {...addServiceList, serviceId: 0, cutoffTime: cutoffChecked, deliveryDay: cutoffChecked? deliveryDay: "", 
      serviceBookingCutoff: serviceBookingCutoff.toLocaleTimeString('en-GB'), 
      deliverBy: cutoffChecked? deliverBy.toLocaleTimeString('en-GB'): "", serviceTimeframe: addServiceList.serviceTimeframe || 0}
    setAddServiceList(newService);
    const addData = await addService(newService);
    if (addData?.data.statusCode === 200) {
      toast.success(addData?.data.message, {
        position: "top-center",
      })
    }
    else if (addData?.data.statusCode === 400) {
        toast.error(addData?.data.message, {
          position: "top-center",
        })
    }
    
    reset({ description: '', rateType: "", distanceBased: "", serviceTimeframe: "", serviceTimeframeUnit: "" });
    setCutoffChecked(false);
    setDeliveryDay("Same Day");
    setServiceBookingCutoff("")
    setDeliverBy("");
    setRateTypeValue("Select")
    refetch()
    serviceData()
  }


  const onUpdate = async (addServiceList) => {
    setAddServiceList(addServiceList)
    const updateData = await updateServiceList(addServiceList);
    setShow(false)
    refetch()
    if (updateData?.data.statusCode === 200) {
      toast.success("Data Updated Sccessfully !", {
        position: "top-center",
      })
    }
    reset({ description: '', rateType: "", distanceBased: "", serviceTimeframe: "", serviceTimeframeUnit: "" });
    serviceData()
  }

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          getServiceDetails(data.row.id)
          setShow(true)

        };
        const onRemove = (e) => {
          e.stopPropagation();
          setDeleteId(data?.id)
          setOpen(true);
        };

        return (
          <>
            <>
              <div className="action_btn">
                {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
                <span className="del_btn" onClick={onRemove}><Delete /></span>
              </div>
            </>
            <>
              <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                  <DialogTitle id="alert-dialog-title">
                    Delete Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this row?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                    <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          </>
        )

      },
    },
    { field: "description", headerName: "Service Type", width: 220 },
    { field: "rateType", headerName: "Rate Type", width: 260 },
    // { field: "distanceBased", headerName: "Distance Based", width: 220 },
    { field: "serviceTimeframeUnit", headerName: "Service Booking Cutoff", width: 220 },
    { field: "serviceTimeframe", headerName: "Service Time Frame", width: 220 },
    { field: "cutoff", headerName: "Cutoff", width: 150 },
    { field: "deliverBy", headerName: "Deliver By", width: 180 },
  ];
  const rows =
    (serviceList?.data || []).map((data) => {
      return {
        id: data?.serviceId,
        description: data?.description,
        rateType: data?.rateType,
        distanceBased: data?.distanceBased,
        serviceTimeframe: data?.serviceTimeframe || "",
        serviceTimeframeUnit: data?.serviceBookingCutoff,
        cutoff: data?.deliveryDay,
        deliverBy: data?.deliverBy,
      }
    })

  return (
    <>
      <Button onClick={() => setIsShow(true)} variant="primary" style={{ width: "20%" }}>
        Create Service
      </Button>
      <Modal
        size="lg"
        show={isShow}
        onHide={() => setIsShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
        id="product-desp"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            {" "}
            Add Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col >
              <div className="form-floating">
                <Form.Control type="text" placeholder="Service Type" name="description" {...register('description', { required: 'Field is required' })} />
                <label htmlFor="Service Type">Service Type</label>
              </div>
              <p className="error-message">{errors.description?.message}</p>
            </Col>
            <Col >
              <div className="form-floating">
                <Form.Select aria-label="Floating label select example" name="rateType" {...register('rateType')} onClick={(e) => setRateTypeValue(e.target.value)}>
                  <option>Select</option>
                  <option value="LOCAL">Local</option>
                  <option value="CWT">CWT</option>
                  <option value="PALLET">PALLET</option>
                  <option value="DISTANCE-BASED">DISTANCE-BASED</option>
                </Form.Select>
                <label htmlFor="accessoial">Rate Type</label>
              </div>
              <p className="error-message">{errors.rateType?.message}</p>
            </Col>
            {/* <Col >
              <div className="form-floating">
                <Form.Select aria-label="Floating label select example" name="distanceBased" {...register('distanceBased')}>
                  <option>Select</option>
                  <option value="true">YES</option>
                  <option value="false">NO</option>
                </Form.Select>
                <label htmlFor="accessoial">Distance-based</label>
              </div>
              <p className="error-message">{errors.distanceBased?.message}</p>
            </Col> */}
            <Col >
              <div className="form-floating">
                {/* <Form.Select aria-label="Floating label select example" name="serviceTimeframeUnit" {...register('serviceTimeframeUnit')}>
                  <option value="">Select</option>
                  <option value="H">Hourly</option>
                  <option value="D">Daily</option>
                </Form.Select>
                <label htmlFor="accessoial">Select Service Time Frame</label> */}
                <TimePickerComponent format="HH:mm:ss" placeholder="Service booking cutoff" name="serviceTimeframeUnit" {...register('serviceTimeframeUnit')}onChange={(e) => setServiceBookingCutoff(e.target.value)}/>
              </div>
              <p className="error-message">{errors.serviceTimeframeUnit?.message}</p>
            </Col>
            { rateTypeValue === "LOCAL" &&
            <>
            <Col sm={1}>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" name="cutoffTime" 
                        onClick={() => {setCutoffChecked(!cutoffChecked)}} checked={cutoffChecked} />
                    <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Cutoff Time</label>
                </div>
            </Col>
            {cutoffChecked? 
              <> <Col sm={1}>
                <div class="field radio_field">
                  <label className="form-check-label"><input onClick={() => setDeliveryDay("Same Day")} className="form-check-input" type="radio" name="deliverBy" defaultChecked value="1"/>
                  Same Day</label>
                  <label className="form-check-label"><input onClick={() => setDeliveryDay("Next Day")} className="form-check-input" type="radio" name="deliverBy" value="2" />
                  Next Day</label>
                </div>
              </Col>
              <Col>
                <TimePickerComponent format="HH:mm:ss" placeholder="Deliver by" onChange={(e) => setDeliverBy(e.target.value)}/>
              </Col>  </> :
              <Col >
                <div className="form-floating">
                  <Form.Control type="Number" placeholder="Service TimeFrame" name="serviceTimeframe" {...register('serviceTimeframe')} />
                  <label htmlFor="Service TimeFrame">Service Time Frame</label>
                </div>
                <p className="error-message">{errors.serviceTimeframe?.message}</p>
              </Col> } </>}
            <Col sm={1}>
              {!show ?
                <div className="section_action_btn">
                  <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)} ><AddCircle /> Add</button></div>
                </div>
                :
                <div className="section_action_btn">
                  <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(onUpdate)} ><Save /> Save</button></div>
                </div>
              }
            </Col>
          </Row>
          <div className="user-role-table" style={{ height: "500px" }}>
            <h6>List of history</h6>
            <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateService;
