import { Container } from '@mui/system'
import React from 'react'
import Wrapper from '../../Wrapper'
import { Dialog, DialogActions, } from "@mui/material";
import { Delete, Visibility } from "@material-ui/icons";
import { useState } from "react";
import { Button, Col, Row } from 'react-bootstrap';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const OpenOrderTable = () => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };
    const columns = [
        {
            field: "actions",
            headerName: "Action",
            sortable: false,
            width: 70,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                };
                const onRemove = (e) => {
                    e.stopPropagation();
                    handleClickOpen(); // don't select this row after clicking
                };

                return (
                    <>
                        <div className="action_btn">
                            <span className="view_btn" onClick={onClick}><Visibility /></span>

                        </div>
                        <>
                            <div>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogActions>
                                        <Button className="btn-secondary" onClick={() => { handleClose(); }}>Cancel</Button>
                                        <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </>
                    </>
                )

            },
        },
        { field: "Order", headerName: "Order", width: 60 },
        { field: "Ready_Time", headerName: "Ready Time", width: 60 },
        { field: "Customer", headerName: "Customer", width: 60 },
        { field: "Service", headerName: "Service", width: 60 },
        { field: "Pr", headerName: "Pr.", width: 60 },
        { field: "City", headerName: "City", width: 60 },
        { field: "Pcs", headerName: "Pcs", width: 60 },
        { field: "Wgt", headerName: "Wgt", width: 60 },
        { field: "Feet", headerName: "Feet", width: 60 },
        { field: "P_U", headerName: "P/U", width: 60 },
        { field: "Rk_Pick", headerName: "Rk/Pick", width: 60 },
        { field: "Rm_Pick", headerName: "Rm-Pick", width: 60 },
        { field: "P", headerName: "P", width: 60 },
        { field: "Pr", headerName: "pr.", width: 60 },
        { field: "Reciever_City", headerName: "Reciever City", width: 60 },
        { field: "L_Sheet", headerName: "L-Sheet", width: 60 },
        { field: "Ro_Loc", headerName: "Ro/Loc", width: 60 },
        { field: "H", headerName: "H", width: 60 },
        { field: "E", headerName: "E", width: 60 },
        { field: "T", headerName: "T", width: 60 },
        { field: "V", headerName: "V", width: 60 },
        { field: "Trip", headerName: "Trip", width: 60 },



    ];
    const rows = [
        { id: 1, Order: "Abc", Ready_Time: "05/09/2022 11:24", Customer: "13", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "111", Pcs: "TRACTOR", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 2, Order: "Akjdhd", Ready_Time: "05/09/2022 11:24", Customer: "23", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "211", Pcs: "FLAT BED", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 3, Order: "Manaj", Ready_Time: "05/09/2022 11:24", Customer: "385", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "101", Pcs: "TRUCK", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 4, Order: "Boll", Ready_Time: "05/09/2022 11:24", Customer: "123", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "200", Pcs: "FWD", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 5, Order: "Pews", Ready_Time: "05/09/2022 11:24", Customer: "7899", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "123", Pcs: "CAR", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 6, Order: "Aqw", Ready_Time: "05/09/2022 11:24", Customer: "345", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "112", Pcs: "CAN", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 7, Order: "Ghwww", Ready_Time: "05/09/2022 11:24", Customer: "577", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "115", Pcs: "WAREHOUSE", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 8, Order: "Sdfdf", Ready_Time: "05/09/2022 11:24", Customer: "222", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "145", Pcs: "VAN", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 9, Order: "Aqwde", Ready_Time: "05/09/2022 11:24", Customer: "565", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "198", Pcs: "XPD", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
        { id: 10, Order: "Adjkfk", Ready_Time: "05/09/2022 11:24", Customer: "233", Service: "ADEW/SDEF/260", Pr: "WSADWEEEEEE1234566", City: "221", Pcs: "VAN", Wgt: "20", Feet: "0", P_U: "60", Rk_Pick: "0", Rm_Pick: "60", P: "0", Pr: "ON", Reciever_City: "Kingston", L_Sheet: "", Ro_Loc: "", H: "", E: "", T: "", V: "", Trip: "" },
    ];
    return (
        <div className="user-role-table" style={{height:"600px"}}>
            <h6>Open Order</h6>
            <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
        </div>
    )
}

export default OpenOrderTable
