import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";

const initialState = {
    list: []
};

export const distanceFuelApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updateDistanceFuelData: builder.mutation({
            query(data) {
                const {distanceFuelID, ...body} = data
                return {
                    url: `Customer/EditFuelDistanceBasedFuelSection?DistanceFuelID=${distanceFuelID}`,
                  method: "POST",
                  body
                };
              },
          }),
          deleteDistanceFuelData: builder.mutation({
            query : (distanceFuelID) => ({
              url: `Customer/DeleteFuelDistanceBasedFuelSection?DistanceFuelID=${distanceFuelID}`,
              method: "POST",
          }),
          }),
          addFuelDistanceAfterEdit: builder.mutation({
            query : (body) => ({
              url: `Customer/AddCustomerFuelDistanceAfterEdit`,
              method: "POST",
              body,
          }),
          })
    })
})

export const { useUpdateDistanceFuelDataMutation, useDeleteDistanceFuelDataMutation ,useAddFuelDistanceAfterEditMutation} = apiSlice



const distanceFuelSlice = createSlice({
    name: "distanceFuel",
    initialState,
    reducers: {
        addDistanceFuel: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteDistanceFuel: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editDistanceFuel : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            return { ...state, list: currentLists };
        },
        resetDistanceFuel: () => initialState,
    },
})

export const { addDistanceFuel, editDistanceFuel, deleteDistanceFuel, resetDistanceFuel } = distanceFuelSlice.actions

export default distanceFuelSlice.reducer