import { apiSlice } from "../../../app/services/authServices";

export const companyProfileApiSlice = apiSlice.injectEndpoints({
    tagTypes: ["CompanyProfile"],
    endpoints: builder => ({
        createCompanyProfile: builder.mutation({
            query: (DTO) => ({
                url: 'Admin/CreateCompanyProfile',
                method: 'POST',
                body: DTO,
                providesTags: ['CompanyProfile'],
            }),
        }),
        getAllCompanyProfiles: builder.query({
            query: () => ({
                url: 'Admin/GetListofCompanyProfiles',
                method: 'GET',
                providesTags: ['CompanyProfile'],
            }),
        }),
    }),
})

export const { useCreateCompanyProfileMutation, useGetAllCompanyProfilesQuery } = companyProfileApiSlice;
