import Wrapper from "../Wrapper";
 const Dashboard = ()=>{
     return (
         <>
        <Wrapper>
        <div className="dashboard-section">
  <h1>Coming soon<span className="dot">.</span></h1>
 </div>

        </Wrapper>
         </>
     );
 }
 export default Dashboard;